import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
export const ForgotPasswordStoreModel = types
  .model("ForgotPasswordStore")
  .props({
    email : ""
    // const [email, setEmail] = useState("")

  })
  .actions(withSetPropAction)
  .views((self) => ({
    get emailValidationError() {
      if (self.email?.length === 0) return "This field can't be blank"
      return ""
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setEmail(value: string) {
      self.email = value.toLocaleLowerCase().trim()
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface ForgotPasswordStore extends Instance<typeof ForgotPasswordStoreModel> {}
export interface ForgotPasswordStoreSnapshotOut extends SnapshotOut<typeof ForgotPasswordStoreModel> {}
export interface ForgotPasswordStoreSnapshotIn extends SnapshotIn<typeof ForgotPasswordStoreModel> {}
export const createForgotPasswordStoreDefaultModel = () => types.optional(ForgotPasswordStoreModel, {})
