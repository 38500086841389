import * as Linking from 'expo-linking';

const config = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      // Define your app screens and their corresponding deep link paths
      // Example:
      Welcome: 'Welcome',
      About: 'About',
      Home: 'Home',
    },
  },
};

export default config;
