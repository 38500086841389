import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { Keyboard, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../navigators"
import { AutoImage, Button, Screen, Text } from "../../../components"
import { Box, HStack, Image, Pressable, VStack, useBreakpointValue } from "native-base"
import { useNavigation } from "@react-navigation/native"
import { colors, typography } from "../../../theme"
import { Ionicons } from "@expo/vector-icons"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

//imports for mobile----->
import Constants from 'expo-constants';

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const PasswordChangeStatusScreen: FC<StackScreenProps<AppStackScreenProps, "PasswordChangeStatus">> = observer(function PasswordChangeStatusScreen() {

  // passwordStatus true means password changed successfully
  // passwordStatus false means password change failed 
  const [passwordStatus, setPasswordStatus] = useState(true);

  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  const SecondaryButton = () => {
    return(
      <Button
        text="Back to login"
        preset="default"
        onPress={() =>{navigation.navigate("Signin")}}          
        style={[{ backgroundColor: colors.palette.neutral100}, { borderRadius: 5, borderColor: colors.palette.primary300 }, { width: "100%" }]}
        pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
        textStyle={[{ fontSize: 14 }, { color: colors.palette.primary300 }]}
        pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
      />
    )
  }

  const WebCode =()=>{
    return(
  <Screen style={$root} preset="scroll">
      <HStack 
        w="100%" height="100vh"
        maxWidth={1440} alignSelf="center"
        style={{
          shadowOffset: {width: 0, height: 0},  
          shadowColor: '#171717',  
          shadowOpacity: 0.2,  
          shadowRadius: 3
        }}
      >
      <VStack 
          w={{base: '100%', md: '45%', lg: '35%', xl:"30%"}} space={5} p={30}
          alignItems="left"
        > 
        {/* reset password background */}    
        <AutoImage 
            source={passwordStatus ? require("../../../../assets/images/sign_in_sign_up/reset_password.png") : require("../../../../assets/images/sign_in_sign_up/pass_change_failed.png")}
            maxHeight={400}
            style={{
              margin: -30,
              height: useBreakpointValue({base: '50%',md:"35%"}), width: '120%',
              }}
              resizeMode='stretch'
          />
          <Box style={{position:"absolute",top:30, left:30 }}>
            <Pressable onPress={()=>navigation.navigate("Home")}>
              <AutoImage 
                source={require("../../../../assets/images/color_logo.png")}
                maxHeight={39}
                style={{
                  // position:"absolute",
                  // top:30, left:30
                }}
              />
            </Pressable>
          </Box>
          
          <VStack space={2} mt={20}>
            <Text
              style={{
                color:colors.palette.neutral800, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24,
                textAlign: 'center'
              }}
            >{passwordStatus ? ' Password Changed!' : ' Password Changing failed!'}</Text>
            <Text
              style={{
                color:colors.palette.neutral400, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24,
                textAlign: 'center'
              }}
            >{passwordStatus ? 'Your password has been changed successfully.' : 'There s a temporary problem with the service, please try again later.'}</Text> 
            <Button
              text={ passwordStatus ? "Back to login" : "Try Again"}
              preset="default"
              onPress={() =>{passwordStatus ? navigation.navigate("Signin") : navigation.navigate("ResetPassword")}}          
              style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
              pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
              textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
            /> 
          { passwordStatus != true && <SecondaryButton/>}
          </VStack> 
        </VStack>
      <AutoImage 
        source={require("../../../../assets/images/sign_in_sign_up/sign_up_img.png")}
        maxHeight={600} style={{height:"100%", width:"75%"}}                   
      />              
    </HStack> 
  </Screen>
    )
  }
  
  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
    </Screen>
    )
  }
  
  
  return (
  <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
      // code for web------------------------>
    default : 
    <Screen style={$root} preset="scroll">
      <HStack 
        w="100%" height="100vh"
        maxWidth={1440} alignSelf="center"
        style={{
          shadowOffset: {width: 0, height: 0},  
          shadowColor: '#171717',  
          shadowOpacity: 0.2,  
          shadowRadius: 3
        }}
      >
      <VStack 
          w={{base: '100%', md: '45%', lg: '35%', xl:"30%"}} space={5} p={30}
          alignItems="left"
        > 
        {/* reset password background */}    
        <AutoImage 
            source={passwordStatus ? require("../../../../assets/images/sign_in_sign_up/reset_password.png") : require("../../../../assets/images/sign_in_sign_up/pass_change_failed.png")}
            maxHeight={400}
            style={{
              margin: -30,
              height: useBreakpointValue({base: '50%',md:"35%"}), width: '120%',
              }}
              resizeMode='stretch'
          />
          <Box style={{position:"absolute",top:30, left:30 }}>
            <Pressable onPress={()=>navigation.navigate("Home")}>
              <AutoImage 
                source={require("../../../../assets/images/color_logo.png")}
                maxHeight={39}
                style={{
                  // position:"absolute",
                  // top:30, left:30
                }}
              />
            </Pressable>
          </Box>
          
          <VStack space={2} mt={20}>
            <Text
              style={{
                color:colors.palette.neutral800, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24,
                textAlign: 'center'
              }}
            >{passwordStatus ? ' Password Changed!' : ' Password Changing failed!'}</Text>
            <Text
              style={{
                color:colors.palette.neutral400, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24,
                textAlign: 'center'
              }}
            >{passwordStatus ? 'Your password has been changed successfully.' : 'There s a temporary problem with the service, please try again later.'}</Text> 
            <Button
              text={ passwordStatus ? "Back to login" : "Try Again"}
              preset="default"
              onPress={() =>{passwordStatus ? navigation.navigate("Signin") : navigation.navigate("ResetPassword")}}          
              style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
              pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
              textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
            /> 
          { passwordStatus != true && <SecondaryButton/>}
          </VStack> 
        </VStack>
      <AutoImage 
        source={require("../../../../assets/images/sign_in_sign_up/sign_up_img.png")}
        maxHeight={600} style={{height:"100%", width:"75%"}}                   
      />              
    </HStack> 
    </Screen>, //code for Mobile devices

      // code for mobile------------------------>
    native:
      <KeyboardAvoidingView behavior={'padding'} style={$root}> 
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>   
          <Screen style={[$root,{marginTop: Constants.statusBarHeight}]}>
            {/* reset password background */}
            <Image 
              source={passwordStatus ? require("../../../../assets/images/sign_in_sign_up/reset_password.png") : require("../../../../assets/images/sign_in_sign_up/pass_change_failed.png")}
              maxHeight={250}
              alt='image'
              style={{
                // margin: -30,
                height: '50%', width: '100%',
              }}
              resizeMode='stretch'
            />
            {/* go back section */}
            <Box style={{position:"absolute", top:2, left: 5 }} minH={100} >
              <HStack space={3} p={5} alignItems={'center'}>
                <Pressable onPress={() => navigation.goBack()}>
                  <Ionicons name="arrow-back" alt="icon" size={30} color={'black'}/>
                </Pressable>
                <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.bold,
                    fontSize: 16,
                    lineHeight: 19,
                    color: 'black',
                  }}
                  >
                    {/* {sellerName} */}
                    Back
                </Text>
              </HStack>
            </Box>
              
            <Box style={{position:"absolute", top:60, left:'33%' }} w='100%' pb={3}>
              <Pressable onPress={()=>navigation.navigate("Home")}>
                <Image 
                  source={require("../../../../assets/images/color_logo.png")}
                  maxHeight={39} 
                  alt="image"
                  style={{ 
                    height: 39,
                    width: 131
                    // position:"absolute",
                    // top:30, left:30
                  }}
                  resizeMode="contain"
                />
              </Pressable>
            </Box>
            {/* <VStack w={'100%'} h="100%" space={3} p={30} alignItems="flex-start" bg='white'> 
            </VStack> */}
            <VStack space={3} mt={10} p={30} bg='white'>
            <Text
              style={{
                color:colors.palette.neutral800, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24,
                textAlign: 'center'
              }}
            >{passwordStatus ? ' Password Changed!' : ' Password Changing failed!'}</Text>
            <Text
              style={{
                color:colors.palette.neutral400, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24,
                textAlign: 'center'
              }}
            >{passwordStatus ? 'Your password has been changed successfully.' : 'There s a temporary problem with the service, please try again later.'}</Text> 
            <Button
              text={ passwordStatus ? "Back to login" : "Try Again"}
              preset="default"
              onPress={() =>{passwordStatus ? navigation.navigate("Signin") : navigation.navigate("ResetPassword")}}          
              style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
              pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
              textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
            /> 
          { passwordStatus != true && <SecondaryButton/>}
          </VStack> 

          </Screen>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 

const $root: ViewStyle = {
  flex: 1,
}
