import React, { FC, useEffect, useState, useCallback, useContext, useRef } from "react"
import { observer } from "mobx-react-lite"
import { Platform, ViewStyle} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Icon, Screen } from "../../components"
import { Alert, Box, FormControl, HStack, Image, Input, Modal, Pressable, ScrollView, Stack, VStack, useBreakpointValue, Text } from "native-base"
import { AntDesign, Fontisto, Ionicons, SimpleLineIcons } from "@expo/vector-icons"
import { colors, typography } from "../../theme"
import { useNavigation } from "@react-navigation/native"
import { PageLayout } from "../../components/PageLayout"
import { InputField } from "../../components/InputField"
import DatePicker from 'react-native-modern-datepicker';
import { Keyboard } from "react-native"
import Dropzone, {useDropzone} from 'react-dropzone'
import { api } from "../../services/api"
import { ProductApi } from "../../services/api/apiFatching/product-api"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"
import { AlertContext } from "../../components/AlertContext"

// @ts-ignore
export const AddNewProductScreen: FC<StackScreenProps<AppStackScreenProps, "AddNewProduct">> = observer(function AddNewProductScreen() {
  // Pull in one of our MST stores
  const { 
    authenticationStore:{
      key, user, isAuthenticated, fetchNewroductRequests
    },
    AddProductStore: { 
      productName,company,category,unitMRP,offerPrice,offer,quantity,manufacturingDate,
      expiry,description,advanceDescription,
      setProductName,setCompany,setCategory,setUnitMRP,setOfferPrice,setOffer,setQuantity,setManufacturingDate,
      setExpiry,setDescription,setAdvanceDescription,
      productNameValidationError,companyValidationError,categoryValidationError,
      unitMRPValidationError,offerPriceValidationError,offerValidationError,quantityValidationError,
      manufacturingDateValidationError,expiryValidationError, 
      } } = useStores()
  
  const [enableShift_80,setEnableShift_80] = useState(50)
  const [enableShift_250,setEnableShift_250] = useState(50)

  const productHeadingBreakpoint = useBreakpointValue({base: 20 ,md:35, lg: 40});
  const headingBreakpoint = useBreakpointValue({base: 22,md: 20,lg: 24});
  const submitButtonBreakpoint = useBreakpointValue({base:"41%", sm: '31%'});
  const uploadImageWidthHeightBreakpoint = useBreakpointValue({base:80,sm: 90,md:100});

  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailError, setThumbnailError] = useState(false);
  const [filesError, setFilesError] = useState(false);

  const [openManufacturingDatePicker, setOpenManufacturingDatePicker] = useState(false)
  const [openExpiryDatePicker, setOpenExpiryDatePicker] = useState(false)
  const [modalVisible, setModalVisible] = React.useState(false);

  const [isKeyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    if(user.seller==null){
      navigation.navigate("GeneralSettings")
    }

    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
      setKeyboardOpen(true);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', (e) => {
      setKeyboardOpen(false);
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  const emptyFields = () => {
    setProductName('')
    setCompany('')
    setCategory('')
    setUnitMRP('')
    setOfferPrice('')
    setOffer('')
    setQuantity('')
    setManufacturingDate('')
    setExpiry('')
    setDescription('')
    setAdvanceDescription('')
  }

  useEffect(() => {
    emptyFields();
  },[])

  useEffect(() => {
    setFiles(files);
  },[files])

  useEffect(() => {
    setThumbnail(thumbnail);
  },[thumbnail])
  
  const { setOverlayVisible } = useContext(OverlayContext);
  const { showAlert } = useContext(AlertContext);
  const [errors, setErrors] = useState({})

  const submitAddProductRequest = async () => { 
    console.log("Form Submission Starting...0");
    try {
      console.log("Form Submission Starting...");
      const formData = new FormData();
      formData.append('seller', user.seller.id);
      formData.append('name', productName);
      formData.append('short_description', description);
      formData.append('long_description', advanceDescription);
      formData.append('thumbnail', thumbnail[0]);
      formData.append('brand', company);
      formData.append('category', category);
      formData.append('price', unitMRP);
      formData.append('offer_price', offerPrice);
      formData.append('manufacturing_date', manufacturingDate);
      formData.append('expiry_date', expiry);
      formData.append('quantity', quantity);

      files.forEach((file) => {
        formData.append('uploaded_images', file);
      });

      console.log("Form Submission Starting-1...");
      const productApi = new ProductApi(api);
      productApi.addNewProductAndOffer(formData, setOverlayVisible, showAlert)
      .then((response:any)=>{ 
        console.log(response)
        if(response.kind='ok'){
          console.log(response)
          navigation.navigate('SellerProfile', {"sellerId":user.seller.id});
        }
        else{
          setErrors({...response.data})
        }
      })      

    } catch (error) {
      console.log(error);
    }    
  }
  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const companyInput = useRef<any>()
  const categoryInput = useRef<any>()
  const unitMRPInput = useRef<any>()
  const offerPriceInput = useRef<any>()
  const offerInput = useRef<any>()
  const quantityInput = useRef<any>()
  const manufacturingDateInput = useRef<any>()
  const expiryInput = useRef<any>()
  const descriptionInput = useRef<any>()
  const advanceDescriptionInput = useRef<any>()

  // validation error ----->
  const productNameError = isSubmitted ? productNameValidationError : ""
  const companyError = isSubmitted ? companyValidationError : ""
  const categoryError = isSubmitted ? categoryValidationError : ""
  const unitMRPError = isSubmitted ? unitMRPValidationError : ""
  const offerPriceError = isSubmitted ? offerPriceValidationError : ""
  const offerError = isSubmitted ? offerValidationError : ""
  const quantityError = isSubmitted ? quantityValidationError : ""
  const manufacturingDateError = isSubmitted ? manufacturingDateValidationError : ""
  const expiryError = isSubmitted ? expiryValidationError : ""

  const filesValidationError = () =>{
    if (files?.length === 0) return "Please attach product images"
    return ""
  }

  const thumbnailValidationError = () =>{
    if (thumbnail?.length === 0) return "Please attach a thumbnail image"
    return ""
  }

  function submitForm() {
    console.log("Form Submitted");
    setIsSubmitted(true)

    if (manufacturingDate && expiry){
      console.log((new Date(expiry)) <= (new Date(manufacturingDate)))
      if((new Date(expiry)) <= (new Date(manufacturingDate))){
          showAlert("Error","Expiry date cannot be earlier than the manufacturing date.")
          return
      }

      if((new Date(manufacturingDate)) > (new Date())){
          showAlert("Error","Manufacturing date cannot be in the future.")
          return
      }
      
      if((new Date(expiry)) < (new Date())){
          showAlert("Error","Expiry date cannot be in the past.")
          return
      }
    }

    //if there is something returned form validation error then return from here and do not call login api
    if (thumbnailValidationError()) return setThumbnailError(true)
    console.log("check-1");
    if (filesValidationError()) return setFilesError(true)
    console.log("check-2");
    if (productNameValidationError) return 
    console.log("check-3");
    if (companyValidationError) return 
    console.log("check-4");
    if (categoryValidationError) return 
    console.log("check-5");
    if (unitMRPValidationError) return 
    console.log("check-6");
    if (offerPriceValidationError) return 
    console.log("check-7");
    // if (offerValidationError) return 
    console.log("check-8");
    if (quantityValidationError) return 
    console.log("check-9");
    if (manufacturingDateValidationError) return 
    console.log("check-10");
    if (expiryValidationError) return 
    console.log("check-11");

    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token.
    console.log("Form Submitted-12");
    submitAddProductRequest();
    
    console.log("check-13");

    setIsSubmitted(false);
    emptyFields();
    
    console.log("check-14");
  }

  // successfully added product modal component------>start
    const SuccessfullyAddedProduct = ({modalVisible}) => {
      const initialRef = React.useRef(null);
      const finalRef = React.useRef(null);
    
      return(
        <>
          <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)} initialFocusRef={initialRef} finalFocusRef={finalRef}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Body style={{alignItems: 'center'}}>
                <Image
                    source={require(`../../../assets/images/add_new_product/success_icon.png`)}
                    maxHeight={80}
                    style={{ width: 90, height: 90}}
                    resizeMode="contain" alt="image"
                  />
                <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.semiBold,
                    fontSize: 18,
                    lineHeight: 24,
                    color: colors.palette.neutral700,
                    textAlign: 'center'
                  }}
                  >
                    You have successfully added your product in the list!
                </Text>
                {/* continue button */}
                <Button
                  text='Continue'
                  preset="default"
                  onPress={() => {setModalVisible(false);}}
                  style={{
                    backgroundColor: colors.palette.primary600,
                    borderRadius: 5,
                    width: "100%",
                    marginTop: 15, 
                  }}
                  pressedStyle={{
                    backgroundColor: colors.palette.neutral100,
                    borderRadius: 5,
                    borderWidth: 0,
                
                  }}
                  textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                  pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                />
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </>
      )
    }
  // successfully added product modal component------>ends
  return (
  <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
       default :     
        <Screen style={$root} preset="scroll">
          <PageLayout  variant={isAuthenticated?1:0}>
            <Stack w="100%" mb={100}>
              {/* Parent VStack */}
              <VStack space={8} ml={{base: 10, lg: 20}} mr={{base: 10, lg: 20}}  mt={5}>
                {/* go back */}
                <VStack>
                  <HStack alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                    </Pressable>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: productHeadingBreakpoint,
                        lineHeight: 48,
                        color: colors.palette.neutral700,
                      }}
                      >
                        Add New Product
                    </Text>
                  </HStack>
    
                  <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.normal,
                    fontSize: 16,
                    lineHeight: 24,
                    color: colors.palette.neutral300,
                  }}
                  >
                   Product you are adding will be reviewed by our support team and a valid product will be added to our master product list that will be available for all other users and you.
                  </Text>
                </VStack>
              
                <VStack space={5}>
                  {errors && Object.keys(errors)?.length>0 && Object.keys(errors).map(key =>{
                    return <Text
                      style={{
                        color:'red', 
                        fontFamily:typography.fonts.poppins.normal,
                        fontSize:16,
                        lineHeight:24
                      }}
                    >{`${key}: ${errors[key]}`}</Text> 
                  })}
                </VStack>
                {/* Input fields */}
                <VStack space={5}>
                  {/* General section inputs */}
                  <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.semiBold,
                    fontSize: headingBreakpoint,
                    lineHeight: 28.8,
                    color: colors.palette.neutral700,
                  }}
                  >
                    General
                  </Text>
                  <Stack space={{base: 3, md: 3}}>
                    <Stack space={{base: 3, md: 0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                      <Box w={{base: '100%', md: '31%'}}>
                        <InputField
                          key="Product Name"
                          value={productName}              
                          onChangeText={(value) => setProductName(value)}
                          label="Product Name*"
                          placeholder="Abel 40mg Tablet"
                          helper={productNameError}
                          status={productNameError ? "error" : undefined}
                          onSubmitEditing={() => companyInput?.current?.focus()}
                        /> 
                      </Box>
                      <Box w={{base: '100%', md: '31%'}}>
                        <InputField
                          ref={companyInput}
                          key="Company"
                          value={company}              
                          onChangeText={(value) => setCompany(value)}
                          label="Company*"
                          placeholder="Enter Brand Name"
                          helper={companyError}
                          status={companyError ? "error" : undefined}
                          onSubmitEditing={() => categoryInput?.current?.focus()}
                        /> 
                      </Box>
                      <Box w={{base: '100%', md: '31%'}}>
                        <InputField
                          ref={categoryInput}
                          key="Category"
                          value={category}              
                          onChangeText={(value) => setCategory(value)}
                          label="Category*"
                          placeholder="Capsule"
                          helper={categoryError}
                          status={categoryError ? "error" : undefined}
                          onSubmitEditing={() => unitMRPInput?.current?.focus()}
                        /> 
                      </Box>
                    </Stack>
                    <Stack space={{base: 3, md: 0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                      <Box w={{base: '100%', md: '31%'}}>
                          <InputField
                            ref={unitMRPInput}
                            key="Unit MRP"
                            value={unitMRP}              
                            onChangeText={(value) => setUnitMRP(value)}
                            label="Unit MRP*"
                            placeholder="1000"
                            helper={unitMRPError}
                            status={unitMRPError ? "error" : undefined}
                            onSubmitEditing={() => offerPriceInput?.current?.focus()}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box>
                        <Box w={{base: '100%', md: '31%'}}>
                          <InputField
                            ref={offerPriceInput}
                            key="Offer Price"
                            value={offerPrice}              
                            onChangeText={(value) => setOfferPrice(value)}
                            label="Offer Price*"
                            placeholder="500"
                            helper={offerPriceError}
                            status={offerPriceError ? "error" : undefined}
                            onSubmitEditing={() => offerInput?.current?.focus()}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box>
                        <Box w={{base: '100%', md: '31%'}}>
                          <InputField
                            ref={quantityInput}
                            key="Quantity"
                            value={quantity}              
                            onChangeText={(value) => setQuantity(value)}
                            label="Quantity*"
                            placeholder="1000"
                            helper={quantityError}
                            status={quantityError ? "error" : undefined}
                            onSubmitEditing={() => manufacturingDateInput?.current?.focus()}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box>
                        {/* <Box w={{base: '100%', md: '31%'}}>
                          <InputField
                            ref={offerInput}
                            key="Offer"
                            value={offer}              
                            onChangeText={(value) => setOffer(value)}
                            label="Offer"
                            placeholder="40%"
                            helper={offerError}
                            status={offerError ? "error" : undefined}
                            onSubmitEditing={() => quantityInput.current?.focus()}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box> */}
                    </Stack>
                    <Stack space={{base: 3, md: 0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                      
                      <Box w={{base: '100%', md: '31%'}}>                        
                        <InputField
                          ref={manufacturingDateInput}
                          key="Manufacturing Date"
                          value={manufacturingDate}              
                          onChangeText={(value) => setManufacturingDate(value)}
                          label="Manufacturing Date*"
                          placeholder="yyyy/mm/dd"
                          helper={manufacturingDateError}
                          status={manufacturingDateError ? "error" : undefined}
                          onSubmitEditing={() => expiryInput?.current?.focus()}
                          RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenManufacturingDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                        /> 
                        <DatePicker mode="calendar" onSelectedChange={(date) => {setManufacturingDate(date?.split(' ')[0])}} />
                      </Box>
                      <Box w={{base: '100%', md: '31%'}}>
                        <InputField
                          ref={expiryInput}
                          key="Expiry"
                          value={expiry}              
                          onChangeText={(value) => setExpiry(value)}
                          label="Expiry*"
                          placeholder="yyyy/mm/dd"
                          helper={expiryError}
                          status={expiryError ? "error" : undefined}
                          onSubmitEditing={() => descriptionInput?.current?.focus()}
                          RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenExpiryDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                        /> 
                        <DatePicker mode="calendar" onSelectedChange={(date) => {setExpiry(date.split(' ')[0])}} /> 
                      </Box>
                      <Box w={{base: '100%', md: '31%'}}>
                        <InputField
                          ref={descriptionInput}
                          key="Description"
                          value={description}              
                          onChangeText={(value) => setDescription(value)}
                          label="Description"
                          placeholder="Short Description"
                          multiline
                          style={{height: 450}}
                        /> 
                        </Box>
                    </Stack>
                  </Stack>                 
    
                  {/* upload your file section */}
                  <Stack space={{base: 5,md: 0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                    <VStack w={{base: '100%', md: '23%'}} space={2}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: headingBreakpoint,
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                        }}
                        >
                          Add Thumbnail
                      </Text>
                      <Dropzone onDrop={acceptedFiles => setThumbnail(acceptedFiles)} maxFiles={1} multiple={false}>
                        {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()}>
                            <VStack 
                              space={1} 
                              w={'100%'} p={3}
                              alignItems='center'
                              borderStyle='dashed'
                              borderWidth={2}
                              borderColor={colors.palette.secondary300}
                              height={205}
                              backgroundColor={colors.palette.primary100}                            
                              >                              
                              <AutoImage
                                source={require(`../../../assets/icons/upload.png`)}
                                maxHeight={uploadImageWidthHeightBreakpoint}
                                style={{ width: uploadImageWidthHeightBreakpoint}}
                                resizeMode="contain"
                              />
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.light,
                                  fontSize: 16,
                                  lineHeight: 24,
                                  color: colors.palette.neutral400,
                                }}
                                >
                                Drag & drop file or
                                <>
                                  <Text style={{color: colors.palette.secondary300}}>  Browse </Text>
                                  <input {...getInputProps()} />
                                </>
                              </Text>
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.light,
                                  fontSize: 13,
                                  lineHeight: 19.5,
                                  color: colors.palette.neutral400,
                                }}
                                >
                                Supported formates: JPEG, PNG, JPG, GIF
                              </Text>
                            </VStack>
                          </div>                          
                        )}
                      </Dropzone>                    
                    {thumbnailError &&  <Text style={{color: 'red', textAlign: 'center'}}>Thumbnail is required</Text>}
                    {thumbnail?.length != 0 && 
                      <HStack maxW={'100%'} justifyContent={'space-between'} style={{borderRadius: 2, borderColor: 'green', borderWidth: 1, padding: 5}}>
                        <Text isTruncated>{thumbnail && thumbnail[0] && thumbnail[0].name}</Text>
                        <AntDesign name="delete" size={18} color="red" 
                          onPress={()=>{setThumbnail([])}}
                        />
                      </HStack>
                    }
                    </VStack>  

                    <VStack w={{base: '100%', md: '25%'}} space={2}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: headingBreakpoint,
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                        }}
                        >
                          Upload Your File
                      </Text>
                      <Dropzone onDrop={acceptedFiles => setFiles([...files, ...acceptedFiles])}>
                        {({getRootProps, getInputProps}) => {
                          return (
                          <div {...getRootProps()}>
                            <VStack 
                              space={1} 
                              w={'100%'} p={3}
                              alignItems='center'
                              borderStyle='dashed'
                              borderWidth={2}
                              borderColor={colors.palette.secondary300}
                              height={205}
                              backgroundColor={colors.palette.primary100}                            
                              >
                              <AutoImage
                                source={require(`../../../assets/icons/upload.png`)}
                                maxHeight={uploadImageWidthHeightBreakpoint}
                                style={{ width: uploadImageWidthHeightBreakpoint}}
                                resizeMode="contain"
                              />
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.light,
                                  fontSize: 16,
                                  lineHeight: 24,
                                  color: colors.palette.neutral400,
                                }}
                                >
                                Drag & drop files or
                                <>
                                  <Text style={{color: colors.palette.secondary300}}>  Browse </Text>
                                  <input {...getInputProps()} />
                                </>
                              </Text>
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.light,
                                  fontSize: 13,
                                  lineHeight: 19.5,
                                  color: colors.palette.neutral400,
                                }}
                                >
                                Supported formates: JPEG, PNG, JPG, GIF
                              </Text>
                            </VStack>
                          </div>                          
                        )}}
                      </Dropzone>                    
                    {filesError && <Text style={{color: 'red', textAlign: 'center'}}>Please upload product images</Text>}
                    {files?.length != 0 && files?.map((image,index)=>{
                        const deleteHandler = (index) => {
                          files.splice(index,1);
                          setFiles([...files])
                          console.log('delete', files)
                        };
                      return(
                        <VStack>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: 14,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              Uploaded
                          </Text>
                          <HStack justifyContent={'space-between'} style={{borderRadius: 2, borderColor: 'green', borderWidth: 1, padding: 5}}>
                            <Text isTruncated>{image?.name}</Text>
                            <AntDesign name="delete" size={18} color="red" 
                              onPress={()=>{deleteHandler(index)}}
                            />
                          </HStack>
                        </VStack>
                      )
                    })}
                    </VStack>

                    <VStack w={{base: '100%', md: '45%'}}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: headingBreakpoint,
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                        }}
                        >
                          Advanced
                      </Text>
                      <Box w={'100%'}>
                        <InputField
                          key="Description"
                          value={advanceDescription}              
                          onChangeText={(value) => setAdvanceDescription(value)}
                          label="Description"
                          placeholder="Long Description"
                          multiline
                          style={{height: 200}}
                          onSubmitEditing={submitForm}
                        /> 
                      </Box>
                    </VStack>
                  </Stack>
                </VStack>
                {/* // successfully added product modal */}
                <SuccessfullyAddedProduct modalVisible={modalVisible}/>
                <Button
                  text='Submit'
                  preset="default"
                  onPress={()=>submitForm()}
                  style={{
                    backgroundColor: colors.palette.primary600,
                    borderRadius: 5,
                    width: submitButtonBreakpoint,
                    marginTop: 5, 
                  }}
                  pressedStyle={{
                    backgroundColor: colors.palette.neutral100,
                    borderRadius: 5,
                    borderWidth: 0,
                
                  }}
                  textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                  pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                />
              </VStack>
            </Stack>
          </PageLayout>
        </Screen>, //code for web and other platforms
        
        native :
        <PageLayout variant={1}>
          <ScrollView style={{backgroundColor: colors.palette.neutral100}}>
            <Stack w="100%" mb={100}>
              {/* Parent VStack */}
              <VStack space={4} ml={5} mr={5}  mt={5}
                paddingBottom={Platform.select({ios: isKeyboardOpen ? enableShift_250 : 20 , android: isKeyboardOpen ? enableShift_80 : 20})}
                >
                {/* go back */}
                <VStack>
                  <HStack alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                    </Pressable>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: useBreakpointValue({base: 20 ,md:35, lg: 40}),
                        lineHeight: 48,
                        color: colors.palette.neutral700,
                      }}
                      >
                        Add New Product
                    </Text>
                  </HStack>
    
                  <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.normal,
                    fontSize: 16,
                    lineHeight: 24,
                    color: colors.palette.neutral300,
                  }}
                  >
                  Product you are adding will be reviewed by our support team and a valid product will be added to our master product list that will be available for all other users and you.
                  </Text>
                </VStack>
              
    
                {/* Input fields */}
                <VStack space={5}>
                  {/* General section inputs */}
                  <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.semiBold,
                    fontSize: useBreakpointValue({base: 22,md: 20,lg: 24}),
                    lineHeight: 28.8,
                    color: colors.palette.neutral700,
                  }}
                  >
                    General
                  </Text>
                  <Stack space={{base: 3, md: 3}}>
                    <Stack space={{base: 3, md: 0}} direction={'column'} w={'100%'} justifyContent={'space-between'}>
                      <Box w={"100%"}>
                        <InputField
                          key="Product Name"
                          value={productName}              
                          onChangeText={(value) => setProductName(value)}
                          label="Product Name"
                          placeholder="Abel 40mg Tablet"
                          onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                        /> 
                      </Box>
                      <Box w={"100%"}>
                        <InputField
                          key="Company"
                          value={company}              
                          onChangeText={(value) => setCompany(value)}
                          label="Company"
                          placeholder="Enter Brand Name"
                          onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                        /> 
                      </Box>
                      <Box w={"100%"}>
                        <InputField
                          key="Category"
                          value={category}              
                          onChangeText={(value) => setCategory(value)}
                          label="Category"
                          placeholder="Capsule"
                        /> 
                      </Box>
                    </Stack>
                    <Stack space={2} direction={'row'} w={'100%'} justifyContent={'space-between'}>
                      <Box w={'49%'}>
                          <InputField
                            key="Unit MRP"
                            value={unitMRP}              
                            onChangeText={(value) => setUnitMRP(value)}
                            label="Unit MRP*"
                            placeholder="1000"
                            onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                          /> 
                        </Box>
                        <Box w={'49%'}>
                          <InputField
                            key="Offer Price"
                            value={offerPrice}              
                            onChangeText={(value) => setOfferPrice(value)}
                            label="Offer Price*"
                            placeholder="500"
                            onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                          /> 
                        </Box>
                    </Stack>
                    <Stack space={2} direction={'row'} w={'100%'} justifyContent={'space-between'}>
                        <Box w={'49%'}>
                          <InputField
                            key="Offer"
                            value={offer}              
                            onChangeText={(value) => setOffer(value)}
                            label="Offer"
                            placeholder="40%"
                            onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                          /> 
                        </Box>
                      <Box w={"49%"}>
                        <InputField
                          key="Quantity"
                          value={quantity}              
                          onChangeText={(value) => setQuantity(value)}
                          label="Quantity*"
                          placeholder="1000"
                        /> 
                      </Box>
                    </Stack>
                    <Stack space={2} direction={'row'} w={'100%'} justifyContent={'space-between'}>
                      <Box w={'49%'}>
                        {/* date picker modal for manufacturing date  */}
                        <Modal isOpen={openManufacturingDatePicker} onClose={() => setOpenManufacturingDatePicker(false)} avoidKeyboard justifyContent="flex-end" bottom="4" size="lg">
                          <Modal.Content>
                            <Modal.CloseButton />
                            <Modal.Header>Choose Date</Modal.Header>
                            <Modal.Body> <DatePicker onSelectedChange={(date) => {setManufacturingDate(date.split(' ')[0])}} /> </Modal.Body>
                          </Modal.Content>
                        </Modal>
                        <InputField
                          key="Manufacturing Date"
                          value={manufacturingDate}              
                          onChangeText={(value) => setManufacturingDate(value)}
                          label="Mfg Date"
                          placeholder="yyyy/mm/dd"
                          RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenManufacturingDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                          onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                        /> 
                      </Box>
                      <Box w={'49%'}>
                        {/*  */}
                        <Modal isOpen={openExpiryDatePicker} onClose={() => setOpenExpiryDatePicker(false)} avoidKeyboard justifyContent="flex-end" bottom="4" size="lg">
                          <Modal.Content>
                            <Modal.CloseButton />
                            <Modal.Header>Choose Date</Modal.Header>
                            <Modal.Body> <DatePicker onSelectedChange={(date) => {setExpiry(date.split(' ')[0])}} /> </Modal.Body>
                          </Modal.Content>
                        </Modal>
                        <InputField
                          key="Expiry"
                          value={expiry}              
                          onChangeText={(value) => setExpiry(value)}
                          label="Expiry*"
                          placeholder="yyyy/mm/dd"
                          RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenExpiryDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                          onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                        /> 
                      </Box>
                    </Stack>
                    <Box w={'100%'}>
                      <InputField
                        key="Description" 
                        value={description}              
                        onChangeText={(value) => setDescription(value)}
                        label="Description"
                        placeholder="Short Description"
                        onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                      /> 
                    </Box>
                  </Stack>
                  
                  {/* upload your file section */}
                  <Stack space={5} w={'100%'} justifyContent={'space-between'}>
                    <VStack w={'100%'} space={2}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: useBreakpointValue({base: 22,md: 20,lg: 24}),
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                        }}
                        >
                          Upload Your File
                      </Text>
                      <VStack space={1} w={'100%'} p={3} style={{alignItems: 'center', borderStyle: 'dashed', borderWidth: 2, borderColor: colors.palette.secondary300, height: 205, backgroundColor: colors.palette.primary100}}>                      
                      <Image
                        source={require(`../../../assets/icons/upload.png`)}
                        maxHeight={80}
                        style={{ width: 80, height: 80}}
                        resizeMode="contain" alt="image"
                      />
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.light,
                          fontSize: 16,
                          lineHeight: 24,
                          color: colors.palette.neutral400,
                        }}
                        >
                        Drag & drop files or
                        <Pressable>
                          <Text style={{color: colors.palette.secondary300}}>  Browse </Text>
                        </Pressable>
                      </Text>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.light,
                          fontSize: 13,
                          lineHeight: 19.5,
                          color: colors.palette.neutral400,
                        }}
                        >
                        Supported formates: JPEG, PNG, JPG, GIF
                      </Text>
                      </VStack>
                      {thumbnail?.length != 0 && 
                          <HStack justifyContent={'space-between'} style={{borderRadius: 2, borderColor: 'green', borderWidth: 1, padding: 5}}>
                          <Text>{thumbnail && thumbnail[0] && thumbnail[0].name}</Text>
                          <AntDesign name="delete" size={18} color="red" 
                            onPress={()=>{setThumbnail([])}}
                          />
                        </HStack>
                      }
                    </VStack>
                    
                    <VStack w={"100%"}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: useBreakpointValue({base: 22,md: 20,lg: 24}),
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                        }}
                        >
                          Advanced
                      </Text>
                      <Box w={'100%'}>
                        <InputField
                          key="Description"
                          value={advanceDescription}              
                          onChangeText={(value) => setAdvanceDescription(value)}
                          label="Description"
                          placeholder="Long Description"
                          multiline
                          style={{height: 200}}
                          onFocus={()=>{setEnableShift_250(250); setEnableShift_80(60)}}
                          onSubmitEditing={submitForm}
                        /> 
                      </Box>
                    </VStack>
                  </Stack>
                </VStack>

                {/* // successfully added product modal */}
                <SuccessfullyAddedProduct modalVisible={modalVisible}/>

                {/* submit button */}
                <Button
                  text='Submit Offer'
                  preset="default"
                  onPress={() => {submitForm(); setModalVisible(true);}}
                  style={{
                    backgroundColor: colors.palette.primary600,
                    borderRadius: 5,
                    width: "100%",
                    marginTop: 5, 
                  }}
                  pressedStyle={{
                    backgroundColor: colors.palette.neutral100,
                    borderRadius: 5,
                    borderWidth: 0,
                
                  }}
                  textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                  pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                />
              </VStack>
            </Stack>
          </ScrollView>
        </PageLayout>
        //code for Mobile devices
      })}
    {/* Platform select close here  */}
  </>
  )  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}
