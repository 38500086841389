/**
 * These are configuration settings for the dev environment.
 *
 * Do not include API secrets in this file or anywhere in your JS.
 *
 * https://reactnative.dev/docs/security#storing-sensitive-info
 */
export default {
  // API_URL: "http://127.0.0.1:8000/api/",
  // MEDIA_URL:"http://127.0.0.1:8000",

   API_URL: "https://api.nearxpiry.com/api/",
   MEDIA_URL:"https://api.nearxpiry.com",
}
