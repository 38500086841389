import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
export const LoginStoreModel = types
  .model("LoginStore")
  .props({    
    email: "",
    password: "",
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get emailValidationError() {
      if (self.email?.length === 0) return "email can't be blank"
      // if (self.email.length <= 11) return "email must be at least 10 characters"
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(self.email) && self.email?.length <= 11) return "must be a valid email address"
      return ""
    },
    get passwordValidationError(){
      if (self.password?.length === 0) return "password cannot be blank"
      if (self.password?.length < 9) return "password must be at least 8 characters"
      return ""
    },

  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setEmail(value: string) {
      self.email = value.toLocaleLowerCase().trim()
    },
    setPassword(value: string) {
      self.password = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface LoginStore extends Instance<typeof LoginStoreModel> {}
export interface LoginStoreSnapshotOut extends SnapshotOut<typeof LoginStoreModel> {}
export interface LoginStoreSnapshotIn extends SnapshotIn<typeof LoginStoreModel> {}
export const createLoginStoreDefaultModel = () => types.optional(LoginStoreModel, {})
