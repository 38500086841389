import { Instance, SnapshotIn, SnapshotOut, flow, getParent, getRoot, types } from "mobx-state-tree"
import { withSetPropAction } from "./helpers/withSetPropAction"
import { AuthenticationApi } from "../services/api/apiFatching/authentication-api";
import { LoginResult, LogoutResult, SignupResult, api } from "../services/api";
import { ApiResponse } from "apisauce";
import { RootStoreModel } from "./RootStore";
import { useInitialRootStore, useStores } from "./helpers/useStores";
/**
 * UserProfile Model description here for TypeScript hints.
 */
interface UserProfile {
  avatar: string,
  email_alert: boolean,
  push_notification: boolean,
  text_message: boolean,
  phone_call: boolean,
  whatsapp_alert: boolean,
  chat_alert: boolean
}
/**
 * UserPaymentOptions Model description here for TypeScript hints.
 */
interface UserPaymentOptions {
  user: string,
  card_number: string,
  cardholder_name: string,
  expiration_date: Date,
  cvv: string,
  card_type: string,
}
/**
 * Review Model description here for TypeScript hints.
 */
interface Review {
  id:number,
  seller: string,
  reviewer: string,
  star_rating: number,
  feedback: string,
  date_posted: Date,
  days_ago: number,
  star_rating_info:any,
}
/**
 * Seller Model description here for TypeScript hints.
 */
interface Seller {
  id: string,
  user: string,
  business_name: string,
  business_address: string,
  registration_number: string,
  license_number: string,
  is_verified: boolean,
  reviews: Array<Review>,
}

export interface location {
  country: string,
  state: string,
  city: string,
  zipcode: string,
}
/**
 * User Model description here for TypeScript hints.
 */
export interface User {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  mobile: string,
  bio: string,
  profile: UserProfile,
  seller: Seller,
  location: number,
  location_info: location,
  is_public_profile: boolean,
  is_show_email: boolean,
  payment_options: Array<UserPaymentOptions>,  
}


export const AuthenticationStoreModel = types
  .model("AuthenticationStore")
  .props({
    isAuthenticated: types.optional(types.boolean,false),
    key: types.optional(types.string, ""),
    user: types.frozen<User>(),
    // newProductRequests: types.frozen<any>(),
    newProductRequests: types.optional(types.array(types.frozen<any>()),[]),
    userRequirements: types.optional(types.array(types.frozen<any>()),[]),
    error: types.array(types.optional(types.string,'')),    
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get sellerStatus(){
      if(self.user.seller.business_address!='' 
        && self.user.seller.business_name!='' 
        && self.user.seller.registration_number!='' 
        && self.user.seller.license_number!='')
          return 'pending'
      else
        return 'incomplete'
      
    },
    fetchUserData: (setOverlayVisible) => {
      setOverlayVisible(true)
      // api.apisauce.setHeaders({'Authorization': `Token ${self.key}`})
      api.apisauce.get('/rest-auth/user/')
      .then((response:ApiResponse<any>)=>{           
        self.setProp("user", response.data)
      })
      .catch((error)=>{setOverlayVisible(false); console.error(error); return error.message})
      .finally(()=>{setOverlayVisible(false);}); 
    },
    async fetchNewroductRequests(setOverlayVisible:any){
      setOverlayVisible(true)      
      return await api.apisauce.get('/newProductsRequest/')
      .then((response:ApiResponse<any>)=>{           
        // console.log(response.data)
        self.setProp("newProductRequests", response.data)
      })
      .catch((error)=>{ console.error(error);})
      .finally(()=>{setOverlayVisible(false)})      
    },
    async fetchUserRequirements(setOverlayVisible:any){      
      setOverlayVisible(true)
      await api.apisauce.get('/userRequirement/')
      .then((response:ApiResponse<any>)=>{           
        self.setProp("userRequirements", response.data)
      })
      .catch((error)=>{ console.error(error);})
      .finally(()=>{setOverlayVisible(false)})
    },
    reset() {
      self.setProp("isAuthenticated", false)
      self.setProp("key", "");
      self.setProp("user",null);
      self.setProp("newProductRequests", []);
      self.setProp("userRequirements", []);
      self.setProp("error", []);
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({    
    signup: flow(function* (username:string, email: string, password1: string, password2:string, setOverlayVisible:any){      
      const authenticationApi = new AuthenticationApi(api);
      const result:SignupResult = yield authenticationApi.signup(username,  email, password1, password2, setOverlayVisible)
      if(result.kind === "ok"){
        self.isAuthenticated = true
        self.key = result.key
        self.fetchUserData(setOverlayVisible)
      }
      else{
        console.error("Signup Failed.")
        self.isAuthenticated = false
        self.key = ""
      }
      return result
    }),    
    login: flow(function* (email: string, password: string, setOverlayVisible:any){      
      const authenticationApi = new AuthenticationApi(api);
      const result: LoginResult = yield authenticationApi.login(email, password, setOverlayVisible)
      
      if(result.kind === "ok"){
        self.isAuthenticated = true
        self.key = result.key        
        // api.apisauce.setHeaders({'Authorization': `Token sdfsdfs`})
        api.apisauce.setHeaders({'Authorization': `Token ${self.key}`})
        self.fetchUserData(setOverlayVisible) 
      }
      else{
        console.error("Login Failed.")
      }      
      return result
    }),
    logout: flow(function* (setOverlayVisible:any){      

      const authenticationApi = new AuthenticationApi(api);
      const result:LogoutResult = yield authenticationApi.logout(setOverlayVisible)
            
      if(result.kind==="ok"){
        getParent<typeof RootStoreModel>(self).resetStores();
        api.apisauce.deleteHeader('Authorization')
      }
      else{
        console.error("Logout Failed.")        
      }      
    }),  

    fetchUserInformation: flow(function* (setOverlayVisible:any){      
      setOverlayVisible(true)
      // api.apisauce.setHeaders({'Authorization': `Token ${self.key}`})
      api.apisauce.get('/rest-auth/user/')
      .then((response:ApiResponse<any>)=>{           
        self.setProp("user", response.data)
      })
      .catch((error)=>{setOverlayVisible(false); console.error(error); return error.message})
      .finally(()=>{setOverlayVisible(false);});     
    }),  
    
    updateUser: flow(function* (data:any, setOverlayVisible:any, showAlert:any){      
      setOverlayVisible(true)
      console.log(data)
      api.apisauce.patch('/rest-auth/user/', data)
      .then((response: any) => {        
        console.error(response);
        if (response.ok) {
          showAlert("Success", "Hurrey! Profile is updated successfully.")          
          self.setProp('user', response.data)
        }else{
          showAlert("Error", response.data.detail)
        }
      })
      .catch((error: any) => {
        showAlert("Error", "Oh! Could not update your profile. Please try again.")
        console.error(error.message);
      })
      .finally(() => {setOverlayVisible(false);})    
    }),    

    updateSeller: flow(function* (data:any, setOverlayVisible:any, showAlert:any){      
      setOverlayVisible(true)
      if(self.user.seller != null) {
        if(data.business_address != self.user.seller.business_address
          || data.business_name != self.user.seller.business_name
          || data.license_number != self.user.seller.license_number
          || data.registration_number != self.user.seller.registration_number) 
          data['is_verified'] = false;
          
        api.apisauce.patch(`/sellers/${self.user.seller.id}/`,data)
        .then((response: any) => { 
          console.error(response);
          if(response.ok){         
            showAlert("Success", "Hurrey! Seller Profile is updated successfully.")            
            self.fetchUserData(setOverlayVisible)
          }else{
            showAlert("Error", response.data.detail)
          }
        })
        .catch((error: any) => {
          console.error(error)
          showAlert("Error", "Oh! Could not update your seller profile. Please try again.")          
        })
        .finally(() => {setOverlayVisible(false);})
      }
      else{
        data['user_id'] = self.user.id
        api.apisauce.post('/sellers/',data)
        .then((response: any) => {
          console.error(response);
          if(response.ok){            
            showAlert("Success", "Hurrey! Seller Profile is updated successfully.")
            self.fetchUserData(setOverlayVisible)
          } else {
            showAlert("Error", response.data.detail)
          }
        })
        .catch((error: any) => {
          console.error(error);
          showAlert("Error", "Oh! Could not update your seller profile. Please try again.")
        })
        .finally(() => {setOverlayVisible(false);})
      }     
    }),
    
    updatePassword: flow(function* (data:any, setOverlayVisible:any, showAlert:any){      
      setOverlayVisible(true)
      api.apisauce.post('/rest-auth/password/change/', data)
      .then((response: any) => {
        showAlert("Success", "Hurrey! Password is updated successfully.")
        self.fetchUserData(setOverlayVisible)
        console.log(response)
      })
      .catch((error: any) => {
        showAlert("Error", "Oh! Could not update your password. Please try again.")
        console.error(error.message)
      })
      .finally(() => {setOverlayVisible(false);})   
    }),
    
    updateBilling: flow(function* (data:any, setOverlayVisible:any, showAlert:any){            
      const newApi = new AuthenticationApi(api)
      newApi.addPaymentOption(
        data.cardNumber, 
        data.cardHolderName, 
        data.exp, 
        data.CVV, 
        data.cardType, 
        setOverlayVisible
      )
      .then((response: any) => {
        showAlert("Success", "Hurrey! Payment Option is added successfully.")
        console.log(response)
        self.fetchUserData(setOverlayVisible)
      })
      .catch((error: any) => {
        showAlert("Error", "Oh! Could not add Payment Option. Please try again.")
        console.error(error.message)
      })  
    }),
    
    deletePaymentOption: flow(function* (card_id:string, setOverlayVisible:any, showAlert:any){            
      const newApi = new AuthenticationApi(api)
      newApi.removePaymentOption(card_id,setOverlayVisible)
      .then((response: any) => {
        showAlert("Success", "Hurrey! Payment Option is deleted successfully.")
        console.log(response)
      })
      .catch((error: any) => {
        showAlert("Error", "Oh! Could not delete Payment Option. Please try again.")
        console.error(error.message)
      })  
    }),

    writeAReview: flow(function* (productId: string, star_rating: string, feedback: string){      
         
    }),

    

  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface AuthenticationStore extends Instance<typeof AuthenticationStoreModel> {}
export interface AuthenticationStoreSnapshotOut extends SnapshotOut<typeof AuthenticationStoreModel> {}
export interface AuthenticationStoreSnapshotIn extends SnapshotIn<typeof AuthenticationStoreModel> {}
export const createAuthenticationStoreDefaultModel = () => types.optional(AuthenticationStoreModel, {})
