import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
export const AddProductStoreModel = types
  .model("AddProductStore")
  .props({
    productName: '',
    company: '',
    category: '',
    unitMRP: '',
    offerPrice: '',
    offer: '',
    quantity: '',
    manufacturingDate: '',
    expiry: '',
    description: '',
    advanceDescription: '',
    isWishlisted: false,
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get productNameValidationError(){
      if (self.productName?.length === 0) return "Product Name cannot be blank"
      return ""
    },
    get companyValidationError(){
      if (self.company?.length === 0) return "company Name cannot be blank"
      return ""
    },
    get categoryValidationError(){
      if (self.category?.length === 0) return "category cannot be blank"
      return ""
    },
    get unitMRPValidationError(){
      if (self?.unitMRP?.length === 0) return "Unit MRP cannot be blank"
      if (/^[0-9]+$/.test(self?.unitMRP) != true) return "Only numbers are allowed"
      return ""
    },
    get offerPriceValidationError(){
      if (self?.offerPrice?.length === 0) return "Unit MRP cannot be blank"
      if (/^[0-9]+$/.test(self?.offerPrice) != true) return "Only numbers are allowed"
      return ""
    },
    get offerValidationError(){
      if (self?.offer?.length === 0) return "offer cannot be blank"
      if (/^[0-9]+$/.test(self?.offer) != true) return "Only numbers are allowed"
      return ""
    },
    get quantityValidationError(){
      if (self?.quantity?.length === 0) return "Quantity cannot be blank"
      if (/^[0-9]+$/.test(self?.quantity) != true) return "Only numbers are allowed"
      return ""
    },
    get manufacturingDateValidationError(){
      if (self.manufacturingDate?.length === 0) return "Manufacturing Date cannot be blank"
      return ""
    },
    get expiryValidationError(){
      if (self.expiry?.length === 0) return "Expiry Date cannot be blank"
      return ""
    },

  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setProductName(value: string) {
      self.productName = value
    },
    setCompany(value: string) {
      self.company = value
    },
    setCategory(value: string) {
      self.category = value
    },
    setUnitMRP(value: string) {
      self.unitMRP = value.replace(/\D/g, '');
    },
    setOfferPrice(value: string) {
      self.offerPrice = value.replace(/\D/g, '');
    },
    setOffer(value: string) {
      self.offer = value.replace(/\D/g, '');
    },
    setQuantity(value: string) {
      self.quantity = value.replace(/\D/g, '');
    },
    setManufacturingDate(value: string) {
      self.manufacturingDate = value.replace(/\//g, "-")
    },
    setExpiry(value: string) {
      self.expiry = value.replace(/\//g, "-")
    },
    setDescription(value: string) {
      self.description = value
    },
    setAdvanceDescription(value: string) {
      self.advanceDescription = value
    },
    setIsWishlisted(value: boolean) {
      self.isWishlisted = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface AddProductStore extends Instance<typeof AddProductStoreModel> {}
export interface AddProductStoreSnapshotOut extends SnapshotOut<typeof AddProductStoreModel> {}
export interface AddProductStoreSnapshotIn extends SnapshotIn<typeof AddProductStoreModel> {}
export const createAddProductStoreDefaultModel = () => types.optional(AddProductStoreModel, {})
