import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { TextStyle, ViewStyle, StyleSheet, Linking } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps, useBackButtonHandler } from "../../navigators"
import { AutoImage, Button, Screen, StarRatting } from "../../components"
import { Box, Divider, HStack, Image, Text, Pressable, ScrollView, Stack, useBreakpointValue, View, VStack, useContrastText } from "native-base"
import { useNavigation, useRoute } from "@react-navigation/native"
import { colors, typography } from "../../theme"
import {  Entypo, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import { PageLayout } from "../../components/PageLayout"
import { ProgressBar } from "react-native-ui-lib"
import { Platform } from "react-native"
// import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { api } from "../../services/api"
import Config from "../../config"
import { OverlayContext } from "../../components/OverlayProvider"
import { AlertContext } from "../../components/AlertContext"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `SellerProfile: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="SellerProfile" component={SellerProfileScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const SellerProfileScreen: FC<StackScreenProps<AppStackScreenProps, "SellerProfile">> = observer(function SellerProfileScreen() {
  const route = useRoute<any>()
  const {showAlert} = useContext(AlertContext)
  const navigation = useNavigation<any>()
  if(!route?.params?.sellerId){
    showAlert("Error","Invalid seller profile. or seller does not exist.")
    navigation.goBack()
  }

  const {setOverlayVisible} = useContext(OverlayContext);
  // Pull in one of our MST stores
  const { authenticationStore: {
    isAuthenticated,    
    user,
    newProductRequests,
    userRequirements,
    error,
    fetchNewroductRequests,
    fetchUserRequirements
  } } = useStores()
  
  const showAboveMd = useBreakpointValue({base: false, md: true});
  const fontSizeReview = useBreakpointValue({base: 13,md: 14, xl:16});
  const reviewStatusBarSize = useBreakpointValue({base: '70%',md: '38%',lg: '40%',xl:'60%'});
  const reviewStatusBarSpace = useBreakpointValue({base: 2,md: 3, lg: 4});
  const reviewSpace = useBreakpointValue({base: 2, lg: 4});
  const reviewStarWidth = useBreakpointValue({base: 20, sm: 22, md: 25});
  const reviewStarHeight = useBreakpointValue({base: 20, md:25});
  const data = useRef<any>({});
  // const [ data, setData ] = useState<any>({});
  const isSelf = user?.seller?.id === route?.params?.sellerId
  
  useEffect(()=>{
    fetchSellerDetails()
  },[route])  

  async function fetchSellerDetails(){
    fetchNewroductRequests(setOverlayVisible)
    fetchUserRequirements(setOverlayVisible)

    route.params != undefined && await api.apisauce.get(`sellers/${route?.params?.sellerId}/`)
    .then((response)=>{
      // setData(response.data);      
      data.current = response.data;
      console.log('data.current', data.current);
    })
    .catch(error=>console.log(error));
  }
  
  // console.log('data?.offers',data?.current?.offers);
  const [ showDeleteModal, setShowDeleteModal ] = React.useState(false);
  const [ deleteOffer, setDeleteOffer ] = React.useState("");
  return (
  <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
        default : 
          <Screen style={$root} preset="scroll">
            <PageLayout  variant={isAuthenticated?1:0}> 
              <Stack w="100%" mb={100}>
                {/* Parent VStack */}
                <VStack space={8} pl={'3vw'} pr={'3vw'} pt={10}>
                  {/* go back */}
                  <HStack space={2} mb={{base: 10, md: 0}} alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                    </Pressable>                    
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: 24,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700,
                      }}
                      >
                        {/* {sellerName} */}
                        {data.current?.user?.first_name} {data.current?.user?.last_name}                        
                    </Text>
                  </HStack>
                  {showAboveMd && <AutoImage
                    source={require("../../../assets/images/sellerProfile/sellerProfile_banner.png")}
                    maxHeight={120}
                    style={{ width: "100%" }}
                    resizeMode="contain"
                  />}
      
                  {/* Main HStack */}
                  <Stack direction={{base: 'column',md: 'row'}} space={'3vw'} mt={{base: 2, md: -5}}>
                    {/*1st half--> side information section */}
                    <VStack w={{base: '100%',md:'30%'}} space={5}>
                        {/* profile card */}
                        <VStack space={reviewStatusBarSpace} mt={-10} p={5} alignItems={'center'} style={{
                          shadowColor: '#171717',
                          shadowOffset: {width: 0, height: 3},
                          shadowOpacity: 0.1,
                          shadowRadius: 20,}}>
                          {data?.current?.user?.avatar ? 
                            <AutoImage
                              source={{uri: data && data?.current?.user && data.current.user.avatar && `${Config.MEDIA_URL}/${data?.current?.user?.avatar}`}}
                              maxHeight={140}
                              style={{ width: 140, borderRadius: 140/2, marginTop: -50, borderColor: colors.palette.secondary300, borderWidth: 5}}
                              // resizeMode="contain"
                            />
                            :
                            <AutoImage
                              source={require('../../../assets/images/user_profile.png')}
                              maxHeight={140}
                              style={{ width: 140, borderRadius: 140/2, marginTop: -50, borderColor: colors.palette.secondary300, borderWidth: 5}}
                              // resizeMode="contain"
                            />
                          }
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: 24,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              {/* {sellerName} */}
                              {data?.current?.first_name} {data?.current?.last_name}
                          </Text>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.medium,
                              fontSize: 18,
                              lineHeight: 24,
                              color: colors.palette.neutral700,
                            }}
                            >
                              {data?.current?.business_name}
                          </Text>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral400,
                              textAlign: 'center'
                            }}
                            >
                              {data?.current?.business_address}
                          </Text>
                          <HStack space={5} mt={2} mb={5}>
                            <Pressable onPress={()=>{Linking.openURL(`tel://${data?.current?.mobile}`)}}>
                              <AutoImage
                                source={require("../../../assets/images/sellerProfile/call.png")}
                                maxHeight={45}
                                style={{ width: 45}}
                                resizeMode="contain"
                              />
                            </Pressable>
                            <Pressable onPress={()=>{Linking.openURL(`mail://${data?.current?.email}`)}}>
                              <AutoImage
                                source={require("../../../assets/images/sellerProfile/call.png")}
                                maxHeight={45}
                                style={{ width: 45}}
                                resizeMode="contain"
                              />
                            </Pressable>
                            <Pressable onPress={()=>{
                              if(data?.current?.mobile)
                                Linking.openURL(`https://wa.me/${data?.current?.mobile}`)
                              else
                                showAlert("Error", "Whats app information not updated by seller. Check later.")
                              }}>
                              <AutoImage
                                source={require("../../../assets/images/sellerProfile/whatsapp.png")}
                                maxHeight={45}
                                style={{ width: 45}}
                                resizeMode="contain"
                              />
                            </Pressable>      
                          </HStack>
                        </VStack>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.semiBold,
                            fontSize: 24,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            Reviews
                        </Text>
                        {/* Reviews section parent vstack */}
                        <Stack direction={{base: 'row',md: 'column'}}>
                          {/* write a review section  */}
                          <VStack 
                            // w={{base: '40%',md:'100%'}} 
                            w={"100%"} 
                            space={4} style={{
                            padding: useBreakpointValue({base: '2vw',lg:'1vw'}),
                            shadowColor: '#171717',
                            shadowOffset: {width: 2, height: 0},
                            shadowOpacity: 0.2,
                            shadowRadius: 5,
                            justifyContent: 'center',
                            }}>
                            <VStack>
                              <VStack w={{base: '100%',xl:'60%'}} space={3}>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.medium,
                                    fontSize: 18,
                                    lineHeight: 22,
                                    color: colors.palette.neutral700,
                                  }}
                                  >
                                    {data?.current?.reviews?.length} Reviews
                                </Text>
                                {data?.current?.avg_reviews >= 0 && 
                                  <Stack direction={{base: 'column', lg: 'row'}} space={3} mb={2}>                                                                    
                                    <ReviewStars rating={data?.current?.avg_reviews} starStyle={{width: 20, height: 20}}/>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 15,
                                        lineHeight: 24,
                                        color: colors.palette.neutral700,
                                      }}
                                      >
                                        {data?.current?.avg_reviews} out of 5
                                    </Text>                                                                   
                                  </Stack>
                                } 
                              </VStack>
                              {
                              (data?.current?.user?.id !== user?.id) && 
                              showAboveMd &&<Box w={'100%'} style={{justifyContent: 'center'}}>
                                <Button
                                  text='Write a Review'
                                  preset="default"
                                  onPress={() => navigation.navigate("Review",{sellerId: data?.current?.id})}
                                  style={{
                                    backgroundColor: colors.palette.primary600,
                                    borderRadius: 5,
                                    width: '100%',
                                    
                                  }}
                                  pressedStyle={{
                                    backgroundColor: colors.palette.neutral100,
                                    borderRadius: 5,
                                    borderWidth: 0,
                                  }}
                                  textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
                                  pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                                />
                              </Box>}
                            </VStack>
      
                            {/* status bar / progress bar */}
                            <VStack space={3} >
                              {/* Star Rating */}
                              {['5','4','3','2','1'].map((star,item)=>{
                                return (
                                  <HStack space={reviewStatusBarSpace} key={`star-rating-${star}`}>
                                    <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: fontSizeReview, lineHeight: 24, color: colors.palette.neutral700, }}> {star} Star </Text>
          
                                    <Box justifyContent={'center'} w={reviewStatusBarSize}>
                                    <ProgressBar 
                                      progress={((
                                        data?.current?.reviews && 
                                        data.current.reviews[0] && 
                                        data.current.reviews[0].star_rating_info && 
                                        data.current.reviews[0].star_rating_info[star].count)/(data?.current?.reviews?.length))*100} 
                                        progressColor={colors.palette.secondary300} style={{backgroundColor: colors.palette.secondary100}}/>
                                    </Box>
          
                                    <Text  style={{ fontFamily: typography.fonts.poppins.normal, fontSize: fontSizeReview, lineHeight: 24, color: colors.palette.neutral700 }}> {/* {noOf5Stars} */} {data?.current?.reviews && data.current.reviews[0] && data.current.reviews[0].star_rating_info && data.current.reviews[0].star_rating_info[star].count} </Text>
                                  </HStack>
                                )
                              })}                              
                            </VStack>
                            {
                            (data?.current?.user?.id !== user?.id) &&
                              showAboveMd !== true && <Box mt={10} w={'100%'} style={{justifyContent: 'center'}}>
                                <Button
                                  text='Write a Review'
                                  preset="default"
                                  onPress={() => navigation.navigate("Review",{sellerId: data?.current?.id})}
                                  style={{
                                    backgroundColor: colors.palette.primary600,
                                    borderRadius: 5,
                                    width: '100%',
                                    
                                  }}
                                  pressedStyle={{
                                    backgroundColor: colors.palette.neutral100,
                                    borderRadius: 5,
                                    borderWidth: 0,
                                  }}
                                  textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
                                  pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                                />
                              </Box>}
                          </VStack>
                          
                        {showAboveMd && <VStack w={{base: '60%',md:'100%'}} >
                          {/* top reviews */}
                          {data?.current?.reviews?.splice(0,2).map((item,index)=>{
                            return (
                              <VStack space={3} pb={{base: 3, md: '40px'}} style={{
                                padding: '2vw',
                                shadowColor: '#171717',
                                shadowOffset: {width: 0, height: 2},
                                shadowOpacity: 0.2,
                                shadowRadius: 5,}}
                                key={`top_review_${index}`}
                                >
        
                                <HStack space={reviewSpace} alignItems='center'>
                                  <AutoImage
                                    source={require("../../../assets/images/account_settings/photo.png")}
                                    maxHeight={60}
                                    style={{ width: 60, borderRadius: 60/2, borderColor: colors.palette.secondary300, borderWidth: 3}}
                                    // resizeMode="contain"
                                  />
                                  <VStack>
                                    <Stack direction={{base: 'column', lg: 'row'}}>
                                      <ReviewStars rating={item?.star_rating} starStyle={{width: reviewStarWidth, height: reviewStarHeight}}/>
                                      <Text  style={{ fontFamily: typography.fonts.poppins.semiBold, fontSize: fontSizeReview, lineHeight: 24, color: colors.palette.neutral400 }}> {/* {noOf5Stars} */} {item?.star_rating} </Text>
                                    </Stack>
                                    <Box w={'100%'} justifyContent={'center'} alignItems={'flex-start'}>
                                      <Text style={{ fontFamily: typography.fonts.poppins.light, fontSize: 13, lineHeight: 19.5, color: colors.palette.neutral400 }}> {/* {noOf5Stars} */}{item?.days_ago} </Text>
                                    </Box>
                                  </VStack>
                                </HStack>
                                <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 18, lineHeight: 22, color: colors.palette.neutral800 }}>{item?.reviewer}</Text>
                                <Box w={'94%'}>
                                  <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral400 }}>{item?.feedback}</Text>
                                </Box>
                              </VStack>
                            )
                          })}
                        </VStack>}
                        </Stack>

                        {/* ViewAllReview */}
                        <Box w={'100%'} style={{justifyContent: 'center'}}>
                          <Button
                            text='View All Reviews'
                            preset="default"
                            onPress={() => {console.log('viewAllClicked'); navigation.navigate("ViewAllReviews",{sellerId: data?.current?.id})}}
                            style={{
                              backgroundColor: colors.palette.primary600,
                              borderRadius: 5,
                              width: '100%',
                              marginTop: 10
                              
                            }}
                            pressedStyle={{
                              backgroundColor: colors.palette.neutral100,
                              borderRadius: 5,
                              borderWidth: 0,
                            }}
                            textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
                            pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                          />
                        </Box>

                    </VStack>
                    {/*2st half--> provided cards */}
                    <VStack w={{base: '100%'}} space={5}>
                      <VStack w={{base: '100%', md:'68%'}} space={5}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.semiBold,
                            fontSize: 24,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            Provided Offers
                        </Text>
                        {data?.current?.offers?.length===0 && 
                          <VStack space={3} mb={20}>
                            <AutoImage
                              source={require(`../../../assets/images/search_empty.png`)}
                              maxHeight={320}
                              style={{ width: "100%" }}
                              resizeMode="contain"
                            />
                            <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.normal,
                                fontSize: 16,
                                lineHeight: 24 ,
                                color: colors.palette.neutral400,
                                textAlign: 'center'
                              }}
                              >
                                Seller has No Offers.
                            </Text>
                          </VStack>
                        }
                        <DeleteConfirmationModal 
                          showDeleteModal={showDeleteModal} 
                          setShowDeleteModal={setShowDeleteModal} 
                          offerId={deleteOffer}                          
                        />
                        <HStack flexWrap='wrap'>
                        {data?.current?.offers?.map((medicine,index)=>(
                          <Box key={`provided_offers_${index}`} maxH={400} mb={5} mr={4} w={{base: '100%',sm: '46.5%',xl:'31%'}}>
                            <MedicineCard
                              key={medicine.index}
                              // medicinePhoto={`${Config.MEDIA_URL}${medicine.product_image}`}
                              medicinePhoto={Config?.MEDIA_URL + medicine?.product_image}
                              // medicinePhoto={{uri: Config?.MEDIA_URL + medicine?.product_image}}
                              medicineImageMaxHeight={155}
                              medicineImageWidth={"100%"}
                              cardWidth={"100%"}
                              cardHeight={400}
                              imageContainer={"45%"}
                              contentContainer={"55%"}
                              productId={medicine?.id}
                              tagShow={true}
                              max_offer_percentage={medicine?.percentage_off}
                            >
                              {/* {medicine.childrenData} */}
                              {/* <Pressable onPress={()=>{navigation.navigate("ProductDescription", {'productId': medicine?.id})}}> */}
                                <VStack space={3}>
                                  <Pressable onPress={()=>navigation.navigate('ProductDescription', {"productId": medicine?.product_id})}>
                                    <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 18, lineHeight: 22, color: colors.palette.neutral800 }}>
                                      {/* Abel 40Mg Tablet */}
                                      {medicine?.product_title}
                                    </Text>
                                  </Pressable>
                                  <HStack space={2} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.neutral400 }}>Unit MRP:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.neutral600 }}>Rs. {medicine?.price}</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.accent100 }}>{medicine?.percentage_off}%off</Text>
                                  </HStack>

                                  <HStack space={2} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.neutral400 }}>Offer Price:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.neutral600 }}>Rs. {medicine?.offer_price}</Text>
                                  </HStack>
                                  
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.angry100 }}>Expiry:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.angry100 }}>{medicine?.expiry_date}</Text>
                                  </HStack>
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>Mfg. Date:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{medicine?.manufacturing_date}</Text>
                                  </HStack>
                                  {isSelf &&
                                    <VStack space={1}>
                                      <Divider/>
                                      <HStack space={5} alignItems="flex-start" justifyContent={'flex-end'}>
                                        <MaterialIcons onPress={()=>{navigation.navigate("AddNewOffer",  {productId: medicine?.product_id, offerId: medicine?.id})}} name="edit" size={24} color={colors.palette.neutral300} />
                                        <Pressable onPress={()=>{
                                          setDeleteOffer(medicine?.id)
                                          setShowDeleteModal(true)
                                        }}>
                                          <MaterialCommunityIcons name="delete" size={24} color={colors.palette.angry100} />
                                        </Pressable>
                                      </HStack>
                                    </VStack>
                                  }
                                </VStack>
                              {/* </Pressable> */}
                            </MedicineCard>
                          </Box>
                          )) } 
                        </HStack>
                      </VStack>    
                      {isSelf &&
                      <>
                        <VStack w={{base: '100%', md:'68%'}} space={5}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: 24,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              New Product Requests
                          </Text>
                          {newProductRequests?.length===0 && 
                            <VStack space={3} mb={20}>
                              <AutoImage
                                source={require(`../../../assets/images/search_empty.png`)}
                                maxHeight={320}
                                style={{ width: "100%" }}
                                resizeMode="contain"
                              />
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.normal,
                                  fontSize: 16,
                                  lineHeight: 24 ,
                                  color: colors.palette.neutral400,
                                  textAlign: 'center'
                                }}
                                >
                                  You did not request for any new product yet.
                              </Text>
                            </VStack>
                          }
                          
                          <HStack flexWrap='wrap'>
                            {newProductRequests?.map((product,index)=>(
                              <VStack                               
                                w={{base: '100%',sm: '46.5%',xl:'31.5%'}}
                                p={2} 
                                minHeight={200} 
                                // maxHeight={'100%'}
                                maxHeight={450}
                                style={{
                                backgroundColor:  colors.palette.neutral100,
                                overflow: 'hidden',
                                // shadowColor: 'black',
                                // shadowOffset: {width: 1, height: 2},
                                shadowOpacity: 0.1,
                                shadowRadius: 15,
                                marginBottom: 10,
                                marginRight:10
                                }} >
                                <HStack h={200} justifyContent='center'>                               
                                  <AutoImage 
                                    source={product?.thumbnail}                                    
                                    maxHeight={200} style={{ alignSelf: "center", width:"100%"}}
                                  />
                                </HStack>
                                  <Text style={{backgroundColor: product?.status === "new" ? '#FFB302' 
                                    : product?.status === "Contected" ? '#FCE83A' 
                                    : product?.status === "Approved" ? '#56F000'
                                    : product?.status === "Seller Verified" ? '#3B7080'
                                    : product?.status === "Rejected" ? '#FF3838'
                                    : product?.status === "Malicious" ? '#F6DC9B'
                                    : product?.status === "Spam" ? '#F39595'
                                    : product?.status === "Repeated" ? '#A4ABB6'
                                    : '#D190F7', 
                                    color: colors.palette.primary100, padding: 5, borderRadius: 2, position: 'absolute', alignSelf: 'flex-end'}}>
                                    {product?.status === "new" ? "Under Review" : product?.status}
                                  </Text>
                                <VStack p={4} space={3} backgroundColor={colors.palette.gray300}>
                                  {/* <Pressable onPress={()=>navigation.navigate('ProductDescription', {"productId": medicine?.product_id})}> */}
                                    <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 18, lineHeight: 22, color: colors.palette.neutral800 }}>
                                      {/* Abel 40Mg Tablet */}
                                      {product?.name}
                                    </Text>
                                  {/* </Pressable> */}
                                  {/* <HStack space={2} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.angry100 }}>Status:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.primary300 }}>{product?.status === "new" ? "Under Review" : product?.status}</Text>
                                  </HStack> */}

                                  <HStack space={2} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.neutral400 }}>Offer Price:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21,  color: colors.palette.neutral600 }}>Rs. {product?.offer_price}</Text>
                                  </HStack>
                                  
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>Expiry:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{product?.expiry_date}</Text>
                                  </HStack>
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>Mfg. Date:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{product?.manufacturing_date}</Text>
                                  </HStack>
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>Category:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{product?.category}</Text>
                                  </HStack>
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>Brand:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{product?.brand}</Text>
                                  </HStack>
                                </VStack>
                              </VStack>                          
                            )) } 
                          </HStack>                          
                        </VStack> 
                        
                        <VStack w={{base: '100%', md:'68%'}} space={5}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: 24,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              Your Requirements Status
                          </Text>
                          {userRequirements?.length===0 && 
                            <VStack space={3} mb={20}>
                              <AutoImage
                                source={require(`../../../assets/images/search_empty.png`)}
                                maxHeight={320}
                                style={{ width: "100%" }}
                                resizeMode="contain"
                              />
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.normal,
                                  fontSize: 16,
                                  lineHeight: 24 ,
                                  color: colors.palette.neutral400,
                                  textAlign: 'center'
                                }}
                                >
                                  You did not submit any requirements yet. Do it now.
                              </Text>
                            </VStack>
                          }
                          
                          <VStack flexWrap='wrap' space={5}>                              
                            {userRequirements?.map((product,index)=>(  
                              <VStack                               
                                w={{base: '100%',sm: '49%',xl:'31.5%'}}
                                p={2} 
                                minHeight={200} 
                                // maxHeight={'100%'}
                                maxHeight={450}
                                style={{
                                backgroundColor:  colors.palette.neutral100,
                                overflow: 'hidden',
                                shadowColor: 'black',
                                shadowOffset: {width: 1, height: 2},
                                shadowOpacity: 0.1,
                                shadowRadius: 15,
                                marginBottom: 10,
                                marginRight: 10,
                                }} 
                              > 
                                <Text style={{backgroundColor: product?.status === "new" ? '#FFB302' 
                                  : product?.status === "Contected" ? '#FCE83A' 
                                  : product?.status === "Approved" ? '#56F000'
                                  : product?.status === "Seller Verified" ? '#3B7080'
                                  : product?.status === "Rejected" ? '#FF3838'
                                  : product?.status === "Malicious" ? '#F6DC9B'
                                  : product?.status === "Spam" ? '#F39595'
                                  : product?.status === "Repeated" ? '#A4ABB6'
                                  : '#D190F7', 
                                  color: colors.palette.primary100, padding: 5, borderRadius: 2, position: 'absolute', alignSelf: 'flex-end', zIndex:2}}>
                                  {product?.status === "new" ? "Under Review" : product?.status}
                                </Text>
                                <VStack p={4} space={3} backgroundColor={colors.palette.gray300}>
                                
                                  <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 18, lineHeight: 22, color: colors.palette.neutral800 }}>
                                    {/* Abel 40Mg Tablet */}
                                    {product?.product_name}
                                  </Text>                                  
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>City:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{product?.city}</Text>
                                  </HStack>
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>Quantity:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{product?.quantity}</Text>
                                  </HStack>
                                  <HStack space={3} alignItems="center">
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral400 }}>Mobile:</Text>
                                    <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 14, lineHeight: 21, color: colors.palette.neutral600 }}>{product?.mobile}</Text>
                                  </HStack>
                                </VStack>
                                <VStack space={1}>
                                  <Divider/>
                                  <HStack 
                                    space={5} 
                                    alignItems="flex-start" 
                                    justifyContent={'flex-end'}
                                  >                                 
                                    <Pressable onPress={()=>{                                      
                                      //API To Be Implemented...
                                    }}>
                                      <MaterialCommunityIcons name="delete" size={24} color={colors.palette.angry100} />
                                    </Pressable>
                                  </HStack>
                                </VStack>
                              </VStack>                                                     
                            )) } 
                          </VStack>
                        </VStack>  
                      </>
                      } 
                    </VStack>                 
                  </Stack>      
                </VStack>
              </Stack>
            </PageLayout>
          </Screen>, //code for web and other platforms
        
        native : <></>
          // <PageLayout  variant={1}>
          //   <ScrollView style={{backgroundColor: colors.palette.neutral100}}>
          //     <Stack w="100%" mb={100}>
          //       {/* Parent VStack */}
          //       <VStack space={4} pl={5} pr={5} pt={10}>
          //         {/* go back */}
          //         <HStack space={2} alignItems="center">
          //           <Pressable onPress={() => navigation.goBack()}>
          //             <Ionicons name="arrow-back" size={25} color={colors.palette.neutral600}/>
          //           </Pressable>
          //           <Text 
          //             style={{
          //               fontFamily: typography.fonts.poppins.bold,
          //               fontSize: 18,
          //               lineHeight: 28.8,
          //               color: colors.palette.neutral700,
          //             }}
          //             >
          //               {/* {sellerName} */}
          //               Priya Mathur’s Profile
          //           </Text>
          //         </HStack>
          //         <Image
          //           source={require("../../../assets/images/sellerProfile/mobile_sellerProfile_banner.png")}
          //           // maxHeight={60}
          //           style={{ width: "100%", height: 110 }}
          //            alt="image"
          //         />
      
          //         {/* Main VStack */}
          //         <Stack direction={'column'} space={4} mt={2}>
          //           {/*profile information section, review */}
          //           <VStack w={"100%"} space={5}>
          //               {/* profile card */}
          //               <VStack space={2} mt={-10} p={5} alignItems={'center'} style={{
          //                 shadowColor: '#171717',
          //                 shadowOffset: {width: 0, height: 3},
          //                 shadowOpacity: 0.1,
          //                 shadowRadius: 20,
          //                 borderColor: colors.palette.gray100,
          //                 borderWidth: 1}}>
          //                 <Image
          //                   source={{uri: data && data.current.user && data.current.user.avatar && `${Config.MEDIA_URL}/${data.current.user.avatar}`}}
          //                   maxHeight={120}
          //                   style={{ width: 120, height: 120, borderRadius: 120/2, marginTop: -60, borderColor: colors.palette.secondary300, borderWidth: 5}}
          //                   // resizeMode="contain" 
          //                   alt="image"
          //                 />
          //                 <Text 
          //                   style={{
          //                     fontFamily: typography.fonts.poppins.semiBold,
          //                     fontSize: 19,
          //                     lineHeight: 28.8,
          //                     color: colors.palette.neutral700,
          //                   }}
          //                   >
          //                     {/* {sellerName} */}
          //                     {data && data.current.first_name} {data && data.current.last_name}
          //                 </Text>
          //                 <Text 
          //                   style={{
          //                     fontFamily: typography.fonts.poppins.medium,
          //                     fontSize: 16,
          //                     lineHeight: 24,
          //                     color: colors.palette.neutral700,
          //                   }}
          //                   >
          //                     {data && data.current.business_name}
          //                 </Text>
          //                 <Text 
          //                   style={{
          //                     fontFamily: typography.fonts.poppins.normal,
          //                     fontSize: 14,
          //                     lineHeight: 24,
          //                     color: colors.palette.neutral400,
          //                     textAlign: 'center'
          //                   }}
          //                   >
          //                     {data && data.current.business_address}
          //                 </Text>
          //                 <HStack space={5} mt={2} mb={5}>
          //                   <Pressable onPress={()=>{Linking.openURL(`tel://${data && data.current.mobile}`)}}>
          //                     <Image
          //                       source={require("../../../assets/images/sellerProfile/call.png")}
          //                       maxHeight={45}
          //                       style={{ width: 45}}
          //                       resizeMode="contain" alt="image"
                                
          //                     />
          //                   </Pressable>
          //                   <Pressable onPress={()=>{Linking.openURL(`email://${data && data.current.email}`)}}>
          //                     <Image
          //                       source={require("../../../assets/images/sellerProfile/msg.png")}
          //                       maxHeight={45}
          //                       style={{ width: 45}}
          //                       resizeMode="contain" alt="image"
          //                     />
          //                   </Pressable>
          //                   <Pressable onPress={()=>{Linking.openURL(`tel://${data && data.current.mobile}`)}}>
          //                     <Image
          //                       source={require("../../../assets/images/sellerProfile/whatsapp.png")}
          //                       maxHeight={45}
          //                       style={{ width: 45}}
          //                       resizeMode="contain" alt="image"
          //                     />              
          //                   </Pressable>              
          //                 </HStack>
          //               </VStack>

          //               {/* review */}
          //               <Text 
          //                 style={{
          //                   fontFamily: typography.fonts.poppins.semiBold,
          //                   fontSize: 20,
          //                   lineHeight: 28.8,
          //                   color: colors.palette.neutral700,
          //                 }}
          //                 >
          //                   Reviews
          //               </Text>
          //               {/* Reviews section parent vstack */}
          //               <Stack direction={'column'}>
          //                 {/* write a review section  */}
          //                 <VStack w={'100%'} space={4} style={{
          //                   padding: 7,
          //                   shadowColor: '#171717',
          //                   shadowOffset: {width: 2, height: 0},
          //                   shadowOpacity: 0.2,
          //                   shadowRadius: 5,
          //                   borderColor: colors.palette.gray100,
          //                   borderWidth: 1
          //                   }}>
          //                   <HStack>
          //                     <VStack w={'49%'} space={3}>
          //                       <Text 
          //                         style={{
          //                           fontFamily: typography.fonts.poppins.medium,
          //                           fontSize: 17,
          //                           lineHeight: 22,
          //                           color: colors.palette.neutral700,
          //                         }}
          //                         >
          //                           {data && data.current.reviews && data.current.reviews.length} Reviews
          //                       </Text>
          //                       <Stack direction={'column'} space={3} mb={2}>
          //                         {/* review star component */}
          //                         <ReviewStars rating={4} starStyle={{width: 27, height: 27}}/>
          //                         <Text 
          //                           style={{
          //                             fontFamily: typography.fonts.poppins.normal,
          //                             fontSize: 15,
          //                             lineHeight: 24,
          //                             color: colors.palette.neutral700,
          //                           }}
          //                           >
          //                             4.7 out of 5
          //                         </Text>
          //                       </Stack>
          //                     </VStack>

          //                     {/* write a review button */}
          //                     {(data?.user?.id !== authenticationStore?.user?.id) && 
          //                     <Box w={'50%'} style={{justifyContent: 'center'}}>
          //                       <Button
          //                         text='Write a Review'
          //                         preset="default"
          //                         onPress={() => navigation.navigate("Review",{sellerId: data?.id})}
          //                         // onPress={() => navigation.navigate("Review")}
          //                         style={{
          //                           backgroundColor: colors.palette.primary600,
          //                           borderRadius: 5,
          //                           width: '100%',
                                    
          //                         }}
          //                         pressedStyle={{
          //                           backgroundColor: colors.palette.neutral100,
          //                           borderRadius: 5,
          //                           borderWidth: 0,
          //                         }}
          //                         textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
          //                         pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
          //                       />
          //                     </Box>}
          //                   </HStack>
      
          //                   {/* status bar / progress bar */}
          //                   <VStack space={3} >
          //                     {['5','4','3','2','1'].map((star,item)=>{
          //                       return (
          //                         <HStack space={2} key={`stars_${item}`}>
          //                           <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral700, }}> {star} Star </Text>
          
          //                           <Box justifyContent={'center'}  w={"60%"}>
          //                           <ProgressBar progress={((data && data.current.reviews && data.current.reviews[0] &&  data.current.reviews[0].star_rating_info && data.current.reviews[0].star_rating_info[star].count)/(data && data.current.reviews && data.current.reviews.length))*100} progressColor={colors.palette.secondary300} style={{backgroundColor: colors.palette.secondary100}}/>
          //                           </Box>
          
          //                           <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral700 }}> {/* {noOf5Stars} */} {data && data.current.reviews && data.current.reviews[0] && data.current.reviews[0].star_rating_info && data.current.reviews[0].star_rating_info[star].count} </Text>
          //                         </HStack>
          //                       )
          //                     })}                                
          //                   </VStack>
          //                 </VStack>
                          
          //                 <VStack w={'100%'}>
          //                   {data && data.current.reviews && data.current.reviews.map((item,index)=>{
          //                     return (
          //                       <VStack space={3} pb={3} style={{
          //                         padding: 10,
          //                         shadowColor: '#171717',
          //                         shadowOffset: {width: 0, height: 2},
          //                         shadowOpacity: 0.2,
          //                         shadowRadius: 5,
          //                         borderColor: colors.palette.gray100,
          //                         borderWidth: 1}}
          //                         key={`data_reviews_${index}`}
          //                         >
          
          //                         <HStack space={2} alignItems='center'>
          //                           <Image
          //                             source={require("../../../assets/images/account_settings/photo.png")}
          //                             maxHeight={60}
          //                             style={{ width: 60, height: 60, borderRadius: 60/2, borderColor: colors.palette.secondary300, borderWidth: 3}}
          //                             // resizeMode="contain"
          //                             alt="image"
          //                           />
          //                           <HStack space={3}>
          //                             <Stack direction={'row'}>
          //                               <ReviewStars rating={item.star_rating} starStyle={{width: 22, height: 22}}/>
          //                               <Text  style={{ fontFamily: typography.fonts.poppins.semiBold, fontSize: 14, lineHeight: 24, color: colors.palette.neutral400 }}> {/* {noOf5Stars} */} {item.star_rating} </Text>
          //                             </Stack>
          //                             <Box w={'100%'} justifyContent={'center'} alignItems={'flex-start'}>
          //                               <Text style={{ fontFamily: typography.fonts.poppins.light, fontSize: 13, lineHeight: 19.5, color: colors.palette.neutral400 }}> {/* {noOf5Stars} */}{item.days_ago}</Text>
          //                             </Box>
          //                           </HStack>
          //                         </HStack>
          //                         <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 18, lineHeight: 22, color: colors.palette.neutral800 }}>{item.reviewer}</Text>
          //                         <Box w={'98%'}>
          //                           <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 15, lineHeight: 24, color: colors.palette.neutral400 }}>{item.feedback}</Text>
          //                         </Box>
          //                       </VStack>                               
          //                     )
          //                   })}                            
          //                 </VStack>
          //               </Stack>
          //           </VStack>

          //           {/* provided offers */}
          //           <VStack w={"100%"} space={5}>
          //             <Text 
          //               style={{
          //                 fontFamily: typography.fonts.poppins.semiBold,
          //                 fontSize: 24,
          //                 lineHeight: 28.8,
          //                 color: colors.palette.neutral700,
          //               }}
          //               >
          //                 Provided Offers
          //             </Text>
          //             <HStack justifyContent={"space-between"} flexWrap='wrap'>
          //             { medicineCarddata.current.map((medicine,index)=>(
          //               <Box key={`med_offers_${index}`} maxH={343} mb={5} w={{base: '100%',sm: '49%',xl:'31.5%'}}>
          //                 <MedicineCard
          //                   key={medicine.itemId}
          //                   medicinePhoto={medicine.medicinePhoto}
          //                   medicineImageMaxHeight={medicine.medicineImageMaxHeight}
          //                   medicineImageWidth={medicine.medicineImageWidth}
          //                   cardWidth={medicine.cardWidth}
          //                   cardHeight={medicine.cardHeight}
          //                   imageContainer={medicine.imageContainer}
          //                   contentContainer={medicine.contentContainer}
          //                   tagShow={medicine.tagShow}
          //                 >
          //                 {medicine.childrenData}
          //                 </MedicineCard>
          //               </Box>
          //               )) } 
          //             </HStack>
          //           </VStack>
                    
          //         </Stack>
      
          //       </VStack>
          //     </Stack>
          //   </ScrollView>
          // </PageLayout>, 
          //code for Mobile devices
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 

import {Button as ReactBaseButton, Modal, Center } from "native-base";

const DeleteConfirmationModal = (props:{showDeleteModal:any, setShowDeleteModal:any, offerId:string}) => {
  const { authenticationStore } = useStores(); 
  const { setOverlayVisible } = React.useContext(OverlayContext)
  const [showModal, setShowModal] = useState(props.showDeleteModal);

  React.useEffect(() => {
    // console.log("Logout Model: ",showModal)    
    setShowModal(props.showDeleteModal)
  },[props.showDeleteModal])

  return <Center>      
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content maxWidth="400px">          
          <Modal.CloseButton />
          <Modal.Header>Confirm Delete</Modal.Header>
          <Modal.Body>
            <Text>Are you sure. Want to Delete?</Text>           
          </Modal.Body>
          <Modal.Footer>
            <ReactBaseButton.Group space={2}>
              <ReactBaseButton variant="ghost" colorScheme="blueGray" onPress={() => {
              props.setShowDeleteModal(false)
              setShowModal(false);
            }}>
                Cancel
              </ReactBaseButton>
              <ReactBaseButton onPress={() => {
                // console.log("props.offerId:: ",props.offerId)
                api.apisauce.delete(`offers/delete/${props.offerId}/`)
                .then((response) => {window.location.reload();})
                props.setShowDeleteModal(false)
                setShowModal(false);
            }}>              
                Confirm
              </ReactBaseButton>
            </ReactBaseButton.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>;
};

const $root: ViewStyle = {
  flex: 1,
}


export const ReviewStars = ({rating, starStyle}) => {
    // To set the default Star Selected
    const [defaultRating, setDefaultRating] = React.useState(rating);
    // To set the max number of Stars
    const [maxRating, setMaxRating] = React.useState([1, 2, 3, 4, 5]);
  
    // Filled Star. You can also give the path from local
    const starImageFilled =
      // 'https://raw.githubusercontent.com/AboutReact/sampleresource/master/star_filled.png';
      require("../../../assets/images/sellerProfile/full_Star.png")
    // Empty Star. You can also give the path from local
    const starImageCorner =
      require("../../../assets/images/sellerProfile/blank_Star.png")
  
  
    return (
      <View style={styles.customRatingBarStyle}>
        {maxRating.map((item, key) => {
          return (
            <View
              key={`sellerProfile-customRating-Bar-x1-${key}`}
              // onPress={() => setDefaultRating(item)}
              >
              <Image
                style={[styles.starImageStyle,starStyle]}
                source={ item <= defaultRating ?  starImageFilled : starImageCorner }
                alt="image"
              />
            </View>
          );
        })}
      </View>
    )
  

}

const $container: ViewStyle = {
  justifyContent: "center",
}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}

const styles = StyleSheet.create({

  customRatingBarStyle: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  starImageStyle: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
});

/**
 * MedicineCard to be used on seller profile
 */
const MedicineCard = (props) => {
  // {backgroundColor,headingColor, medicinePhoto, cardWidth}
  const navigation = useNavigation<any>()

  return (
    <VStack 
      // space={2}
      // w={'32%'} 
      w={props.cardWidth}
      // p={2} 
      minHeight={props.cardHeight} 
      style={{
      backgroundColor:  colors.palette.neutral100,
      overflow: 'hidden',
      shadowColor: 'black',
      shadowOffset: {width: 1, height: 2},
      shadowOpacity: 0.1,
      shadowRadius: 3,
      borderColor: colors.palette.gray100,
      borderWidth: 1
      }} >
      <HStack h={props.imageContainer} p={4} justifyContent='center' backgroundColor={colors.palette.primary100}>
        <Pressable style={{flex: 1}} onPress={()=>navigation.navigate("ProductDescription", {'productId': props?.productId})}>
          <Image 
            source={{uri: props.medicinePhoto}}
            // source={props.medicinePhoto}
            maxHeight={props.medicineImageMaxHeight} 
            style={{ alignSelf: "center", width: props.medicineImageWidth, height: props.medicineImageMaxHeight}}
            // resizeMode='contain'
            alt="image"
          />
        </Pressable>
      </HStack>
      {props.tagShow && 
        <Box style={{width: 110, position: 'absolute', alignSelf: 'flex-end'}}>
          {/* offer_tag */}
            <Image 
            source={require(`../../../assets/images/landing_screen/offer_tag.png`)}
            maxHeight={50} 
            style={{ alignSelf: "flex-end", width: '100%', height: 50, marginRight: -10}}
            // resizeMode='contain' 
            alt="image"
          />
          <Text style={{color: colors.palette.primary100, padding: 5, borderRadius: 2, position: 'absolute', alignSelf: 'flex-end'}}>Upto- {props.max_offer_percentage}%</Text>
        </Box>
       }

      <Box h={props.contentContainer} p={4}>
         {props.children}
      </Box>
      
    </VStack>
  )
}