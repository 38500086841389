// TODO: write documentation for colors and palette in own markdown file and add links from here
// 
const palette = {
  neutral100: "#FFFFFF",
  neutral200: "#C7E9F8",
  neutral300: "#878787",
  neutral400: "#797979",
  neutral500: "#4B5559",
  neutral600: "#144356",
  neutral700: "#1D343E",
  neutral800: "#2D2A2A",
  neutral900: "#0E3849",
  neutral1000: "#040C10",
  neutral1100: "#000000",
  neutral1200: "#B4BEC8",
  neutral1300: "#FAFAFA",

  primary100: "#EBF9FF",
  primary200: "#DAF1FB",
  primary300: "#005c83",
  primary400: "#005376",
  primary500: "#004a69",
  primary600: "#004562",
  primary700: "#F6F8FA",

  secondary100: "#ECFFFC",
  secondary200: "#BFF4ED",
  secondary300: "#32DAC4",

  accent100: "#E68303",
  accent200: "#FFE1B2",
  accent300: "#2980BA",
  accent400: "#F6F6F6",

  angry100: "#C70706",
  angry500: "#FF2D26",

  overlay20: "rgba(25, 16, 21, 0.2)",
  overlay50: "rgba(25, 16, 21, 0.5)",

  // mobile_colors
  gray100: "#F5F5F5",
  gray200: "#A1A8B0",
  gray300: "#F7F6F7",
  gray400: "#434343",

  brandGradient_blue: "#95D3D3",

} as const

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral800,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral600,
  /**
   * The default color of the screen background.
   */
  background: palette.neutral100,
  /**
   * The default border color.
   */
  border: palette.neutral400,
  /**
   * The main tinting color.
   */
  tint: palette.primary500,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: palette.angry500,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,
}  