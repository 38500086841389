import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, Pressable, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, ContactForm, Icon, Screen } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, Divider, HStack, Stack, Text, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../theme"
import { Avatar } from "@rneui/themed"
import { BlogItem } from "../../components/BlogItem"
import { useNavigation } from "@react-navigation/native"
import Config from "../../config"
import { useStores } from "../../models"

//imports for mobile----->
import Constants from 'expo-constants';
import { api } from '../../services/api'
import { ApiResponse } from "apisauce"
import { parseInitialURLAsync } from "expo-linking"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Product } from "../../components/Product"

// @ts-ignore
export const HomeScreen: FC<StackScreenProps<AppStackScreenProps, "Home">> = observer(function HomeScreen() {    
  const { 
    authenticationStore,
    ProductListStore: {dealOfTheDayProducts}
   } = useStores()
  const blogItemWidthBreakpoint = useBreakpointValue({base: '100%', sm: '47%', md: '31.5%', lg: '23.5%'});
  const why_nxp_cardsBreakpoint = useBreakpointValue({base: '100%',sm: '70 %',md: '40%',lg:"25%"});
  const showAboveMd = useBreakpointValue({base: 2, lg: 3});
  const showBlogItem = useBreakpointValue({base: 2, sm: 2, md: 3, lg: 4 });

  const [data, setData] = useState<any>()
  
  const handleInitialLink = async () => {      
    parseInitialURLAsync()
    .then((url) => {    
      console.log(url);
      console.log(window.location.pathname);
       
      if(window.location.pathname != "/Welcome"){    
        console.log("Not Welcome Page");      
        if(Object.keys(url?.queryParams)?.length>0){
          if(url.path === "api/password/reset/confirm/uid/token/"){              
            navigation.navigate('ResetPassword', url.queryParams)
          }
          else{
            navigation.navigate(url?.path,url?.queryParams)
          }
        }
        else{
          navigation.navigate(url?.path)}
      }
    })
  };

  useEffect(()=>{
    handleInitialLink(); 

    api.apisauce.get("pages/?type=pages.HomePage&fields=*")
    .then(
      (response:ApiResponse<any,any>)=>{        
        if(response.status == 403){
          AsyncStorage.clear()
          navigation.navigate('Signin')
        }
        else if(response?.data?.items)
          setData(response?.data?.items[0])        
      }
    ).catch(error =>{console.log(error)})  

  },[])
  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  //for mobile


  const WebCode =()=>{
    return(
    <Screen style={$root} preset="scroll">
      <PageLayout variant={authenticationStore.isAuthenticated?1:0}>
        <Stack w="100%" mb={100}>
          <Stack minHeight={useBreakpointValue({base: '55vw',md: '40vw',lg: 550})}>
            {/* Banner Image */}  
            <AutoImage 
              
              source={require("../../../assets/images/landing_screen/banner_background.png")}
              maxHeight={
                useBreakpointValue({base: '55vw',md: '40vw',lg: 550})
              } 
              style={{width:"100%"}}
            />
              {/* Banner Capsule */}
              <AutoImage 
                source={require("../../../assets/images/landing_screen/banner_Capsule.png")} 
                maxHeight={535} style={{
                  width: useBreakpointValue({base:'40vw',xl: 500}),
                  height: useBreakpointValue({base:'40vw',xl: 500}),
                  position:"absolute", left: 0, zIndex: 1}}
                // resizeMode="contain"
              />
              {/* Banner photo */}
              <AutoImage 
                  source={require("../../../assets/images/landing_screen/Banner_photo.png")} 
                  maxHeight={530} style={{
                    width: useBreakpointValue({base:'40vw',xl: 500}),
                    height: useBreakpointValue({base:'40vw',xl: 500}),
                    position:"absolute", left: useBreakpointValue({base: '50%', xl: '55%'}), top: 20, alignSelf: 'center'}}
                  resizeMode="contain"
                />
            {/* Banner Text */}
            <VStack position="absolute" h={
              useBreakpointValue({base: '53vw',md: '40vw',lg: 550})} 
              ml={{base: 13,sm: '10vw',md:20}} justifyContent="center" zIndex={2}>               
                <Text
                  style={{
                    color:colors.palette.primary600, 
                    fontFamily:typography.fonts.poppins.bold,
                    fontSize: useBreakpointValue({base: 18,sm: 30,md: 40,lg: 60}),
                    lineHeight: useBreakpointValue({base: 25,sm: 40,md: 50,lg: 72})
                  }}
                >{data?.banners[0].text_option_1}</Text>
              <Text
                style={{
                  color:colors.palette.secondary300, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize: useBreakpointValue({base: 18,sm: 30,md: 40,lg: 60}),
                  lineHeight: useBreakpointValue({base: 25,sm: 40,md: 50,lg: 72})
                }}
              >{data?.banners[0].text_option_2}</Text>
              <Text
                style={{
                  color:colors.palette.primary600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize: useBreakpointValue({base: 18,sm: 30,md: 40,lg: 60}),
                  lineHeight: useBreakpointValue({base: 25,sm: 40,md: 50,lg: 72})             
                }}
              >{data?.banners[0].text_option_3}</Text>
              <Box w={'55%'}>
                <Text
                  style={{
                    color:colors.palette.secondary300, 
                    fontFamily:typography.fonts.poppins.medium,
                    fontSize: useBreakpointValue({base: 14,sm: 16, md: 20}),
                    lineHeight: useBreakpointValue({base: 19,sm: 24, md: 30})
                  }}
                >{data?.banners[0].text}</Text>
              </Box>
              {authenticationStore.isAuthenticated === false && <Button
                text={data?.banners[0].button_text}
                preset="default"
                onPress={() => {navigation.navigate("Signup")}}          
                style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: 123 }, {marginTop:10}]}
                pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
              />}
            </VStack>
          </Stack>

          {/* Banner bottom callout */}
          <Box background={colors.palette.primary400} p={2}>
            <Text 
              style={{
                alignSelf:"center",
                color:colors.palette.neutral100, 
                fontFamily:typography.fonts.poppins.bold,
                fontSize: useBreakpointValue({base: 16,sm: 24,md: 38,lg: 50, xl: 60}),
                lineHeight: useBreakpointValue({base: 25,sm: 30,md: 50,lg: 62, xl: 72}) 
              }}
            >
              {data?.callout_text}
            </Text>
          </Box>
          {/* Brand recognitions like iStart and Startup India */}
          <HStack justifyContent="space-evenly" m={5} mb={10}>
            {data?.recognitions?.map((item,index)=>{              
              return (
                <Box style={{ width: '20%',borderColor: colors.palette.primary300, borderWidth: 1, borderRadius: 5}} key={`nonAuthHome-recognition-${index}`}>
                <AutoImage 
                  source={{uri: Config.MEDIA_URL+item.image.url}}
                  maxHeight={useBreakpointValue({base: 30,sm: 40,md: 80, lg: 114})} style={{width: '90%', alignSelf: 'center'}} resizeMode='contain'            
                />
              </Box>
              )            
            })}
          </HStack>
          {/* About Us Section */}
          <VStack 
            w="100%" p={10} 
            justifyContent="center"
            space={5} background={colors.palette.secondary100}>
            <VStack space={2}>
              <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:40,              
                  alignSelf:"center"
                }}
              >{data?.aboutus_heading}</Text>
              <HStack justifyContent="center">
                <Divider 
                  width={190} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}                          
                />
              </HStack>
            </VStack>
            <Text
              style={{
                color:colors.palette.neutral800, 
                fontFamily:typography.fonts.poppins.bold,
                fontSize:20,
                lineHeight:22,
                alignSelf:"center",
                textAlign: 'center'
              }}
            >"{data?.aboutus_subheading}"</Text>
            <Stack 
              direction={{base: 'column', xl: 'row'}}
              w="100%" space={10}              
              justifyContent="center"
            >
              {data && data.aboutus_image &&
              <AutoImage 
                source={{uri: Config.MEDIA_URL + data.aboutus_image.url}}
                maxHeight={useBreakpointValue({base: 250,md:360})}   style={{alignSelf:'center'}} 
              />}
              <VStack 
                w={{base: '100%',xl:"60%"}} space={5}
                justifyContent="center"
              >
                <Text
                  style={{
                    color:colors.palette.neutral800, 
                    fontFamily:typography.fonts.poppins.medium,
                    fontSize:18,
                    lineHeight:22
                  }}
                >What is NearXpiry?</Text>
                <Text
                  style={{
                    color:colors.palette.neutral400,
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24,
                    textAlign:"justify"
                  }}
                >
                  {data?.aboutus_text}
                </Text>
                <Button
                  text="More Information"
                  preset="default"
                  onPress={() => {navigation.navigate("About")}}          
                  style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: 176 }, {marginTop:10}]}
                  pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                  textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                  pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                  RightAccessory={(props) => <Icon icon="more" />}
                />
              </VStack>
            </Stack>
          </VStack>
          {/* Why NearXpiry Section */}
          <VStack w="100%" p={{base:10, md:20}} space={10} background={colors.palette.neutral100}>
            <VStack space={2}>
              <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:useBreakpointValue({base: 30,md:40}),              
                  alignSelf:"center"
                }}
              >Why NearXpiry?</Text>              
              <HStack justifyContent="center">
                <Divider 
                  width={{base: 250, md:350}} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}                          
                />
              </HStack>
            </VStack>            
            <HStack w="100%" space={{base: 0, lg: 5}} justifyContent="center" flexWrap={{base:'wrap', lg: 'none'}}>         
              {data?.usps?.map((item,index)=>{                 
                return (
                  <VStack 
                    w={why_nxp_cardsBreakpoint} space={5} 
                    m={{base: '2vw',lg:'0vw'}}
                    alignItems="center"
                    borderWidth={1.5}
                    borderColor={colors.palette.primary400}
                    px={5} py={20} borderRadius={3} shadow="9"
                    background={colors.palette.neutral100}
                    key={`nonAuthHome-usp-${index}`}
                  >
                    <AutoImage 
                      source={{uri: Config.MEDIA_URL+item.icon}}
                      maxHeight={60} style={{marginBottom:10}}                        
                    />
                    <Text
                      style={{
                        color:colors.palette.neutral700, 
                        fontFamily:typography.fonts.poppins.semiBold,
                        fontSize:18,
                        lineHeight:26,
                        textAlign:"center"
                      }}
                    >{item.title}</Text>
                    <Text
                      style={{
                        color:colors.palette.neutral400,
                        fontFamily:typography.fonts.poppins.normal,
                        fontSize:16,
                        lineHeight:24,
                        textAlign:"center"
                      }}
                    >
                      {item.description}
                    </Text>
                  </VStack>
                )            
              })}   
            </HStack>
          </VStack>
          {/* Services Section */}
          <VStack w="100%" p={10} space={10} 
              background={colors.palette.primary200}
            >
            <VStack space={3}>
              <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:40,              
                  alignSelf:"center"
                }}
              >Services</Text>
              <HStack justifyContent="center">
                <Divider 
                  width={180} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}                          
                />
              </HStack>
            </VStack> 
            <Stack direction={{base: 'column', md: 'row'}}
              w="100%" space={5} justifyContent="center"               
            >
              {data?.services?.map((item,index)=>{                 
                return (
                  index%2 ===0?
                  <VStack 
                    w={{base: '100%', md: '40%', lg: "25%"}} space={5} 
                    alignItems="center"
                    p={5} borderRadius={3} shadow="9"
                    background={colors.palette.primary400}
                    key={`nonAuthHome-service-${index}`}
                  >
                    <AutoImage 
                      source={{uri:Config.MEDIA_URL+item.icon}}
                      maxHeight={50}                            
                    />
                    <Text
                      style={{
                        color:colors.palette.neutral100, 
                        fontFamily:typography.fonts.poppins.semiBold,
                        fontSize:18,
                        lineHeight:26,
                        textAlign:"center"
                      }}
                    >{item.title}</Text>
                    <Text
                      style={{
                        color:colors.palette.neutral200,
                        fontFamily:typography.fonts.poppins.normal,
                        fontSize:16,
                        lineHeight:24,
                        textAlign:"center"
                      }}
                    >
                      {item.description}
                    </Text>
                  </VStack>
                  :
                  <VStack 
                    w={{base: '100%',md: '40%', lg: "25%"}} space={5} 
                    alignItems="center"
                    p={5} borderRadius={3} shadow="9"
                    background={colors.palette.neutral100}
                    key={`nonAuthHome-service-${index}`}
                  >
                    <AutoImage 
                      source={{uri:Config.MEDIA_URL+item.icon}}
                      maxHeight={50}                            
                    />
                    <Text
                      style={{
                        color:colors.palette.primary500, 
                        fontFamily:typography.fonts.poppins.semiBold,
                        fontSize:18,
                        lineHeight:26,
                        textAlign:"center"
                      }}
                    >{item.title}</Text>
                    <Text
                      style={{
                        color:colors.palette.neutral400,
                        fontFamily:typography.fonts.poppins.normal,
                        fontSize:16,
                        lineHeight:24,
                        textAlign:"center"
                      }}
                    >
                      {item.description}
                    </Text>
                  </VStack>
                )            
              })}               
            </Stack>
          </VStack>
          {/* Deal of the day section */}
          <VStack w="100%" p={{sm: 5, md:10}} space={10} 
              background={colors.palette.neutral100} mt={5}
            >
            <VStack space={3}>
              <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:useBreakpointValue({base: 30,md:40}),              
                  alignSelf:"center"
                }}
              >Deals of the day</Text>
              <HStack justifyContent="center">
                <Divider 
                  width={{base: 250, md:350}} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}                          
                />
              </HStack>
            </VStack> 
            <HStack 
              w="100%" justifyContent="center" flexWrap={'wrap'}              
            >             
              {dealOfTheDayProducts.slice(0,showAboveMd).map((product, index) =>{
                return (
                  <Product key={`product_item_${index}`} product={product} variant={1}/>
                )
              })}              
            </HStack>
          </VStack>
          {/* Blogs section */}
          <VStack w="100%" px={{base: 5, md:20}} py={10} space={5} 
              background={colors.palette.secondary100}
              justifyContent="center"
            >
            <VStack space={3}>
              <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:40,              
                  alignSelf:"center"
                }}
              >Blogs</Text>
              <HStack justifyContent="center">
                <Divider 
                  width={120} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}                          
                />
              </HStack>
            </VStack> 
            <HStack 
              w="100%" space={4} justifyContent="center" flexWrap={'wrap'}          
              >
              {data?.blogs.slice(0,showBlogItem).map((item,index)=>{
                return (
                  <BlogItem blogItemWidth={blogItemWidthBreakpoint} navigation={navigation} data={item}  key={`nonAuthHome-blog-${index}`}/>
                )
              })}
            </HStack>
            <Button        
              text="View All"   
              preset="default"
              onPress={() => {navigation.navigate("Blog")}}          
              style={[{ borderRadius: 5 },{ width: 275 },{ height: 48 },{ alignSelf: "center" }]}
              pressedStyle={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5 }]}
              textStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
            /> 
          </VStack>
          {/* Testimonials section */}
          <VStack w="100%" py={10} space={10}
              background={colors.palette.neutral100}
              justifyContent="center"
            >
            <VStack space={3}>
              <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:40,              
                  alignSelf:"center"
                }}
              >Testimonials</Text>
              <HStack justifyContent="center">
                <Divider 
                  width={280} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}                          
                />
              </HStack>
            </VStack> 
            <Testimonials data={data?.testimonials}/>
          </VStack>
          {/* Mobile app download PLAY STORE CALLOUT Section */}
          <VStack w="100%" p={10} space={10}
              background={colors.palette.primary100}
              justifyContent="center" alignItems="center"
            >            
            <Text
              style={{
                color:colors.palette.primary600, 
                fontFamily:typography.fonts.poppins.semiBold,
                fontSize:24, lineHeight:28.8,
                alignSelf:"center"
              }}
            >ANDROID APP FOR MOBILE</Text> 
            <AutoImage source={require("../../../assets/images/landing_screen/google_play_btn.png")} maxHeight={104}/>
            <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:24, lineHeight:28.8,             
                  alignSelf:"center"
                }}
              >FREE DOWNLOAD</Text>      
          </VStack>
          {/* Contact Us Section and Form */}
          <VStack 
            w="100%" p={10} 
            justifyContent="center"
            space={5} background={colors.palette.neutral100}>
            <VStack space={2}>
              <Text
                style={{
                  color:colors.palette.neutral600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:40,              
                  alignSelf:"center"
                }}
              >Contact</Text>
              <HStack justifyContent="center">
                <Divider 
                  width={190} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}                          
                />
              </HStack>
            </VStack>
            <Stack direction={{base: 'column-reverse', lg: 'row'}}
              w="100%" space={10}              
              justifyContent="center"
            >
              <VStack 
                w={{base: '90%',sm: '80%',lg:"35%", xl: '40%'}} space={5}
                justifyContent="center" alignSelf={'center'}
              >
                <Text
                  style={{
                    color:colors.palette.primary600, 
                    fontFamily:typography.fonts.poppins.semiBold,
                    fontSize:24,
                    lineHeight:28.8
                  }}
                >Leave us a message</Text>  
                <ContactForm/>              
                
              </VStack>
              <AutoImage 
                source={require("../../../assets/images/landing_screen/contact_us_img.png")}
                maxHeight={useBreakpointValue({base: 300,md: 350, lg: 400, xl: 450})} style={{alignSelf:'center'}}                           
              />              
            </Stack>
          </VStack>
        </Stack>        
      </PageLayout>
    </Screen>
    )
}

  const MobileCode=()=>{
    return(
    <Screen style={[$root,{marginTop: Constants.statusBarHeight}]} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{ backgroundColor: 'red'}}>
         {/* <Pressable onPress={()=>{navigation.navigate('Welcome')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('About')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('Blog')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('BlogDetail')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('Contact')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('FAQ')}}> */}
         <Pressable onPress={()=>{navigation.navigate('Signin')}}>
         {/* <Pressable onPress={()=>{navigation.navigate('Signup')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('ResetPassword')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('ForgotPassword')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('PasswordChanged')}}> */}
        

         {/* <Pressable onPress={()=>{navigation.navigate('Welcome')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('ProductDescription')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Notifications')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Chat')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('AddNewProduct')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('AddNewOffer')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('GeneralSettings')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('OpenChat')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Review')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('SellerProfile')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Wishlist')}}> */}
            <Text> Home Route </Text>
          </Pressable>
      </Box>
    </Screen>
    )
  }
  


return (
  <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
       default : <WebCode/>, //code for web and other platforms
       native : <MobileCode/>, //code for Mobile devices
      })}
    {/* Platform select close here  */}
  </>
)

}) // observer close here 

// Styles for the page
const $root: ViewStyle = {
  flex: 1,
}

/**
 * Testimonials to be used on page for Deals of the day
 */
export interface TestimonialProps {
  /**
   * An optional style override useful for padding & margin.
   */  
  data:any
}

const Testimonials = (props:TestimonialProps) => {
  const fontSizeTestimonial = useBreakpointValue({base: 10, md: 12, lg: 16})
  const lineHeightTestimonial = useBreakpointValue({base: 18, md: 20, lg: 24})
  const showAboveMd = useBreakpointValue({base: false, md: true})
  const avtarSize = useBreakpointValue({base: 'medium',md: "large",lg:"xlarge"})
  const testimonialSize = useBreakpointValue({base: 300,md: 320, lg: 460, xl: 512})
  
  return (props.data && 
   <Stack direction={{base: 'column', sm: 'row'}} space={{base: 5, md:1}} w="100%" mt={10} justifyContent={'center'} alignItems={'center'}>
    {/* left testimonial */}
    <Stack 
      w={{base: '70%', sm: '40%',md:"30%"}} justifyContent="center" alignItems="center"      
    >      
      <AutoImage
        source={require("../../../assets/images/landing_screen/testimonials_bg_1.png")}
        maxWidth={useBreakpointValue({base: 350,md: 320, lg: 380, xl: 410})}
        style={{
          width:"100%", shadowOffset: {width: 0, height: 0},  
          shadowColor: '#171717',  
          shadowOpacity: 0.2,  
          shadowRadius: 3, 
        }}
      /> 
      <Stack position="absolute" top={{base: 0,lg:10}} left={0} justifyContent="center" alignItems="center" w="100%">
        <VStack space={{base: 2,lg: 3,xl:5}} alignItems="center" justifyContent="center" px={{base: 2,lg:7, xl: 10}}>
          <Avatar
            rounded 
            size="large"
            onPress={() => console.log("Works!")}
            source={{uri:Config.MEDIA_URL+props.data[0]?.image}}
          />
          <Text
            style={{
              color:colors.palette.neutral800, 
              fontFamily:typography.fonts.poppins.medium,
              fontSize:18,
              lineHeight:22,
              textAlign:"center"
            }}
          >{props.data[0]?.name}</Text>
          <Text
            style={{
              color:colors.palette.primary400, 
              fontFamily:typography.fonts.poppins.light,
              fontSize:13,
              lineHeight:19.5,
              textAlign:"center"
            }}
          >{props.data[0]?.designation}</Text>
          <Text
            style={{
              color:colors.palette.neutral400,
              fontFamily:typography.fonts.poppins.normal,
              fontSize: fontSizeTestimonial,
              lineHeight:lineHeightTestimonial,
              textAlign:"center", width:"80%"
            }}
          >
            {props.data[0]?.text}
          </Text> 
        </VStack>
      </Stack>
    </Stack>
    {/* middle testimonial */}
    {showAboveMd && <Stack 
      w={{md: '30%',lg:"40%"}} justifyContent="center" alignItems="center"     
    >      
      <AutoImage
        source={require("../../../assets/images/landing_screen/testimonials_bg_2.png")}
        style={{
          width:"100%", shadowOffset: {width: 0, height: 0},  
          shadowColor: '#171717',  
          shadowOpacity: 0.2,  
          shadowRadius: 3, 
        }} 
        maxWidth={testimonialSize} 
        // resizeMode="contain"
      /> 
      <Stack position="absolute" top={{md: 0,lg:-50}} left={0} justifyContent="center" alignItems="center" w="100%">
        <VStack space={{base: 2, lg: 3, xl:5}} alignItems="center" justifyContent="center">
          <Avatar
            rounded 
            size={avtarSize}
            onPress={() => console.log("Works!")}
            source={{uri:Config.MEDIA_URL+props.data[1]?.image}}
          />
          <Text
            style={{
              color:colors.palette.neutral800, 
              fontFamily:typography.fonts.poppins.medium,
              fontSize:18,
              lineHeight:22,
              textAlign:"center"
            }}
          >{props.data[1]?.name}</Text>
          <Text
            style={{
              color:colors.palette.primary400, 
              fontFamily:typography.fonts.poppins.light,
              fontSize:13,
              lineHeight:19.5,
              textAlign:"center"
            }}
          >{props.data[1]?.designation}</Text>
          <Text
            style={{
              color:colors.palette.neutral400,
              fontFamily:typography.fonts.poppins.normal,
              fontSize: fontSizeTestimonial,
              lineHeight:lineHeightTestimonial,
              textAlign:"center", width:"80%"
            }}
          >
            {props.data[1]?.text}
          </Text> 
        </VStack>
      </Stack>
    </Stack>}
    {/* right testimonial */}
    <Stack 
      w={{base: '70%', sm: '40%',md:"30%"}} justifyContent="center" alignItems="center"      
    >      
      <AutoImage
        source={require("../../../assets/images/landing_screen/testimonials_bg_3.png")}
        maxWidth={useBreakpointValue({base: 350,md: 320, lg: 380, xl: 410})}
        style={{
          width:"100%", shadowOffset: {width: 0, height: 0},  
          shadowColor: '#171717',  
          shadowOpacity: 0.2,  
          shadowRadius: 3, 
        }}
      /> 
      <Stack position="absolute" top={{base: 0,lg: 10}} left={0} justifyContent="center" alignItems="center" w="100%">
        <VStack space={{base: 2, lg: 3, xl:5}} alignItems="center" justifyContent="center" px={{base: 2,lg:7, xl: 10}}>
          <Avatar
            rounded 
            size="large"
            onPress={() => console.log("Works!")}
            source={{uri:Config.MEDIA_URL+props.data[2]?.image}}
          />
          <Text
            style={{
              color:colors.palette.neutral800, 
              fontFamily:typography.fonts.poppins.medium,
              fontSize:18,
              lineHeight:22,
              textAlign:"center"
            }}
          >{props.data[2]?.name}</Text>
          <Text
            style={{
              color:colors.palette.primary400, 
              fontFamily:typography.fonts.poppins.light,
              fontSize:13,
              lineHeight:19.5,
              textAlign:"center"
            }}
          >{props.data[2]?.designation}</Text>
          <Text
            style={{
              color:colors.palette.neutral400,
              fontFamily:typography.fonts.poppins.normal,
              fontSize: fontSizeTestimonial,
              lineHeight:lineHeightTestimonial,
              textAlign:"center", width:"80%"
            }}
          >
            {props.data[2]?.text}
          </Text> 
        </VStack>
      </Stack>
    </Stack>
   </Stack>
  )
}
