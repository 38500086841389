import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../navigators"
import { AutoImage, Button, Screen, Text, TextFieldAccessoryProps } from "../../../components"
import { useNavigation } from "@react-navigation/native"
import { Box, HStack, Image, Pressable, VStack } from "native-base"
import { colors, typography } from "../../../theme"
import { Divider } from "@rneui/base"
import { InputField } from "../../../components/InputField"
import { Feather, Ionicons } from "@expo/vector-icons"
import { useStores } from "../../../models"
import { AuthenticationStore } from "../../../models/AuthenticationStore"

//imports for mobile----->
import Constants from 'expo-constants';
import { Dimensions } from "react-native"
import { OverlayContext } from "../../../components/OverlayProvider"

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const RegisterationScreen: FC<StackScreenProps<AppStackScreenProps, "Registeration">> = observer(function RegisterationScreen() {
  // Pull in one of our MST stores
  const { authenticationStore,
    RegistrationStore: {
      fullName, setFullName, 
      email, setEmail, 
      password, setPassword, 
      confirmPassword, setConfirmPassword, 
      fullNameValidationError, emailValidationError, passwordValidationError, confirmPasswordValidationError} 
  } = useStores()
  const {  setOverlayVisible } = useContext(OverlayContext);
  const [errors, setErrors] = useState({})

  // const [fullName, setFullName] = useState("")
  // const [email, setEmail] = useState("")
  // const [password, setPassword] = useState("")
  // const [confirmPassword, setConfirmPassword] = useState("")

  const [isAuthPasswordHidden , setIsAuthPasswordHidden] = React.useState(true)
  const [isAuthConfirmPasswordHidden , setIsAuthConfirmPasswordHidden] = React.useState(true)

  const PasswordRightAccessory = React.useMemo(
    () =>
      function PasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isAuthPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsAuthPasswordHidden(!isAuthPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isAuthPasswordHidden],
  )
  const ConfirmPasswordRightAccessory = React.useMemo(
    () =>
      function ConfirmPasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isAuthConfirmPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsAuthConfirmPasswordHidden(!isAuthConfirmPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isAuthConfirmPasswordHidden],
  )
  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const { height } = Dimensions.get('window');

  const [isSubmitted, setIsSubmitted] = useState(false)
  const refEmailInput = useRef<any>()
  const refPasswordInput = useRef<any>()
  const refConfirmPasswordInput = useRef<any>()
  //validation error ----->

  const fullNameError = isSubmitted ? fullNameValidationError : ""
  const emailError = isSubmitted ? emailValidationError : ""
  const passwordError = isSubmitted ? passwordValidationError : ""
  const confirmPasswordError = isSubmitted ? confirmPasswordValidationError : ""

  function signUp() {
    setIsSubmitted(true)

    //if there is something returned form validation error then return from here and do not call signUp api
    if (fullNameValidationError) return 
    if (emailValidationError) return 
    if (passwordValidationError) return 
    if (confirmPasswordValidationError) return 

    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token.
    authenticationStore.signup(fullName, email, password, confirmPassword, setOverlayVisible)
    .then((response:any)=>{ 
      console.log(response)
      if(response.kind==='ok')
        navigation.navigate( 'Home');
      else{
        setErrors({...response.data})
      }
    })        
    setIsSubmitted(false)
    // setEmail("")
    // setPassword("")
  }
  
  useEffect(() => {
    setFullName('')
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  },[])

  return (
  <>
    {/* divert code for web and android here  */}
      {Platform.select({ 
        //code for Web ------------------->
        default : 
        <Screen style={$root} preset="scroll">
        <HStack 
          w="100%" height={height < 750 ? '100%' : height}
          maxWidth={1440} alignSelf="center"
          style={{
            shadowOffset: {width: 0, height: 0},  
            shadowColor: '#171717',  
            shadowOpacity: 0.2,  
            shadowRadius: 3
          }}
        >
          <VStack 
            w={{base: '100%', md: '45%', lg: '35%', xl:"30%"}} space={5} p={30}
            alignItems="left"
          > 
            <Box style={{minHeight: 90}}>
              <Pressable  onPress={()=>navigation.navigate("Home")}>
              <AutoImage 
                source={require("../../../../assets/images/color_logo.png")}
                maxHeight={39}
                style={{
                  // position:"absolute",
                  // top:30, left:30
                }}
              />
              </Pressable>
            </Box>
            <VStack space={2}>
              <Text
                style={{
                  color:colors.palette.primary600, 
                  fontFamily:typography.fonts.poppins.bold,
                  fontSize:40,
                  lineHeight:48
                }}
              >Sign Up</Text>                
              <Text
                style={{
                  color:colors.palette.neutral400, 
                  fontFamily:typography.fonts.poppins.normal,
                  fontSize:16,
                  lineHeight:24
                }}
              >Getting started is easy</Text>     
            </VStack> 
            <VStack>
              {errors && Object.keys(errors).length>0 && Object.keys(errors).map(key =>{
                return <Text
                  style={{
                    color:'red', 
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24
                  }}
                >{errors[key]}</Text> 
              })}
            </VStack>
            {/* <HStack space={5}>
              <AutoImage 
                source={require("../../../../assets/images/sign_in_sign_up/google_btn.png")}
                maxHeight={48} style={{
                  borderWidth:1, borderColor:colors.palette.secondary300, borderRadius:5
                }}          
              /> 
              <AutoImage 
                source={require("../../../../assets/images/sign_in_sign_up/facebook_btn.png")}
                maxHeight={48} style={{
                  borderWidth:1, borderColor:colors.palette.neutral300, borderRadius:5
                }}                      
              /> 
            </HStack>      
            <Divider subHeader="Or continue with" 
                subHeaderStyle={{
                // textAlign:'center',
                marginTop: -12, 
                backgroundColor: 'white',
                zIndex: 1,
                width: 'max-content',
                alignSelf: 'center'
                }}/>         
            */}
            <VStack space={3}>
              <InputField
                  key="name"
                  value={fullName}              
                  onChangeText={(value) => setFullName(value)}
                  label="Your Name"
                  placeholder="Your Name"
                  helper={fullNameError}
                  status={fullNameError ? "error" : undefined}
                  onSubmitEditing={() => refEmailInput.current?.focus()}
                />    
              <InputField
                  ref={refEmailInput}
                  key="email"
                  value={email}              
                  onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                  label="Email Address"
                  placeholder="Email Address"
                  helper={emailError}
                  status={emailError ? "error" : undefined}
                  onSubmitEditing={() => refPasswordInput.current?.focus()}
                />    
              <InputField 
                 ref={refPasswordInput}
                  key="password"
                  value={password}              
                  onChangeText={(value) => setPassword(value)}
                  label="Password"
                  placeholder="Password"
                  secureTextEntry={isAuthPasswordHidden}
                  helper={passwordError}
                  status={passwordError ? "error" : undefined}
                  onSubmitEditing={() => refConfirmPasswordInput.current?.focus()}
                  RightAccessory={PasswordRightAccessory}
                />    
              <InputField
                  ref={refConfirmPasswordInput}
                  key="Confirm Password"
                  value={confirmPassword}              
                  onChangeText={(value) => setConfirmPassword(value)}
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  secureTextEntry={isAuthConfirmPasswordHidden}
                  helper={confirmPasswordError}
                  status={confirmPasswordError ? "error" : undefined}
                  RightAccessory={ConfirmPasswordRightAccessory}
                  onSubmitEditing={signUp}
                />    
              <Button
                text="Create Account"
                preset="default"
                onPress={ () => {signUp()}}          
                style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
                pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
              />   
              <Text
                style={{
                  color:colors.palette.neutral400, 
                  fontFamily:typography.fonts.poppins.normal,
                  fontSize:16,
                  lineHeight:24                
                }}              
              >
                Already have an account? <Pressable onPress={()=>navigation.navigate("Signin")}><span style={{ color:colors.palette.accent300}}>Sign in</span></Pressable>
              </Text> 
            </VStack>           
          </VStack>
          <AutoImage 
            source={require("../../../../assets/images/sign_in_sign_up/sign_up_img.png")}
            maxHeight={900} style={{height: "100%", width: "70%"}}                   
          />              
        </HStack> 
         </Screen>,
        //code for Mobile ------------------->
        native : 
        <Screen style={[$root,{marginTop: Constants.statusBarHeight}]} preset="scroll">
          <VStack bg={'#005C83'} w="100%" height="100%">
            {/* go back section */}
            <Box minH={100} >
              <HStack space={3} p={5} alignItems={'center'}>
                <Pressable onPress={() => navigation.goBack()}>
                  <Ionicons name="arrow-back" alt="icon" size={30} color={colors.palette.neutral100}/>
                </Pressable>
                <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.bold,
                    fontSize: 16,
                    lineHeight: 19,
                    color: colors.palette.neutral100,
                  }}
                  >
                    {/* {sellerName} */}
                    Back
                </Text>
              </HStack>
            </Box>
              
            {/* get started section */}
            <VStack bg={'white'} p={5} pt={10} pb={10} style={{borderTopLeftRadius: 70, borderTopRightRadius: 70}}>
              <VStack alignItems='center' space={5}>
                <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.bold,
                    fontSize: 24,
                    lineHeight: 28.8,
                    color: colors.palette.primary300,
                  }}
                  >
                    Get Started
                </Text>
                <VStack space={5} w={'100%'}>
                  <HStack space={5} justifyContent={'center'}>
                    <Image 
                      source={require("../../../../assets/images/sign_in_sign_up/google_btn.png")}
                      maxHeight={48} alt="image" style={{
                        width: 140, height: 48,
                        borderWidth:1, borderColor:colors.palette.secondary300, borderRadius:5
                      }}          
                    /> 
                    <Image 
                      source={require("../../../../assets/images/sign_in_sign_up/facebook_btn.png")}
                      maxHeight={48} alt="image" style={{
                        width: 140, height: 48,
                        borderWidth:1, borderColor:colors.palette.neutral300, borderRadius:5
                      }}                      
                    /> 
                  </HStack>     
                  <Divider subHeader="Or continue with" 
                    subHeaderStyle={{
                    marginTop: -10, 
                    backgroundColor: 'white',
                    zIndex: 1,
                    width: 110,
                    alignSelf: 'center'
                    }}/>           
                  <VStack space={5}>
                    <InputField
                        key="name"
                        value={fullName}              
                        onChangeText={(value) => setFullName(value)}
                        label="Your Name"
                        placeholder="Your Name"
                        helper={fullNameError}
                        status={fullNameError ? "error" : undefined}
                        onSubmitEditing={() => refEmailInput.current?.focus()}
                        // RightAccessory={() => <Icon icon="check" />}
                        // LeftAccessory={() => <Icon icon="bell" />}
                      />    
                    <InputField
                        ref={refEmailInput}
                        key="email"
                        value={email}              
                        onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                        label="Email Address"
                        placeholder="Email Address"
                        helper={emailError}
                        status={emailError ? "error" : undefined}
                        onSubmitEditing={() => refPasswordInput.current?.focus()}
                        // RightAccessory={() => <Icon icon="check" />}
                        // LeftAccessory={() => <Icon icon="bell" />}
                      />    
                    <InputField
                        ref={refPasswordInput}
                        key="password"
                        value={password}
                        onChangeText={(password) => setPassword(password)}
                        label="Password"              
                        placeholder="Password"
                        secureTextEntry={isAuthPasswordHidden}
                        helper={confirmPasswordError}
                        status={confirmPasswordError ? "error" : undefined}
                        onSubmitEditing={() => refConfirmPasswordInput.current?.focus()}
                        RightAccessory={PasswordRightAccessory}
                        // LeftAccessory={() => <Icon icon="bell" />}
                      />
                    <InputField
                        ref={refConfirmPasswordInput}
                        key="Confirm Password"
                        value={confirmPassword}              
                        onChangeText={(value) => setConfirmPassword(value)}
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        secureTextEntry={isAuthConfirmPasswordHidden}
                        helper={confirmPasswordError}
                        status={confirmPasswordError ? "error" : undefined}
                        RightAccessory={ConfirmPasswordRightAccessory}
                        onSubmitEditing={signUp}
                        // LeftAccessory={() => <Icon icon="bell" />}
                      />    
                    <Button
                      text="Create Account"
                      preset="default"
                      onPress={ () => {signUp()}}                    
                      style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
                      pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                      textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                      pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                    />   
                    
                    <HStack alignItems='center' space={2}>
                      <Text
                        style={{
                          color:colors.palette.neutral400, 
                          fontFamily:typography.fonts.poppins.normal,
                          fontSize:16,
                          lineHeight:24,
                          
                        }}              
                      >
                        Already have an account? 
                      </Text> 
                      <Pressable onPress={()=>navigation.navigate("Signin")}>
                        <Text style={{ color:colors.palette.accent300}}>Sign in</Text>
                      </Pressable>
                    </HStack>
                  </VStack> 
                </VStack>
              </VStack>
            </VStack>
              
          </VStack>
        </Screen>
        })}
      {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}
