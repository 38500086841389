import { Instance, SnapshotIn, SnapshotOut, flow, types } from "mobx-state-tree"
import { withSetPropAction } from "./helpers/withSetPropAction"
import { api } from "../services/api";
import { ProductApi } from "../services/api/apiFatching/product-api";

/**
 * Model description here for TypeScript hints.
 */
interface notification{
  user: string
  message: string,
  created_at: string,
  is_read: boolean,
  date: string,
  time: string,
}

export const NotificationStoreModel = types
  .model("NotificationStore")
  .props({
    notificationList: types.optional(types.array(types.frozen<notification>()),[]),
  })
  .actions(withSetPropAction)
  .views((self) => ({
    reset() {
      self.setProp("notificationList",[])
    },
    seenNotification(){
      return self?.notificationList?.filter(item => item.is_read === true)
    },
    unseenNotification(){
      return self?.notificationList?.filter(item => item.is_read === false)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({    
    fetchNotificationList: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.notificationList(setOverlayVisible) 

      self.setProp("notificationList", result.data)
      // self.products = result.data
    }),

    markAllRead: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.markAllRead(setOverlayVisible) 
      console.log('notification markAllRead', result);
      // self.products = result.data
    }),
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface NotificationStore extends Instance<typeof NotificationStoreModel> {}
export interface NotificationStoreSnapshotOut extends SnapshotOut<typeof NotificationStoreModel> {}
export interface NotificationStoreSnapshotIn extends SnapshotIn<typeof NotificationStoreModel> {}
export const createNotificationStoreDefaultModel = () => types.optional(NotificationStoreModel, {})
