import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
export const AddOfferStoreModel = types
  .model("AddOfferStore")
  .props({
    productName: '',
    offer: '',
    manufacturingDate: '',
    expiry: '',
    quantity: '',
    unitMRP: '',
    offerPrice: '',
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get productNameValidationError() {
      if (self.productName?.length === 0) return "This field can't be blank"
      return ""
    },
    get offerValidationError() {
      if (self.offer?.length === 0) return "This field can't be blank"
      if (/^[0-9]+$/.test(self?.unitMRP) != true) return "Only numbers are allowed"
      return ""
    },
    get manufacturingDateValidationError() {
      if (self.manufacturingDate?.length === 0) return "Manufacturing Date can't be blank"
      if (/^[0-9]+$/.test(self?.unitMRP) != true) return "Only numbers are allowed"
      return ""
    },
    get expiryValidationError() {
      if (self.expiry?.length === 0) return "Expiry can't be blank"
      if (/^[0-9]+$/.test(self?.unitMRP) != true) return "Only numbers are allowed"
      return ""
    },
    get quantityValidationError() {
      if (self.quantity?.length === 0) return "Quantity can't be blank"
      if (/^[0-9]+$/.test(self?.unitMRP) != true) return "Only numbers are allowed"
      return ""
    },
    get unitMRPValidationError() {
      if (self.unitMRP?.length === 0) return "This field can't be blank"
      if (/^[0-9]+$/.test(self?.unitMRP) != true) return "Only numbers are allowed"

      return ""
    },
    get offerPriceValidationError() {
      if (self.offerPrice?.length === 0) return "This field can't be blank"
      return ""
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setOffer(value: string) {
      self.offer = value
    },
    setProductName(value: string) {
      self.productName = value
    },
    setManufacturingDate(value: string) {
      self.manufacturingDate = value.replace(/\//g, "-")
    },
    setExpiry(value: string) {
      self.expiry = value.replace(/\//g, "-")
    },
    setQuantity(value: string) {
      self.quantity = value.replace(/\D/g, '');
    },
    setUnitMRP(value: string) {
      self.unitMRP = value.replace(/\D/g, '');
    },
    setOfferPrice(value: string) {
      self.offerPrice = value.replace(/\D/g, '');
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface AddOfferStore extends Instance<typeof AddOfferStoreModel> {}
export interface AddOfferStoreSnapshotOut extends SnapshotOut<typeof AddOfferStoreModel> {}
export interface AddOfferStoreSnapshotIn extends SnapshotIn<typeof AddOfferStoreModel> {}
export const createAddOfferStoreDefaultModel = () => types.optional(AddOfferStoreModel, {})
