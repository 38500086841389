import React, { createContext, useState, ReactNode } from 'react';

interface AlertContextProps {
  showAlert: (title: string, message: string) => void;
  hideAlert: () => void;
  alertVisible: boolean;
  alertContent: {
    title: string;
    message: string;
  };
}

export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {},
  hideAlert: () => {},
  alertVisible: false,
  alertContent: {
    title: '',
    message: '',
  },
});

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertContent, setAlertContent] = useState({ title: '', message: '' });

  const showAlert = (title: string, message: string) => {    
    setAlertContent({ title, message });
    setAlertVisible(true);
  };

  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        hideAlert,
        alertVisible,
        alertContent,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
