import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Linking, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen, Text } from "../../components"
import { useNavigation, useRoute } from "@react-navigation/native"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"
import { api } from "../../services/api"
import { PageLayout } from "../../components/PageLayout"
import { Box, HStack, Pressable, Stack, VStack, useBreakpointValue } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import { colors, typography } from "../../theme"
import { ReviewStars } from "./SellerProfileScreen"
import { ProgressBar } from "react-native-ui-lib"
import Config from "../../config"

// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `AllReviews: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="AllReviews" component={AllReviewsScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const AllReviewsScreen: FC<StackScreenProps<AppStackScreenProps, "AllReviews">> = observer(function AllReviewsScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  // const { setOverlayVisible } = React.useContext(OverlayContext)
  const route = useRoute<any>()
  const [ data, setData ] = useState<any>({});

  const showAboveMd = useBreakpointValue({base: false, md: true});
  const fontSizeReview = useBreakpointValue({base: 13,md: 14, xl:16});
  const reviewStatusBarSize = useBreakpointValue({base: '70%',md: '38%',lg: '40%',xl:'60%'});
  const reviewStatusBarSpace = useBreakpointValue({base: 2,md: 3, lg: 4});
  const reviewSpace = useBreakpointValue({base: 2, lg: 4});
  const reviewStarWidth = useBreakpointValue({base: 20, sm: 22, md: 25});
  const reviewStarHeight = useBreakpointValue({base: 20, md:25});

  const {setOverlayVisible} = useContext(OverlayContext);
  // Pull in one of our MST stores
  const { authenticationStore: {
    isAuthenticated,
    user,
    newProductRequests,
    userRequirements,
    error,
    fetchNewroductRequests,
  } } = useStores()

  useEffect(()=>{
    fetchSellerDetails()
  },[route])  

  async function fetchSellerDetails(){

    route.params != undefined && await api.apisauce.get(`sellers/${route?.params?.sellerId}/`)
    .then((response)=>{
      setData(response.data);      
      // data = response.data;
    })
    .catch(error=>console.log(error));

  }


  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  console.log('data--------;;;;;;;;;;;', data);

  return (
    <Screen style={$root} preset="scroll">
      <PageLayout  variant={isAuthenticated?1:0}> 
        <Stack w="100%" mb={100}>
          {/* Parent VStack */}
          <VStack space={8} pl={'3vw'} pr={'3vw'} pt={10}>
            {/* go back */}
            <HStack space={2} mb={{base: 10, md: 0}} alignItems="center">
              <Pressable onPress={() => navigation.goBack()}>
                <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
              </Pressable>                    
              <Text 
                style={{
                  fontFamily: typography.fonts.poppins.bold,
                  fontSize: 24,
                  lineHeight: 28.8,
                  color: colors.palette.neutral700,
                }}
                >
                  {/* {sellerName} */}
                  {data?.user?.first_name} {data?.user?.last_name}                        
              </Text>
            </HStack>
            {showAboveMd && <AutoImage
              source={require("../../../assets/images/sellerProfile/sellerProfile_banner.png")}
              maxHeight={120}
              style={{ width: '60%', alignSelf: 'center' }}
              resizeMode="contain"
            />}

            {/* Main HStack */}
            <Stack direction={{base: 'column',md: 'row'}} space={'3vw'} mt={{base: 2, md: -5}}
            justifyContent={'center'}>
              {/*1st half--> side information section */}
              <VStack w={'60%'} space={5}>
                  {/* profile card */}
                  <VStack space={reviewStatusBarSpace} mt={-10} p={5} alignItems={'center'} style={{
                    shadowColor: '#171717',
                    shadowOffset: {width: 0, height: 3},
                    shadowOpacity: 0.1,
                    shadowRadius: 20,}}>
                    {data?.user?.avatar ? 
                      <AutoImage
                        source={{uri: data?.user?.avatar && `${Config.MEDIA_URL}/${data?.user?.avatar}`}}
                        maxHeight={140}
                        style={{ width: 140, borderRadius: 140/2, marginTop: -50, borderColor: colors.palette.secondary300, borderWidth: 5}}
                        // resizeMode="contain"
                      />
                      :
                      <AutoImage
                        source={require('../../../assets/images/user_profile.png')}
                        maxHeight={140}
                        style={{ width: 140, borderRadius: 140/2, marginTop: -50, borderColor: colors.palette.secondary300, borderWidth: 5}}
                        // resizeMode="contain"
                      />
                    }
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: 24,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700,
                      }}
                      >
                        {/* {sellerName} */}
                        {data?.first_name} {data?.last_name}
                    </Text>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.medium,
                        fontSize: 18,
                        lineHeight: 24,
                        color: colors.palette.neutral700,
                      }}
                      >
                        {data?.business_name}
                    </Text>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.normal,
                        fontSize: 16,
                        lineHeight: 24,
                        color: colors.palette.neutral400,
                        textAlign: 'center'
                      }}
                      >
                        {data?.business_address}
                    </Text>
                    <HStack space={5} mt={2} mb={5}>
                      <Pressable onPress={()=>{Linking.openURL(`tel://${data?.mobile}`)}}>
                        <AutoImage
                          source={require("../../../assets/images/sellerProfile/call.png")}
                          maxHeight={45}
                          style={{ width: 45}}
                          resizeMode="contain"
                        />
                      </Pressable>
                      <Pressable onPress={()=>{Linking.openURL(`mail://${data?.email}`)}}>
                        <AutoImage
                          source={require("../../../assets/images/sellerProfile/msg.png")}
                          maxHeight={45}
                          style={{ width: 45}}
                          resizeMode="contain"
                        />
                      </Pressable>
                      <Pressable onPress={()=>{Linking.openURL(`https://wa.me/${data?.mobile}`)}}>
                        <AutoImage
                          source={require("../../../assets/images/sellerProfile/whatsapp.png")}
                          maxHeight={45}
                          style={{ width: 45}}
                          resizeMode="contain"
                        />
                      </Pressable>      
                    </HStack>
                  </VStack>
                  <Text 
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                      color: colors.palette.neutral700,
                    }}
                    >
                      Reviews
                  </Text>
                  {/* Reviews section parent vstack */}
                  <Stack direction={{base: 'row',md: 'column'}}>
                    {/* write a review section  */}
                    <VStack 
                      // w={{base: '40%',md:'100%'}} 
                      w={"100%"} 
                      space={4} style={{
                      padding: useBreakpointValue({base: '2vw',lg:'1vw'}),
                      shadowColor: '#171717',
                      shadowOffset: {width: 2, height: 0},
                      shadowOpacity: 0.2,
                      shadowRadius: 5,
                      justifyContent: 'center',
                      }}>
                      <VStack>
                        <VStack w={{base: '100%',xl:'60%'}} space={3}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.medium,
                              fontSize: 18,
                              lineHeight: 22,
                              color: colors.palette.neutral700,
                            }}
                            >
                              {data?.reviews?.length} Reviews
                          </Text>
                          {data?.avg_reviews >= 0 && 
                            <Stack direction={{base: 'column', lg: 'row'}} space={3} mb={2}>                                                                    
                              <ReviewStars rating={data?.avg_reviews} starStyle={{width: 20, height: 20}}/>
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.normal,
                                  fontSize: 15,
                                  lineHeight: 24,
                                  color: colors.palette.neutral700,
                                }}
                                >
                                  {data?.avg_reviews} out of 5
                              </Text>                                                                   
                            </Stack>
                          } 
                        </VStack>
                        {
                        (data?.user?.id !== user?.id) && 
                        showAboveMd &&<Box w={'100%'} style={{justifyContent: 'center'}}>
                          <Button
                            text='Write a Review'
                            preset="default"
                            onPress={() => navigation.navigate("Review",{sellerId: data?.id})}
                            style={{
                              backgroundColor: colors.palette.primary600,
                              borderRadius: 5,
                              width: '100%',
                              
                            }}
                            pressedStyle={{
                              backgroundColor: colors.palette.neutral100,
                              borderRadius: 5,
                              borderWidth: 0,
                            }}
                            textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
                            pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                          />
                        </Box>}
                      </VStack>

                      {/* status bar / progress bar */}
                      <VStack space={3} >
                        {/* Star Rating */}
                        {['5','4','3','2','1'].map((star,item)=>{
                          return (
                            <HStack space={reviewStatusBarSpace} key={`star-rating-${star}`}>
                              <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: fontSizeReview, lineHeight: 24, color: colors.palette.neutral700, }}> {star} Star </Text>
    
                              <Box justifyContent={'center'} w={reviewStatusBarSize}>
                              <ProgressBar 
                                progress={((
                                  data?.reviews && 
                                  data.reviews[0] && 
                                  data.reviews[0].star_rating_info && 
                                  data.reviews[0].star_rating_info[star].count)/(data?.reviews?.length))*100} 
                                  progressColor={colors.palette.secondary300} style={{backgroundColor: colors.palette.secondary100}}/>
                              </Box>
    
                              <Text  style={{ fontFamily: typography.fonts.poppins.normal, fontSize: fontSizeReview, lineHeight: 24, color: colors.palette.neutral700 }}> {/* {noOf5Stars} */} {data?.reviews && data.reviews[0] && data.reviews[0].star_rating_info && data.reviews[0].star_rating_info[star].count} </Text>
                            </HStack>
                          )
                        })}                              
                      </VStack>
                      {
                      (data?.user?.id !== user?.id) &&
                        showAboveMd !== true && <Box mt={10} w={'100%'} style={{justifyContent: 'center'}}>
                          <Button
                            text='Write a Review'
                            preset="default"
                            onPress={() => navigation.navigate("Review",{sellerId: data?.id})}
                            style={{
                              backgroundColor: colors.palette.primary600,
                              borderRadius: 5,
                              width: '100%',
                              
                            }}
                            pressedStyle={{
                              backgroundColor: colors.palette.neutral100,
                              borderRadius: 5,
                              borderWidth: 0,
                            }}
                            textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
                            pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                          />
                        </Box>}
                    </VStack>
                    
                  {showAboveMd && <VStack w={{base: '60%',md:'100%'}} >
                    {/* top reviews */}
                    {data?.reviews?.map((item,index)=>{
                      return (
                        <VStack space={3} pb={{base: 3, md: '40px'}} style={{
                          padding: '2vw',
                          shadowColor: '#171717',
                          shadowOffset: {width: 0, height: 2},
                          shadowOpacity: 0.2,
                          shadowRadius: 5,}}
                          key={`top_review_${index}`}
                          >

                          <HStack space={reviewSpace} alignItems='center'>
                            <AutoImage
                              source={require("../../../assets/images/account_settings/photo.png")}
                              maxHeight={60}
                              style={{ width: 60, borderRadius: 60/2, borderColor: colors.palette.secondary300, borderWidth: 3}}
                              // resizeMode="contain"
                            />
                            <VStack>
                              <Stack direction={{base: 'column', lg: 'row'}}>
                                <ReviewStars rating={item?.star_rating} starStyle={{width: reviewStarWidth, height: reviewStarHeight}}/>
                                <Text  style={{ fontFamily: typography.fonts.poppins.semiBold, fontSize: fontSizeReview, lineHeight: 24, color: colors.palette.neutral400 }}> {/* {noOf5Stars} */} {item?.star_rating} </Text>
                              </Stack>
                              <Box w={'100%'} justifyContent={'center'} alignItems={'flex-start'}>
                                <Text style={{ fontFamily: typography.fonts.poppins.light, fontSize: 13, lineHeight: 19.5, color: colors.palette.neutral400 }}> {/* {noOf5Stars} */}{item?.days_ago} </Text>
                              </Box>
                            </VStack>
                          </HStack>
                          <Text style={{ fontFamily: typography.fonts.poppins.medium, fontSize: 18, lineHeight: 22, color: colors.palette.neutral800 }}>{item?.reviewer}</Text>
                          <Box w={'94%'}>
                            <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral400 }}>{item?.feedback}</Text>
                          </Box>
                        </VStack>
                      )
                    })}
                  </VStack>}
                  </Stack>

              </VStack>
              
            </Stack>      
          </VStack>
        </Stack>
      </PageLayout>
    </Screen>
  )
})

const $root: ViewStyle = {
  flex: 1,
}
