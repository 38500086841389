import React, { createContext, useState, ReactNode } from 'react';

interface OverlayContextProps {
  overlayVisible: boolean;
  setOverlayVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OverlayContext = createContext<OverlayContextProps>({
  overlayVisible: false,
  setOverlayVisible: () => {},
});

interface OverlayProviderProps {
  children: ReactNode;
}

export const OverlayProvider: React.FC<OverlayProviderProps> = ({ children }) => {
  const [overlayVisible, setOverlayVisible] = useState(false);

  return (
    <OverlayContext.Provider value={{ overlayVisible, setOverlayVisible }}>
      {children}
    </OverlayContext.Provider>
  );
};