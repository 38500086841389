import * as React from "react"
import { Pressable, TextStyle, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, spacing, typography } from "../theme"
import { useNavigation } from "@react-navigation/native"
import { IconButton, Image, Text } from "native-base"
import { Entypo, Fontisto, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import {
  AddNewOfferScreen,
  AddNewProductScreen, 
  AuthenticatedHomeScreen, 
  ChatScreen, 
  SellerProfileScreen, 
  WishlistScreen } from "../screens"


/**
 * Tab Navigation Component to be used in on PageLayout for mobile devices
 */
const Tab = createBottomTabNavigator()

export const TabNavigation = observer(function TabNavigation() {

  const { bottom } = useSafeAreaInsets()
  const navigation = useNavigation<any>()

  return(
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarHideOnKeyboard: true,
        tabBarStyle: [$tabBar, { height: bottom + 70 }],
        tabBarActiveTintColor: colors.text,
        tabBarInactiveTintColor: colors.text,
        tabBarLabelStyle: $tabBarLabel,
        tabBarItemStyle: $tabBarItem,
      }}
    >
      <Tab.Screen name="Home" component={AuthenticatedHomeScreen}  
        options={{
          tabBarLabel: ({ focused }) => <Text style={{ fontSize: 12, color: focused ? colors.palette.primary300 : colors.palette.neutral400 }}>Home</Text>,
          tabBarIcon: ({focused }) => <Entypo name="home" size={24} color={ focused ? colors.palette.primary300 : colors.palette.neutral400} />
          }}/> 
      <Tab.Screen name="AddNewOffer" component={AddNewOfferScreen} 
        options={{
          tabBarLabel: ({ focused }) => <Text style={{ fontSize: 12, color: focused ? colors.palette.primary300 : colors.palette.neutral400 }}>Add Offer</Text>,
          tabBarIcon: ({focused}) => 
          <Fontisto name="shopping-sale" size={24} color={ focused ? colors.palette.primary300 : colors.palette.neutral400} />
          }}/> 
      <Tab.Screen name="AddNewProduct" component={AddNewProductScreen} 
        options={{
          tabBarLabel: ({ focused }) => <Pressable onPress={()=>{navigation.navigate("AddNewProduct")}}><Text style={{ textAlign: 'center', fontSize: 12, lineHeight: 14, color: focused ? colors.palette.primary300 : colors.palette.neutral400 }}>Add Product</Text></Pressable>,
          tabBarIcon: ({ focused }) => 
            <Pressable onPress={()=>{navigation.navigate("AddNewProduct")}}>
              <IconButton onPress={()=>{navigation.navigate("AddNewProduct")}} icon={<Image source={require("../../assets/icons/tabNavigation_plus_1.png")}
                style={{width: 100, height: 100, marginTop: -55,
                  shadowOffset: {width: 0, height: -5 },  
                  shadowColor: '#171717',   
                  shadowOpacity: 0.1,  
                  shadowRadius: 5, 
                  zIndex: 1 }} resizeMode="contain" alt="image"/>}/>
            </Pressable>
          }}/> 
      
      <Tab.Screen name="Chat" component={ChatScreen} 
        options={{
          tabBarLabel: ({ focused }) => <Text style={{ fontSize: 12, color: focused ? colors.palette.primary300 : colors.palette.neutral400 }}>Chat</Text>,
          tabBarIcon: ({ focused }) => <Ionicons name="chatbubble-ellipses-outline" size={24} color={ focused ? colors.palette.primary300 : colors.palette.neutral400} />
          }}/>
      <Tab.Screen name="SellerProfile" component={SellerProfileScreen} 
        options={{
          tabBarLabel: ({ focused }) => <Text style={{ fontSize: 12, color: focused ? colors.palette.primary300 : colors.palette.neutral400 }}>Profile</Text>,
          tabBarIcon: ({ focused }) => <Ionicons name="person-outline" size={24} color={ focused ? colors.palette.primary300 : colors.palette.neutral400} />
          }}/>
    </Tab.Navigator>

  )
})


const $tabBar: ViewStyle = {
  maxHeight: 70,
  backgroundColor: colors.background,
  borderTopColor: colors.transparent,
  shadowOffset: {width: 0, height: -3},  
  shadowColor: '#171717',  
  shadowOpacity: 0.3,  
  shadowRadius: 3,  
}

const $tabBarItem: ViewStyle = {
  paddingTop: spacing.medium,
}

const $tabBarLabel: TextStyle = {
  fontSize: 12,
  fontFamily: typography.primary.medium,
  lineHeight: 16,
  flex: 1,
}
