import { ApiResponse } from "apisauce";
import { Api } from "../api";
import { getGeneralApiProblem } from "../apiProblem";
import { DealOfTheDayProductsResult, ProductListResult, Categories, Brands, Wishlist } from "../api.types";
import { authApi } from "./authentication-api";


export class ProductApi {
    private api: Api;    

    constructor(api: Api) {
        this.api = api;
    }

    async newProductRequestList(setOverlayVisible:any): Promise<ProductListResult> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("products/");            
            setOverlayVisible(false)
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                if(problem) {
                    authApi.logout(setOverlayVisible)
                    return problem;
                }
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }
    
    async productList(setOverlayVisible:any): Promise<ProductListResult> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("products/");            
            setOverlayVisible(false)
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                if(problem) {
                    authApi.logout(setOverlayVisible)
                    return problem;
                }
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }
    
    async productDetail(productId:string, setOverlayVisible:any): Promise<any> {
        try {            
            setOverlayVisible(true)
            return await this.api.apisauce.get(`products/${productId}/`)
            .then(response => console.log(response))
            .catch((error)=>console.log(error.message))
            .finally(() => {setOverlayVisible(false);});
            
        } catch (e){
            console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    async dealOfTheDayProducts(setOverlayVisible:any): Promise<DealOfTheDayProductsResult> {
        try {            
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("deals/");
            setOverlayVisible(false)         
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    async categories(setOverlayVisible:any): Promise<Categories> {
        try {            
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("categories/");
            setOverlayVisible(false)
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    async brands(setOverlayVisible:any): Promise<Brands> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("brands/top/");
            setOverlayVisible(false)
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    async addNewProductAndOffer(formData:any, setOverlayVisible:any, showAlert:any): Promise<any> {
        try {            
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.post('/newProductsRequest/create/', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            })
            setOverlayVisible(false)
            if(!response.ok) {
                console.log(response)
                showAlert("Error","Your request for new Product & offer is Failed. Try again later.");
                const problem = getGeneralApiProblem(response);
                if(problem) return {...problem, ...response};
            }            
            showAlert("Success","Your request for new Product & offer is submitted successfully. Will be available after review.")
            return { kind: "ok", ...response.data };              
            
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }
    
    async addNewOfferToExistingProduct(formData:any, setOverlayVisible:any, showAlert:any): Promise<any> {
        try {  
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.post('/offers/create/', formData)
            setOverlayVisible(false)
            if(!response.ok) {
                console.log(response)
                showAlert('Error', "Offer Submission Failed. Check error at top of the form.")
                const problem = getGeneralApiProblem(response);
                if(problem) return {...problem, ...response};
            }            
            showAlert('Success', "Offer Submission Successul");
            return { kind: "ok", ...response.data };            
            
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }
    
    async addNewProductRequirement(formData:any, setOverlayVisible:any, showAlert:any): Promise<any> {
        try {                       
            setOverlayVisible(true);
            const response: ApiResponse<any> = await this.api.apisauce.post('/userRequirement/', formData)
            setOverlayVisible(false)
            if(!response.ok) {
                console.log(response)
                showAlert('Error', "Your requirement submission failed. Check error at top of the form.")
                const problem = getGeneralApiProblem(response);
                if(problem) return {...problem, ...response};
            }            
            showAlert('Success', "Requirement Submitted Successully, Our executive will reach you soon!");
            return { kind: "ok", ...response.data };         
            
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }


    async wishlist(setOverlayVisible:any): Promise<Wishlist | any> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("wishlist/");   
            setOverlayVisible(false)         
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);                
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    // /api/offers/update/0cd5c5ba-731d-4c55-a134-25f3a8f83e2d/

    async ChatList(setOverlayVisible:any): Promise<any> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("chat/");   
            setOverlayVisible(false)         
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);                
                if(problem){                     
                    authApi.logout(setOverlayVisible)
                    return problem;
                }
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    async notificationList(setOverlayVisible:any): Promise<any> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("notifications/");   
            setOverlayVisible(false)         
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);                
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    async markAllRead(setOverlayVisible:any): Promise<any> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.get("read_notifications/");   
            setOverlayVisible(false)         
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);                
            }
            return { data: response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    // async ChatMessages(chatId: string, setOverlayVisible:any): Promise<any> {
    //     try {
    //         setOverlayVisible(true)
    //         const response: ApiResponse<any> = await this.api.apisauce.get(`chat/${chatId}/`);   
    //         setOverlayVisible(false)         
    //         if(!response.ok) {
    //             const problem = getGeneralApiProblem(response);                
    //             if(problem){                     
    //                 authApi.logout(setOverlayVisible)
    //                 return problem;
    //             }
    //         }
    //         return { data: response.data };
    //     } catch (e){
    //         __DEV__ && console.tron.log(e.message)
    //         return { kind: "bad-data" };
    //     }
    // }


}