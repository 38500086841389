import { ApiResponse } from "apisauce";
import { Api, api } from "../api";
import { getGeneralApiProblem } from "../apiProblem";
import { ChangePasswordResult, LoginResult, LogoutResult, SignupResult, Wishlist } from "../api.types";
import { AuthenticationStoreSnapshotOut } from "../../../models/AuthenticationStore";
import { err } from "react-native-svg/lib/typescript/xml";

export class AuthenticationApi {
    private api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async signup(username:string, email: string, password1: string, password2:string, setOverlayVisible:any): Promise<SignupResult> {
        try {
            setOverlayVisible(true)
            let first_name = username.split(' ')[0];
            let last_name = username.split(' ')[1]?username.split(' ')[1]:first_name;
            const response: ApiResponse<any> = await this.api.apisauce.post(
                "/rest-auth/registration/",
                {"username":email, first_name, last_name, email, password1, password2}
            )
            setOverlayVisible(false)
            if(!response.ok) {
                console.log(response)
                const problem = getGeneralApiProblem(response);
                if(problem) return {...problem, ...response};
            }            
            return { kind: "ok", ...response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }

    async login(email: string, password: string, setOverlayVisible:any): Promise<LoginResult> {
        try {
            setOverlayVisible(true)            
            const response: ApiResponse<any> = await this.api.apisauce.post(
                "/rest-auth/login/",
                {email, password}
            );            
            setOverlayVisible(false)            
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                // if(problem) return problem;
                if(problem) return {kind: problem.kind, ...response.data};
            }
            // if()

            return { kind: "ok", ...response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            console.log(e.message)
            return { kind: "bad-data" };
        }
    }
    
    async logout(setOverlayVisible?:any): Promise<LogoutResult> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.post(
                "/rest-auth/logout/")
            setOverlayVisible(false)
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }            
            return { kind: "ok", ...response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }
    
    async changePassword(password1:string, password2:string, setOverlayVisible:any): Promise<ChangePasswordResult> {
        try {
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.patch(
                "/rest-auth/password/change/", {password1, password2}
            )
            setOverlayVisible(false)
            if(!response.ok) {
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }

            return { kind: "ok", ...response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }  
    
    async resetPassword(new_password1:string, new_password2:string, uid:string, token:string, setOverlayVisible:any): Promise<ChangePasswordResult> {
        try {            
            setOverlayVisible(true)
            console.log(this.api.apisauce.headers)
            const response: ApiResponse<any> = await this.api.apisauce.patch(
                "/password/reset/confirm/", {new_password1, new_password2, uid, token}
            )
            setOverlayVisible(false)
            if(!response.ok) {
                console.log("reset password failed")
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }
            console.log("reset password successful")
            return { kind: "ok", ...response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }  
    
    async addPaymentOption(card_number:string, cardholder_name:string, expiration_date:string, cvv:string, card_type:string, setOverlayVisible:any): Promise<ChangePasswordResult> {
        try {              
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.post(
                "/payment-options/add/", {card_number, cardholder_name, expiration_date, cvv, card_type}
            )
            setOverlayVisible(false)            
            if(!response.ok) {
                console.log("addtion of payment option failed")
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }
            console.log("Payment option added successfully.")
            return { kind: "ok", ...response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }  
    
    async removePaymentOption(card_id:string, setOverlayVisible:any): Promise<ChangePasswordResult> {
        try {              
            setOverlayVisible(true)
            const response: ApiResponse<any> = await this.api.apisauce.delete(
                "payment-options/delete/"+card_id+"/")
            setOverlayVisible(false)            
            if(!response.ok) {
                console.log("deletion of payment option failed")
                const problem = getGeneralApiProblem(response);
                if(problem) return problem;
            }
            console.log("Payment option deleted successfully.")
            return { kind: "ok", ...response.data };
        } catch (e){
            __DEV__ && console.tron.log(e.message)
            return { kind: "bad-data" };
        }
    }  
    
}

export const authApi = new AuthenticationApi(api);