import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { KeyboardAvoidingView, Platform, View, ViewStyle, StyleSheet } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Alert, Box, HStack, Image, Modal, Pressable, ScrollView, Stack, VStack, useBreakpointValue } from "native-base"
import { useNavigation, useRoute } from "@react-navigation/native"
import { Fontisto, Ionicons } from "@expo/vector-icons"
import { colors, typography } from "../../theme"
import { InputField } from "../../components/InputField"
import DatePicker from 'react-native-modern-datepicker';
import { TouchableWithoutFeedback } from "react-native"
import { Keyboard } from "react-native"
import { ProductApi } from "../../services/api/apiFatching/product-api"
import { api } from "../../services/api"

// import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../models" 
import { AlertContext } from "../../components/AlertContext"
import { OverlayContext } from "../../components/OverlayProvider"
import { TextInput } from "react-native"
import { FlatList } from "react-native"
// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `AddNewOffer: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="AddNewOffer" component={AddNewOfferScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
    padding: 10,
  },
  itemContainer: {
    backgroundColor: '#fff',
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 2,
  },
  itemText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export const AddNewOfferScreen: FC<StackScreenProps<AppStackScreenProps, "AddNewOffer">> = observer(function AddNewOfferScreen() {
  // Pull in one of our MST stores
  const { ProductListStore, authenticationStore, 
    AddOfferStore: {
      productName, setProductName, productNameValidationError, offer, setOffer, manufacturingDate, setManufacturingDate, expiry, setExpiry, quantity, setQuantity, 
      unitMRP, setUnitMRP, offerPrice, setOfferPrice, offerValidationError, manufacturingDateValidationError,
      offerPriceValidationError, expiryValidationError, quantityValidationError, unitMRPValidationError
  } } = useStores();
    
  // const [offer, setOffer] = useState("");
  // const [manufacturingDate, setManufacturingDate] = useState("");
  // const [expiry, setExpiry] = useState("");
  // const [quantity, setQuantity] = useState("");
  // const [unitMRP, setUnitMRP] = useState("");
  // const [offerPrice, setOfferPrice] = useState("");
  const [enableShift, setEnableShift] = React.useState(false)

  const [openManufacturingDatePicker, setOpenManufacturingDatePicker] = useState(false)
  const [openExpiryDatePicker, setOpenExpiryDatePicker] = useState(false) 
  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const route = useRoute<any>()
   // Pull in navigation via hook
   const navigation = useNavigation<any>() 

   const [ product, setProduct] = React.useState<any>(route?.params?.productId ? ProductListStore.getProductDetail(route?.params?.productId) : {})
    // setProductName(product?.name)
   // const [ product, setProduct] = React.useState<any>(ProductListStore.getProductDetail('8e3f8149-f917-4005-ba50-7772461c20a4'))
  
  useEffect(()=>{
    if(authenticationStore?.user?.seller==null){
      // Alert("Seller details are mendatory to add an Offer.")
      navigation.navigate("GeneralSettings")
    }
  })

  useEffect(()=>{
    setProductName(product?.name)
    setOffer('')
    setManufacturingDate('')
    setExpiry('')
    setQuantity('')
    setUnitMRP('')
    setOfferPrice('')
  },[]);

  useEffect(()=>{
    // console.log(ProductListStore.getProductDetail(route.params.productId))
    route?.params?.offerId && api.apisauce.get(`offers/update/${route?.params?.offerId}/`)
    .then((result:any)=>{
      console.log('offers/update/',result.data)
      // setProduct(result.data)
      setProductName(result?.data?.product_title)
      setManufacturingDate(result?.data?.manufacturing_date)
      setExpiry(result?.data?.expiry_date)
      setQuantity(`${result?.data?.quantity}`)
      setOffer(`${Math.floor(result?.data?.percentage_off)}`)
      setUnitMRP(`${Math.floor(result?.data?.price)}`)
      setOfferPrice(`${Math.floor(result?.data?.offer_price)}`)
    }) 
    .catch((error)=>{
      console.log(error.message)
    })
  },[authenticationStore.isAuthenticated, route.params])

  const {  setOverlayVisible } = useContext(OverlayContext);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    const filterProduct = productName != "" && ProductListStore?.products?.filter((item) => item?.name?.toLowerCase().startsWith(productName?.toLowerCase()));
    productName != '' && setProduct(filterProduct && filterProduct[0])
    // console.log('filterProduct', filterProduct,'product', product)
  },[productName])

  const [errors, setErrors] = useState({})

  const submitAddOfferRequest = async () => { 
    try {               
      console.log("SubmitAddOfferCalled")
      const formData = {
        "product": product?.id,
        "price": unitMRP,
        "offer_price": offerPrice,
        "manufacturing_date": manufacturingDate,
        "expiry_date": expiry,
        "quantity": quantity,
      }

      const productApi = new ProductApi(api);
      console.log(formData)
      productApi.addNewOfferToExistingProduct(formData, setOverlayVisible, showAlert)      
      .then((response:any)=>{ 
        console.log(response)
        if(response?.ok)
          navigation.navigate('SellerProfile');
        else{
          setErrors({...response?.data})
        }
      }) 

    } catch (error) {
      console.log(error);
    }
    // update offer request------>

  }

  const updateOfferHandler = async () => {
    try {               
      setOverlayVisible(true)
      const formData = {
        "product": route?.params?.productId,        
        "price": unitMRP,
        "offer_price": offerPrice,
        "manufacturing_date": manufacturingDate,
        "expiry_date": expiry,
        "quantity": quantity
      }

      console.log(formData)
      route?.params?.offerId && api.apisauce.patch(`offers/update/${route?.params?.offerId}/`, formData)
      .then((result:any)=>{
        showAlert("Success", "Offers updated successfully.")
        console.log('offers/update/updateOfferHandler',result.data)
      }) 
      .catch((error)=>{
        showAlert("Error", "Offers update Failed. Try again later.")
        console.log(error.message)
      }).finally(()=>{setOverlayVisible(false)})
  } catch (error) {
    console.log(error);
  }

}

  const expiryInput = useRef<any>()
  const quantityInput = useRef<any>()
  const unitMRPInput = useRef<any>()
  const offerPriceInput = useRef<any>()
  const offerInput = useRef<any>()


  // validation error ----->
  const offerError = isSubmitted ? offerValidationError : ""
  const manufacturingDateError = isSubmitted ? manufacturingDateValidationError : ""
  const expiryError = isSubmitted ? expiryValidationError : ""
  const quantityError = isSubmitted ? quantityValidationError : ""
  const unitMRPError = isSubmitted ? unitMRPValidationError : ""
  const offerPriceError = isSubmitted ? offerPriceValidationError : ""
  const productError = isSubmitted ? productNameValidationError : ""

  function submitForm() {
    console.log("Submit Fomr")
    setIsSubmitted(true)

    if (manufacturingDate && expiry){
      console.log((new Date(expiry)) <= (new Date(manufacturingDate)))
      if((new Date(expiry)) <= (new Date(manufacturingDate))){
          showAlert("Error","Expiry date cannot be earlier than the manufacturing date.")
          return
      }

      if((new Date(manufacturingDate)) > (new Date())){
          showAlert("Error","Manufacturing date cannot be in the future.")
          return
      }
      
      if((new Date(expiry)) < (new Date())){
          showAlert("Error","Expiry date cannot be in the past.")
          return
      }
    }

    //if there is something returned form validation error then return from here and do not call login api
    
    // console.log("check-0", offer)
    // if (offerValidationError) return 
    console.log("check-1")
    if (manufacturingDateValidationError) return 
    console.log("check-2")
    if (expiryValidationError) return 
    console.log("check-3")
    if (quantityValidationError) return 
    console.log("check-4")
    if (unitMRPValidationError) return 
    console.log("check-5")
    if (offerPriceValidationError) return 
    console.log("check-6")
    if (productNameValidationError) return 
    console.log("check-7")
    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token. 
    console.log(route?.params?.offerId)     
    if(route?.params?.offerId){
      console.log("check-9")
      updateOfferHandler() 
    }
    else{
      console.log("check-8")
      submitAddOfferRequest() ;
    }
    setIsSubmitted(false)
    // setProductName("");
  }
  const data = ['Apple', 'Banana', 'Babukosa', 'Cherry', 'Date'];
  const [query, setQuery] = useState(productName);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleTextChange = (text) => {
    setQuery(text);  
    setProductName(text);
    setShowSuggestions(text?.length > 0); // Show suggestions when text is not empty
    setSuggestions(ProductListStore.products.filter((value)=> {return value.name.includes(text)}))
  };

  const handleSuggestionPress = (item) => {
    setQuery(item.name);
    setShowSuggestions(false); // Hide suggestions after selecting an item
  };

  const renderSuggestion = ({ item }) => (
    <Text onPress={() => handleSuggestionPress(item)}>{item.name}</Text>
  );

  // console.log('route.params', route.params);

  return (
  <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
        default :     
          <Screen style={[$root, { backgroundColor: colors.palette.accent400 }]} preset="scroll">
            <PageLayout  variant={authenticationStore?.isAuthenticated?1:0}>
              <Stack w="100%" mb={100}>
                {/* Parent VStack */}
                <VStack space={8} ml={10} mr={10} mt={5}>
                  {/* go back */}
                  <HStack alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                    </Pressable>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: 24,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700,
                      }}
                      >
                        Add New Offer
                    </Text>
                  </HStack>                  
                  {/* Description and fill your detail section */}
                  <Stack direction={{base: 'column',md: 'row'}} space={{base: 10, md: 5, lg: 10}}>
                    {/* Description section*/}
                    {productName === "" ? 
                      <Image
                        source={require(`../../../assets/images/add_new_offer/addOfferEmptyScreen.png`)}
                        w={{base: '100%', md: '64%', lg:'65%'}} 
                        h={160}                        
                        resizeMode="contain"  alt="image"
                      />
                      :
                      <VStack w={{base: '100%', md: '64%', lg:'65%'}} style={{backgroundColor: colors.palette.neutral100}}>
                        <Text
                          style={{
                            fontFamily: typography.fonts.poppins.semiBold,
                            fontSize: 24,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                            backgroundColor: colors.palette.primary100,
                            padding: 10,
                            paddingLeft: 25
                          }}
                        >
                        {productName !== "" && "Description"}
                        </Text>
                        
                        <VStack space={5} p={8}>
                          <Text
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: 18,
                              lineHeight: 22,
                              color: colors.palette.neutral800,
                            }}
                          >
                            {product?.name}
                          </Text>
                          <AutoImage
                            source={{uri: product?.thumbnail}}
                            maxHeight={260}
                            style={{ width: "60%" }}
                            resizeMode="contain"
                          />
          
                          {/* Description */}
                          <VStack space={2}>
                            <Text
                              style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: 18,
                                lineHeight: 22,
                                color: colors.palette.neutral800,
                              }}
                            >
                              Description
                            </Text>
                            <Text
                              style={{
                                fontFamily: typography.fonts.poppins.light,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral400,
                              }}
                            >
                              {product?.short_description}
                            </Text>
                          </VStack>
                          
          
                          {/* Advanced */}
                          <VStack space={2}>
                            <Text
                              style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: 18,
                                lineHeight: 22,
                                color: colors.palette.neutral800,
                              }}
                            >
                              Advanced
                            </Text>
                            <Text
                              style={{
                                fontFamily: typography.fonts.poppins.light,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral400,
                              }}
                            >
                              {product?.long_description}
                            </Text>
                        </VStack>
                        
                        </VStack>
                        
                      </VStack>
                    }  
                    {/* Fill your detail section */}
                    <VStack w={{base: '100%', md: '35%', lg:'32%'}} style={{backgroundColor: colors.palette.neutral100}}>
                      <Text
                          style={{
                            fontFamily: typography.fonts.poppins.semiBold,
                            fontSize: 24,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                            backgroundColor: colors.palette.primary100,
                            padding: 10,
                            paddingLeft: 25
                          }}
                        >
                        Fill Your Details
                      </Text>                      
                      <VStack p={8} space={5}>
                        {errors && Object.keys(errors).length>0 && Object.keys(errors).map(key =>{
                          return <Text
                            style={{
                              color:'red', 
                              fontFamily:typography.fonts.poppins.normal,
                              fontSize:16,
                              lineHeight:24
                            }}
                          >{`${key}: ${errors[key]}`}</Text> 
                        })}
                        <View style={{zIndex:1}}>
                          <InputField
                            key="Product Name"
                            defaultValue={product?.name}
                            value={ query }              
                            onChangeText={handleTextChange}
                            label="Product Name*"
                            placeholder="Search Product By Name"
                            helper={productError}
                            status={productError ? "error" : undefined}
                            // onSubmitEditing={() => expiryInput.current?.focus()}
                            // RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenManufacturingDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                            // LeftAccessory={() => <Icon icon="bell" />}
                            onFocus={()=>setEnableShift(false)}
                          />
                          {showSuggestions && (
                            <View style={styles.container}>
                              <FlatList
                                data={suggestions}
                                renderItem={renderSuggestion}
                                style={{borderColor:'black'}}
                                contentContainerStyle={styles.itemContainer}
                              />
                            </View>
                          )}
                        </View>
                        {/* <InputField
                            key="Product Name"
                            defaultValue={product?.name}
                            value={ productName }              
                            onChangeText={(value) => {setProductName(value)}}
                            label="Product Name"
                            placeholder="Select Product"
                            helper={productError}
                            status={productError ? "error" : undefined}
                            // onSubmitEditing={() => expiryInput.current?.focus()}
                            // RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenManufacturingDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                            // LeftAccessory={() => <Icon icon="bell" />}
                            onFocus={()=>setEnableShift(false)}
                          /> */}
                        <Box w={'100%'}>
                          {/* date picker modal for manufacturing date  */}
                          {/* <Modal isOpen={openManufacturingDatePicker} onClose={() => setOpenManufacturingDatePicker(false)} avoidKeyboard justifyContent="flex-end" bottom="4" size="lg">
                            <Modal.Content>
                              <Modal.CloseButton />
                              <Modal.Header>Choose Date</Modal.Header>
                              <Modal.Body> 
                                <DatePicker 
                                  options={{
                                    backgroundColor: '#090C08',
                                    textHeaderColor: '#FFA25B',
                                    textDefaultColor: '#F6E7C1',
                                    selectedTextColor: '#fff',
                                    mainColor: '#F4722B',
                                    textSecondaryColor: '#D6C7A1',
                                    borderColor: 'rgba(122, 146, 165, 0.1)',
                                  }}
                                  current="2020-01-01"
                                  selected="2020-01-01"
                                  mode="calendar"
                                  minuteInterval={30}
                                  style={{ borderRadius: 10 }}
                                  onSelectedChange={(date) => {                                
                                  setManufacturingDate(date.split(' ')[0].replace(/\//g, "-"))}} 
                                /> 
                              </Modal.Body>
                            </Modal.Content>
                          </Modal> */}
                          <InputField
                            key="Manufacturing Date"
                            defaultValue={manufacturingDate}
                            value={manufacturingDate}              
                            onChangeText={(value) => setManufacturingDate(value)}
                            label="Manufacturing Date*"
                            placeholder="yyyy-mm-dd"
                            helper={manufacturingDateError}
                            status={manufacturingDateError ? "error" : undefined}
                            onSubmitEditing={() => expiryInput?.current?.focus()}
                            RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenManufacturingDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                          <DatePicker
                            mode="calendar"
                            onSelectedChange={(date) => {                                
                              setManufacturingDate(date?.split(' ')[0].replace(/\//g, "-"))}
                            }
                            // style={{display:openManufacturingDatePicker?'block':'none'}}
                          />                          
                        </Box>
                        <Box w={'100%'}>
                        {/*  */}
                        {/* <Modal isOpen={openExpiryDatePicker} onClose={() => setOpenExpiryDatePicker(false)} avoidKeyboard justifyContent="flex-end" bottom="4" size="lg">
                          <Modal.Content>
                            <Modal.CloseButton />
                            <Modal.Header>Choose Date</Modal.Header>
                            <Modal.Body> 
                              <DatePicker 
                                mode="calendar"
                                onSelectedChange={(date) => {setExpiry(date.split(' ')[0].replace(/\//g, "-"))}} 
                              /> 
                            </Modal.Body>
                          </Modal.Content>
                        </Modal> */}                        
                        <InputField
                          ref={expiryInput}
                          key="Expiry"
                          defaultValue={expiry}
                          value={expiry}              
                          onChangeText={(value) => setExpiry(value)}
                          label="Expiry*"
                          placeholder="yyyy-mm-dd"
                          helper={expiryError}
                          status={expiryError ? "error" : undefined}
                          onSubmitEditing={() => quantityInput?.current?.focus()}
                          RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenExpiryDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                          // LeftAccessory={() => <Icon icon="bell" />}
                        /> 
                        <DatePicker
                            mode="calendar"
                            onSelectedChange={(date) => {setExpiry(date?.split(' ')[0].replace(/\//g, "-"))}} 
                            // style={{display:openManufacturingDatePicker?'block':'none'}}
                          />
                        </Box>
                        <Box w={'100%'}>
                          <InputField
                            ref={quantityInput}
                            key="Quantity"
                            defaultValue={quantity}
                            value={quantity}              
                            onChangeText={(value) => setQuantity(value)}
                            label="Quantity*"
                            placeholder="500"
                            helper={quantityError}
                            status={quantityError ? "error" : undefined}
                            onSubmitEditing={() => unitMRPInput?.current?.focus()}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box>
                        <Box w={'100%'}>
                          <InputField
                            ref={unitMRPInput}
                            key="Unit MRP*"
                            defaultValue={unitMRP}
                            value={unitMRP}              
                            onChangeText={(value) => setUnitMRP(value)}
                            label="Unit MRP*"
                            placeholder="1000"
                            helper={unitMRPError}
                            status={unitMRPError ? "error" : undefined}
                            onSubmitEditing={() => offerPriceInput?.current?.focus()}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box>
                        <Box w={'100%'}>
                          <InputField
                            ref={offerPriceInput}
                            key="Offer Price"
                            defaultValue={offerPrice}
                            value={offerPrice}              
                            onChangeText={(value) => setOfferPrice(value)}
                            label="Offer Price*"
                            placeholder="1000"
                            helper={offerPriceError}
                            status={offerPriceError ? "error" : undefined}
                            onSubmitEditing={submitForm}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box>
                        {/* <Box w={'100%'}>
                          <InputField
                            ref={offerInput}
                            key="Offer"
                            value={offer}              
                            onChangeText={(value) => setOffer(value)}
                            label="Offer"
                            placeholder="50%"
                            helper={offerError}
                            status={offerError ? "error" : undefined}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          /> 
                        </Box> */}
                        <Button
                        text='Submit Offer'
                        preset="default"
                        onPress={()=>{
                          submitForm();
                        }}
                        style={{
                          backgroundColor: colors.palette.primary600,
                          borderRadius: 5,
                          width: "100%",
                          marginTop: 5, 
                        }}
                        pressedStyle={{
                          backgroundColor: colors.palette.neutral100,
                          borderRadius: 5,
                          borderWidth: 0,
                      
                        }}
                        textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                        pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                        />
                      </VStack>
                    </VStack>
                  </Stack>
        
                </VStack>
              </Stack>
            </PageLayout>
          </Screen>, //code for web and other platforms
        
        native : 
          <PageLayout  variant={1}>
            <ScrollView style={{backgroundColor: colors.palette.neutral100}}>
              <KeyboardAvoidingView behavior={"position"} contentContainerStyle={{flex: 1}} style={{flex:1}} enabled={enableShift}> 
                <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={{flex:1}}> 
                    <Stack w="100%" mb={100}>
                      {/* Parent VStack */}
                      <VStack space={5} ml={5} mr={5} mt={2}>
                        {/* go back */}
                        <HStack alignItems="center">
                          <Pressable onPress={() =>{ navigation.goBack(); setProductName('')}}>
                            <Ionicons name="arrow-back" size={25} color={colors.palette.neutral600}/>
                          </Pressable>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.bold,
                              fontSize: 18,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              Add New Offer
                          </Text>
                        </HStack>
              
                        {/* Description and fill your detail section */}
                        <Stack space={5} style={{borderColor: colors.palette.gray100, borderWidth: 1}}>
                          {/* Fill your detail section */}
                          <VStack w={"100%"} style={{backgroundColor: colors.palette.neutral100}}>
                            <Text
                                style={{
                                  fontFamily: typography.fonts.poppins.semiBold,
                                  fontSize: 18,
                                  lineHeight: 28.8,
                                  color: colors.palette.neutral700,
                                  backgroundColor: colors.palette.primary100,
                                  padding: 10,
                                  paddingLeft: 25
                                }}
                              >
                              Fill Your Details
                            </Text>
              
                            <VStack p={4} space={5}>
                              <InputField
                                key="Product Name"
                                defaultValue={product?.name}
                                value={ productName }
                                onChangeText={(value) => {setProductName(value)}}
                                label="Product Name*"
                                placeholder="Search Product By Name"
                                helper={productError}
                                status={productError ? "error" : undefined}
                                // onSubmitEditing={() => expiryInput.current?.focus()}
                                // RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenManufacturingDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                                // LeftAccessory={() => <Icon icon="bell" />}
                                onFocus={()=>setEnableShift(false)}
                              />
                              <Box w={'100%'}>
                                {/* date picker modal for manufacturing date  */}
                                <Modal isOpen={openManufacturingDatePicker} onClose={() => setOpenManufacturingDatePicker(false)} avoidKeyboard justifyContent="flex-end" bottom="4" size="lg">
                                  <Modal.Content>
                                    <Modal.CloseButton />
                                    <Modal.Header>Choose Date</Modal.Header>
                                    <Modal.Body> <DatePicker onSelectedChange={(date) => {setManufacturingDate(date?.split(' ')[0].replace(/\//g, "-"))}} /> </Modal.Body>
                                    {/* <Modal.Footer> <Button onPress={() => { setOpenManufacturingDatePicker(false);}}> Proceed</Button> </Modal.Footer> */}
                                  </Modal.Content>
                                </Modal>
                                <InputField
                                  key="Manufacturing Date"
                                  value={manufacturingDate}              
                                  onChangeText={(value) => setManufacturingDate(value)}
                                  label="Manufacturing Date*"
                                  placeholder="yyyy-mm-dd"
                                  helper={manufacturingDateError}
                                  status={manufacturingDateError ? "error" : undefined}
                                  onSubmitEditing={() => expiryInput?.current?.focus()}
                                  RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenManufacturingDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>setEnableShift(false)}
                                /> 
                              </Box>
                              <Box w={'100%'}>
                              {/*  */}
                              <Modal isOpen={openExpiryDatePicker} onClose={() => setOpenExpiryDatePicker(false)} avoidKeyboard justifyContent="flex-end" bottom="4" size="lg">
                                <Modal.Content>
                                  <Modal.CloseButton />
                                  <Modal.Header>Choose Date</Modal.Header>
                                  <Modal.Body> <DatePicker onSelectedChange={(date) => {setExpiry(date?.split(' ')[0].replace(/\//g, "-"))}} /> </Modal.Body>
                                  {/* <Modal.Footer> <Button onPress={() => { setOpenExpiryDatePicker(false);}}> Proceed</Button> </Modal.Footer> */}
                                </Modal.Content>
                              </Modal>
                              <InputField
                                ref={expiryInput}
                                key="Expiry"
                                value={expiry}              
                                onChangeText={(value) => setExpiry(value)}
                                label="Expiry*"
                                placeholder="yyyy-mm-dd"
                                helper={expiryError}
                                status={expiryError ? "error" : undefined}
                                onSubmitEditing={() => quantityInput?.current?.focus()}
                                RightAccessory={() => <Fontisto name="date" size={24} color="black" onPress={() => setOpenExpiryDatePicker(true)} style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 5}}/> }
                                // LeftAccessory={() => <Icon icon="bell" />}
                                onFocus={()=>setEnableShift(false)}
                              /> 
                              </Box>
                              <Box w={'100%'}>
                                <InputField
                                  ref={quantityInput}
                                  key="Quantity"
                                  value={quantity}              
                                  onChangeText={(value) => setQuantity(value)}
                                  label="Quantity*"
                                  placeholder="500"
                                  helper={quantityError}
                                  status={quantityError ? "error" : undefined}
                                  onSubmitEditing={() => unitMRPInput?.current?.focus()}
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>setEnableShift(false)}
                                /> 
                              </Box>
                              <Box w={'100%'}>
                                <InputField
                                  ref={unitMRPInput}
                                  key="Unit MRP*"
                                  value={unitMRP}              
                                  onChangeText={(value) => setUnitMRP(value)}
                                  label="Unit MRP*"
                                  placeholder="1000"
                                  helper={unitMRPError}
                                  status={unitMRPError ? "error" : undefined}
                                  onSubmitEditing={() => offerPriceInput?.current?.focus()}
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>setEnableShift(true)}
                                /> 
                              </Box>
                              <Box w={'100%'}>
                                <InputField
                                  ref={offerPriceInput}  
                                  key="Offer Price"
                                  value={offerPrice}              
                                  onChangeText={(value) => setOfferPrice(value)}
                                  label="Offer Price*"
                                  placeholder="1000"
                                  helper={offerPriceError}
                                  status={offerPriceError ? "error" : undefined}
                                  onSubmitEditing={() => offerInput?.current?.focus()}
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>setEnableShift(true)}
                                /> 
                              </Box>
                              {/* <Box w={'100%'}>
                                <InputField
                                  ref={offerInput}
                                  key="Offer"
                                  value={offer}              
                                  onChangeText={(value) => setOffer(value)}
                                  label="Offer"
                                  placeholder="50%"
                                  helper={offerError}
                                  status={offerError ? "error" : undefined}
                                  // onSubmitEditing={() => authPasswordInput.current?.focus()}
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>setEnableShift(true)}
                                /> 
                              </Box> */}
                              <Button
                              text='Submit Offer'
                              preset="default"
                              onPress={() => submitForm()}
                              style={{
                                backgroundColor: colors.palette.primary600,
                                borderRadius: 5,
                                width: "100%",
                                marginTop: 5, 
                              }}
                              pressedStyle={{
                                backgroundColor: colors.palette.neutral100,
                                borderRadius: 5,
                                borderWidth: 0,
                            
                              }}
                              textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                              pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                              />
                            </VStack>
                          </VStack>
                        </Stack>
              
                      </VStack>
                    </Stack>
                </TouchableWithoutFeedback>
              </KeyboardAvoidingView>
            </ScrollView>
        </PageLayout>, 
        //code for Mobile devices
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}


const NoSearchProduct = () => {
  const navigation = useNavigation<any>()
  return(
    <VStack space={3} mb={20}>
      <Image
        source={require(`../../../assets/images/add_new_offer/addOfferEmptyScreen.png`)}
        // maxHeight={320}
        style={{ width: "100%", height: '50%'}}
        resizeMode="contain" alt="image"
      />
    </VStack>  
  )
}