import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
interface requirement {
  product_name: "",
  user: "",
  city: "",
  mobile: "",
  quantity: "",
}
export const AuthenticatedHomeStoreModel = types
  .model("AuthenticatedHomeStore")
  .props({
    requirement: types.frozen<requirement>()
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get product_nameValidationError(){
      if (self.requirement?.product_name.length === 0) return "Product name cannot be blank"
      return ""
    },
    get userValidationError() {
      if (self?.requirement?.user?.length === 0) return "Name can't be blank"
      return ""
    },
    get cityValidationError(){
      if (self?.requirement?.city?.length === 0) return "City cannot be blank"
      return ""
    },
    get mobileValidationError(){
      if (self?.requirement?.mobile?.length === 0) return "Mobile cannot be blank"
      if (/^[0-9]+$/.test(self?.requirement?.mobile) != true) return "Only numbers are allowed"
      if (self?.requirement?.mobile?.length < 10) return "Mobile number is incorrect"
      return ""
    },
    get quantityValidationError(){
      if (self?.requirement?.quantity?.length === 0) return "Please provide quantity"
      if (/^[0-9]+$/.test(self?.requirement?.quantity) != true) return "Only numbers are allowed"
      return ""
    },

  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setRequirement(value: any) {
      self.requirement = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface AuthenticatedHomeStore extends Instance<typeof AuthenticatedHomeStoreModel> {}
export interface AuthenticatedHomeStoreSnapshotOut extends SnapshotOut<typeof AuthenticatedHomeStoreModel> {}
export interface AuthenticatedHomeStoreSnapshotIn extends SnapshotIn<typeof AuthenticatedHomeStoreModel> {}
export const createAuthenticatedHomeStoreDefaultModel = () => types.optional(AuthenticatedHomeStoreModel, {})
