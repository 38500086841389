import * as React from "react"
import { Share, StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { Text } from "./Text"
import { HStack, Link, Pressable, VStack, useBreakpointValue } from "native-base"
import { AutoImage } from "./AutoImage"
import { useNavigation } from "@react-navigation/native"
import Config from "../config"

export interface BlogItemProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>  
  blogItemWidth: string | number
  navigation:any
  data:any
}

/**
 * Describe your component here
 */
export const BlogItem = observer(function BlogItem(props: BlogItemProps) {
  const { style, blogItemWidth } = props
  const $styles = [$container, style]

  const handleSharePress = () => {
    const contentToShare = {
      message: 'checkout this blog item',
      url: window.location.hostname+'/BlogDetail/'+props.data?.slug,
    };

    Share.share(contentToShare)
      .then(result => {
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            console.log('Shared with activity type:', result.activityType);
          } else {
            console.log('Shared');
          }
        } else if (result.action === Share.dismissedAction) {
          console.log('Share dismissed');
        }
      })
      .catch(error => console.log(error));
  };
  
  return (
    
      <VStack
        w={blogItemWidth} space={5} p={3} mt={5}
        alignItems="center"
        borderRadius={3} shadow="9"
        background={colors.palette.neutral100}
      >    
        <TouchableOpacity onPress={()=>props.navigation.navigate("BlogDetail",{slug:props.data?.slug})}>   
          <AutoImage 
            source={{uri: Config.MEDIA_URL+props.data?.intro_image}}
            maxHeight={useBreakpointValue({base: 155})} style={{borderRadius:5, width:"100%"}}
          />  
          <VStack space={3}>
            <Text
              style={{
                color:colors.palette.neutral800, 
                fontFamily:typography.fonts.poppins.medium,
                fontSize:18,
                lineHeight:22,
                textAlign:"left",
                paddingTop:10
              }}
            >{props.data?.title}</Text>
            <Text
              style={{
                color:colors.palette.neutral400,
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24,
                textAlign:"left"
              }}
            >
              {props.data?.intro}
            </Text>        
            <HStack justifyContent="space-between" space={3}>
              <Text
                style={{
                  color:colors.palette.neutral100,
                  fontFamily:typography.fonts.poppins.normal,
                  fontSize:13, padding:"5px", borderRadius:5,
                  lineHeight:19.5,
                  textAlign:"left",
                  backgroundColor:colors.palette.primary600           
                }}
              >
                Medical
              </Text>          
            </HStack>
            <HStack justifyContent="space-between" space={3}>
              <Text
                style={{
                  color:colors.palette.neutral400,
                  fontFamily:typography.fonts.poppins.normal,
                  fontSize:13,
                  lineHeight:19.5,
                  textAlign:"left",
                }}
              >
                On {(new Date(Date.parse(props.data?.publish_Date))).toDateString()}
              </Text>
              <TouchableOpacity onPress={handleSharePress} style={{ padding: 16 }}>
                <AutoImage 
                  source={require("../../assets/icons/Share.png")}
                  maxHeight={16} style={{borderRadius:5}}
                />
              </TouchableOpacity>
            </HStack>
          </VStack>
        </TouchableOpacity>
      </VStack>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}
