import { Instance, SnapshotIn, SnapshotOut, flow, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"
import { ProductApi } from "../../services/api/apiFatching/product-api"
import { api } from "../../services/api"

/**
 * Model description here for TypeScript hints.
 */
interface Chat {
  id: string,
  offer: string,
  seller: {
    id: string,
    name: string,
    product: string,
    product_id: string
  },
  buyer: {
    id: string,
    name: string,
    product: string,
    product_id: string
  },
  last_message: {
    content: string,
    sender: string,
    timestamp: string
  }
}
export const ChatStoreModel = types
  .model("ChatStore")
  .props({
    allChatsList: types.optional(types.array(types.frozen<Chat>()), []),
  })
  .actions(withSetPropAction)
  .views((self) => ({
    reset() {
      self.setProp("allChatsList",[])
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({    
    fetchChatList: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.ChatList(setOverlayVisible) 

      self.setProp("allChatsList", result.data)      
    }),
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface ChatStore extends Instance<typeof ChatStoreModel> {}
export interface ChatStoreSnapshotOut extends SnapshotOut<typeof ChatStoreModel> {}
export interface ChatStoreSnapshotIn extends SnapshotIn<typeof ChatStoreModel> {}
export const createChatStoreDefaultModel = () => types.optional(ChatStoreModel, {})
