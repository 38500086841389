import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
interface review {
  feedback: "",
  star_rating: 0,
  seller: "",

}

export const ReviewStoreModel = types
  .model("ReviewStore")
  .props({
    review: types.frozen<review>()
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get feedbackValidationError(){
      if (self.review?.feedback?.length === 0) return "feedback cannot be blank"
      return ""
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setReview(value: any) {
      self.review = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface ReviewStore extends Instance<typeof ReviewStoreModel> {}
export interface ReviewStoreSnapshotOut extends SnapshotOut<typeof ReviewStoreModel> {}
export interface ReviewStoreSnapshotIn extends SnapshotIn<typeof ReviewStoreModel> {}
export const createReviewStoreDefaultModel = () => types.optional(ReviewStoreModel, {})
