import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Alert, Box, HStack, Stack, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../theme"
import { BlogItem } from "../../components/BlogItem"
import { useNavigation } from "@react-navigation/native"
import { ApiResponse } from "apisauce"
import { api } from "../../services/api"
import { useRoute } from '@react-navigation/native';
import { WebView } from 'react-native-webview';
import Config from "../../config"
import { useStores } from "../../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `BlogDetail: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="BlogDetail" component={BlogDetailScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const BlogDetailScreen: FC<StackScreenProps<AppStackScreenProps, "BlogDetail">> = observer(function BlogDetailScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated} } = useStores()

  const blogItemWidthBreakpoint = useBreakpointValue({base: '100%', sm: '48%', md: '32%', lg: '24%'});
  const showBlogItem = useBreakpointValue({base: 2, sm: 3, md: 4, lg: 4 });

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const route = useRoute<any>();
  const [data, setData] = useState<any>()
  
  useEffect(()=>{
    api.apisauce.get(`pages/?type=blog.BlogDetailsPage&fields=*&slug=${route.params.slug}`)
    .then(
      (response:ApiResponse<any,any>)=>{
        setData(response.data?.items[0])
        console.log(response)
      }
    )
  },[route])

  const WebCode =()=>{
    let date = new Date(Date.parse(data?.meta.first_published_at))    
    return(
    <Screen style={$root} preset="scroll">
      <PageLayout variant={isAuthenticated?1:0}>
        <VStack w="100%" p={10} pb={100} space={5} 
            background={colors.palette.primary100}
            justifyContent="center"
        >          
          <VStack space={3}>
            <Text
              style={{
                color:colors.palette.neutral600, 
                fontFamily:typography.fonts.poppins.bold,
                fontSize:useBreakpointValue({base: 24,md: 40}),
                lineHeight: 47,        
                alignSelf:"center"
              }}
            >{data?.title}</Text>
          </VStack> 
          <Text
            style={{
              color:colors.palette.neutral400, 
              fontFamily:typography.fonts.poppins.medium,
              fontSize:14
            }}
          >By - {data?.blog_authors[0].author_name} on <span style={{fontFamily:typography.fonts.poppins.semiBold}}>{date.toDateString()}</span> </Text>
          <AutoImage source={{uri: data?.blog_image.full_url}} maxHeight={useBreakpointValue({base: 165,md: 550})} style={{width:"100%"}}/>
          <Box>
            { 
              (
                Platform.OS === 'web'?
                <div dangerouslySetInnerHTML={{ __html: data?.rendered_content }} style={{fontFamily:typography.fonts.poppins.light}}/>
                : <WebView
                    originWhitelist={['*']}
                    source={{html:data?.rendered_content}}
                />
              )
            }
          </Box>
          {/* Trending Blogs Section */}
          <Text
            style={{
              color:colors.palette.primary500, 
              fontFamily:typography.fonts.poppins.semiBold,
              fontSize:24,             
            }}
          >Trending Blogs</Text>
          <HStack 
            w="100%" space={2} justifyContent="center" flexWrap={'wrap'}          
          >
            {data?.trending.slice(0,showBlogItem).map((item,index)=>{
              return (
                <BlogItem blogItemWidth={blogItemWidthBreakpoint} navigation={navigation} data={item}  key={`blogDetail-list-x1-${index}`}/>
              )
            })}
          </HStack>
        </VStack>
      </PageLayout>
    </Screen>
    )
}

const MobileCode=()=>{
  return(
  <Screen style={$root} preset="scroll">
    <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
  </Screen>
  )
}



return (
<>
  {/* divert code for web and android here  */}
   {Platform.select({ 
      native : <MobileCode/>, //code for Mobile devices
      default : <WebCode/>, //code for web and other platforms
    })} 
  {/* Platform select close here  */}
</>
)

}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}
