export const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

export const truncateString = (inputString: string, maxLength: number): string => {
  const truncatedString = inputString.length > maxLength
  ? inputString.substring(0, maxLength) + "..."
  : inputString; 

  return truncatedString;
}