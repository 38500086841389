import React, { FC, useState, useEffect, useContext } from "react"
import { observer } from "mobx-react-lite"
import { Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, Divider, HStack, Pressable, Stack, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../theme"
import { api } from "../../services/api/api"
import WebView from "react-native-webview"
import Config from "../../config"
import { ApiResponse } from "apisauce"
import { useNavigation } from "@react-navigation/native"
import { Ionicons } from "@expo/vector-icons"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"
import AsyncStorage from "@react-native-async-storage/async-storage"
// import { useNavigation } from "@react-navigation/native"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `AboutUs: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="AboutUs" component={AboutUsScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const AboutUsScreen: FC<StackScreenProps<AppStackScreenProps, "AboutUs">> = observer(function AboutUsScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated, logout} } = useStores()
  const {overlayVisible,setOverlayVisible} = useContext(OverlayContext)
  const image_N_contentBreakpoint = useBreakpointValue({base:"100%", lg: '50%'});
  const why_nxp_cardsBreakpoint = useBreakpointValue({base: '100%',sm: '43%',md: '40%',lg:"25%"});

  const [data, setData] = useState<any>()
  
  useEffect(()=>{
    api.apisauce.get("pages/?type=pages.AboutUsPage&slug=about-us&fields=*")
    .then(
      (response:ApiResponse<any,any>)=>{        
        console.log(response.status)
        if(response.status == 403){
          AsyncStorage.clear()
          navigation.navigate('Signin')
        }
        else if(response.data?.items)
          setData(response.data?.items[0]) 
      }
    ).catch(error =>{console.log(error)})
  },[])

  const navigation = useNavigation<any>()

  const WebCode = () => {
    return(
    <Screen style={$root} preset="scroll">
      <PageLayout variant={isAuthenticated?1:0}>
        <Stack w="100%" pb={100}  background={colors.palette.primary100}>
          <VStack 
            w="100%"
            justifyContent="center"
            space={10} px={5}>
            <VStack space={2} pt={10}>
              <Text
                  style={{
                    color:colors.palette.neutral600, 
                    fontFamily:typography.fonts.poppins.bold,
                    fontSize:40,              
                    alignSelf:"center"
                  }}
                >{data?.title}</Text>
              <HStack justifyContent="center" alignItems="center">
                <Divider
                  width={190} alignSelf="center"
                  height={0.5}                
                  background={colors.palette.primary300}
                  zIndex={1}
                />
                <AutoImage
                  source={require("../../../assets/icons/Heading_Design.png")}
                  maxWidth={24} style={{marginLeft:-10, marginTop:-3}}
                  resizeMode="stretch"                          
                />
              </HStack>
            </VStack>
            {/* What is NearXpiry? Section*/}
            <Stack direction={{base: 'column', lg: 'row'}} 
              w="100%" space={10}              
              justifyContent="center"
              >
              <AutoImage 
                source={{uri:Config.MEDIA_URL+data?.ImageParaSection[0].image.url}}
                maxHeight={useBreakpointValue({base: 200,md: 360})} style={{width:useBreakpointValue({base: "100%",md: "50%"})}}                   
              />
              <VStack 
                w={image_N_contentBreakpoint} space={5}
                justifyContent="center" pr={{base: 0, lg:10}}
              >
                <Text
                  style={{
                    color:colors.palette.neutral800, 
                    fontFamily:typography.fonts.poppins.semiBold,
                    fontSize:24,
                    lineHeight:28.8
                  }}
                >{data?.ImageParaSection[0].heading}</Text>
                <Text
                  style={{
                    color:colors.palette.neutral400,
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24,
                    textAlign:"left"
                  }}
                >
                  {data?.ImageParaSection[0].text}
                </Text>
              </VStack>
            </Stack>  
            {/* Who we are? Section*/}
            <Stack  direction={{base: 'column-reverse', lg: 'row'}}
              w="100%" space={10}              
              justifyContent="center"
              >
              <VStack 
                w={image_N_contentBreakpoint} space={5}
                justifyContent="center" pl={{base: 0, lg:10}}
              >
                <Text
                  style={{
                    color:colors.palette.neutral800, 
                    fontFamily:typography.fonts.poppins.semiBold,
                    fontSize:24,
                    lineHeight:28.8
                  }}
                >{data?.ImageParaSection[1].heading}</Text>
                <Text
                  style={{
                    color:colors.palette.neutral400,
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24,
                    textAlign:"left"
                  }}
                >
                  {data?.ImageParaSection[1].text}
                </Text>
              </VStack>              
              <AutoImage 
                source={{uri:Config.MEDIA_URL+data?.ImageParaSection[1].image.url}}
                maxHeight={useBreakpointValue({base: 200,md: 360})} style={{width:useBreakpointValue({base: "100%",md: "50%"})}}                    
              />
            </Stack>
            {/* Why NearXpiy Section */}          
            <VStack w="100%" px={{base: 0,md: 5,lg:10}} space={10}>
              <Text
                style={{
                  color:colors.palette.neutral800, 
                  fontFamily:typography.fonts.poppins.semiBold,
                  fontSize:24,
                  lineHeight:28.8
                }}
              >Why NearXpiry?</Text>
              <HStack w="100%" space={{base: 0, lg: 5}} justifyContent="center" flexWrap={{base:'wrap', lg: 'none'}}>  
                {data?.usps.map((item,index)=>{ 
                    return (                                  
                      <VStack 
                        w={why_nxp_cardsBreakpoint} space={5} 
                        m={{base: '2vw',lg:'0vw'}}
                        alignItems="center"
                        borderTopWidth={5}
                        borderBottomWidth={5}
                        borderColor={colors.palette.primary400}
                        px={5} py={20} borderRadius={3} shadow="9"
                        background={colors.palette.neutral100}
                        key={`Aboutus-usps-${index}`}
                        >
                        <AutoImage 
                          source={{uri: Config.MEDIA_URL+item.icon}}
                          maxHeight={60} style={{marginBottom:10}}                        
                        />
                        <Text
                          style={{
                            color:colors.palette.neutral700, 
                            fontFamily:typography.fonts.poppins.semiBold,
                            fontSize:18,
                            lineHeight:26,
                            textAlign:"center"
                          }}
                        >{item.title}</Text>
                        <Text
                          style={{
                            color:colors.palette.neutral400,
                            fontFamily:typography.fonts.poppins.normal,
                            fontSize:16,
                            lineHeight:24,
                            textAlign:"center"
                          }}
                        >
                          {item.description}
                        </Text>
                      </VStack>   
                    )                 
                  })
                }                
              </HStack>
            </VStack> 
            {/* Our Mission Section */}
            <Stack direction={{base: 'column', lg: 'row'}} 
              w="100%" space={10}              
              justifyContent="center"
              >
              <AutoImage 
                source={{uri:Config.MEDIA_URL+data?.ImageParaSection[2].image.url}}
                maxHeight={useBreakpointValue({base: 200,md: 360})} style={{width:useBreakpointValue({base: "100%",md: "50%"})}}                     
              />
              <VStack 
                w={image_N_contentBreakpoint} space={5}
                justifyContent="center" pr={{base: 0, lg:10}}
              >
                <Text
                  style={{
                    color:colors.palette.neutral800, 
                    fontFamily:typography.fonts.poppins.semiBold,
                    fontSize:24,
                    lineHeight:28.8
                  }}
                >{data?.ImageParaSection[2].heading}</Text>
                <Text
                  style={{
                    color:colors.palette.neutral400,
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24,
                    textAlign:"left"
                  }}
                >
                  {data?.ImageParaSection[2].text}
                </Text>
              </VStack>
            </Stack>  
            {/* Our Vision Section */}
            <Stack  direction={{base: 'column-reverse', lg: 'row'}}
              w="100%" space={10}              
              justifyContent="center"
              >
              <VStack 
                w={image_N_contentBreakpoint} space={5}
                justifyContent="center" pl={{base: 0, lg:10}}
              >
                <Text
                  style={{
                    color:colors.palette.neutral800, 
                    fontFamily:typography.fonts.poppins.semiBold,
                    fontSize:24,
                    lineHeight:28.8
                  }}
                >{data?.ImageParaSection[3].heading}</Text>
                <Text
                  style={{
                    color:colors.palette.neutral400,
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24,
                    textAlign:"left"
                  }}
                >
                  {data?.ImageParaSection[3].text}
                </Text>
              </VStack>              
              <AutoImage 
                source={{uri:Config.MEDIA_URL+data?.ImageParaSection[3].image.url}}
                maxHeight={useBreakpointValue({base: 200,md: 360})} style={{width:useBreakpointValue({base: "100%",md: "50%"})}}                    
              />
            </Stack>
          </VStack>          
        </Stack>
      </PageLayout>
    </Screen>
    )
  }

  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
    </Screen>
    )
  }

  // Pull in navigation via hook
  // const navigation = useNavigation()

return (
  <>
    {/* divert code for web and android here  */}
      {Platform.select({ 
        native : <MobileCode/>, //code for Mobile devices
        default : <WebCode/>, //code for web and other platforms
      })}
    {/* Platform select close here  */}
  </>
)

}) // observer close here 

const $root: ViewStyle = {
  flex: 1,
}
