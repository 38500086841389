/**
 * This Api class lets you define an API endpoint and methods to request
 * data and process it.
 *
 * See the [Backend API Integration](https://github.com/infinitered/ignite/blob/master/docs/Backend-API-Integration.md)
 * documentation for more details.
 */
import {
  ApisauceInstance,
  create,
} from "apisauce"
import Config from "../../config"
import type {
  ApiConfig,
} from "./api.types"
import { useStores } from "../../models"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useContrastText } from "native-base"
import { useContext } from "react"
import { OverlayContext } from "../../components/OverlayProvider"
import { useNavigation } from "@react-navigation/native"

/**
 * Configuring the apisauce instance.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 20000,
}

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */
export class Api {
  apisauce: ApisauceInstance
  config: ApiConfig    
  /**
   * Set up our API instance. Keep this lightweight!
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) { 
    this.config = config
    this.apisauce = create({
      baseURL: this.config.url,
      // timeout: this.config.timeout,
      headers: {
        Accept: "application/json",        
      },
    })

    this.apisauce.get('csrftoken/')
    .then((response:any) => {      
      //console.log('CSRF Token:', response.data.csrfToken);
      // console.log(location.protocol+'//'+location.hostname+':'+location.port)
      this.apisauce.setHeader('X-CSRFToken', response.data.csrfToken)
      // this.apisauce.setHeader('Origin', location.protocol+'//'+location.hostname+':'+location.port)
      // this.apisauce.setHeader('Referer', location.protocol+'//'+location.hostname+':'+location.port+'/')
    })
    .catch(error => {
      console.error('Error:', error);
    }); 
    
    const value = AsyncStorage.getItem('root-v1') 
    .then((value)=> {
      let authObject = JSON.parse(value).authenticationStore       
      if(authObject.isAuthenticated){
        // this.apisauce.setHeader('Authorization',`Token fsdffdsfs`)
        this.apisauce.setHeader('Authorization',`Token ${authObject.key}`)        
      }
      else{
        
        this.apisauce.deleteHeader('Authorization')
      }       
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }  
}

// Singleton instance of the API for convenience
export const api = new Api()
