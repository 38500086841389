import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { Text } from "./Text"
import { InputField } from "./InputField"
import { Button } from "./Button"
import { Alert } from "react-native"
import { Toast, VStack, useToast } from "native-base"
import { api } from "../services/api/api"
import { useStores } from "../models"
import { AlertContext } from "./AlertContext"

export interface ContactFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const ContactForm = observer(function ContactForm(props: ContactFormProps) {
  const { style } = props
  const $styles = [style]
  // const [name, setName] = React.useState("");
  // const [email, setEmail] = React.useState("");
  // const [msg, setMsg] = React.useState("");
  const { 
    ContactStore: {email, setEmail, name, setName, msg, setMsg, 
      emailValidationError, nameValidationError, msgValidationError} 
  } = useStores()

  const toast = useToast();
  const id = "contact_request_success"

  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const emailInputRef = React.useRef<any>()
  const msgInputRef = React.useRef<any>()

    //validation error ----->
    const nameError = isSubmitted ? nameValidationError : ""
    const emailError = isSubmitted ? emailValidationError : ""
    const msgError = isSubmitted ? msgValidationError : ""

    const {showAlert} = React.useContext(AlertContext)
    function submitForm() {
      setIsSubmitted(true)
      console.log('submitForm',nameError, emailError, msgError)
      //if there is something returned form validation error then return from here and do not call login api
      if (emailValidationError) return 
      if (nameValidationError) return 
      if (msgValidationError) return 
  
      // Make a request to your server to get an authentication token.
      // If successful, reset the fields and set the token.
      api.apisauce.post("/contactRequest/", {"name":name, "email":email, "message":msg}).then((response)=>{
          setName("")
          setEmail("")
          setMsg("")
          if (!toast.isActive(id)) {
            showAlert("Success", "Request Successfully Submitted.")           
          }
          else{
            showAlert("Error", "In-appropriate data. submission failed.")
          }
        
        }).catch((error)=>{console.error(error)}) 

      setIsSubmitted(false)
      // setEmail("")
      // setPassword("")
    }


  return (
    <VStack space={5}>
         <InputField
            key="name"
            value={name}              
            onChangeText={(value) => setName(value)}
            label="Name"
            placeholder="Your Name"
            helper={nameError}
            status={nameError ? "error" : undefined}
            onSubmitEditing={() => emailInputRef.current?.focus()}
            // status="error"
            // helper="Enter your name"
            // RightAccessory={() => <Icon icon="check" />}
            // LeftAccessory={() => <Icon icon="bell" />}
          />
         <InputField
            ref={emailInputRef}
            key="email"
            value={email}              
            onChangeText={(value) => setEmail(value.toLocaleLowerCase())}
            label="Email"
            placeholder="Email Address"
            helper={emailError}
            status={emailError ? "error" : undefined}
            onSubmitEditing={() => msgInputRef.current?.focus()}
            // status="error"
            // helper="Enter your name"
            // RightAccessory={() => <Icon icon="check" />}
            // LeftAccessory={() => <Icon icon="bell" />}
          />
        <InputField
            ref={msgInputRef}
            key="msg"
            value={msg}              
            onChangeText={(value) => setMsg(value)}
            label="Message"
            placeholder="Your message"
            multiline
            style={{height: 120}}
            helper={msgError}
            status={msgError ? "error" : undefined}
            // onSubmitEditing={() => authPasswordInput.current?.focus()}
            // status="error"
            // helper="Enter your name"
            // RightAccessory={() => <Icon icon="check" />}
            // LeftAccessory={() => <Icon icon="bell" />}
          />
        <Button
            text="Send"
            preset="default"
            onPress={()=>{submitForm()}}
            style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
            pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
            textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
            pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
          />
    </VStack>
  )
})
