import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"
import { AuthenticationStoreModel } from "../AuthenticationStore"

/**
 * Model description here for TypeScript hints.
 */

export const AccountSettingStoreModel = types
  .model("AccountSettingStore")
  .props({

    // Notifications setting checkboxes-->
    emailAlerts: true,
    pushNotifications: true,
    textMessages: true,
    phoneCalls: true,
    whatsappAlerts: true,
    chatAlerts: true,
// Billing setting inputs---->
    cardNumber: types.maybeNull(types.string),
    cardHolderName: types.maybeNull(types.string),
    expiry: types.maybeNull(types.string),
    billingZipCode: types.maybeNull(types.string),
    CVV: types.maybeNull(types.string),
    cardType: types.maybeNull(types.string),
// Security setting inputs---->
    currentPassword: types.maybeNull(types.string),
    newPassword: types.maybeNull(types.string),
    repeatPassword: types.maybeNull(types.string),
    switchPublicProfile: true,
    switchExposeEmail: true,
 // Business setting inputs---->
    RegBusinessName: types.maybeNull(types.string),
    GSTNumber: types.maybeNull(types.string),
    DLNumber: types.maybeNull(types.string),
    businessAddress: types.maybeNull(types.string),
    manager: types.maybeNull(types.string),
    business_mobile: types.maybeNull(types.string),
    businessCountry: types.maybeNull(types.string),
    businessState: types.maybeNull(types.string),
    businessCity: types.maybeNull(types.string),
    businessZipCode: types.maybeNull(types.string),
  // General setting inputs---->
    profileImage: types.array(types.string),
    fullName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    mobile: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    bio: types.maybeNull(types.string),
  })
  .actions(withSetPropAction)
  .views((self) => ({
    // security setting input validation
    get currentPasswordValidationError(){
      return ""
    },
    get newPasswordValidationError(){
      if (self.newPassword?.length === 0) return "password cannot be blank"
      if (self.newPassword?.length < 9) return "password must be at least 9 characters"
      return ""
    },
    get repeatPasswordValidationError(){
      if (self.repeatPassword?.length === 0) return "repeatPassword cannot be blank"
      if (self.repeatPassword !== self.newPassword) return "Password should be same"
      return ""
    },
    // Business settings inputs validations
    get RegBusinessNameValidationError() {
      if (/^[0-9]+$/.test(self?.RegBusinessName) != false) return "Please type valid Name"
      return ""
    },
    get GSTNumberValidationError() {
      return ""
    },
    get DLNumberValidationError() {
      return ""
    },
    get businessAddressValidationError() {
      if (/^[0-9]+$/.test(self?.businessAddress) != false) return "Please type valid Address"
      return ""
    },
    get managerValidationError() {
      if (/^[0-9]+$/.test(self?.manager) != true) return "Please type valid Number"
      return ""
    },
    get business_mobileValidationError() {
      if (/^[0-9]+$/.test(self?.business_mobile) != true) return "Please type valid Number"
      return ""
    },
    get businessCountryValidationError() {
      if (/^[0-9]+$/.test(self?.businessCountry) != false) return "You have provided wrong information"
      return ""
    },
    get businessStateValidationError() {
      if (/^[0-9]+$/.test(self?.businessState) != false) return "You have provided wrong information"
      return ""
    },
    get businessCityValidationError() {
      if (/^[0-9]+$/.test(self?.businessCity) != false) return "You have provided wrong information"
      return ""
    },
    get businessZipCodeValidationError() {
      if (/^[0-9]+$/.test(self?.businessZipCode) != true) return "Please type valid zip code"
      return ""
    },

    //Billing inputs errors---------->
    get cardNumberValidationError() {     
        let data = self?.cardNumber?.split(' ').join("")
      if(data){ 
        let length = data.length < 16 || data.length > 16
        let number = !(/^[0-9]+$/.test(self?.cardNumber?.split(' ').join("")))
        if (length || number) return "Please type valid number"
      }
      return ""
    },
    get cardHolderNameValidationError() {
      let data = self?.cardHolderName
      if(data){ 
        if (/^[0-9]+$/.test(data) != false || self?.cardHolderName.length == 0) return "Please type valid name"
      }
      return ""
    },
    get expiryValidationError() {
      let data = self?.expiry?.split('/')
      if(data){
        let month = parseInt(data[0]) > 12;
        let year = parseInt(data[1]) < parseInt((new Date().getFullYear()).toString().slice(3,4))
        let number = !(/^[0-9]+$/.test(self?.expiry?.split('/').join("")))
        if (month || year || number) return "Please type valid date"
      }      
      return ""
    },
    get billingZipCodeValidationError() {
      let data = self?.billingZipCode
      if (data){
        if (/^[0-9]+$/.test(data) != true) return "Please type valid zip code"
      }
      return ""
    },
    get CVVValidationError() {
      let data = self?.CVV
      if(data){
        if (/^[0-9]+$/.test(data) != true) return "Please type valid cvv"
      }
      return ""
    },

    // General setting inputs validations---->
    get fullNameValidationError(){
      if (self?.fullName?.length === 0) return "Full Name cannot be blank"
      if (/^[0-9]+$/.test(self?.fullName) != false) return "Please type valid name"
      return ""
    },
    get emailValidationError() {
      if (self.email?.length === 0) return "Email can't be blank"
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(self.email)) return "Must be a valid email address"
      return ""
    },
    get mobileValidationError() {
      if (self.mobile?.length === 0) return "This field can't be blank"
      if (/^[0-9]+$/.test(self?.mobile) != true) return "Please type valid Mobile number"
      return ""
    },
    get addressValidationError() {
      if (self.address?.length === 0) return "This field can't be blank"
      if (self.address?.length < 3) return "Please provide full address"
      return ""
    },
    get countryValidationError() {
      if (self.country?.length === 0) return "This field can't be blank"
      return ""
    },
    get stateValidationError() {
      if (self.state?.length === 0) return "This field can't be blank"
      return ""
    },
    get cityValidationError() {
      if (self.city?.length === 0) return "This field can't be blank"
      return ""
    },
    get zipCodeValidationError() {
      if (self.zipCode?.length === 0) return "This field can't be blank"
      return ""
    },
    get bioValidationError() {
      if (self.bio?.length === 0) return "This field can't be blank"
      return ""
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setEmailAlerts(value: boolean) {
      self.emailAlerts = value
    },
    setPushNotifications(value: boolean) {
      self.pushNotifications = value
    },
    setTextMessages(value: boolean) {
      self.textMessages = value
    },
    setPhoneCalls(value: boolean) {
      self.phoneCalls = value
    },
    setWhatsappAlerts(value: boolean) {
      self.whatsappAlerts = value
    },
    setChatAlerts(value: boolean) {
      self.chatAlerts = value
    },

    // Billing setting inputs---->
    setCardNumber(value: string) {
      self.cardNumber = value!="" ? value?.replace(/\s/g, "").match(/.{1,4}/g).join(' '): value
    },
    setCardHolderName(value: string) {
      self.cardHolderName = value
    },
    setExpiry(value: string) {
      self.expiry = value!="" ? value?.replace(/[\s/]/g, "").match(/.{1,2}/g).join('/').slice(0,5): value
    },
    setBillingZipCode(value: string) {
      self.billingZipCode = value
    },
    setCVV(value: string) {
      self.CVV = value!="" ? value?.replace(/[\s/]/g, "").slice(0,3): value
    },
    setCardType(value: string) {
      self.cardType = value
    },
  // Business setting inputs---->
    setRegBusinessName(value: string) {
      self.RegBusinessName = value
    },
    setGSTNumber(value: string) {
      self.GSTNumber = value
    },
    setDLNumber(value: string) {
      self.DLNumber = value
    },
    setbusinessAddress(value: string) {
      self.businessAddress = value
    },
    setManager(value: string) {
      self.manager = value
    },
    setBusiness_Mobile(value: string) {
      self.business_mobile = value
    },
    setBusinessCountry(value: string) {
      self.businessCountry = value
    },
    setBusinessState(value: string) {
      self.businessState = value
    },
    setBusinessCity(value: string) {
      self.businessCity = value
    },
    setBusinessZipCode(value: string) {
      self.businessZipCode = value
    },
    // Security setting inputs---->
    setCurrentPassword(value: string) {
      self.currentPassword = value
    },
    setNewPassword(value: string) {
      self.newPassword = value
    },
    setRepeatPassword(value: string) {
      self.repeatPassword = value
    },
    setSwitchPublicProfile(value: boolean) {
      self.switchPublicProfile = value
    },
    setSwitchExposeEmail(value: boolean) {
      self.switchExposeEmail = value
    },

    // General setting inputs---->
    setProfileImage(value: any) {
      self.profileImage = value
    },
    setFullName(value: string) {
      self.fullName = value
    },
    setEmail(value: string) {
      self.email = value?.toLowerCase().trim()
    },
    setMobile(value: string) {
      self.mobile = value
    },
    setAddress(value: string) {
      self.address = value
    },
    setCountry(value: string) {
      self.country = value
    },
    setState(value: string) {
      self.state = value
    },
    setCity(value: string) {
      self.city = value
    },
    setZipCode(value: string) {
      self.zipCode = value
    },
    setLocation(value: string) {
      self.location = value
    },
    setBio(value: string) {
      self.bio = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface AccountSettingStore extends Instance<typeof AccountSettingStoreModel> {}
export interface AccountSettingStoreSnapshotOut extends SnapshotOut<typeof AccountSettingStoreModel> {}
export interface AccountSettingStoreSnapshotIn extends SnapshotIn<typeof AccountSettingStoreModel> {}
export const createAccountSettingStoreDefaultModel = () => types.optional(AccountSettingStoreModel, {})
