import { Instance, SnapshotIn, SnapshotOut, flow, getRoot, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"
import { withStatus } from "../../services/api/with-status";
import { api } from "../../services/api";
import { ProductApi } from "../../services/api/apiFatching/product-api";
import { User } from "../AuthenticationStore";
import { RootStoreModel } from "../RootStore";
import { getRootStore } from "../helpers/getRootStore";
import { stringifyKey } from "mobx/dist/internal";

/**
 * Model description here for TypeScript hints.
 */

export interface Products{
  id :string | '',
  name: string | '',
  short_description: string | '',
  long_description: string | '',
  thumbnail: string | '',
  brand: {
      id: number | 0,
     name: string | '',
     image: string | '' | null,
   },
  category: {
    id: number | 0,
   name: string | '',
   image: string | '' | null,
 },
 images:  Array<{
  id: number | 0,
  image: string | '' | null,
  product: number | "",
}>,
offers: Array<{
  id: number | 0,
  user: string | '',
  price: string | '',
  offer_price: string | '',
  manufacturing_date: string |'',
  expiry_date: string | '',
  quantity: number | 0,
}>,
offers_count: number | 0,
price_range: Array<number> | [0],
expiry_month_range:Array<number> | [0],
offer_price_range:Array<number> | [0],
expiry_date_range:Array<number> | [0],
quantity_range: Array<number> | [0],
mfd_range: Array<number> | [0],
min_mrp_offer: Array<number> | [0],
min_offer_percentage: number | 0, 
max_offer_percentage: number | 0,
unique_seller_count: number | 0,

}

interface category {
  id: number,
  name: string,
  image: string
}

interface brand {
  id: number,
  name: string,
  image: string
}

interface wishlist {
  id: string,
  user: string,
  product: Products,
}

export const ProductListStoreModel = types
  .model("ProductListStore")
  .props({
    // products: types.array(ProductStoreModel),
    products: types.array(types.frozen<Products>()),
    dealOfTheDayProducts: types.array(types.frozen<Products>()),
    categories: types.array(types.frozen<category>()),
    brands: types.array(types.frozen<brand>()),
    wishlist: types.array(types.frozen<wishlist>())
  })
  .actions(withSetPropAction)
  .views((self) => ({
    reset() {
      self.setProp("wishlist",[])
    },
    isFavorite(id:string){
      return self.wishlist.filter((wishlist) =>{return wishlist.product.id === id}).length>0;
    },
    getProductDetail(id:string) {
      return self.products.find((product) => product.id === id);
    }
  }))
  .actions((self) => ({    
    fetchProductList: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.productList(setOverlayVisible) 

      self.setProp("products", result.data)
      // self.products = result.data
    }),

    fetchDealOfTheDayProducts: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.dealOfTheDayProducts(setOverlayVisible) 

      self.setProp("dealOfTheDayProducts", result.data)
    }),

    fetchCategories: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.categories(setOverlayVisible) 

      self.setProp("categories", result.data)
    }),

    fetchBrands: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.brands(setOverlayVisible) 

      self.setProp("brands", result.data)
    }),

    fetchWishlist: flow(function* (setOverlayVisible:any){
      const productApi = new ProductApi(api);
      const result = yield productApi.wishlist(setOverlayVisible)      
      self.setProp("wishlist", result.data)
    }),

  }))

export interface ProductListStore extends Instance<typeof ProductListStoreModel> {}

export interface ProductListStoreSnapshotOut extends SnapshotOut<typeof ProductListStoreModel> {}
export interface ProductListStoreSnapshotIn extends SnapshotIn<typeof ProductListStoreModel> {}
// export const createProductListStoreDefaultModel = () => types.optional(ProductListStoreModel, {})
