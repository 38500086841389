/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignoreWarnings"
import { useFonts } from "expo-font"
import React, { useEffect } from "react"
import { initialWindowMetrics, SafeAreaProvider } from "react-native-safe-area-context"
import * as Linking from "expo-linking"
import { RootStoreProvider, useInitialRootStore, useStores } from "./models"
import { AppNavigator, useNavigationPersistence } from "./navigators"
import { ErrorBoundary } from "./screens/ErrorScreen/ErrorBoundary"
import * as storage from "./utils/storage"
import { colors, customFontsToLoad, typography } from "./theme"
import { setupReactotron } from "./services/reactotron"
import Config from "./config"
import { Modal, NativeBaseProvider } from "native-base"
import Overlay from 'react-native-loading-spinner-overlay';
import { OverlayContext, OverlayProvider } from "./components/OverlayProvider"
import { AlertContext, AlertProvider } from "./components/AlertContext"
import { Text } from 'react-native';
import { AutoImage, Button } from "./components"
import { registerServiceWorker } from '../registerServiceWorker';
// import { NativeBaseProvider } from "native-base"

// Set up Reactotron, which is a free desktop app for inspecting and debugging
// React Native apps. Learn more here: https://github.com/infinitered/reactotron
setupReactotron({
  // clear the Reactotron window when the app loads/reloads
  clearOnLoad: true,
  // generally going to be localhost
  host: "localhost",
  // Reactotron can monitor AsyncStorage for you
  useAsyncStorage: true,
  // log the initial restored state from AsyncStorage
  logInitialState: true,
  // log out any snapshots as they happen (this is useful for debugging but slow)
  logSnapshots: false,
})


export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

// Web linking configuration
const prefix = Linking.createURL("/")
const config = {
  screens: {
    Login: {
      path: "",
    },
    Welcome: "Welcome",
    Demo: {
      screens: {
        DemoShowroom: {
          path: "showroom/:queryIndex?/:itemIndex?",
        },
        DemoDebug: "debug",
        DemoPodcastList: "podcast",
        DemoCommunity: "community",
      },
    },
  },
}

interface AppProps {
  hideSplashScreen: () => Promise<void>
}

/**
 * This is the root component of our app.
 */
function App(props: AppProps) {
  useEffect(()=>{
    registerServiceWorker();
  },[])
  
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  const [areFontsLoaded] = useFonts(customFontsToLoad)

  const { rehydrated, rootStore } = useInitialRootStore(() => {
    // This runs after the root store has been initialized and rehydrated.

    // If your initialization scripts run very fast, it's good to show the splash screen for just a bit longer to prevent flicker.
    // Slightly delaying splash screen hiding for better UX; can be customized or removed as needed,
    // Note: (vanilla Android) The splash-screen will not appear if you launch your app via the terminal or Android Studio. Kill the app and launch it normally by tapping on the launcher icon. https://stackoverflow.com/a/69831106
    // Note: (vanilla iOS) You might notice the splash-screen logo change size. This happens in debug/development mode. Try building the app for release.
    setTimeout(hideSplashScreen, 500)
  })

  const { authenticationStore } = useStores()
  if (authenticationStore.isAuthenticated) {
    // SplashScreen.hideAsync()
  }

  const { hideSplashScreen } = props
  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rehydrated || !isNavigationStateRestored || !areFontsLoaded) return null

  const linking = {
    prefixes: [prefix],
    config,
  } 
  // otherwise, we're ready to render the app

  return (    
    <RootStoreProvider value={rootStore}>
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <ErrorBoundary catchErrors={Config.catchErrors}>
          <NativeBaseProvider>
            <OverlayProvider>
              <AlertProvider>
                <AppNavigator
                  linking={linking}
                  initialState={initialNavigationState}
                  onStateChange={onNavigationStateChange}
                />  
                <AlertContext.Consumer>
                  {({alertVisible, alertContent, hideAlert}) => (
                    <Modal
                      isOpen={alertVisible}
                      onClose={hideAlert}     
                    >
                      <Modal.Content maxWidth="400px">
                        <Modal.CloseButton />
                        <Modal.Header borderBottomWidth={0} justifyContent="center" alignItems="center">      
                          {alertContent.title==="Error" ?
                            <AutoImage
                              source={require('../assets/icons/error.png')}
                              // maxWidth={282} style={{borderRadius:5, width:"100%"}}
                            /> 
                            :
                            <AutoImage
                              source={require('../assets/icons/success.png')}
                              // maxWidth={282} style={{borderRadius:5, width:"100%"}}
                            /> 
                          }                  
                          <Text style={{
                            color:alertContent.title==="Success"?"#5FB643":(alertContent.title==="Error"?"#DF1525":"black"),
                            fontFamily:typography.fonts.poppins.medium,
                            fontSize:22,
                            fontWeight:"bold",
                            textAlign:"center",
                          }}
                          >
                            {(alertContent.title).toUpperCase()}
                          </Text>
                        </Modal.Header>
                        <Modal.Body>                                                
                          <Text                          
                            style={{
                              color:colors.palette.neutral1000,
                              fontFamily:typography.fonts.poppins.medium,
                              fontSize:16,
                              textAlign:"center",
                              paddingLeft:10,                          
                              paddingRight:10,                          
                            }}
                          >
                            {alertContent.message}
                          </Text>
                        </Modal.Body>
                        <Modal.Footer borderTopWidth={0} style={{justifyContent:'center'}}>                        
                        <Button
                          text='Ok! Thank You'
                          preset="default"
                          onPress={hideAlert}
                          style={{ 
                            backgroundColor: colors.palette.primary600,
                            borderRadius: 5,
                            width: "100%",
                            marginTop: 30, 
                          }}
                          pressedStyle={{
                            backgroundColor: colors.palette.neutral100,
                            borderRadius: 5,
                            borderWidth: 0,
                          }}
                          textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
                          pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                        />
                        </Modal.Footer>
                      </Modal.Content>                    
                    </Modal>
                  )}
                </AlertContext.Consumer>          
              </AlertProvider>
              <OverlayContext.Consumer>
                {({ overlayVisible }) => (
                  <Overlay
                    visible={overlayVisible}
                    animation="fade"
                    overlayColor="rgba(0, 0, 0, 0.5)"
                  />
                )}
              </OverlayContext.Consumer>
            </OverlayProvider>          
          </NativeBaseProvider>
        </ErrorBoundary>
      </SafeAreaProvider>
    </RootStoreProvider>
  )
}

export default App