import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
export const RegistrationStoreModel = types
  .model("RegistrationStore")
  .props({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get fullNameValidationError() {
      if (self.fullName?.length === 0) return "Name can't be blank"
      return ""
    },
    get emailValidationError() {
      if (self.email?.length === 0) return "Email can't be blank"
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(self.email) && self.email?.length <= 11) return "must be a valid email address"
      return ""
    },
    get passwordValidationError(){
      if (self.password?.length === 0) return "Password cannot be blank"
      if (self.password?.length < 9) return "Password must be at least 8 characters"
      return ""
    },
    get confirmPasswordValidationError(){
      if (self.confirmPassword?.length === 0) return "Confirm Password cannot be blank"
      if (self.confirmPassword !== self.password) return "Password should be same"
      return ""
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setFullName(value: string) {
      self.fullName = value
    },
    setEmail(value: string) {
      self.email = value.toLocaleLowerCase().trim()
    },
    setPassword(value: string) {
      self.password = value
    },
    setConfirmPassword(value: string) {
      self.confirmPassword = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface RegistrationStore extends Instance<typeof RegistrationStoreModel> {}
export interface RegistrationStoreSnapshotOut extends SnapshotOut<typeof RegistrationStoreModel> {}
export interface RegistrationStoreSnapshotIn extends SnapshotIn<typeof RegistrationStoreModel> {}
export const createRegistrationStoreDefaultModel = () => types.optional(RegistrationStoreModel, {})
