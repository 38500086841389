import React, { FC,useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Alert, Linking, Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { Button, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, ChevronDownIcon, HStack, Icon, InfoIcon, Input, SearchIcon, Stack, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../theme"
import { Avatar, ListItem } from "@rneui/base"
import { useNavigation } from "@react-navigation/native"
import { ApiResponse } from "apisauce"
import { api } from "../../services/api"
import WebView from "react-native-webview"
import { useStores } from "../../models"

// import { useNavigation } from "@react-navigation/native"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Faq: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Faq" component={FaqScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const FaqScreen: FC<StackScreenProps<AppStackScreenProps, "Faq">> = observer(function FaqScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated} } = useStores()

  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  const [data, setData] = useState<any>()
  
  useEffect(()=>{
    api.apisauce.get("pages/?type=pages.FAQPage&fields=*")
    .then(
      (response:ApiResponse<any,any>)=>{
        setData(response.data?.items[0])
        console.log(response)
      }
    )
  },[])

  // open gmail on click get in touch button
  const handleEmailPress = () => {
    const email = 'piyush@technoace.in';
    const url = `mailto:${email}`;

    Linking.canOpenURL(url)
      .then(supported => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          console.log('Mail application is not available');
        }
      })
      .catch(error => console.log(error));
  };

  const WebCode =()=>{
    return(
    <Screen style={$root} preset="scroll">
      <PageLayout variant={isAuthenticated?1:0}>
        <VStack w="100%" p={{base: 5, md:10}} pb={100} space={{base: 5, md:10}} 
          background={colors.palette.primary100}            
        >
          <Text
            style={{
              color:colors.palette.neutral600, 
              fontFamily:typography.fonts.poppins.bold,
              fontSize:40,              
              alignSelf:"center"
            }}
          >{data?.title}</Text>
          <Text
            style={{
              color:colors.palette.secondary300, 
              fontFamily:typography.fonts.poppins.bold,
              fontSize: useBreakpointValue({base: 24,sm: 35,md:48}),              
              alignSelf:"center"
            }}
          >{data?.subtitle}</Text>
          <Text
          style={{
            color:colors.palette.neutral400, 
            fontFamily:typography.fonts.poppins.medium,
            fontSize:16,
            alignSelf:"center"
          }}
          >{data?.introduction}</Text>
          <Input 
            InputLeftElement={
              <SearchIcon size="5" p={3}  pl="2" color={colors.palette.neutral400} background={colors.palette.neutral100}/>
            } 
            size={5} ml="2" color="muted.400" maxWidth={400} width="100%" placeholder="Search Keyword" alignSelf="center"
            style={{
              backgroundColor:colors.palette.neutral100,
            }}
          />        
          {/* FAQ Accordian */}
          {
            data?.faqs.map((item,i)=>{
              return (
                <Accordian key={`faqScreen-x1-${i}`} data={item}/>
              )
            })
          }

          <Stack w="80%" alignItems="center" alignSelf="center">
            <Stack direction={{base: 'column', md: 'row'}}
              p={5} 
              background={colors.palette.primary100} 
              borderRadius={10} space={5}
              shadow={10} alignItems="center" justifyContent="space-between"
              style={{
                shadowOffset: {width: 0, height: 0},  
                shadowColor: '#171717',  
                shadowOpacity: 0.2,  
                shadowRadius: 3,  
              }}>
              <VStack w={{base: "100%", md: '70%', lg: '75%'}}>
                <Text style={{color:colors.palette.neutral800, fontSize:18, fontFamily:typography.fonts.poppins.semiBold}}>Still have questions?</Text>
                <Text style={{color:colors.palette.neutral400, fontSize:14, fontFamily:typography.fonts.poppins.normal}}>Can t find the answer you re looking for? Please chat to our friendly team.</Text>
              </VStack>
              <Button
                text="Get in touch"
                preset="default"
                onPress={() =>{ handleEmailPress()}}          
                style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }]}
                pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 0 }, { borderRadius: 5 }]}
                textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
              />
            </Stack>
          </Stack>
        </VStack>
      </PageLayout>
    </Screen>
    )
  }
  
  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
    </Screen>
    )
  }
  
  
  return (
  <>
    {/* divert code for web and android here  */}
    {Platform.select({ 
        native : <MobileCode/>, //code for Mobile devices
        default : <WebCode/>, //code for web and other platforms
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 
           

const $root: ViewStyle = {
  flex: 1,
}

export interface FAQItemProps {
  data:any
}

const Accordian = (props: FAQItemProps) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <ListItem.Accordion
      content={
        <>
          <InfoIcon size="5" m="2" color={colors.palette.neutral400} background={colors.palette.neutral100}/>
          <ListItem.Content>
            <ListItem.Title>{props.data.question}</ListItem.Title>
          </ListItem.Content>
        </>
      }
      icon={<ChevronDownIcon/>}
      style={{
        borderTopWidth:5,
        borderTopColor:colors.palette.neutral200
      }}
      isExpanded={expanded}
      onPress={() => {
        setExpanded(!expanded);
      }}
    >
      { 
        (
          Platform.OS === 'web'?
          <div dangerouslySetInnerHTML={{ __html: props.data.rich_answer }} 
            style={{
              color:colors.palette.neutral400, 
              fontFamily:typography.fonts.poppins.normal,
              fontSize:16
            }}
          />
          : <WebView
              originWhitelist={['*']}
              source={{html:props.data.rich_answer}}
          />
        )
      }
      {/* <Text
        style={{
          color:colors.palette.neutral800, 
          fontFamily:typography.fonts.poppins.semiBold,
          fontSize:18,
          lineHeight:50                
        }}
      >Lorem ipsum dolor</Text>
      <Text
        style={{
          color:colors.palette.neutral400, 
          fontFamily:typography.fonts.poppins.normal,
          fontSize:16
        }}
      >
        <p>Lorem ipsum dolor sit amet. Ut laudantium adipisci et animi dolorum et neque error aut maxime nesciunt. Vel recusandae ducimus At dolorem iste id magni quam id corrupti officiis At minus minus et quidem rerum! 33 nihil quas aut consequatur asperiores est sunt illo et sunt commodi.</p>

        <p>Non magni quisquam ex possimus ducimus id fuga dicta et obcaecati blanditiis sed quidem sint est molestiae ea quam corrupti. Nam itaque quae et ratione error vel possimus sunt! Qui cupiditate quae ut magnam veritatis vel nemo dolores id aspernatur deleniti sed itaque aut odio placeat.</p>
      </Text> */}
    </ListItem.Accordion>
  )
}