import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, TouchableWithoutFeedback, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../../navigators"
import { Button, Screen, Text } from "../../../../components"
import { PageLayout } from "../../../../components/PageLayout"
import { Box, HStack, KeyboardAvoidingView, Pressable, ScrollView, Stack, VStack } from "native-base"
import { colors, typography } from "../../../../theme"
import { Keyboard } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { Checkbox } from "react-native-ui-lib"
import { Ionicons } from "@expo/vector-icons"

// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"
//imports for mobile----->
import Constants from 'expo-constants';

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `BusinessSettingMobileScreen: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="BusinessSettingMobileScreen" component={BusinessSettingMobileScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const NotificationSettingMobileScreen: FC<StackScreenProps<AppStackScreenProps, "NotificationSettingMobile">> = observer(function NotificationSettingMobileScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const [enableShift_80,setEnableShift_80] = useState(50)
  const [enableShift_350,setEnableShift_350] = useState(50)
  const [emailAlerts,setEmailAlerts] = useState(false)
  const [pushNotifications,setPushNotifications] = useState(false)
  const [textMessages,setTextMessages] = useState(false)
  const [phoneCalls, setPhoneCalls] = useState(false);
  const [whatsappAlerts, setWhatsappAlerts] = useState(false);
  const [chatAlerts, setChatAlerts] = useState(false);
  
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  // const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
      setKeyboardOpen(true);
      // setKeyboardHeight(e.endCoordinates.height);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', (e) => {
      setKeyboardOpen(false);
      // setKeyboardHeight(0);
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);


  const SecondaryButton = ({width}) => {
    return(
      <Button
        text="Reset All"
        preset="default"
        onPress={() =>{navigation.navigate("Signin")}}          
        style={[{ backgroundColor: colors.palette.neutral100}, { borderRadius: 5, borderColor: colors.palette.primary300 }, { width: width }]}
        pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
        textStyle={[{ fontSize: 12 }, { color: colors.palette.primary300 }]}
        pressedTextStyle={[{ fontSize: 12 }, { color: colors.palette.neutral100 }]}
      />
    )
  }

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  return (
    <>
      {/* divert code for web and android here  */}
       {Platform.select({ 
          default : <></> , //code for web and other platforms
          
          native : 
            <PageLayout  variant={1}>
              <ScrollView style={{backgroundColor: colors.palette.primary700}}>
                {/* <KeyboardAvoidingView behavior={"position"} contentContainerStyle={{flex: 1}} style={{flex:1}} enabled={enableShift}> 
                  <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={{flex:1}}>  */}
                   
                    {/* parent stack */}
                    <VStack bg={colors.palette.primary300} w="100%" h="100%" paddingBottom={50}>
                      <Box minH={100} ></Box>
                        
                      {/* get started section */}
                      <VStack bg={'white'} space={6} p={5} pt={10} pb={10} style={{borderTopLeftRadius: 70, borderTopRightRadius: 70}} 
                        paddingBottom={Platform.select({ios: isKeyboardOpen ? enableShift_350 : 70 , android: isKeyboardOpen ? enableShift_80 : 60})}>
                        <VStack space={2}>
                          {/* go back section */}
                          <HStack space={3} alignItems={'center'}>
                            <Pressable onPress={() => navigation.goBack()}>
                              <Ionicons name="arrow-back" alt="icon" size={30} color={colors.palette.primary300}/>
                            </Pressable>
                            <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.bold,
                                fontSize: 20,
                                lineHeight: 28.8,
                                color: colors.palette.primary300,
                              }}
                              >
                                {/* {sellerName} */}
                                Notification Settings
                            </Text>
                          </HStack>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.neutral300,
                            }}
                            >
                              Change account information and settings
                          </Text>
                        </VStack>
                        
                        {/* Notification settings input fields */}
                        <VStack w={"100%"} space={8}>
                          {/* heading */}
                          <Stack direction={'row'} alignItems={"center"}>
                            <VStack w={"75%"}>
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.semiBold,
                                  fontSize: 15,
                                  lineHeight: 28.8,
                                  color: colors.palette.neutral700,
                                }}
                                >
                                  Update website notifications
                              </Text>
                            </VStack>
                            {/* reset all button */}
                            <Box w={"25%"} alignItems='flex-end'>
                              <SecondaryButton width={"100%"}/>
                            </Box>
                          </Stack>
                          <Stack space={5} direction={'column'}>
                            {/* System settings */}
                              <VStack space={5} w={'100%'}>
                                <Box>
                                  <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.medium,
                                        fontSize: 15,
                                        lineHeight: 22,
                                        color: colors.palette.neutral800,
                                      }}
                                      >
                                        System settings
                                    </Text>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 14,
                                        lineHeight: 24,
                                        color: colors.palette.neutral400,
                                      }}
                                      >
                                        You will receive emails in your business email address
                                    </Text>
                                </Box>
                                <VStack space={7}>
                                  <HStack w={'100%'} space={2}>
                                    <Checkbox value={emailAlerts} 
                                      onValueChange={() => {setEmailAlerts(!emailAlerts)}} 
                                      color={colors.palette.primary600}
                                      style={{ borderColor: 'gray', borderRadius: 2}}/>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 14,
                                        lineHeight: 24,
                                        color: colors.palette.neutral400,
                                      }}
                                      >
                                      E-mail Alerts
                                    </Text>
                                  </HStack>
                                  <HStack w={'100%'} space={2}>
                                    <Checkbox value={pushNotifications} 
                                      onValueChange={() => {setPushNotifications(!pushNotifications)}} 
                                      color={colors.palette.primary600}
                                      style={{ borderColor: 'gray', borderRadius: 2}}/>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 14,
                                        lineHeight: 24,
                                        color: colors.palette.neutral400,
                                      }}
                                      >
                                      Push Notifications
                                    </Text>
                                  </HStack>
                                  <HStack w={'100%'} space={2}>
                                    <Checkbox value={textMessages} 
                                      onValueChange={() => {setTextMessages(!textMessages)}} 
                                      color={colors.palette.primary600}
                                      style={{ borderColor: 'gray', borderRadius: 2}}/>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 14,
                                        lineHeight: 24,
                                        color: colors.palette.neutral400,
                                      }}
                                      >
                                      Text Messages
                                    </Text>
                                  </HStack>
                                  <HStack w={'100%'} space={2}>
                                    <Checkbox value={phoneCalls} 
                                      onValueChange={() => {setPhoneCalls(!phoneCalls)}}
                                      color={colors.palette.primary600} 
                                      style={{ borderColor: 'gray', borderRadius: 2}}/>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 14,
                                        lineHeight: 24,
                                        color: colors.palette.neutral400,
                                      }}
                                      >
                                      Phone Calls
                                    </Text>
                                  </HStack>
                                </VStack>
                              </VStack>
                            {/* Chat settings */}
                              <VStack space={5} w={'100%'}>
                                <Box>
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.medium,
                                      fontSize: 15,
                                      lineHeight: 22,
                                      color: colors.palette.neutral800,
                                    }}
                                    >
                                      Chat settings
                                  </Text>
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.normal,
                                      fontSize: 14,
                                      lineHeight: 24,
                                      color: colors.palette.neutral400,
                                    }}
                                    >
                                      You will receive emails in your business email address
                                  </Text>
                                </Box>
                                <VStack space={7}>
                                  <HStack w={'100%'} space={2}>
                                    <Checkbox value={whatsappAlerts} 
                                      onValueChange={() => {setWhatsappAlerts(!whatsappAlerts)}} 
                                      color={colors.palette.primary600}
                                      style={{ borderColor: 'gray', borderRadius: 2}}/>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 14,
                                        lineHeight: 24,
                                        color: colors.palette.neutral400,
                                      }}
                                      >
                                      Whatsapp Alerts
                                    </Text>
                                  </HStack>
                                  <HStack w={'100%'} space={2}>
                                    <Checkbox value={chatAlerts} 
                                      onValueChange={() => {setChatAlerts(!chatAlerts)}} 
                                      color={colors.palette.primary600}
                                      style={{ borderColor: 'gray', borderRadius: 2}}/>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.normal,
                                        fontSize: 14,
                                        lineHeight: 24,
                                        color: colors.palette.neutral400,
                                      }}
                                      >
                                      Chat Alerts
                                    </Text>
                                  </HStack>
                                </VStack>
                              </VStack>
                          </Stack>
          
                          {/* save button */}
                          <Button
                              text='Save'
                              preset="default"
                              // onPress={() => Alert.alert("pressed")}
                              style={{
                                backgroundColor: colors.palette.primary600,
                                borderRadius: 5,
                                width: "40%",
                              }}
                              pressedStyle={{
                                backgroundColor: colors.palette.neutral100,
                                borderRadius: 5,
                                borderWidth: 0,
                            
                              }}
                              textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                              pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                            />
                            
                          <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.normal,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral400,
                              }}
                              >
                                You may also consider to update your <Text style={{color: colors.palette.secondary300}}> billing information.</Text> 
                            </Text>
                        </VStack>
                      </VStack>
                        
                    </VStack>
                  {/* </TouchableWithoutFeedback>
                </KeyboardAvoidingView> */}
              </ScrollView>
          </PageLayout>, //code for Mobile devices
        })}
      {/* Platform select close here  */}
    </>
    )
})

const $root: ViewStyle = {
  flex: 1,
}
