import * as React from "react"
import {useState} from "react"
import { Dimensions, Linking, Platform, Pressable, StyleProp, TextStyle, TouchableOpacity, View, ViewStyle, Alert } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, spacing, typography } from "../theme"
import { Header } from "./Header"
import { AutoImage } from "./AutoImage"
import { ButtonGroup, Divider} from '@rneui/themed'; 
import { getActiveRouteName } from "../navigators";
import { useNavigation } from "@react-navigation/native"
import {Box, HStack, IconButton, Image, Input, Link, Menu, Stack, VStack, useBreakpointValue, useToast } from "native-base"
import { Button } from "./Button"
import { Text } from "./Text"
import { InputField } from "./InputField"
import { EvilIcons, Feather, Ionicons } from "@expo/vector-icons"


//imports for mobile----->
import Constants from 'expo-constants';
import { api } from "../services/api"
import { useStores } from "../models"
import { OverlayContext } from "./OverlayProvider"

export interface PageLayoutProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  children?:JSX.Element  
  variant:Number | undefined
  noProductFound?: any
}

/**
 * This is a wrapper for website pages. It contains Header Menu and Footer. and renders children between them
 */
export const PageLayout = observer(function PageLayout(props: PageLayoutProps) {
  const { authenticationStore, ProductListStore:{categories} } = useStores()
  const {setOverlayVisible} = React.useContext(OverlayContext)
  const { style } = props
  const navigation = useNavigation<any>()
  const $styles = [$container,$header, style]  
  const [search, setSearch] = React.useState('');

  const showAboveLg = useBreakpointValue({base: Platform.select({default: false, native: true}), xl: true});
  const headerPadding = useBreakpointValue({base: 15, md: 30});
  // const headerPadding = useBreakpointValue({base: 10, md: 30});
  const [noDataScreenShow, setNoDataScreenShow] = React.useState(props.noProductFound);
  
  React.useEffect(()=>{
    setNoDataScreenShow(props.noProductFound)
  },[props.noProductFound])

  function searchProducts(){
    navigation.navigate("Products",{"searchString":search}); 
    setNoDataScreenShow(true)
  }

  return (
    <AppContainer>
      <View style={[$container, {maxWidth: Platform.select({default: "1440px", native: '100%'})}]}>
        {
          props.variant===1
          ?
          <React.Fragment>
            <Header     
              style={
                Platform.select({ 
                  // 1nd header style diversion for mobile and web
                  default: [$header, { padding: 5, paddingLeft: headerPadding, paddingRight: headerPadding}],
                  native: [$headerStyleHeader, { padding: 5, paddingLeft: headerPadding, paddingRight: headerPadding}]
                })}
              titleStyle={{ color: "black" }}
              RightActionComponent={
                Platform.select({ 
                  default: <WebMenuItems1 navigation={navigation} setNoDataScreenShow={setNoDataScreenShow}/>, 
                  native:<NativeMenuItems1/> 
                })}
              LeftActionComponent={<Pressable onPress={()=>navigation.navigate("Welcome")}><Image source={require("../../assets/images/color_logo.png")} maxHeight={39} style={{width: 160, height: 39}} alt='image'/></Pressable>}        
            />
            { showAboveLg  && 
            <Header      
              style={ 
                // 2nd header style diversion for mobile and web
                Platform.select({
                  default: [$header, {padding: 5, paddingLeft: headerPadding, paddingRight: headerPadding}],
                  native: [$headerStyleHeader, {marginTop: -Constants.statusBarHeight-1, paddingBottom: 5, paddingTop: 0, paddingLeft: headerPadding, paddingRight: headerPadding}]
                })}
              titleStyle={{ color: "black" }}
              RightActionComponent={
                Platform.select({ 
                  default: <WebMenuItems2 navigation={navigation}/>, 
                  native:<NativeMenuItems2 setNoDataScreenShow={setNoDataScreenShow} /> 
                })}
              LeftActionComponent={
                <HStack space={2} alignItems="center">
                  <Box w={Platform.select({ default: "100%", native: '50' })} alignItems="center">
                    <Menu w={"350"} style={{backgroundColor: 'white'}} trigger={triggerProps => {
                        return (
                          <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                            <HStack space={1} alignItems={'center'}>
                                <Image source={require("../../assets/icons/menu_3x.png")} style={{width: 30, height: 25}} alt="image"/>
                                {/* only show on web */}
                                {Platform.select({
                                  default: 
                                  <Text
                                      style={{
                                        color:colors.palette.neutral800, 
                                        fontFamily:typography.fonts.poppins.medium,
                                        fontSize: 16,
                                        lineHeight: 21
                                      }}
                                    >Browse By Category</Text>,
                                  native: 
                                    <></>
                                })}
                            </HStack>
                          </Pressable>
                        )
                      }}>
                        <> 
                        {/* Browse by category burgermenu items for web and mobile */}
                            {Platform.select({
                              default: 
                                <> 
                                  {useBreakpointValue({base:true,md:false}) &&                              
                                  <InputField
                                    key="search"
                                    value={search}
                                    onChangeText={(searchTerm) => setSearch(searchTerm)}        
                                    placeholder="Search for products"
                                    RightAccessory={()=> (
                                      <Feather 
                                        name="search"
                                        size={24} 
                                        style={{alignSelf: 'center', margin: 1, borderRadius:5, position: 'absolute', right: 0, backgroundColor:colors.palette.primary600, padding:10}}
                                        color={colors.palette.neutral100}  
                                        onPress={() => searchProducts()}/>
                                      )}
                                      onSubmitEditing={searchProducts}
                                  />}
                                  {categories?.map((category:any,index) => {
                                      return <Menu.Item onPress={()=>navigation.navigate("Products",{"categoryName":category?.name})} key={`bbc_MenuItem_${index}`}>
                                        {category.name}
                                      </Menu.Item>
                                    })
                                  }
                                </>,
                              
                              native: 
                                <>
                                  <Menu.Item onPress={()=>{navigation.navigate("About")}}>About</Menu.Item>
                                  <Menu.Item onPress={()=>{navigation.navigate("Contact")}}>Contact</Menu.Item>
                                  <Menu.Item onPress={()=>{navigation.navigate("Blog")}}>Blog</Menu.Item>
                                  <Menu.Item onPress={()=>{navigation.navigate("AddNewOffer")}}>Add New Offer</Menu.Item>
                                  <Menu.Item onPress={()=>{navigation.navigate("AccountSettings_mobile")}}>Account Settings</Menu.Item>
                                  {authenticationStore.isAuthenticated ?
                                    <Menu.Item onPress={()=>{navigation.navigate("Signin")}}>Login</Menu.Item>
                                  :
                                    <Menu.Item onPress={()=>{navigation.navigate("Signin")}}>Login</Menu.Item>
                                  }
                                </>
                            })}
                      </>
                    </Menu>
                  </Box>
                </HStack>
              }        
            />}
          </React.Fragment>
          :
          <Header     
            style={[$header, {paddingLeft: headerPadding, paddingRight: headerPadding}]}
            titleStyle={{ color: "black" }}
            RightActionComponent={
              Platform.select({ 
                web: <WebMenuItems navigation={navigation}/>, 
                default:<NativeMenuItems1/> 
              })}
            LeftActionComponent={<Pressable onPress={()=>navigation.navigate("Home")}><AutoImage source={require("../../assets/images/color_logo.png")} maxHeight={39} style={{width: 130, height: 39}} resizeMode="contain"/></Pressable>}        
          />
        }

        {noDataScreenShow ? <NoSearchProduct setNoDataScreenShow={setNoDataScreenShow}/> :  props.children} 

        {/* footer  */}
        {Platform.select({
          default: <PageFooter navigation={navigation}/>,
          native: <></>
        })} 
      </View>
    </AppContainer>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
  width:"100%",
  alignSelf:"center"   
}

const $header: ViewStyle = {
  backgroundColor: "white",
  shadowOffset: {width: 0, height: 1},  
  shadowColor: '#171717',  
  shadowOpacity: 0.2,  
  shadowRadius: 15,   
  zIndex:10,
}

const $headerStyleHeader: ViewStyle = {
  backgroundColor: '#ebfffc',

}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}


/**
 * Menu Buttons Component to be used in top header in on web
 */
const WebMenuItems = ({navigation}) => {     
  const buttons = ['Welcome','Home','Products', 'About','Blog', 'Contact']  
  const selectedMenu = React.useRef(buttons.indexOf(getActiveRouteName(navigation.getState()).replace("_"," ")))
 
  const showAboveLg = useBreakpointValue({base: false, xl: true});
  const fontsizeButton = useBreakpointValue({base: 12, md: 14});
  
  const updateButtonGroup = (index) => {
    console.log("index: " + index);
    selectedMenu.current = index    
    navigation.navigate(buttons[index].replace(" ","_"))
  }

  const { authenticationStore:{isAuthenticated} } = useStores()
  const [ showLogoutModal, setShowLogoutModal ] = React.useState(false);
  return (<>
    <LogoutModal showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal}/>
    {showAboveLg ? 
    <HStack h="100%" space={3} justifyContent="space-between" width="70%">
      <ButtonGroup
        onPress={updateButtonGroup}
        selectedIndex={selectedMenu.current}
        buttons={buttons}
        containerStyle={{height: "90%", width:"500px", borderWidth:0, }}  
        buttonContainerStyle={{ backgroundColor:colors.palette.neutral100}}
        selectedButtonStyle={{backgroundColor:colors.palette.neutral100, borderBottomWidth:3, borderBottomColor:colors.palette.primary600}}
        selectedTextStyle={{color:colors.palette.primary600, fontWeight:"bold"}}
        innerBorderStyle={{width:0}}
        textStyle={{fontSize:14, lineHeight:40, fontWeight:"bold", color:colors.palette.primary500}}            
      /> 
      
      <HStack alignItems="center" space={3}>
        {!isAuthenticated ?
          <>
           <Button        
              text="Login" 
              preset="default"
              onPress={() => navigation.navigate("Signin")}          
              style={[{ borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
              pressedStyle={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
              textStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.primary600 }]}
              pressedTextStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.neutral100 }]}
            /> 
            <Button
              text="Register Now"
              preset="default"
              onPress={() => navigation.navigate("Signup")}          
              style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
              pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
              textStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.neutral100 }]}
              pressedTextStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.primary600 }]}
            />
          </>
          :
          <Button        
            text="Logout" 
            preset="default"
            onPress={() => {
              setShowLogoutModal(true);
            }}          
            style={[{ borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
            pressedStyle={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
            textStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.primary600 }]}
            pressedTextStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.neutral100 }]}
          /> 
        }
      </HStack>
    </HStack>
  :
  <HStack h="100%" space={3} alignItems="center" justifyContent={'flex-end'} width="35%">
    
    <HStack alignItems="center" space={{base: 1, md: 3}}>
      {!isAuthenticated ?
        <>
          <Button        
            text="Login" 
            preset="default"
            onPress={() => navigation.navigate("Signin")}          
            style={[{ borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
            pressedStyle={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5, marginTop: 8, marginBottom: 8 ,paddingVertical: 8, paddingHorizontal: 8}]}
            textStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.primary600 }]}
            pressedTextStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.neutral100 }]}
          /> 
          <Button
            text="Register Now"
            preset="default"
            onPress={() => navigation.navigate("Signup")}          
            style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
            pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
            textStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.neutral100 }]}
            pressedTextStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.primary600 }]}
          />
        </>
        :
        <Button        
          text="Logout" 
          preset="default"
          onPress={() => {setShowLogoutModal(true)}}          
          style={[{ borderRadius: 5, marginTop: 8, marginBottom: 8 ,paddingVertical: 8, paddingHorizontal: 8}]}
          pressedStyle={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5, marginTop: 8, marginBottom: 8,paddingVertical: 8, paddingHorizontal: 8 }]}
          textStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.primary600 }]}
          pressedTextStyle={[{ fontSize: fontsizeButton }, { color: colors.palette.neutral100 }]}
        /> 
      }
    </HStack>
    
    <Box w="10%" alignItems="center">
      <Menu w="350" style={{backgroundColor: 'white'}} trigger={triggerProps => {
          return (
            <Pressable accessibilityLabel="More options menu" {...triggerProps}>
              <AutoImage source={require("../../assets/icons/menu_3x.png")} maxHeight={25}/>
            </Pressable>
          )
          }}>
        <Menu.Item onPress={()=> navigation.navigate('Welcome')}>Home</Menu.Item>
        <Menu.Item onPress={()=> navigation.navigate('About')}>About</Menu.Item>
        <Menu.Item onPress={()=> navigation.navigate('Blog')}>Blog</Menu.Item>
        <Menu.Item onPress={()=> navigation.navigate('Contact')}>Contact</Menu.Item>
      </Menu>
    </Box>
    </HStack>
  }
    </>
  )
}

/**
 * Menu Variant 1 upper part Component to be used in top header in on web
 */
const WebMenuItems1 = ({navigation, setNoDataScreenShow}) => {     
  const { authenticationStore } = useStores()
  const [search, setSearch] = React.useState("")

  const showAboveLg = useBreakpointValue({base: false, xl: true});
  const iconPadding = useBreakpointValue({base: 3, sm: 10});
  
  const {  setOverlayVisible } = React.useContext(OverlayContext);
  const { showAlert } = React.useContext(AlertContext);
  const [ showLogoutModal, setShowLogoutModal ] = React.useState(false);
 
  function searchProducts(){
    navigation.navigate("Products",{"searchString":search}); 
    setNoDataScreenShow(true)
  }
  return (
    <>
      <LogoutModal showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal}/>
      {showAboveLg ?
        <HStack h="100%" space={3} justifyContent="space-between" alignItems="center" width="70%">
          <InputField
            key="search"
            value={search}
            onChangeText={(searchTerm) => setSearch(searchTerm)}        
            placeholder="Search for products"
            RightAccessory={()=> (
              <Feather 
                name="search"
                size={24} 
                style={{alignSelf: 'center', margin: 1, borderRadius:5, position: 'absolute', right: 0, backgroundColor:colors.palette.primary600, padding:10}}
                color={colors.palette.neutral100}  
                onPress={() => searchProducts()}/>
            )}
          />
          <HStack alignItems="center" space={{base: 0, md: 3}}>
            <IconButton onPress={()=>{navigation.navigate("Chat")}} icon={<AutoImage source={require("../../assets/icons/Chat.png")} maxHeight={28}/>}/>
            <IconButton onPress={()=>{navigation.navigate("Wishlist")}} icon={<AutoImage source={require("../../assets/icons/Wishlist.png")} maxHeight={28}/>}/>
            <IconButton onPress={()=>{navigation.navigate("Notifications")}} icon={<AutoImage source={require("../../assets/icons/Notifications.png")} maxHeight={28}/>}/>
            <Menu w="190" style={{backgroundColor: 'white'}} trigger={triggerProps => {
                return (
                  <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                    <AutoImage source={require("../../assets/icons/user.png")} maxHeight={28}/>
                  </Pressable>
                )
                }}>
              <Menu.Item onPress={()=> {authenticationStore?.user?.seller != null ? navigation.navigate('SellerProfile', {sellerId: authenticationStore?.user?.seller?.id}) :  navigation.navigate('GeneralSettings'); Alert.alert("you are not a seller change your profile settings")}}>Profile</Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate('GeneralSettings')}>Account Settings</Menu.Item>
              <Menu.Item onPress={()=>{setShowLogoutModal(true)}}>Logout</Menu.Item>
            </Menu>
          </HStack>
        </HStack>
        :
        <HStack h="100%" space={3} alignItems="center" justifyContent={'flex-end'} width="35%">
          <HStack space={{base: 0, md: 3}}>
            <IconButton onPress={()=>{navigation.navigate("Chat")}} icon={<AutoImage source={require("../../assets/icons/Chat.png")} maxHeight={23}/>} style={{padding: iconPadding}}/>
            <IconButton onPress={()=>{navigation.navigate("Wishlist")}} icon={<AutoImage source={require("../../assets/icons/Wishlist.png")} maxHeight={23}/>} style={{padding: iconPadding}}/>
            <IconButton onPress={()=>{navigation.navigate("Notifications")}} icon={<AutoImage source={require("../../assets/icons/Notifications.png")} maxHeight={23}/>} style={{padding: iconPadding}}/>
          </HStack>
          <Box w="10%" alignItems="center">
            <Menu w="200" style={{backgroundColor: 'white'}} trigger={triggerProps => {
                return (
                  <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                    <AutoImage source={require("../../assets/icons/menu_3x.png")} maxHeight={25}/>
                  </Pressable>
                )
                }}>              
              <Menu.Item onPress={()=> navigation.navigate('Welcome')}>Welcome</Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate( 'Home')}>Home</Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate( 'Products')}>Products</Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate('About')}>About</Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate('Blog')}>Blog</Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate('Contact')}>Contact</Menu.Item>
              <Menu.Item onPress={()=> {
                if(authenticationStore.user.seller.is_verified)
                  navigation.navigate('AddNewProduct')
                else if(authenticationStore.sellerStatus === 'incomplete'){
                    console.log(authenticationStore.sellerStatus)
                    showAlert("Error", "To use this feature, Business profile must be complete and verified. Please complete your profile and try again.")
                    navigation.navigate('GeneralSettings')
                  }else{
                    showAlert("Error", "We are verifying changes to your business profile. Please try later")
                  }
                }
              }>
                  Add New Product
              </Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate('AddNewOffer')}>Add New Offer</Menu.Item>
              <Menu.Item onPress={()=> navigation.navigate('GeneralSettings')}>Account Settings</Menu.Item>
              <Menu.Item onPress={()=> {authenticationStore?.user?.seller != null ? navigation.navigate('SellerProfile', {sellerId: authenticationStore?.user?.seller?.id}) :  navigation.navigate('GeneralSettings'); Alert.alert("you are not a seller change your profile settings")}}>Profile</Menu.Item>
              <Menu.Item onPress={()=>{setShowLogoutModal(true)}}>Logout</Menu.Item>
            </Menu>
          </Box>
        </HStack>
      }
    </>
  )
}

import {Button as ReactBaseButton, Modal, FormControl, Center } from "native-base";
import { AlertContext } from "./AlertContext"
import { AppContainer } from "./AppContainer"
import { validateEmail } from "../utils/functions"

export const LogoutModal = (props:{showLogoutModal:any, setShowLogoutModal:any}) => {
  const { authenticationStore } = useStores(); 
  const { setOverlayVisible } = React.useContext(OverlayContext)
  const [showModal, setShowModal] = useState(props.showLogoutModal);

  React.useEffect(() => {
    // console.log("Logout Model: ",showModal)    
    setShowModal(props.showLogoutModal)
  },[props.showLogoutModal])

  return <Center>      
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content maxWidth="400px">          
          <Modal.CloseButton />
          <Modal.Header>Confirm Logout</Modal.Header>
          <Modal.Body>
            <Text>Are you sure. Want to Logout?</Text>
          </Modal.Body>
          <Modal.Footer>
            <ReactBaseButton.Group space={2}>
              <ReactBaseButton variant="ghost" colorScheme="blueGray" onPress={() => {
              props.setShowLogoutModal(false)
              setShowModal(false);
            }}>
                Cancel
              </ReactBaseButton>
              <ReactBaseButton onPress={() => {
                authenticationStore.logout(setOverlayVisible)
                props.setShowLogoutModal(false)
                setShowModal(false);
            }}>              
                Confirm
              </ReactBaseButton>
            </ReactBaseButton.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>;
};
/**
 * Menu Variant 1 lower part Component 2 to be used in top header in on web
 */
const WebMenuItems2 = ({navigation}) => {     
  const {authenticationStore} = useStores();
  const { showAlert } = React.useContext(AlertContext);
  const buttons = ['Welcome','Home','Products', 'About', 'Blog', 'Contact']  
  const selectedMenu = React.useRef(buttons.indexOf(getActiveRouteName(navigation.getState()).replace("_"," ")))
  
  const updateButtonGroup = (index) => {
    selectedMenu.current = index
    
    // on authenticated Home page if user click on Home then authenticated home page should be rendered not home page
    // if(index === 0 )    
    //   navigation.navigate("Home")
    // else
    navigation.navigate(buttons[index].replace(" ","_"))
  }

  return (
    <HStack h="100%" space={3} width="70%">
      <ButtonGroup
        onPress={updateButtonGroup}
        selectedIndex={selectedMenu.current}
        buttons={buttons}
        containerStyle={{height: "90%", width:"500px", borderWidth:0, }}  
        buttonContainerStyle={{ backgroundColor:colors.palette.neutral100}}
        selectedButtonStyle={{backgroundColor:colors.palette.neutral100, borderBottomWidth:3, borderBottomColor:colors.palette.primary600}}
        selectedTextStyle={{color:colors.palette.primary600, fontWeight:"bold"}}
        innerBorderStyle={{width:0}}
        textStyle={{fontSize:14, lineHeight:40, fontWeight:"normal", color:colors.palette.primary500}}            
      />      
      <Button
          text="Add New Product"
          preset="default"
          onPress={() => {
            if(authenticationStore?.user?.seller?.is_verified)
              navigation.navigate('AddNewProduct')
            else if(authenticationStore?.sellerStatus === 'incomplete'){
                console.log(authenticationStore?.sellerStatus)
                showAlert("Error", "To use this feature, Business profile must be complete and verified. Please complete your profile and try again.")
                navigation.navigate('GeneralSettings')
              }else{
                showAlert("Error", "We are verifying changes to your business profile. Please try later")
              }
            }}          
          style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }]}
          pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 0 }, { borderRadius: 5 }]}
          textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
          pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
          LeftAccessory={() => <EvilIcons name="plus" size={24} color="white" style={{marginRight: 2}}/>
        }
        />      
    </HStack>
  )
}
/**
 * Burger Menu with drawer option to be used in top header on Mobile Devices
 */
const NativeMenuItems1 = () => {

  const navigation = useNavigation<any>()

  return (
    <Stack>
      <HStack alignItems="center" space={{base: 0, md: 3}}>
        <IconButton onPress={()=>{navigation.navigate("Wishlist")}} icon={<Image source={require("../../assets/icons/Wishlist.png")} maxHeight={28} style={{width: 22, height: 22}} alt="image"/>}/>
        <IconButton onPress={()=>{navigation.navigate("Notifications")}} icon={<Image source={require("../../assets/icons/Notifications.png")} maxHeight={28} style={{width: 25, height: 25}} alt="image"/>}/>
      </HStack>
    </Stack>
  )
}

const NativeMenuItems2 = ({setNoDataScreenShow}) => {

  const navigation = useNavigation<any>();
  const [search, setSearch] = React.useState('');
  function searchProducts(){
    navigation.navigate("Products",{"searchString":search}); 
    setNoDataScreenShow(true)
  }
  return (
    <Box w={'80%'}>
    <InputField
        key="search"
        value={search}
        onChangeText={(searchTerm) => setSearch(searchTerm)}        
        placeholder="Search for products"
        RightAccessory={()=> (
          <Feather 
            name="search"
            size={24} 
            style={{alignSelf: 'center', borderRadius:5, position: 'absolute', right: 0, backgroundColor:colors.palette.primary600, padding:10}}
            color={colors.palette.neutral100}  
            onPress={() => {searchProducts()}}/>
        )}
      />
    </Box>
  )
}



/**
 * PageFooter Component to be used in on PageLayout for web
 */
const PageFooter = ({navigation}) => {   
  
  const [isHovered_home, setIsHovered_home] = React.useState(false);
  const [isHovered_about, setIsHovered_about] = React.useState(false);
  const [isHovered_contact, setIsHovered_contact] = React.useState(false);
  const [isHovered_faq, setIsHovered_faq] = React.useState(false);

  // open map on click address
  const handleAddressPress = () => {
    const latitude = 26.8678;
    const longitude = 75.8183;

    const url = `https://maps.google.com/maps?q=${latitude},${longitude}`;

    Linking.canOpenURL(url)
      .then(supported => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          console.log('Map application is not available');
        }
      })
      .catch(error => console.log(error));
  };

  // open gmail on click email address
  const handleEmailPress = () => {
    const email = 'piyush@technoace.in';
    const url = `mailto:${email}`;

    Linking.canOpenURL(url)
      .then(supported => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          console.log('Mail application is not available');
        }
      })
      .catch(error => console.log(error));
  };

  const toast = useToast()
  const id = "subscriber-id"  
  const [email, setEmail]  = useState("")
  const {showAlert} = React.useContext(AlertContext)

  const addSubscriber = () => {
    if(validateEmail(email)) {
      api.apisauce.post("/subscriber/", {"email":email})
      .then((response)=>{
        setEmail("")
        if (!toast.isActive(id)) {
          showAlert("Success", "Request Successfully Submitted.")
        }      
      })
      .catch((error)=>{console.error(error)}) 
    }
    else{
      showAlert("Error", "Invalid email address.")
    }    
     
  }
  return (
    <VStack w="100%" pb={10} background={colors.palette.primary500}>
      <Stack w={{base: '90%',xl:"80%"}} alignItems="center" alignSelf="center">
        <Stack direction={{base: 'column', lg: 'row'}}
          p={5} 
          background={colors.palette.primary100} 
          mt={-70} borderRadius={10} space={5}
          shadow={10} alignItems="center"
          style={{
            shadowOffset: {width: 0, height: 0},  
            shadowColor: '#171717',  
            shadowOpacity: 0.2,  
            shadowRadius: 3,  
          }}>
          <VStack w={{base: '100%', lg: '57%', xl:"60%"}}>
            <Text style={{color:colors.palette.primary500, fontSize:24, fontFamily:typography.fonts.poppins.semiBold}}>Subscribe To Get Updates</Text>
            <Text style={{color:colors.palette.primary300, fontSize:16, fontFamily:typography.fonts.poppins.normal}}>Dont lose a chance to be among the firsts to know about our upcoming news and updates.</Text>
          </VStack>
          <Stack direction={{base: 'column', sm: 'row'}} space={5} w={{base: '100%', lg: '43%', xl:"40%"}}>
            <Input placeholder="Enter your email" value={email} borderColor={colors.palette.primary400} fontSize={16} onChangeText={(value) => setEmail(value.toLocaleLowerCase())}/>
            <Button
              text="Subscribe Now"
              preset="default"
              onPress={addSubscriber}          
              style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }]}
              pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 0 }, { borderRadius: 5 }]}
              textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={{base: 'column', lg: 'row'}} m={10} space={{base: 10, lg: 10}}>
        <VStack w={{base: '100%', lg:"35%"}} space={3}>
          <AutoImage source={require("../../assets/images/white_logo.png")} maxHeight={39}/>
          <Text 
            style={{
              color:colors.palette.neutral100,
              fontFamily:typography.fonts.poppins.light
            }}
          >
            When you visit or interact with our sites, services or tools, we or our authorized service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.
          </Text>
        </VStack>
        <Stack direction={{base: 'column', sm: 'row'}} space={{base: 3, sm: 0}}>
          <Stack w={{base: '100%', sm: '25%', lg: "25%"}} alignItems={{base: "flex-start", lg: 'center'}}>
            <VStack space={{base: 1, sm: 3, md: 5}} w={200}>
              <Text style={{color:colors.palette.neutral100, fontSize:18, fontFamily:typography.fonts.poppins.medium}}>Information</Text>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_home(true)}
                onPressOut={()=>setIsHovered_home(false)} 
                onPress={()=>navigation.navigate("Welcome")}>
                <Text style={[isHovered_home ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>Welcome</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_home(true)}
                onPressOut={()=>setIsHovered_home(false)} 
                onPress={()=>navigation.navigate("Home")}>
                <Text style={[isHovered_home ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>Home</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_about(true)}
                onPressOut={()=>setIsHovered_about(false)} 
                onPress={()=>navigation.navigate("About")}>
                <Text style={[isHovered_about ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>About Us</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_contact(true)}
                onPressOut={()=>setIsHovered_contact(false)} 
                onPress={()=>navigation.navigate("Contact")}>
                <Text style={[isHovered_contact ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>Get in Touch</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_faq(true)}
                onPressOut={()=>setIsHovered_faq(false)} 
                onPress={()=>navigation.navigate("FAQ")}>
                <Text style={[isHovered_faq ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>FAQ's</Text>
              </TouchableOpacity>
            </VStack>
          </Stack>
          <Stack w={{base: '100%', sm: '30%', lg: "25%"}} alignItems={{base: "flex-start", lg: 'center'}}>
            <VStack space={{base: 1, sm: 3, md: 5}} w={200}>
              <Text style={{color:colors.palette.neutral100, fontSize:18, fontFamily:typography.fonts.poppins.medium}}>Policies</Text>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_home(true)}
                onPressOut={()=>setIsHovered_home(false)} 
                onPress={()=>navigation.navigate("ITPolicy")}>
                <Text style={[isHovered_home ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>IT Policy</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_about(true)}
                onPressOut={()=>setIsHovered_about(false)} 
                onPress={()=>navigation.navigate("UserPolicy")}>
                <Text style={[isHovered_about ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>User Policy</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_contact(true)}
                onPressOut={()=>setIsHovered_contact(false)} 
                onPress={()=>navigation.navigate("PrivacyPolicy")}>
                <Text style={[isHovered_contact ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>Privacy Policy</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPressIn={()=>setIsHovered_faq(true)}
                onPressOut={()=>setIsHovered_faq(false)} 
                onPress={()=>navigation.navigate("TermsAndCondition")}>
                <Text style={[isHovered_faq ? { color: colors.palette.gray100} : {color:colors.palette.neutral100}]}>Terms & Conditions</Text>
              </TouchableOpacity>
            </VStack>
          </Stack>
          <VStack w={{base: '100%', sm: '50%', lg: "50%"}} space={3}>
            <HStack space={2} mr={3} alignItems={'center'}>
              <AutoImage source={require("../../assets/icons/location.png")} maxWidth={16}/>
              <Box width={'100%'}>
                <Pressable onPress={handleAddressPress}>
                  <Text 
                    style={{
                      color:colors.palette.neutral100,
                      fontFamily:typography.fonts.poppins.normal,
                      flexWrap: 'wrap',
                    }}
                    >
                    VR99+57M, Jhalana Doongri, Jaipur, Rajasthan 302004</Text>
                </Pressable>
              </Box>
            </HStack>
            <HStack space={2} alignItems={'center'}>
              <AutoImage source={require("../../assets/icons/email.png")} maxWidth={16}/>
              <TouchableOpacity onPress={handleEmailPress}>
                <Text 
                  style={{
                      color:colors.palette.neutral100,
                      fontFamily:typography.fonts.poppins.normal
                    }}
                  >
                    info@nearxpiry.com
                  </Text>
              </TouchableOpacity>
            </HStack>
            <HStack space={5}>
              <Pressable onPress={()=>{Linking.openURL("https://www.facebook.com/nearxpiry")}}>
                <AutoImage source={require("../../assets/icons/facebook.png")} maxHeight={24}/>
              </Pressable>
              <Pressable onPress={()=>{Linking.openURL("https://www.instagram.com/p/Cs5AweCvwE1/?igshid=NTc4MTIwNjQ2YQ==")}}>
                <AutoImage source={require("../../assets/icons/insta.png")} maxHeight={24}/>
              </Pressable>
              <Pressable onPress={()=>{Linking.openURL("https://www.linkedin.com/company/nearxpiry/")}}>
                <AutoImage source={require("../../assets/icons/linkedin.png")} maxHeight={24}/>
              </Pressable>
              <Pressable onPress={()=>{Linking.openURL("https://www.facebook.com/nearxpiry")}}>
                <AutoImage source={require("../../assets/icons/twitter.png")} maxHeight={24}/>
              </Pressable>
            </HStack>
          </VStack>
        </Stack>
      </Stack>
      <Divider color={colors.palette.neutral100} style={{marginLeft:spacing.huge, marginRight:spacing.huge, marginBottom:spacing.small}}/>
      <Text style={{color:colors.palette.neutral100, alignSelf:"center", padding: 3, textAlign: 'center'}}>© NearXpiry App. 2023, NearXpiry. All rights reserved</Text>
    </VStack>
  )
}


// if search result is empty ------->

const NoSearchProduct = ({setNoDataScreenShow}) => {
  const screenHeight = Dimensions.get('window').height;
  const productsHeadingBreakpoint = useBreakpointValue({base: 16 ,sm: 19, md: 21, lg: 24})

  const navigation = useNavigation<any>()
  return(
    <VStack space={8} pl={Platform.select({default:20, native: 5})} pr={Platform.select({default:20, native: 5})} 
      pt={Platform.select({default:5, native: 5})} bg={colors.palette.neutral100} height={screenHeight-190}>
    {/* go back */}
    <HStack alignItems="center">
      <Pressable onPress={() => {setNoDataScreenShow((false))}}>
        <Ionicons name="arrow-back" size={Platform.select({default:35, native: 25})} color={colors.palette.neutral600}/>
      </Pressable>
      <Text 
        style={{
          fontFamily: typography.fonts.poppins.bold,
          fontSize: Platform.select({default:productsHeadingBreakpoint, native: 18}),
          lineHeight: 28.8,
          color: colors.palette.neutral700,
        }}
        >
          No Product found
      </Text>
    </HStack>
    <VStack space={3} mb={20}>
      <Image
        source={require(`../../assets/images/search_empty.png`)}
        style={{ width: "100%", height: Platform.select({default: useBreakpointValue({base: 170, md: 320}), native: 200}) }}
        resizeMode="contain" alt="image"
      />
      <Text 
        style={{
          fontFamily: typography.fonts.poppins.normal,
          fontSize: 16,
          lineHeight: 24 ,
          color: colors.palette.neutral400,
          textAlign: 'center'
        }}
        >
          Choose a different category, brand or search term
      </Text>
    </VStack>
  </VStack>
  
  )
}