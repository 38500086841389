import React, { useEffect, useState } from "react"
import { useInitialRootStore, useStores } from "../models"
import { observer } from "mobx-react-lite"
import { OverlayContext } from "./OverlayProvider"
import { useNavigation } from "@react-navigation/native"
import { api } from "../services/api"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Platform } from "react-native"
import { parseInitialURLAsync } from "expo-linking"

export interface AppContainerProps {
    /**
     * An optional style override useful for padding & margin.
     */    
    children?:JSX.Element      
  }

export const isFirefox = Platform.OS === 'web' && navigator.userAgent.toLowerCase().includes('firefox');

export const AppContainer = observer(function AppContainer(props: AppContainerProps) {
    const {       
      authenticationStore:{
        isAuthenticated, user, logout, fetchUserInformation
      } 
    } = useStores()
    const {setOverlayVisible} = React.useContext(OverlayContext)            
    const navigation = useNavigation<any>()    
    
    useEffect(() => {
      navigation.addListener('focus', () => {        
        verifyToken()
      });
    }, []);
    
    const verifyToken = async () => {
      try {
          if(isAuthenticated) {
              // console.log(api.apisauce.headers)
              await api.apisauce.get(`verifyToken/`)
              .then((response:any) =>{
                  // console.log("Token: ",response)
                  if(response.data.status != 'ok'){
                    api.apisauce.deleteHeader('Authorization')                        
                    logout(setOverlayVisible)
                  }
                  else{
                      fetchUserInformation(setOverlayVisible)
                  }
              }) 
          }
      } catch (error) {
        console.error(error);
      }
    };
        
    return <>
        {props.children}
    </>
})