import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, Keyboard, KeyboardAvoidingView, TouchableWithoutFeedback, TouchableOpacity, TextInput, StyleSheet } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, HStack, Image, Menu, Pressable, ScrollView, Stack, VStack, View, useBreakpointValue } from "native-base"
import { useNavigation, useRoute } from "@react-navigation/native"
import { Entypo, Feather, FontAwesome5, Foundation, Ionicons, MaterialCommunityIcons, SimpleLineIcons } from "@expo/vector-icons"
import { colors, typography } from "../../theme"
import { InputField } from "../../components/InputField"
import { Platform } from "react-native"
import { Dimensions } from "react-native"
import { Linking } from "react-native"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"
import { api } from "../../services/api"
import Config from "../../config"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `OpenChat: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="OpenChat" component={OpenChatScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const OpenChatScreen: FC<StackScreenProps<AppStackScreenProps, "OpenChat">> = observer(function OpenChatScreen() {
  const route = useRoute<any>()
  // Pull in one of our MST stores
  const { authenticationStore: {isAuthenticated, user},
  // ChatStore:{allChatsMessages, fetchChatMessages}
  } = useStores()
  const fontSizeHeading = useBreakpointValue({base: 15 , sm: 20, md: 24});

  const [message ,setMessage] = useState('');
  const [sendMsg ,setSendMsg] = useState([]);
  const [time ,setTime] = useState('');
  const [date ,setDate] = useState('');
  const [chatData ,setChatData] = useState<any>();

  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  // const [keyboardHeight, setKeyboardHeight] = useState(0);

  const { setOverlayVisible } = useContext(OverlayContext);

  const scrollViewRef = useRef(null);
  // const scrollToBottom = () => {
  //   if (scrollViewRef.current) {
  //     scrollViewRef.current.scrollToEnd({ animated: true });
  //   }
  // };

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
      setKeyboardOpen(true);
      // setKeyboardHeight(e.endCoordinates.height);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', (e) => {
      setKeyboardOpen(false);
      // setKeyboardHeight(0);
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  const fetchChat = () => {
    // fetchChatMessages(route?.params?.chatId, setOverlayVisible);
    api.apisauce.get(`chat/${route?.params?.chatId}/`)
    .then((result:any)=>{
      // console.log('--------->//',result.data)
      setChatData(result.data)
    })
    .catch((error)=>{
      console.log(error.message)
    })
    // scrollToBottom()
  }

  useEffect(() => {
    fetchChat()
  },[])
  
  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  // create your own vh
  const screenHeight = Dimensions.get('window').height;
  const _73vh = screenHeight * 0.73;
  const _36vh = screenHeight * 0.36;

  // const keyboardHeightMinus = screenHeight - keyboardHeight;

  // const sendMsgFun = () =>{
  //   // convert 24 hour time format to 12 hour time formar
  //   var hours = new Date().getHours() ; // gives the value in 24 hours format
  //   var AmOrPm = hours >= 12 ? 'PM' : 'AM';
  //   hours = (hours % 12) || 12;
  //   var minutes = new Date().getMinutes() ;
  //   var convertedTime = `${hours}:${minutes} ${AmOrPm}`; 

  //   // create an array of object with msg, msgDate, msgTime
    
  //   setSendMsg([...sendMsg, 
  //     {msg: message, 
  //     msgDate: new Date().toLocaleString().split(',')[0], 
  //     msgTime: convertedTime
  //     }
  //   ]);
  //   // empty imput field after the click 
  //   setMessage('')
  // }

  const sendMsgFun = () =>{
    // create an array of object with msg, msgDate, msgTime
    const formData = {
      "chat": route?.params.chatId,
      "sender": user.id,
      "content": message
  }

    api.apisauce.post("messages/create/", formData)
    .then((response)=>{
      console.log(response)
      if(response.ok === true){
        fetchChat()
      }
    })
    .catch((error)=>{console.error(error)}) 

    // empty imput field after the click 
    setMessage('')
    // scrollToBottom()

  }

  // open phone app
  const handlePhonePress = () => {
    const phoneNumber = '7014882547';
    const url = `tel:${phoneNumber}`;

    Linking.canOpenURL(url)
      .then(supported => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          console.log('Phone app is not available');
        }
      })
      .catch(error => console.log(error));
  };

  // open whatsapp
  const handleWhatsappPress = () => {
    const phoneNumber = '7014882547';
    const url = `whatsapp://send?phone=${phoneNumber}`;

    Linking.canOpenURL(url)
      .then(supported => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          console.log('WhatsApp is not available');
        }
      })
      .catch(error => console.log(error));
  };
  

  console.log('chatData',chatData)
  const userIsSellerOrBuyer = user?.id === chatData?.buyer?.id ? 'buyer' : 'seller' ;
  const avatar = userIsSellerOrBuyer === 'buyer' ? chatData?.seller?.avatar : chatData?.buyer?.avatar

  return (
  <>
    {/* divert code for web and android here  */}
    {Platform.select({ 
       default : 
        <Screen style={$root} preset="scroll">
          <PageLayout  variant={isAuthenticated?1:0}>
            {/* Parent Stack */}
            <Stack w="100%" mb={100}>
                {/* go back */}
                <HStack pl={{base: 4,sm: 10, md:20}} pr={{base: 4,sm: 10, md:20}} pt={3} pb={3} style={{ backgroundColor: colors.palette.secondary100}} alignItems="center" justifyContent='space-between'>
                  <HStack space={3} alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                    </Pressable>
                    <AutoImage
                      source={{uri : Config.MEDIA_URL + avatar}}
                      // source={require(`../../../assets/images/product_description_screen/sellerPhoto.png`)}
                      maxHeight={50}
                      style={{ width: 50 , borderRadius: 50/2}}
                      // resizeMode="contain"
                    />
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: fontSizeHeading,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700,
                      }}
                      >
                        {/* Kristin Waston */}
                      {userIsSellerOrBuyer === 'buyer' ? chatData?.seller?.name : chatData?.buyer?.name}
                    </Text>
                  </HStack>
                  <HStack space={{base: 3,sm: 4, md:8}}>
                    {/* make a call by pressing on phone icon */}
                    <TouchableOpacity onPress={fetchChat}>
                      <Foundation name="refresh" size={30} color={colors.palette.secondary300} />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={handlePhonePress}>
                      <SimpleLineIcons name="phone" size={22} color={colors.palette.secondary300} />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={handleWhatsappPress}>
                      <FontAwesome5 name="whatsapp" size={22} color={colors.palette.secondary300} />
                    </TouchableOpacity>
  
                    {/* menu dropdown */}
                    <Box alignItems="center">
                      <Menu w="150" trigger={triggerProps => {
                      return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                <Entypo name="dots-three-vertical" size={22} color={colors.palette.secondary300}/>
                            </Pressable>;
                      }}>
                        <Menu.Item>Active</Menu.Item>
                        <Menu.Item>Inactive</Menu.Item>
                        <Menu.Item>Delete</Menu.Item>
                      </Menu>
                    </Box>
                  </HStack>
                </HStack>
    
                <VStack pl={{base: 7,sm: 10, md:20}} pr={{base: 7,sm: 10, md:20}} pt={3} minHeight={'70vh'} maxHeight={'70vh'}>
                  {/* msg date stamp */}
                  {console.log('sendMsg', sendMsg)} 
                <ScrollView 
                  ref={scrollViewRef}
                  onContentSizeChange={() => scrollViewRef.current.scrollToEnd()}
                  >
                    {chatData?.messages?.map((chat, i)=>{
                      return(
                        <>
                          {/* print date ----------> */}
                          {/* check date of the msg is equal to the privious msg or not / we should print this date or not */}
                            {i === 0 && 
                              <Text 
                              key={`openChat-msg-date-stemp-${i}`}
                              style={{
                                fontFamily: typography.fonts.poppins.light,
                                fontSize: 13,
                                lineHeight: 19.5,
                                color: colors.palette.neutral400,
                                padding: 8,
                                paddingLeft: 15,
                                paddingRight: 15,
                                backgroundColor: colors.palette.primary100,
                                width: 'max-content',
                                alignSelf: 'center'
                              }}
                              >
                                {/* if msgDate is equal to Today's date then Print 'Today' else print msgDate */}
                                {/* {chat?.msgDate == new Date().toLocaleString().split(',')[0] ? 'Today' : chat?.msgDate} */}
                                {chat?.date}
                              </Text> 
                            }
                            {i>0 && chat?.date !== chatData?.messages[i-1]?.date &&
                              <Text 
                              key={i}
                              style={{
                                fontFamily: typography.fonts.poppins.light,
                                fontSize: 13,
                                lineHeight: 19.5,
                                color: colors.palette.neutral400,
                                padding: 8,
                                paddingLeft: 15,
                                paddingRight: 15,
                                backgroundColor: colors.palette.primary100,
                                width: 'max-content',
                                alignSelf: 'center'
                              }}
                              >
                                {/* if msgDate is equal to Today's date then Print 'Today' else print msgDate */}
                                {/* {chat?.msgDate == new Date().toLocaleString().split(',')[0] ? 'Today' : chat?.msgDate} */}
                                {chat?.date}
                              </Text>
                            }

                        {/* print msg----------> */}
                          {chat?.sender !== user.id 
                          ? 
                            <>
                              {/* chat bubble Left side (second person) */}
                              <ChatBubbleLeft 
                                key={`openChat-chat-bubble-leftSide-${i}`}
                                content={chat?.content}
                                time={chat?.time}
                                id={i}
                              />

                            </>
                          :
                            <>
                              <ChatBubbleRight 
                                key={`openChat-chat-bubble-rightSide-${i}`}
                                content={chat?.content}
                                time={chat?.time}
                                id={i}
                                />
                            </>
                          }
                        </>
                      )}
                    )}
                  
                </ScrollView>
    
                <VStack  mt={3} justifyContent={'flex-end'} style={{
                  padding: 2,
                  paddingLeft: 30,
                  shadowColor: '#171717', 
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.4,
                  shadowRadius: 2}}>
    
                  <HStack justifyContent={'space-between'}>
                    <HStack alignItems={'center'} w={{base: '70%',sm: '75%',md:'85%'}}>
                      <Feather name="smile" size={20} color="black" />
                      <Box w={'98%'}>
                        <InputField
                          key="Full Name"
                          value={message}              
                          onChangeText={(value) => setMessage(value)}
                          placeholder="Start typing ...."
                          style={{borderColor: colors.palette.neutral100 }}
                          onSubmitEditing={()=> {
                            sendMsgFun();
                          }}
                          // status="error"
                          // helper="Enter your name"
                          // RightAccessory={() => <Icon icon="check" />}
                          // LeftAccessory={() => <Icon icon="bell" />}
                        /> 
                        </Box>
                    </HStack>
                    <HStack w={{base: '30%',sm: '25%',md:'8%'}} space={2} alignItems={'center'} justifyContent='flex-end' pr={3}>
                      <Text style={{color: colors.palette.neutral800, fontSize: 19}}>@</Text>
                      <MaterialCommunityIcons onPress={()=>{
                        sendMsgFun();
                      }} 
                        name="send-circle" size={40} color={colors.palette.primary300} />
                    </HStack>
                  </HStack>
    
                </VStack>
                  
                </VStack>
            </Stack>
          </PageLayout>
        </Screen>, //code for web and other platforms

       native : <></>
          // <PageLayout  variant={1}>
          //   <VStack style={{backgroundColor: colors.palette.neutral100}}>
          //     {/* go back */}
          //     <HStack pl={4} pr={4} pt={3} pb={3} style={{ backgroundColor: colors.palette.secondary100}} alignItems="center" justifyContent='space-between'>
          //         <HStack space={3} alignItems="center">
          //           <Pressable onPress={() => navigation.goBack()}>
          //             <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
          //           </Pressable>
          //           <Image
          //             source={require(`../../../assets/images/product_description_screen/sellerPhoto.png`)}
          //             maxHeight={50}
          //             style={{ width: 50 ,height: 50, borderRadius: 50/2}}
          //             // resizeMode="contain"
          //             alt="image"
          //           />
          //           <Text 
          //             style={{
          //               fontFamily: typography.fonts.poppins.bold,
          //               fontSize: fontSizeHeading,
          //               lineHeight: 28.8,
          //               color: colors.palette.neutral700,
          //             }}
          //             >
          //               Kristin Waston
          //           </Text>
          //         </HStack>
          //         <HStack space={{base: 3,sm: 4, md:8}}>
          //           {/* make a call by pressing on phone icon */}
          //           <TouchableOpacity onPress={handlePhonePress}>
          //             <SimpleLineIcons name="phone" size={22} color={colors.palette.secondary300} />
          //           </TouchableOpacity>

          //           <TouchableOpacity onPress={handleWhatsappPress}>
          //             <FontAwesome5 name="whatsapp" size={22} color={colors.palette.secondary300} />
          //           </TouchableOpacity>
                    
          //           {/* menu dropdown */}
          //           <Box alignItems="center">
          //             <Menu w="150" trigger={triggerProps => {
          //             return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
          //                       <Entypo name="dots-three-vertical" size={22} color={colors.palette.secondary300}/>
          //                   </Pressable>;
          //             }}>
          //               <Menu.Item>Active</Menu.Item>
          //               <Menu.Item>Inactive</Menu.Item>
          //               <Menu.Item>Delete</Menu.Item>
          //             </Menu>
          //           </Box>
          //         </HStack>
          //       </HStack>

          //     {/* Parent Stack */}
          //     <VStack pt={3} minHeight={ isKeyboardOpen ? _36vh : _73vh} maxHeight={isKeyboardOpen ? _36vh : _73vh}>
          //         {/* msg date stamp */}
          //         {sendMsg.map((chat, i)=>(
          //           <Stack key={`msg_index_${i}`}>
          //           {i === 0 && 
          //             <Text 
          //             key={i}
          //             style={{
          //               fontFamily: typography.fonts.poppins.light,
          //               fontSize: 13,
          //               lineHeight: 19.5,
          //               color: colors.palette.neutral400,
          //               padding: 8,
          //               paddingLeft: 15,
          //               paddingRight: 15,
          //               backgroundColor: colors.palette.primary100,
          //               // width: 'max-content',
          //               alignSelf: 'center'
          //             }}
          //             >
          //               {/* if msgDate is equal to Today's date then Print 'Today' else print msgDate */}
          //               {/* {chat?.msgDate == new Date().toLocaleString().split(',')[0] ? 'Today' : chat?.msgDate} */}
          //               {chat?.msgDate}
          //             </Text> 
          //           }
          //           { i>0 && chat?.msgDate !== sendMsg[i-1]?.msgDate &&
          //             <Text 
          //             key={i}
          //             style={{
          //               fontFamily: typography.fonts.poppins.light,
          //               fontSize: 13,
          //               lineHeight: 19.5,
          //               color: colors.palette.neutral400,
          //               padding: 8,
          //               paddingLeft: 15,
          //               paddingRight: 15,
          //               backgroundColor: colors.palette.primary100,
          //               // width: 'max-content',
          //               alignSelf: 'center'
          //             }}
          //             >
          //               {/* if msgDate is equal to Today's date then Print 'Today' else print msgDate */}
          //               {/* {chat?.msgDate == new Date().toLocaleString().split(',')[0] ? 'Today' : chat?.msgDate} */}
          //               {chat?.msgDate}
          //             </Text>
          //           }
          //           </Stack>
                    
          //         ))}
          //       <ScrollView 
          //         ref={scrollViewRef}
          //         style={styles.scrollView}
          //         contentContainerStyle={styles.scrollViewContent}
          //         keyboardShouldPersistTaps="handled"
          //         onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({ animated: true })}                    
          //         >
                  
          //         {/* chat bubble Left side (second person) */}
          //           <ChatBubbleLeft 
          //           content='Lorem ipsum ipsum' 
          //           secondPersonName='Jay' 
          //           time='11:00 AM'
          //           id={1}/>

          //             {/* chat bubble right side (sender)*/}
          //             {sendMsg.map((chat, i)=>(
          //             <ChatBubbleRight 
          //             key={`chat_bubble_${i}`}
          //             content={chat.msg}
          //             time={chat.msgTime}
          //             id={i}/>
          //           ))}

          //       </ScrollView>
          //       <VStack  mt={3} justifyContent={'flex-end'} style={{
          //       padding: 2,
          //       shadowColor: '#171717', 
          //       shadowOffset: {width: 0, height: 1},
          //       shadowOpacity: 0.4,
          //       shadowRadius: 2,
          //       backgroundColor: colors.palette.neutral100}}>

          //       <HStack justifyContent={'space-between'}>
          //         <HStack alignItems={'center'} w={"70%"}>
          //           <Feather name="smile" size={20} color="black" />
          //           <Box w={'98%'}>
          //             <InputField
          //               key="Full Name"
          //               value={message}              
          //               onChangeText={(value) => setMessage(value)}
          //               placeholder="Start typing ...."
          //               style={{borderColor: colors.palette.neutral100 }}
          //               onSubmitEditing={()=> {
          //                 sendMsgFun();
          //               }}
          //               // status="error"
          //               // helper="Enter your name"
          //               // RightAccessory={() => <Icon icon="check" />}
          //               // LeftAccessory={() => <Icon icon="bell" />}
          //             /> 
          //             </Box>
          //         </HStack>
          //         <HStack w={'20%'} space={2} alignItems={'center'} justifyContent='flex-end' pr={3}>
          //           <Text style={{color: colors.palette.neutral800, fontSize: 19}}>@</Text>
          //           <MaterialCommunityIcons onPress={()=>{
          //             sendMsgFun();
          //           }} 
          //             name="send-circle" size={40} color={colors.palette.primary300} />
          //         </HStack>
          //       </HStack>

          //       </VStack>
          //     </VStack>
          //   </VStack>
          // </PageLayout>

        , //code for Mobile devices
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  scrollView: {
    // flex: 1,
    paddingHorizontal: 10,
    // minHeight: 600
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: '#fff',
  },
  input: {
    flex: 1,
    height: 40,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 20,
    paddingHorizontal: 10,
    marginRight: 10,
  },
});


const ChatBubbleLeft = ({content, time, id}) => {
  return(
    <>
      <Box 
        key={id}
        style={{
          marginTop: 15,
          padding: 15,
          paddingLeft: 30,
          paddingRight: 30,
          backgroundColor: colors.palette.neutral1300,
          // width: 'max-content',
          minWidth: '20%',
          maxWidth: '80%',
          alignSelf: 'flex-start',
          borderRadius: 50,
          borderBottomLeftRadius: 0
        }}
        >
          {/* <Text style={{
          fontFamily: typography.fonts.poppins.medium,
          fontSize: 18,
          lineHeight: 24,
          color: colors.palette.neutral800,}}>
            {secondPersonName}
          </Text> */}
          <Text style={{
          fontFamily: typography.fonts.poppins.normal,
          fontSize: 16,
          lineHeight: 24,
          color: colors.palette.neutral400,}}>
            {content}
          </Text>
          <Text style={{
          fontFamily: typography.fonts.poppins.light,
          fontSize: 13,
          lineHeight: 19.5,
          color: colors.palette.neutral400,
          alignSelf: 'flex-end'
          }}>
            {time}
          </Text>
      </Box>
    </>
  )
}

const ChatBubbleRight = ({content, time, id}) => {
  return(
    <Stack key={id}>
      <Box 
        key={id}
        style={{
          marginTop: 15,
          paddingTop: 15,
          paddingBottom: 10,
          paddingLeft: 30,
          paddingRight: 30,
          backgroundColor: colors.palette.primary300,
          // width: 'max-content',
          minWidth: '30%',
          maxWidth: '80%',
          alignSelf: 'flex-end',
          borderRadius: 50,
          borderBottomRightRadius: 0
        }}
        >
          <Text style={{
          fontFamily: typography.fonts.poppins.normal,
          fontSize: 16,
          lineHeight: 24,
          color: colors.palette.neutral100,}}>
            {content}
          </Text>
          <Text style={{
          fontFamily: typography.fonts.poppins.light,
          fontSize: 13,
          lineHeight: 19.5,
          color: colors.palette.neutral100,
          alignSelf: 'flex-end'
          }}>
            {time}
          </Text>
      </Box>
    </Stack>
  )
}