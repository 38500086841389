import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen, StarRatting, Text } from "../../components"
import { Box, HStack, Pressable, Stack, VStack, useBreakpointValue } from "native-base"
import { Entypo, Ionicons } from "@expo/vector-icons"
import { colors, typography } from "../../theme"
import { PageLayout } from "../../components/PageLayout"
import { useNavigation, useRoute } from "@react-navigation/native"
import { InputField } from "../../components/InputField"
import { Platform } from "react-native"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

//imports for mobile----->
import Constants from 'expo-constants';
import { api } from "../../services/api"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"
import { AlertContext } from "../../components/AlertContext"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Review: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Review" component={ReviewScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const ReviewScreen: FC<StackScreenProps<AppStackScreenProps, "Review">> = observer(function ReviewScreen() {
  // Pull in one of our MST stores
  const { authenticationStore, 
    ReviewStore: {review, setReview, feedbackValidationError } 
  } = useStores()
  // const [starCount, setStarCount] = useState<number>();
  const route = useRoute<any>()
  const showAboveMd = useBreakpointValue({base: false, md: true});
  const reviewContainerHeight = useBreakpointValue({base: 700, md: 600});
  const [ data, setData ] = useState<any>({})
  const [rating, setRating] = useState(0);
  const zeroReview =  {
    feedback: "",
    star_rating: 0,
    seller: route.params.sellerId,
  }

  useEffect(() => {setReview({...zeroReview})},[]);

  // const [review, setReview] = useState({...zeroReview})

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const {  setOverlayVisible } = useContext(OverlayContext);
  const { showAlert } = useContext(AlertContext);

  const [isSubmitted, setIsSubmitted] = React.useState(false)

  // validation error ----->
  const feedbackError = isSubmitted ? feedbackValidationError : ""

  const submtiReview = ()  => {    
    setOverlayVisible(true)
    api.apisauce.post(`sellerReviews/${route.params.sellerId}/`,{...review, star_rating:rating})
    .then((response)=>{
      console.log('response',response);
      showAlert('Success', 'Your review has been submitted successfully.');
      
      setReview({...zeroReview}); 
      setRating(0)
    })
    .catch((error)=>{
      console.log(error.message); 
      // showAlert('Error', 'Something went wrong. Please try again.');
      return error.message;})
    .finally(()=>{setOverlayVisible(false)})
  }

  function submitForm() {
    setIsSubmitted(true)
    console.log('submitForm',feedbackError)
    //if there is something returned form validation error then return from here and do not call login api
    if (feedbackValidationError) return 

    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token.
    submtiReview();

    setIsSubmitted(false)
  }

  return (
  <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
        default : 
          <Screen style={$root} preset="scroll">
            <PageLayout  variant={authenticationStore.isAuthenticated?1:0}>
              <Stack w="100%" mb={100}>
                {/* Parent VStack */}
                <VStack space={8} ml={10} mr={10} mt={5}>
                  {/* go back */}
                  <HStack space={2} alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                    </Pressable>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: 24,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700,
                      }}
                      >
                        Review
                    </Text>
                  </HStack>
      
                  {/* leave a review form container and image */}
                  <HStack space={10} mb={50}>
                    <VStack space={4} w={{base: '100%',md:'42%'}}  pl={'5vw'} pr={'5vw'} p={'35px'} style={{
                      shadowColor: '#171717',
                      shadowOffset: {width: 1, height: 1},
                      shadowOpacity: 0.2,
                      shadowRadius: 15,
                      alignSelf: 'center'
                    }}>
                      
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: 24,
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                        }}
                        >
                          Leave a Review
                      </Text>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.normal,
                          fontSize: 16,
                          lineHeight: 24,
                          color: colors.palette.neutral400,
                        }}
                        >
                          Please provide ratings
                      </Text>
                      
                      <StarRatting setRating={setRating}/>
      
                      <Box w={'100%'} mt={5}>
                        <InputField
                          key="Write your Review"
                          value={review?.feedback}              
                          onChangeText={(value) => setReview({...review, feedback:value})}
                          label="Write your Review"
                          placeholder="Lorem ipsum.............."
                          multiline
                          style={{height: 117}}
                          helper={feedbackError}
                          status={feedbackError ? "error" : undefined}
                          // RightAccessory={() => <Icon icon="check" />}
                          // LeftAccessory={() => <Icon icon="bell" />}
                        /> 
                      </Box>
      
                      <Button
                        text='Submit Review'
                        preset="default"
                        onPress={()=>{submitForm();}}
                        style={{
                          backgroundColor: colors.palette.primary600,
                          borderRadius: 5,
                          width: "100%",
                          marginTop: 30, 
                        }}
                        pressedStyle={{
                          backgroundColor: colors.palette.neutral100,
                          borderRadius: 5,
                          borderWidth: 0,
                        }}
                        textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
                        pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                      />
                      
                    </VStack>
                    {showAboveMd && <AutoImage
                      source={require("../../../assets/images/review/review_photo.png")}
                      maxHeight={600}
                      style={{ width: "55%" }}
                      resizeMode="contain"
                    />}
                  </HStack>
      
                </VStack>
              </Stack>
            </PageLayout>
          </Screen>, //code for web and other platforms
       
        native : <></>
          // <Screen style={[$root,{marginTop: Constants.statusBarHeight}]} preset="scroll">
          //   <VStack bg={colors.palette.gray200} w="100%" height="100%">
          //       <Box minH={250} ></Box>
                
          //     {/* get started section */}
          //     <VStack bg={'white'} space={4} p={5} pt={10} pb={10} style={{borderTopLeftRadius: 70, borderTopRightRadius: 70}}>
          //         <HStack justifyContent={'space-between'}>
          //           {/* go back section */}
          //           <HStack space={3} alignItems={'center'}>
          //             <Pressable onPress={() => navigation.goBack()}>
          //               <Ionicons name="arrow-back" alt="icon" size={30} color={colors.palette.primary300}/>
          //             </Pressable>
          //             <Text 
          //               style={{
          //                 fontFamily: typography.fonts.poppins.bold,
          //                 fontSize: 20,
          //                 lineHeight: 28.8,
          //                 color: colors.palette.primary300,
          //               }}
          //               >
          //                 {/* {sellerName} */}
          //                 Leave a Review
          //             </Text>
          //           </HStack>
          //           <Pressable onPress={() => navigation.goBack()}>
          //             <Entypo name="cross" size={40} color={colors.palette.primary300} />
          //           </Pressable>
          //         </HStack>
                  
          //         <VStack space={4} w={"100%"} style={{alignSelf: 'center'}}>
          //             <Box>
          //               <Text 
          //                 style={{
          //                   fontFamily: typography.fonts.poppins.normal,
          //                   fontSize: 14,
          //                   lineHeight: 24,
          //                   color: colors.palette.neutral400,
          //                   textAlign: 'center',
          //                 }}
          //                 >
          //                   Please provide ratings
          //               </Text>
                        
          //               <StarRatting setRating={setRating}/>
          //             </Box>
      
          //             <Box w={'100%'}>
          //               <InputField
          //                 key="Write your Review"
          //                 value={review?.feedback}              
          //                 onChangeText={(value) => setReview({...review, feedback: value})}
          //                 label="Write your Review"
          //                 placeholder="Enter Your review description"
          //                 multiline
          //                 style={{height: 117, padding: 3}}
          //                 helper={feedbackError}
          //                 status={feedbackError ? "error" : undefined}
          //                 // RightAccessory={() => <Icon icon="check" />}
          //                 // LeftAccessory={() => <Icon icon="bell" />}
          //               /> 
          //             </Box>
      
          //             <Button
          //               text='Submit Review'
          //               preset="default"
          //               onPress={()=>submitForm()}
          //               style={{
          //                 backgroundColor: colors.palette.primary600,
          //                 borderRadius: 5,
          //                 width: "100%",
          //                 marginTop: 30, 
          //               }}
          //               pressedStyle={{
          //                 backgroundColor: colors.palette.neutral100,
          //                 borderRadius: 5,
          //                 borderWidth: 0,
          //               }}
          //               textStyle={{ fontSize: 14, color: colors.palette.neutral100}}
          //               pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
          //             />
                      
          //           </VStack>
          //     </VStack>
                
          //   </VStack>
          // </Screen>, 
          //code for Mobile devices
      })} 
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}
