import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../../navigators"
import { Screen, Text } from "../../../../components"
import { Platform } from "react-native"
import { PageLayout } from "../../../../components/PageLayout"
import { HStack, Image, Pressable, ScrollView, Stack, VStack } from "native-base"
import { colors, typography } from "../../../../theme"
import { KeyboardAvoidingView } from "react-native"
import { TouchableWithoutFeedback } from "react-native"
import { Keyboard } from "react-native"
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `AccountSettingMobile: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="AccountSettingMobile" component={AccountSettingMobileScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const AccountSettingMobileScreen: FC<StackScreenProps<AppStackScreenProps, "AccountSettingMobile">> = observer(function AccountSettingMobileScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const [enableShift,setEnableShift] = useState(false);

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  return (
    <>
      {/* divert code for web and android here  */}
       {Platform.select({ 
          default : <></> , //code for web and other platforms
          
          native : 
            <PageLayout  variant={1}>
              <ScrollView style={{backgroundColor: colors.palette.primary700}}>
                <KeyboardAvoidingView behavior={"position"} contentContainerStyle={{flex: 1}} style={{flex:1}} enabled={enableShift}> 
                  <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={{flex:1}}> 
                      <Stack w="100%" mb={100}>
                        {/* Parent VStack */}
                        <VStack>
                          {/* go back */}
                          <VStack space={1} ml={5} mr={5} mt={4} mb={2}>
                            <HStack alignItems="center">
                              <Pressable onPress={() => navigation.goBack()}>
                                <Ionicons name="arrow-back" size={25} color={colors.palette.neutral600}/>
                              </Pressable>
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.bold,
                                  fontSize: 18,
                                  lineHeight: 28.8,
                                  color: colors.palette.neutral700,
                                }}
                                >
                                  Account Settings
                              </Text>
                            </HStack>
                            <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.normal,
                                fontSize: 14,
                                lineHeight: 24,
                                color: colors.palette.neutral300,
                              }}
                              >
                                Change account information and settings
                            </Text>
                          </VStack>
                          {/* profile image */}
                          <VStack>
                            <Image
                                source={require("../../../../../assets/images/account_settings/mobile/AccountSetting_mobileBg.png")}
                                style={{ width: "100%", height: 62 }}
                                resizeMode="contain" alt="image"
                              />
                            <VStack space={3} w={"50%"} style={{ marginTop: -40, alignSelf: 'center'}}>
                              <Image
                                source={require("../../../../..//assets/images/account_settings/photo.png")}
                                maxHeight={130}
                                style={{ width: 130, height: 130, borderRadius: 130/2, borderColor: colors.palette.secondary300, borderWidth: 5, alignSelf: 'center'}}
                                // resizeMode="contain" 
                                alt="image"
                              />
                              <VStack>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.bold,
                                    fontSize: 16,
                                    lineHeight: 28.8,
                                    color: colors.palette.neutral700,
                                    textAlign: 'center'
                                  }}
                                  >
                                    Mobina Mirbagheri
                                </Text>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 14,
                                    lineHeight: 28.8,
                                    color: colors.palette.neutral700,
                                    textAlign: 'center'
                                  }}
                                  >
                                    mobina@technoace.in
                                </Text>
                              </VStack>

                            </VStack>
                          </VStack>

                          {/* tabs */}
                          <VStack space={3} ml={5} mr={5} mt={4} mb={2}>

                            {/* General tab */}
                            <Pressable onPress={()=>{navigation.navigate("GeneralSettings_mobile")}}>
                              <HStack space={2} p={3} alignItems='center' justifyContent={'space-between'} backgroundColor={ colors.palette.neutral100}
                                style={{borderColor: colors.palette.gray100, borderWidth: 2}}>
                                <HStack space={4} alignItems={'center'}>
                                  <Image
                                    source={require("../../../../../assets/images/account_settings/General.png")}
                                    style={{ width: 30, height: 30 }}
                                    resizeMode="contain" alt="image"
                                  />
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.normal,
                                      fontSize: 18,
                                      lineHeight: 24,
                                      color: colors.palette.neutral600,
                                    }}
                                    >
                                      General
                                  </Text>
                                </HStack>
                                <MaterialCommunityIcons name="greater-than" size={18} color={colors.palette.neutral600} />
                              </HStack>
                            </Pressable>

                            {/* Business tab */}
                            <Pressable onPress={()=>{navigation.navigate("BusinessSettings_mobile")}}>
                              <HStack space={2} p={3} alignItems='center' justifyContent={'space-between'} backgroundColor={ colors.palette.neutral100}
                                style={{borderColor: colors.palette.gray100, borderWidth: 2}}>
                                <HStack space={4} alignItems={'center'}>
                                  <Image
                                    source={require("../../../../../assets/images/account_settings/Add_business.png")}
                                    style={{ width: 30, height: 30 }}
                                    resizeMode="contain" alt="image"
                                  />
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.normal,
                                      fontSize: 18,
                                      lineHeight: 24,
                                      color: colors.palette.neutral600,
                                    }}
                                    >
                                      Business
                                  </Text>
                                </HStack>
                                <MaterialCommunityIcons name="greater-than" size={18} color={colors.palette.neutral600} />
                              </HStack>
                            </Pressable>

                            {/* Security tab */}
                            <Pressable onPress={()=>{navigation.navigate("SecuritySettings_mobile")}}>
                              <HStack space={2} p={3} alignItems='center' justifyContent={'space-between'} backgroundColor={ colors.palette.neutral100}
                                style={{borderColor: colors.palette.gray100, borderWidth: 2}}>
                                <HStack space={4} alignItems={'center'}>
                                  <Image
                                    source={require("../../../../../assets/images/account_settings/Security.png")}
                                    style={{ width: 30, height: 30 }}
                                    resizeMode="contain" alt="image"
                                  />
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.normal,
                                      fontSize: 18,
                                      lineHeight: 24,
                                      color: colors.palette.neutral600,
                                    }}
                                    >
                                      Security
                                  </Text>
                                </HStack>
                                <MaterialCommunityIcons name="greater-than" size={18} color={colors.palette.neutral600} />
                              </HStack>
                            </Pressable>

                            {/* Billing tab */}
                            <Pressable onPress={()=>{navigation.navigate("BillingSettings_mobile")}}>
                              <HStack space={2} p={3} alignItems='center' justifyContent={'space-between'} backgroundColor={ colors.palette.neutral100}
                                style={{borderColor: colors.palette.gray100, borderWidth: 2}}>
                                <HStack space={4} alignItems={'center'}>
                                  <Image
                                    source={require("../../../../../assets/images/account_settings/Billing.png")}
                                    style={{ width: 30, height: 30 }}
                                    resizeMode="contain" alt="image"
                                  />
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.normal,
                                      fontSize: 18,
                                      lineHeight: 24,
                                      color: colors.palette.neutral600,
                                    }}
                                    >
                                      Billing
                                  </Text>
                                </HStack>
                                <MaterialCommunityIcons name="greater-than" size={18} color={colors.palette.neutral600} />
                              </HStack>
                            </Pressable>

                            {/* Notification tab */}
                            <Pressable onPress={()=>{navigation.navigate("NotificationSettings_mobile")}}>
                              <HStack space={2} p={3} alignItems='center' justifyContent={'space-between'} backgroundColor={ colors.palette.neutral100}
                                style={{borderColor: colors.palette.gray100, borderWidth: 2}}>
                                <HStack space={4} alignItems={'center'}>
                                  <Image
                                    source={require("../../../../../assets/images/account_settings/Notifications.png")}
                                    style={{ width: 30, height: 30 }}
                                    resizeMode="contain" alt="image"
                                  />
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.normal,
                                      fontSize: 18,
                                      lineHeight: 24,
                                      color: colors.palette.neutral600,
                                    }}
                                    >
                                      Notification
                                  </Text>
                                </HStack>
                                <MaterialCommunityIcons name="greater-than" size={18} color={colors.palette.neutral600} />
                              </HStack>
                            </Pressable>

                            {/* Logout tab */}
                            <Pressable onPress={()=>{navigation.navigate("GeneralSettings_mobile")}}>
                              <HStack space={2} p={3} alignItems='center' justifyContent={'space-between'} backgroundColor={ colors.palette.neutral100} 
                                style={{borderColor: colors.palette.gray100, borderWidth: 2}}>
                                <HStack space={4} alignItems={'center'}>
                                  <Image
                                    source={require("../../../../../assets/images/account_settings/Logout.png")}
                                    style={{ width: 28, height: 28 }}
                                    resizeMode="contain" alt="image"
                                  />
                                  <Text 
                                    style={{
                                      fontFamily: typography.fonts.poppins.normal,
                                      fontSize: 18,
                                      lineHeight: 24,
                                      color: colors.palette.neutral600,
                                    }}
                                    >
                                      Logout
                                  </Text>
                                </HStack>
                                <MaterialCommunityIcons name="greater-than" size={18} color={colors.palette.neutral600} />
                              </HStack>
                            </Pressable>
                          </VStack>

                        </VStack>
                      </Stack>
                  </TouchableWithoutFeedback>
                </KeyboardAvoidingView>
              </ScrollView>
          </PageLayout>, //code for Mobile devices
        })}
      {/* Platform select close here  */}
    </>
    )
})

const $root: ViewStyle = {
  flex: 1,
}
