import React, { FC, useState, useEffect, useContext } from "react"
import { observer } from "mobx-react-lite"
import { Alert, Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, ContactForm, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, HStack, Input, Stack, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../theme"
import { api } from "../../services/api/api"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `ContactUs: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="ContactUs" component={ContactUsScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const ContactUsScreen: FC<StackScreenProps<AppStackScreenProps, "ContactUs">> = observer(function ContactUsScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated, logout} } = useStores()
  const {overlayVisible,setOverlayVisible} = useContext(OverlayContext)
  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const [data, setData] = useState<any>()
  
  useEffect(()=>{
    api.apisauce.get("pages/?type=pages.HomePage&fields=*")
    .then(
      (response: any)=>{
        if(response.status == 403){
          AsyncStorage.clear()
          navigation.navigate('Signin')
        }
        else if(response.data?.items)
          setData(response.data?.items[0]) 
      }
    )
  },[])

  const WebCode =()=>{
    return(
    <Screen style={$root} preset="scroll">
      <PageLayout variant={isAuthenticated?1:0}>
        <VStack mb={100}>
          <Box 
            width="100%" height={200}
            justifyContent="center"            
            alignItems="center"
            background={colors.palette.primary100} >
            <Text
              style={{
                fontSize:40,
                fontFamily:typography.fonts.poppins.bold,
                color:colors.palette.primary600                
              }}
            >Contact Us</Text>
          </Box>
          <Stack direction={{base: 'column-reverse', md: 'row'}} space={5}
            w="100%" px={20}              
            justifyContent="space-between"
            py={10}
          >
            {/* Leave us a message section */}
            <VStack 
              w={{base: '100%',md: '50%',lg:"30%"}} space={5}
              justifyContent="center"
            >
              <Text
                style={{
                  color:colors.palette.primary600, 
                  fontFamily:typography.fonts.poppins.semiBold,
                  fontSize:24,
                  lineHeight:28.8
                }}
              >Leave us a message</Text>
              <ContactForm/>                
              {/* <Input placeholder='Your Name'/>               
              <Input placeholder='Email Address'/>               
              <Input placeholder='Your Message' multiline numberOfLines={5}/>            
              <Button
                text="Send"
                preset="default"
                onPress={() => Alert.alert("pressed")}          
                style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
                pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
              />               */}
            </VStack>
            {/* image section */}
            <AutoImage 
              source={require("../../../assets/images/contact_us/Contact_Us_2x.png")}
              maxWidth={useBreakpointValue({base: 300, lg:600})} style={{alignSelf: 'center'}}
            />              
          </Stack>          
        </VStack>
      </PageLayout>
    </Screen>
    )
  }
  
  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
    </Screen>
    )
  }
  
  
  return (
  <>
    {/* divert code for web and android here  */}
    {Platform.select({ 
        native : <MobileCode/>, //code for Mobile devices
        default : <WebCode/>, //code for web and other platforms
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 

const $root: ViewStyle = {
  flex: 1,
}
