import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
interface StringArray {
  value: string[] | ['', '', '', ''];
}

export const ResetPasswordStoreModel = types
  .model("ResetPasswordStore")
  .props({
    email: "",
    newPassword: "",
    confirmPassword: "",
    // otp: types.frozen<StringArray>()
    // const [email, setEmail] = useState('');
    // const [newPassword, setNewPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');
    // const [otp, setOtp] = useState(['', '', '', '']);
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get emailValidationError() {
      if (self.email?.length === 0) return "email can't be blank"
      // if (self.email.length <= 11) return "email must be at least 10 characters"
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(self.email) && self.email?.length <= 11) return "must be a valid email address"
      return ""
    },
    get newPasswordValidationError(){
      if (self.newPassword?.length === 0) return "password cannot be blank"
      if (self.newPassword?.length < 9) return "password must be at least 6 characters"
      return ""
    },
    get confirmPasswordValidationError(){
      if (self.confirmPassword?.length === 0) return "Confirm Password cannot be blank"
      if (self.confirmPassword !== self.newPassword) return "Password should be same"
      return ""
    },
    // get otpValidationError(){
    //     // Check if any OTP field is empty
    //   const isEmpty = self.otp.value.some((otp) => otp.length === 0);
    //   if (isEmpty) return "OTP fields cannot be blank";

    //   // Check if any OTP field is not a valid number
    //   const isNotNumber = self.otp.value.some((otp) => isNaN(Number(otp)));
    //   if (isNotNumber) return "OTP fields must contain only numeric values";

    //   // Additional custom validation logic for OTP can be added here
    //   return "";
    // },

  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setEmail(value: string) {
      self.email = value.toLowerCase()
    },
    setNewPassword(value: string) {
      self.newPassword = value
    },
    setConfirmPassword(value: string) {
      self.confirmPassword = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface ResetPasswordStore extends Instance<typeof ResetPasswordStoreModel> {}
export interface ResetPasswordStoreSnapshotOut extends SnapshotOut<typeof ResetPasswordStoreModel> {}
export interface ResetPasswordStoreSnapshotIn extends SnapshotIn<typeof ResetPasswordStoreModel> {}
export const createResetPasswordStoreDefaultModel = () => types.optional(ResetPasswordStoreModel, {})
