import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, TextStyle, ViewStyle, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Dimensions, StyleSheet, TextInput } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../navigators"
import { AutoImage, Button, Screen, Text, TextFieldAccessoryProps } from "../../../components"
import { useNavigation, useRoute } from "@react-navigation/native"
import { Box, HStack, Image, Pressable, ScrollView, VStack, View, useBreakpointValue } from "native-base"
import { colors, typography } from "../../../theme"
import { InputField } from "../../../components/InputField"
import { Feather, Ionicons } from "@expo/vector-icons"
// import OTPInputView from 'react-native-otp-input-reborn'
// import OtpInputs, { OtpInputsRef } from "react-native-otp-inputs" //ios is not supporting this 

//imports for mobile----->
import Constants from 'expo-constants';
import { useStores } from "../../../models"
import { AuthenticationApi } from "../../../services/api/apiFatching/authentication-api"
import { api } from "../../../services/api"
import { OverlayContext } from "../../../components/OverlayProvider"
import { AlertContext } from "../../../components/AlertContext"


// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `ResetPassword: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const ResetPasswordScreen: FC<StackScreenProps<AppStackScreenProps, "ResetPassword">> = observer(function ResetPasswordScreen() {
  // Pull in one of our MST stores
  const route = useRoute<any>()
  const { ResetPasswordStore: {email, 
    newPassword, 
    confirmPassword, 
    setEmail, 
    setNewPassword, 
    setConfirmPassword,
    emailValidationError,
    newPasswordValidationError,
    confirmPasswordValidationError,
  } } = useStores()

  const [isConfirmPasswordHidden , setIsConfirmPasswordHidden] = React.useState(true)
  const [isNewPasswordHidden , setIsNewPasswordHidden] = React.useState(true)
  const [enableShift, setEnableShift] = React.useState(false)

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const newPasswordInput = useRef<any>()
  const confirmPasswordInput = useRef<any>()

  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    setEmail((route?.params?.email).toLocaleLowerCase().trim())
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
      setKeyboardOpen(true);
      setKeyboardHeight(e.endCoordinates.height);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', (e) => {
      setKeyboardOpen(false);
      setKeyboardHeight(0);
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);


  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  // const otpRef = useRef<OtpInputsRef>()

  // create your own vh
  const screenHeight = Dimensions.get('window').height;
  const availableHeight = screenHeight - keyboardHeight;

  const NewPasswordShow = React.useMemo(
    () =>
      function NewPasswordShow(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isNewPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsNewPasswordHidden(!isNewPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isNewPasswordHidden],
  )

  const ConfirmPasswordShow = React.useMemo(
    () =>
      function ConfirmPasswordShow(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isConfirmPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsConfirmPasswordHidden(!isConfirmPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isConfirmPasswordHidden],
  )

  // otp related functions-->>>
  const [otp, setOtp] = useState(['', '', '', '']);
  const refs = useRef([]);

  const handleOtpChange = (index, value) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value && index < refs.current.length - 1) {
      refs.current[index + 1].focus();
    }
  };
  
 //validation error ----->
 const emailError = isSubmitted ? emailValidationError : ""
 const newPasswordError = isSubmitted ? newPasswordValidationError : ""
 const confirmPasswordError = isSubmitted ? confirmPasswordValidationError : ""

 const otpValidationError=(otp: any)=>{
  // Check if any OTP field is empty
  const isEmpty = otp.some((otp: any) => otp.length === 0);
  if (isEmpty) return "OTP fields cannot be blank";

  // Check if any OTP field is not a valid number
  const isNotNumber = otp.some((otp: any) => isNaN(Number(otp)));
  if (isNotNumber) return "OTP fields must contain only numeric values";

  // Additional custom validation logic for OTP can be added here
  return "";
  }

  const {  setOverlayVisible } = useContext(OverlayContext); 
  const { showAlert } = useContext(AlertContext);
  function resetPassword() {
    console.log("reset password")
    setIsSubmitted(true)

    //if there is something returned form validation error then return from here and do not call login api
    if (emailValidationError) return 
    if (newPasswordValidationError) return 
    if (confirmPasswordValidationError) return 

    // if(otpValidationError(otp)) return setOtpError(true);

    // Make a request to your server to get an authentication token.   
    
    const authApi = new AuthenticationApi(api)
    authApi.resetPassword(newPassword, confirmPassword, route?.params?.uid, route?.params?.token, setOverlayVisible).then((response:any) => {
      // showAlert("Success", response.detail)     
      // If successful, reset the fields and set the token. 
      setEmail("")
      setNewPassword("")
      setConfirmPassword("")
       // setOtp(['', '', '', ''])
      navigation.navigate("PasswordChanged")
    }).catch((error) => {
      showAlert("Error", "Choose a strong password.")
    }).finally(() => {      
      setIsSubmitted(false)
    }); 
}

useEffect(()=>{
  setEmail('')
  setNewPassword('')
  setConfirmPassword('')
},[])

  return (
  <>
    {Platform.select({
      // code for web------------------------>
    default : 
    <Screen style={$root} preset="scroll">
      <HStack 
        w="100%" height="112vh"
        maxWidth={1440} alignSelf="center"
        style={{
          shadowOffset: {width: 0, height: 0},  
          shadowColor: '#171717',  
          shadowOpacity: 0.2,  
          shadowRadius: 3
        }}
      >
        <VStack w={{base: '100%', md: '45%', lg: '35%', xl:"30%"}} space={5} p={30} alignItems="flex-start"> 
          {/* reset password background */}
          <AutoImage 
              source={require("../../../../assets/images/sign_in_sign_up/reset_password.png")}
              maxHeight={400}
              style={{
                margin: -30,
                height: useBreakpointValue({base: '50%',md:"35%"}), width: '120%',
              }}
              resizeMode='stretch'
            />
          
          <Box style={{position:"absolute", top:30, left:30 }}>
            <Pressable onPress={()=>navigation.navigate("Home")}>
              <AutoImage 
                source={require("../../../../assets/images/color_logo.png")}
                maxHeight={39}
                style={{
                  // position:"absolute",
                  // top:30, left:30
                }}
              />
            </Pressable>
          </Box>
          
          <VStack space={2}>
            <Text
              style={{
                color:colors.palette.neutral400, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24
              }}
            >Enter code that we have sent to your email</Text>                
            <Text
              style={{
                color:colors.palette.neutral500, 
                fontFamily:typography.fonts.poppins.normal,
                fontSize:16,
                lineHeight:24
              }}
            >{route?.params?.email}</Text>     
          </VStack> 
          <VStack space={3} w={'100%'}>
            <InputField
                key="email"
                value={email}              
                onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                label="Email Address"
                placeholder="Email Address"
                helper={emailError}
                status={emailError ? "error" : undefined}
                onSubmitEditing={() => newPasswordInput.current?.focus()}
                // status="error"
                // helper="Enter your name"
                // LeftAccessory={() => <Icon icon="bell" />}
              />    
            <InputField
                ref={newPasswordInput}
                key="New password"
                value={newPassword}              
                onChangeText={(value) => setNewPassword(value)}
                label="New Password"
                placeholder="Password"
                secureTextEntry={isNewPasswordHidden}
                helper={newPasswordError}
                status={newPasswordError ? "error" : undefined}
                onSubmitEditing={() => confirmPasswordInput.current?.focus()}
                // status="error"
                // helper="Enter your name"
                RightAccessory={NewPasswordShow}
                // LeftAccessory={() => <Icon icon="bell" />}
              />    
            <InputField
                ref={confirmPasswordInput}
                key="Confirm Password"
                value={confirmPassword}              
                onChangeText={(value) => setConfirmPassword(value)}
                label="Confirm Password"
                placeholder="Confirm Password"
                secureTextEntry={isConfirmPasswordHidden}
                RightAccessory={ConfirmPasswordShow}
                helper={confirmPasswordError}
                status={confirmPasswordError ? "error" : undefined}
                // onSubmitEditing={() => confirmPasswordInput.current?.focus()}
              />    
              {/*<Text
                style={{
                  color:colors.palette.neutral500, 
                  fontFamily:typography.fonts.poppins.normal,
                  fontSize:16,
                  lineHeight:24,
                  alignSelf: 'center'
                }}
              >OTP</Text>
              
              <View style={styles.container}>
                  {otp?.map((digit, index) => (
                    <InputField
                      key={`otp_index_${index}`}
                      style={styles.input}
                      keyboardType="numeric"
                      maxLength={1}
                      value={digit}
                      onChangeText={(value) => handleOtpChange(index, value)}
                      ref={(ref) => (refs.current[index] = ref)}
                      onSubmitEditing={() => {
                        if (index < refs.current.length - 1) {
                          refs.current[index + 1].focus();
                        }
                      }}
                      // helper={otpValidationError(otp)}
                      // status={otpValidationError(otp) ? "error" : undefined}
                    />
                  ))}
                </View>
                {otpError &&  <Text style={{color: 'red', textAlign: 'center'}}>{otpValidationError(otp)}</Text>} */}
            <Button
              text="Submit"
              preset="default"
              onPress={() =>{resetPassword(); }}          
              style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" , marginBottom: 10}]}
              pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
              textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
            />   
          </VStack>           
        </VStack>

        <AutoImage 
          source={require("../../../../assets/images/sign_in_sign_up/sign_up_img.png")}
          maxHeight={600} style={{height:"100%", width:"75%"}}                   
        />              
      </HStack> 
    </Screen>,

      // code for mobile------------------------>
    native:
    <KeyboardAvoidingView behavior={"position"} contentContainerStyle={{flex: 1}} style={{flex:1}} enabled={enableShift}> 
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={{flex:1}}>   
        <Screen style={[$root,{marginTop: Constants.statusBarHeight}]}>
          {/* reset password background */}
          <Image 
            source={require("../../../../assets/images/sign_in_sign_up/reset_password.png")}
            maxHeight={250}
            alt='image'
            style={{
              // margin: -30,
              height: '50%', width: '100%',
              zIndex: 1
            }}
            resizeMode='stretch'
          />
          {/* go back section */}
          <Box 
          style={{position:"relative", top:-240, left: 5, zIndex: 1 }} 
          minH={100} >
            <HStack space={3} p={5} alignItems={'center'}>
              <Pressable onPress={() => navigation.goBack()}>
                <Ionicons name="arrow-back" alt="icon" size={30} color={'black'}/>
              </Pressable>
              <Text 
                style={{
                  fontFamily: typography.fonts.poppins.bold,
                  fontSize: 16,
                  lineHeight: 19,
                  color: 'black',
                }}
                >
                  {/* {sellerName} */}
                  Back
              </Text>
            </HStack>
          </Box>
          {/* logo */}
          <Box 
          style={{position:"relative", top:-260, left:'33%', zIndex: 2 }} 
          w='100%' pb={3}>
            <Pressable onPress={()=>navigation.navigate("Home")}>
              <Image 
                source={require("../../../../assets/images/color_logo.png")}
                maxHeight={39} 
                alt="image"
                style={{ 
                  height: 39,
                  width: 131
                  // position:"absolute",
                  // top:30, left:30
                }}
                resizeMode="contain"
              />
            </Pressable>
          </Box>

          <VStack w={'100%'} h={availableHeight} space={3} mt={-170} p={30} alignItems="flex-start" bg='white'> 
            <VStack space={2}>
              <Text
                style={{
                  color:colors.palette.neutral400, 
                  fontFamily:typography.fonts.poppins.normal,
                  fontSize:16,
                  lineHeight:24
                }}
              >Enter code that we have sent to your email</Text>
              <Text
                style={{
                  color:colors.palette.neutral500, 
                  fontFamily:typography.fonts.poppins.normal,
                  fontSize:16,
                  lineHeight:24
                }}
              >{route?.params?.email}</Text>     
            </VStack> 
            <VStack space={3} w={'100%'} h='100%'>
              <InputField
                  key="email"
                  value={email}              
                  onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                  label="Email Address"
                  placeholder="Email Address"
                  helper={emailError}
                  status={emailError ? "error" : undefined}
                  onSubmitEditing={() => newPasswordInput.current?.focus()}
                  onFocus={()=>setEnableShift(false)}
                />    
              <InputField
                  ref={newPasswordInput}
                  key="New password"
                  value={newPassword}              
                  onChangeText={(value) => setNewPassword(value)}
                  label="New Password"
                  placeholder="Password"
                  secureTextEntry={isNewPasswordHidden}
                  helper={newPasswordError}
                  status={newPasswordError ? "error" : undefined}
                  onSubmitEditing={() => confirmPasswordInput.current?.focus()}
                  RightAccessory={NewPasswordShow}
                  // LeftAccessory={() => <Icon icon="bell" />}
                  onFocus={()=>setEnableShift(true)}

                />    
              <InputField
                  ref={confirmPasswordInput}
                  key="Confirm Password"
                  value={confirmPassword}              
                  onChangeText={(value) => setConfirmPassword(value)}
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  secureTextEntry={isConfirmPasswordHidden}
                  RightAccessory={ConfirmPasswordShow}
                  onFocus={()=>setEnableShift(true)}
                  helper={confirmPasswordError}
                  status={confirmPasswordError ? "error" : undefined}
                  // onSubmitEditing={() => confirmPasswordInput.current?.focus()}
                />    
                <Text
                  style={{
                    color:colors.palette.neutral500, 
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24,
                    alignSelf: 'center'
                  }}
                >OTP</Text>
               {/* otp inputs */}
               <View style={styles.container}>
                  {otp?.map((digit, index) => (
                    <TextInput
                      key={`otp_index_2_${index}`}
                      style={styles.input}
                      keyboardType="numeric"
                      maxLength={1}
                      value={digit}
                      onChangeText={(value) => handleOtpChange(index, value)}
                      ref={(ref) => (refs.current[index] = ref)}
                      onSubmitEditing={() => {
                        if (index < refs.current.length - 1) {
                          refs.current[index + 1].focus();
                        }
                      }}
                      onFocus={() =>{setEnableShift(true)}}
                    />
                  ))}
                </View>
                {otpError &&  <Text style={{color: 'red', textAlign: 'center'}}>{otpValidationError(otp)}</Text>}

              {/* submit button */}
              <Button
                text="Submit"
                preset="default"
                onPress={() =>{resetPassword(); }}           
                style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" , marginBottom: 10}]}
                pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
              />   
            </VStack>           
          </VStack>
        </Screen>
     </TouchableWithoutFeedback> 
     </KeyboardAvoidingView>
    })}
  </>
)
  
}) // observer close here 

const $root: ViewStyle = {
  flex: 1,
}

// otp related styles------->
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  input: {
    width: 40,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    textAlign: 'center',
    marginLeft: 5,
    marginRight: 5,
  },
});