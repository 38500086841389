/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  useNavigation,
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { StackScreenProps } from "@react-navigation/stack"
import { observer } from "mobx-react-lite"
import React, { useContext, useEffect } from "react"
import { Linking, Platform, useColorScheme } from "react-native"
import Config from "../config"
import { AccountSettingMobileScreen, 
  AddNewOfferScreen, 
  AddNewProductScreen, 
  AllReviewsScreen, 
  AuthenticatedHomeScreen, 
  BillingSettingMobileScreen, 
  BusinessSettingMobileScreen, 
  ChatScreen, 
  ForgotPasswordScreen, 
  GeneralSettingMobileScreen, 
  GeneralSettingsScreen, 
  NotificationSettingMobileScreen, 
  NotificationsScreen, 
  OpenChatScreen, 
  PasswordChangeStatusScreen, 
  ProductDescriptionScreen, 
  ReviewScreen, 
  SecuritySettingMobileScreen, 
  SellerProfileScreen, 
  WishlistScreen } from "../screens"
import { AboutUsScreen } from "../screens/StaticPages/AboutUsScreen"
import { BlogDetailScreen } from "../screens/StaticPages/BlogDetailScreen"
import { BlogScreen } from "../screens/StaticPages/BlogScreen"
import { ContactUsScreen } from "../screens/StaticPages/ContactUsScreen"
import { FaqScreen } from "../screens/StaticPages/FaqScreen"
import { HomeScreen } from "../screens/StaticPages/HomeScreen"
import { LoginScreen } from "../screens/StaticPages/signIn_signOut/LoginScreen"
import { RegisterationScreen } from "../screens/StaticPages/signIn_signOut/RegisterationScreen"
import { ResetPasswordScreen } from "../screens/StaticPages/signIn_signOut/ResetPasswordScreen"
import { navigateToScreen, navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { TabNavigation } from "./TabNavigator" // @demo remove-current-line
import { useStores } from "../models"
import { TermsAndConditionScreen } from "../screens/StaticPages/TermsAndConditions"
import { ITPolicyScreen } from "../screens/StaticPages/ITPolicy"
import { PrivacyPolicyScreen } from "../screens/StaticPages/PrivacyPolicy"
import { CopyrightPolicyScreen } from "../screens/StaticPages/CopyrightPolicy"
import { UserPolicyScreen } from "../screens/StaticPages/UserPolicy"
import { GDPRPolicyScreen } from "../screens/StaticPages/GDPRPolicy"
import { ProductsScreen } from "../screens/StaticPages/ProductsScreen"
import { OverlayContext } from "../components/OverlayProvider"
import LinkingConfiguration from '../../LinkingConfiguration';
import { getInitialURL, parseInitialURLAsync } from "expo-linking"
import { api } from "../services/api"
import AsyncStorage from "@react-native-async-storage/async-storage"
// import { TabNavigation } from "../components/PageLayout"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type AppStackParamList = {
  Welcome: undefined
  Home: undefined
  About: undefined
  Blog: undefined
  BlogDetail: undefined
  Contact: undefined
  FAQ: undefined
  Signin:undefined
  Signup:undefined
  password:undefined
  ResetPassword:undefined
  ForgotPassword:undefined
  PasswordChanged:undefined
  Products:undefined

  // POLICY SCREENS TITLE:  
  TermsAndCondition:undefined
  ITPolicy:undefined
  UserPolicy:undefined
  CopyrightPolicy:undefined
  PrivacyPolicy:undefined
  GDPRPolicy:undefined
  // 🔥 Your screens go here
  
  // Authenticated
  AuthenticatedHome:undefined
  ProductDescription:undefined
  Notifications:undefined
  Chat:undefined
  AddNewProduct:undefined
  AddNewOffer:undefined
  GeneralSettings:undefined
  OpenChat:undefined
  Review:undefined
  ViewAllReviews:undefined
  SellerProfile:undefined
  Wishlist:undefined

  TabNavigation:undefined
  // TabNavigation2_Home_Offer_product_chat_profile:undefined

  // for mobile
  AccountSettings_mobile:undefined
  GeneralSettings_mobile:undefined
  BusinessSettings_mobile:undefined
  SecuritySettings_mobile:undefined
  BillingSettings_mobile:undefined
  NotificationSettings_mobile:undefined
}

/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */
const exitRoutes = Config.exitRoutes

export type AppStackScreenProps<T extends keyof AppStackParamList> = StackScreenProps<
  AppStackParamList,
  T
>

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<AppStackParamList>()

const AppStack = observer(function AppStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Welcome" component={HomeScreen} />
      <Stack.Screen name="Home" component={AuthenticatedHomeScreen} />
      <Stack.Screen name="About" component={AboutUsScreen} />
      <Stack.Screen name="Blog" component={BlogScreen} />
      <Stack.Screen name="BlogDetail" component={BlogDetailScreen} />
      <Stack.Screen name="Contact" component={ContactUsScreen} />
      <Stack.Screen name="FAQ" component={FaqScreen} />
      <Stack.Screen name="Signin" component={LoginScreen} />
      <Stack.Screen name="Signup" component={RegisterationScreen} />      
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />      
      <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />      
      <Stack.Screen name="PasswordChanged" component={PasswordChangeStatusScreen} />        
      <Stack.Screen name="Products" component={ProductsScreen} /> 
      <Stack.Screen name="ProductDescription" component={ProductDescriptionScreen} /> 
      {/* POLICY SECTION */}
      <Stack.Screen name="TermsAndCondition" component={TermsAndConditionScreen} /> 
      <Stack.Screen name="ITPolicy" component={ITPolicyScreen} /> 
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} /> 
      <Stack.Screen name="CopyrightPolicy" component={CopyrightPolicyScreen} /> 
      <Stack.Screen name="UserPolicy" component={UserPolicyScreen} /> 
      <Stack.Screen name="GDPRPolicy" component={GDPRPolicyScreen} /> 

      {/* remove this  */}
      {/* <Stack.Screen name="Home" component={TabNavigation} /> */}
    </Stack.Navigator>
  )
})


const AuthStack = observer(function AuthStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>      
      {/* authenticated screens */}
      {/* for web AuthenticatedHome will render AuthenticatedHomeScreen */}
      {/* for mobile AuthenticatedHome will render DemoNavigator it contains All Tab Navigation screens  */}      
      {
        Platform.select({
          default: 
        <Stack.Screen name="Home" component={AuthenticatedHomeScreen} />,
        
          native: 
        <Stack.Screen name="Home" component={TabNavigation} />
        })
      } 
      <Stack.Screen name="Welcome" component={HomeScreen} />
      <Stack.Screen name="Notifications" component={NotificationsScreen} />
      <Stack.Screen name="Chat" component={ChatScreen} />
      <Stack.Screen name="AddNewProduct" component={AddNewProductScreen} />
      <Stack.Screen name="AddNewOffer" component={AddNewOfferScreen} />         
      <Stack.Screen name="ProductDescription" component={ProductDescriptionScreen} /> 

      <Stack.Screen name="OpenChat" component={OpenChatScreen} />
      <Stack.Screen name="Review" component={ReviewScreen} />
      <Stack.Screen name="ViewAllReviews" component={AllReviewsScreen} />
      <Stack.Screen name="SellerProfile" component={SellerProfileScreen} />
      <Stack.Screen name="Wishlist" component={WishlistScreen} />
      
      {/* account settings for web */}
      <Stack.Screen name="GeneralSettings" component={GeneralSettingsScreen} /> 

      {/* created for mobile screens*/}
      <Stack.Screen name="AccountSettings_mobile" component={AccountSettingMobileScreen} />
      <Stack.Screen name="GeneralSettings_mobile" component={GeneralSettingMobileScreen} />
      <Stack.Screen name="BusinessSettings_mobile" component={BusinessSettingMobileScreen} />
      <Stack.Screen name="SecuritySettings_mobile" component={SecuritySettingMobileScreen} />
      <Stack.Screen name="BillingSettings_mobile" component={BillingSettingMobileScreen} />
      <Stack.Screen name="NotificationSettings_mobile" component={NotificationSettingMobileScreen } />

      {/** 🔥 Your screens go here */}      
      <Stack.Screen name="About" component={AboutUsScreen} />
      <Stack.Screen name="Blog" component={BlogScreen} />
      <Stack.Screen name="BlogDetail" component={BlogDetailScreen} />
      <Stack.Screen name="Contact" component={ContactUsScreen} />
      <Stack.Screen name="FAQ" component={FaqScreen} />
      <Stack.Screen name="Products" component={ProductsScreen} />

      {/* POLICY SECTION */}
      <Stack.Screen name="TermsAndCondition" component={TermsAndConditionScreen} /> 
      <Stack.Screen name="ITPolicy" component={ITPolicyScreen} /> 
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} /> 
      <Stack.Screen name="CopyrightPolicy" component={CopyrightPolicyScreen} /> 
      <Stack.Screen name="UserPolicy" component={UserPolicyScreen} /> 
      <Stack.Screen name="GDPRPolicy" component={GDPRPolicyScreen} /> 
    </Stack.Navigator>
  )
})

const MobileStack = observer(function MobileStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>      
      {/* authenticated screens */}
      {/* for web AuthenticatedHome will render AuthenticatedHomeScreen */}
      {/* for mobile AuthenticatedHome will render DemoNavigator it contains All Tab Navigation screens  */}      
      <Stack.Screen name="Signin" component={LoginScreen} />
      <Stack.Screen name="Home" component={TabNavigation} />
          
      <Stack.Screen name="Notifications" component={NotificationsScreen} />
      <Stack.Screen name="Chat" component={ChatScreen} />
      <Stack.Screen name="AddNewProduct" component={AddNewProductScreen} />
      <Stack.Screen name="AddNewOffer" component={AddNewOfferScreen} />         
      <Stack.Screen name="ProductDescription" component={ProductDescriptionScreen} /> 

      <Stack.Screen name="OpenChat" component={OpenChatScreen} />
      <Stack.Screen name="Review" component={ReviewScreen} />
      <Stack.Screen name="ViewAllReviews" component={AllReviewsScreen} />
      <Stack.Screen name="SellerProfile" component={SellerProfileScreen} />
      <Stack.Screen name="Wishlist" component={WishlistScreen} />
      
      {/* account settings for web */}
      <Stack.Screen name="GeneralSettings" component={GeneralSettingsScreen} /> 

      {/* created for mobile screens*/}
      <Stack.Screen name="AccountSettings_mobile" component={AccountSettingMobileScreen} />
      <Stack.Screen name="GeneralSettings_mobile" component={GeneralSettingMobileScreen} />
      <Stack.Screen name="BusinessSettings_mobile" component={BusinessSettingMobileScreen} />
      <Stack.Screen name="SecuritySettings_mobile" component={SecuritySettingMobileScreen} />
      <Stack.Screen name="BillingSettings_mobile" component={BillingSettingMobileScreen} />
      <Stack.Screen name="NotificationSettings_mobile" component={NotificationSettingMobileScreen } />

      {/** 🔥 Your screens go here */}

      <Stack.Screen name="Welcome" component={HomeScreen} />
      <Stack.Screen name="About" component={AboutUsScreen} />
      <Stack.Screen name="Blog" component={BlogScreen} />
      <Stack.Screen name="BlogDetail" component={BlogDetailScreen} />
      <Stack.Screen name="Contact" component={ContactUsScreen} />
      <Stack.Screen name="FAQ" component={FaqScreen} />
      <Stack.Screen name="Products" component={ProductsScreen} />

      {/* POLICY SECTION */}
      <Stack.Screen name="TermsAndCondition" component={TermsAndConditionScreen} /> 
      <Stack.Screen name="ITPolicy" component={ITPolicyScreen} /> 
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} /> 
      <Stack.Screen name="CopyrightPolicy" component={CopyrightPolicyScreen} /> 
      <Stack.Screen name="UserPolicy" component={UserPolicyScreen} /> 
      <Stack.Screen name="GDPRPolicy" component={GDPRPolicyScreen} /> 

      {/* for mobile */}
      
      <Stack.Screen name="Signup" component={RegisterationScreen} />      
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />      
      <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />      
      <Stack.Screen name="PasswordChanged" component={PasswordChangeStatusScreen} />   
    </Stack.Navigator>
  )
})

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  const colorScheme = useColorScheme()
  const { 
    authenticationStore,
    ProductListStore: {
      products, 
      dealOfTheDayProducts,
      categories,
      brands,
      wishlist,
      fetchProductList, 
      fetchDealOfTheDayProducts,
      fetchCategories,
      fetchBrands,
    }
   } = useStores()

  useBackButtonHandler((routeName) => exitRoutes.includes(routeName))
  const { setOverlayVisible } = useContext(OverlayContext);
  
  useEffect(()=>{    
    fetchProductList(setOverlayVisible);
    fetchDealOfTheDayProducts(setOverlayVisible);
    fetchCategories(setOverlayVisible);
    fetchBrands(setOverlayVisible);
  },[])

  return (
  <React.Fragment>
    <NavigationContainer
      linking={LinkingConfiguration}
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    > 
    {Platform.select({
        default: authenticationStore.isAuthenticated ? <AuthStack /> : <AppStack/>, 
        native: <MobileStack/>})}
        {/* <AuthStack/> */}
        {/* {authenticationStore.isAuthenticated ? <AuthStack /> : <AppStack/>} */}
        {/* <MobileStack/> */}
      

    </NavigationContainer>

    {/* tab navigation for mobile
    <NavigationContainer
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      <TabNavigation/>
    </NavigationContainer> */}
</React.Fragment>
  )
})
