import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { AuthenticationStoreModel } from "./AuthenticationStore"
import { ProductListStoreModel } from "./product/ProductListStore"
import { LoginStoreModel } from "./signIn_signout/LoginStore"
import { RegistrationStoreModel } from "./signIn_signout/RegistrationStore"
import { ForgotPasswordStoreModel } from "./signIn_signout/ForgotPasswordStore"
import { ResetPasswordStoreModel } from "./signIn_signout/ResetPasswordStore"
import { ContactStoreModel } from "./pagesStore/ContactStore"
import { AuthenticatedHomeStoreModel } from "./pagesStore/AuthHomeStore"
import { ReviewStoreModel } from "./pagesStore/ReviewStore"
import { AddOfferStoreModel } from "./pagesStore/AddOfferStore"
import { AddProductStoreModel } from "./pagesStore/AddProductStore"
import { AccountSettingStoreModel } from "./pagesStore/AccountSettingStore"
import { ChatStoreModel } from "./pagesStore/ChatStore"
import { NotificationStoreModel } from "./NotificationStore"

/**
 * A RootStore model.
 */
export const RootStoreModel = types
    .model("RootStore")
    .props({
        authenticationStore: types.optional(AuthenticationStoreModel, {} as any),
        ProductListStore: types.optional(ProductListStoreModel, {} as any),
        LoginStore: types.optional(LoginStoreModel, {} as any),
        RegistrationStore: types.optional(RegistrationStoreModel, {} as any),
        ForgotPasswordStore: types.optional(ForgotPasswordStoreModel, {} as any),
        ResetPasswordStore: types.optional(ResetPasswordStoreModel, {} as any),
        ContactStore: types.optional(ContactStoreModel, {} as any),
        AuthHomeStore: types.optional(AuthenticatedHomeStoreModel, {} as any),
        ReviewStore: types.optional(ReviewStoreModel, {} as any),
        AddOfferStore: types.optional(AddOfferStoreModel, {} as any),
        AddProductStore: types.optional(AddProductStoreModel, {} as any),
        AccountSettingStore: types.optional(AccountSettingStoreModel, {} as any),
        ChatStore: types.optional(ChatStoreModel, {} as any),
        NotificationStore: types.optional(NotificationStoreModel, {} as any),
    })
    .views((self) => ({
        resetStores() {
            self.authenticationStore.reset();
            self.ProductListStore.reset();
            self.ChatStore.reset();
            self.NotificationStore.reset();
        }
    }));   

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}
/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
