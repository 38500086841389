import React, { FC,useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Alert, Linking, Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { Button, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, ChevronDownIcon, HStack, Icon, InfoIcon, Input, SearchIcon, Stack, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../theme"
import { Avatar, ListItem } from "@rneui/base"
import { useNavigation } from "@react-navigation/native"
import { ApiResponse } from "apisauce"
import { api } from "../../services/api"
import WebView from "react-native-webview"
import { useStores } from "../../models"

// import { useNavigation } from "@react-navigation/native"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `GDPRPolicy: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="GDPRPolicy" component={GDPRPolicyScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const GDPRPolicyScreen: FC<StackScreenProps<AppStackScreenProps, "GDPRPolicy">> = observer(function GDPRPolicyScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated} } = useStores()

  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  const [data, setData] = useState<any>()
  
  useEffect(()=>{
    api.apisauce.get("pages/?type=pages.FlexPage&slug=gdpr-policy&fields=*")
    .then(
      (response:ApiResponse<any,any>)=>{
        setData(response.data?.items[0])
        console.log(response)
      }
    )
  },[])

  // open gmail on click get in touch button
  const handleEmailPress = () => {
    const email = 'piyush@technoace.in';
    const url = `mailto:${email}`;

    Linking.canOpenURL(url)
      .then(supported => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          console.log('Mail application is not available');
        }
      })
      .catch(error => console.log(error));
  };

  const WebCode =()=>{
    return(
    <Screen style={$root} preset="scroll">
      <PageLayout variant={isAuthenticated?1:0}>        
        <VStack w="100%" p={{base: 5, md:10}} pb={100} space={{base: 5, md:10}} 
        background={colors.palette.primary100}            
        >
        <Text
            style={{
            color:colors.palette.neutral600, 
            fontFamily:typography.fonts.poppins.bold,
            fontSize:40,              
            alignSelf:"center"
            }}
        >{data?.title || "Loading..."}</Text>
        <Text
            style={{
            color:colors.palette.secondary300, 
            fontFamily:typography.fonts.poppins.bold,
            fontSize: useBreakpointValue({base: 24,sm: 35,md:48}),              
            alignSelf:"center"
            }}
        >{data?.subtitle || "Loading..."}</Text>
        <Box backgroundColor="white" padding={50} borderRadius={15}>

            { data?.content.map((content,index):any=>{                
                if (content.type === "richtext_block"){
                    console.log(content)
                    return (
                        Platform.OS === 'web'?
                        <div dangerouslySetInnerHTML={{ __html: content.value }} style={{fontFamily:typography.fonts.poppins.light}}/>
                        : <WebView
                            originWhitelist={['*']}
                            source={{html:content.value}}
                        />
                    )
                }
                else{
                    return <Text>{content.type} Block not supported as of now. Please contact development team.</Text>
                }

            })
                
            }
        </Box>                 
        </VStack>
      </PageLayout>
    </Screen>
    )
  }
  
  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
    </Screen>
    )
  }
  
  
  return (
  <>
    {/* divert code for web and android here  */}
    {Platform.select({ 
        native : <MobileCode/>, //code for Mobile devices
        default : <WebCode/>, //code for web and other platforms
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 
           

const $root: ViewStyle = {
  flex: 1,
}