import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../helpers/withSetPropAction"

/**
 * Model description here for TypeScript hints.
 */
export const ContactStoreModel = types
  .model("ContactStore")
  .props({
    name: '',
    email: '',
    msg: '',
  })
  .actions(withSetPropAction)
  .views((self) => ({
    get nameValidationError(){
      if (self.name?.length === 0) return "Name cannot be blank"
      return ""
    },
    get emailValidationError() {
      if (self.email?.length === 0) return "Email can't be blank"
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(self.email)) return "Must be a valid email address"
      return ""
    },
    get msgValidationError(){
      if (self.msg?.length === 0) return "Message cannot be blank"
      return ""
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setName(value: string) {
      self.name = value
    },
    setEmail(value: string) {
      self.email = value.toLocaleLowerCase().trim()
    },
    setMsg(value: string) {
      self.msg = value
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface ContactStore extends Instance<typeof ContactStoreModel> {}
export interface ContactStoreSnapshotOut extends SnapshotOut<typeof ContactStoreModel> {}
export interface ContactStoreSnapshotIn extends SnapshotIn<typeof ContactStoreModel> {}
export const createContactStoreDefaultModel = () => types.optional(ContactStoreModel, {})
