import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, HStack, Image, Pressable, ScrollView, Stack, useBreakpointValue, View, VStack } from "native-base"
import { Feather, Ionicons } from "@expo/vector-icons"
import { colors, typography } from "../../theme"
import { useNavigation, useRoute } from "@react-navigation/native"
import { WantToAddYourOfferCard } from "./AuthenticatedHomeScreen"
import { useStores } from "../../models" 
import { api } from "../../services/api"
import Config from "../../config"
import { Linking } from "react-native"
import { AlertContext } from "../../components/AlertContext"

// @ts-ignore
export const ProductDescriptionScreen: FC<StackScreenProps<AppStackScreenProps, "ProductDescription">> = observer(function ProductDescriptionScreen() {

  const route = useRoute<any>()

  // Pull in one of our MST stores
  const { authenticationStore: {isAuthenticated} } = useStores()
  const [ product, setProduct] = useState<any>()
  
  useEffect(()=>{
    api.apisauce.get(`products/${route.params && route.params.productId}/`)
    .then((result)=>{
      setProduct(result.data)
    })
    .catch((error)=>{
      console.log(error.message)
    })
  },[isAuthenticated])

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const { showAlert } = useContext(AlertContext);
  
  return (
    <>    
    {/* divert code for web and android here  */}    
     {Platform.select({ 
      //code for Web
      default : 
        <Screen style={$root} preset="scroll">
          <PageLayout variant={isAuthenticated? 1:0}>
            <Stack w="100%" mb={100}>
              {/* Parent VStack */}
              <VStack space={8} ml={{base: 5, sm: 10, lg: 20}} mr={{base: 5, sm: 10, lg: 20}} mt={5}>
                {/* go back and breadcrum */}
                <VStack>
                  <HStack width="100%" alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600} width="8.5%"/>
                    </Pressable>
                    <View style={{width: '91.5%'}}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.bold,
                          fontSize: 40,
                          lineHeight: 48,
                          color: colors.palette.neutral600,
                          textAlign: 'center',
                        }}
                        >
                          {product && product.brand && product.brand.name}
                      </Text>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.bold,
                          fontSize: 16,
                          lineHeight: 18,
                          color: colors.palette.neutral600,
                          textAlign: 'center',
                        }}
                        >
                          (Brand Name)
                      </Text>
                    </View>
                  </HStack>
                </VStack>
    
                {/* product and description */}
                <Stack direction={{base: 'column',md: 'row'}} space={5}>
                  {/*- product */}
                  <AutoImage
                    source={{uri: product && product.thumbnail}}
                    maxHeight={600}
                    style={{ width: useBreakpointValue({base: "100%", md:"50%"}) }}
                    resizeMode="contain"
                  />
                  {/*- description */}
                  <VStack space={5} p={5} pt={10} pb={10} w={{base: "100%", md:"50%"}}>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: 24,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700,
                      }}
                      >
                        {product && product.name}
                    </Text>
                    {/* general part */}
                    <VStack space={2}>
                      <HStack>
                        <Text 
                          style={{
                            width: '150px',
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral500,
                          }}
                          >
                            Category:
                        </Text>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral500,
                          }}
                          >
                            {product?.category?.name}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text 
                          style={{
                            width: '150px',
                            fontFamily: typography.fonts.poppins.medium,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.angry100,
                          }}
                          >
                            Expiry:
                        </Text>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.medium,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.angry100,
                          }}
                          >
                            {/* 8-12 Months */}
                            {product?.expiry_month_range ? `${product.expiry_month_range && product.expiry_month_range[0]}-${product.expiry_month_range && product.expiry_month_range[1]} Months`:"No offer added"}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text 
                          style={{
                            width: '150px',
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral500,
                          }}
                          >
                            Sellers:
                        </Text>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral500,
                          }}
                          >
                            {product?.unique_seller_count? product?.unique_seller_count: "0 Sellers - "}
                            <Pressable onPress={()=>{
                              isAuthenticated 
                                ? navigation.navigate("AddNewOffer", {'productId': product?.id}) 
                                : showAlert('Error', "You need to Login first to sell products.")
                              }}
                              >
                              <Text style={{color:"blue"}}> (Start selling)</Text>
                            </Pressable>
                        </Text>
                      </HStack>
                    </VStack>
                    {/* description part */}
                    <VStack space={3}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.medium,
                          fontSize: 18,
                          lineHeight: 22,
                          color: colors.palette.neutral800,
                        }}
                        >
                          Description
                      </Text>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.normal,
                          fontSize: 18,
                          lineHeight: 20,
                          color: colors.palette.neutral400,
                        }}
                        >
                          {product?.short_description}
                      </Text>
                    </VStack>
                    {/* advanced part */}
                    <VStack space={3}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.medium,
                          fontSize: 18,
                          lineHeight: 22,
                          color: colors.palette.neutral800,
                        }}
                        >
                          Advanced
                      </Text>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.normal,
                          fontSize: 18,
                          lineHeight: 20,
                          color: colors.palette.neutral400,
                        }}
                        >
                          {product?.long_description}
                      </Text>
                    </VStack>
                  </VStack>
                </Stack>
    
                {/* Available Offers */}
                <VStack space={5}>
                  <Text 
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                      color: colors.palette.neutral700,
                    }}
                    >
                    Available Offers
                  </Text>
                  
                  {/* want to add your offer card and offers card section */}
                  {product?.unique_seller_count ?
                    <HStack flexWrap={'wrap'} justifyContent={{base:'center', md:'left'}}>
                      <Box width={{base: '96%',md: '45%',lg:'22%'}} margin={4}>
                        <WantToAddYourOfferCard cardWidth={"100%"} cardHeight={550} productId={product && product.id}/>
                      </Box>
                      {product?.offers?.map((offer,index)=>(
                        <SellerCard 
                          key={`productDesc-offerCard-x1-${index}`} 
                          {...offer}
                        />
                      ))}                    
                    </HStack>
                  : 
                    <VStack>
                    <Pressable w={"100%"} onPress={()=>{navigation.navigate("AddNewOffer", {productId: product?.id})}}>
                        <Image
                          source={require(`../../../assets/images/product_description_screen/no_offer_empty_screen.png`)}
                          style={{ width: "100%" , height: 300, borderColor: colors.palette.neutral100, borderWidth: 2}}
                          resizeMode="contain"
                          alt="image"
                          rounded={5}
                        />
                      </Pressable>
                    </VStack>
                }
                </VStack>
    
                {/* Sell Your Product section */}
                <Stack direction={{base: 'column', md: 'row'}} w={{base: '100%',md:'70%'}} mb={5} p={5} style={{ backgroundColor: colors.palette.primary200, alignSelf: 'center', justifyContent: 'center' }}>
                  
                  <VStack space={2} w={{base: '100%',md:'75%'}} pl={{base: 0, md: 5, lg: 10}} justifyContent={"center"}>
                    <Text
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: 24,
                        lineHeight: 28.8,
                        color: colors.palette.neutral600,
                      }}
                    >
                      Do you want to add your offer?
                    </Text>
    
                    <Text
                      style={{
                        fontFamily: typography.fonts.poppins.normal,
                        color: colors.palette.neutral400,
                        fontSize: 16,
                        lineHeight: 24,
                      }}
                    >
                      We will get you wings. Add your offers to the product and we will save you a great time and money.
                    </Text>
                  </VStack>
                    <Pressable w={{base: '50%',md:'25%'}} justifyContent={"center"} alignItems={"center"} 
                      onPress={()=>{
                        isAuthenticated 
                          ? navigation.navigate("AddNewOffer", {'productId': route.params.productId}) 
                          : showAlert('Error', "You need to Login first to sell products.")
                        }}>
                      <AutoImage
                        source={require(`../../../assets/images/product_description_screen/Sell_btn.png`)}
                        maxHeight={100}
                        style={{ width: "50%" }}
                        resizeMode="contain"
                      />
                    </Pressable>
                </Stack>    
              </VStack>
            </Stack>
          </PageLayout>
        </Screen>, 
    
      //code for Mobile
        native : 
        <>
          <PageLayout  variant={isAuthenticated?1:0}>
            <ScrollView>
              <Stack w="100%" bg={'white'} pl={3} pr={3} pt={5} pb={100}>
                {/* Parent VStack */}
                <VStack space={3}>
                  {/* go back */}
                  <HStack space={2} alignItems="center">
                    <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                    </Pressable>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: 18,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700,
                      }}
                      >
                        {product && product.name}
                    </Text>
                  </HStack>

                  {/* product and description */}
                  <Stack direction={'row'} space={3}>
                    {/*- product */}
                    <Image
                      source={{uri: product && product.thumbnail}}
                      style={{ width: "50%" , height: 200}}
                      resizeMode="contain"
                      alt="image"
                    />
                    {/*- description */}
                    <VStack space={5} pt={6} pb={10} w={"50%"}>

                      {/* general part */}
                      <VStack space={2}>
                        <HStack space={2}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral500,
                            }}
                            >
                              Category:
                          </Text>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral500,
                            }}
                            >
                              {product?.category?.name}
                          </Text>
                        </HStack>
                        <HStack space={2}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.medium,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.angry100,
                            }}
                            >
                              Expiry:
                          </Text>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.medium,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.angry100,
                            }}
                            >
                              {product && product.expiry_month_range ? `${product.expiry_month_range && product.expiry_month_range[0]}-${product.expiry_month_range && product.expiry_month_range[1]} Months`:"Not Applicable"}
                          </Text>
                        </HStack>
                        <HStack space={2}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral500,
                            }}
                            >
                              Sellers:
                          </Text>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.neutral500,
                            }}
                            >
                              {product?.unique_seller_count ? product.unique_seller_count : 0}
                          </Text>
                        </HStack>
                        {product && product.offers_count === 0 && 
                          <Pressable onPress={()=>
                          isAuthenticated 
                                ? navigation.navigate("AddNewOffer", {'productId': product?.id}) 
                                : showAlert('Error', "You need to Login first to sell products.")
                          }>
                            <Text style={{color:"blue"}}> (Start selling)</Text>
                        </Pressable>}
                      </VStack>

                    </VStack>
                  </Stack>
                  
                  {/* Available offers */}
                  <VStack space={5}>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: 20,
                        lineHeight: 22,
                        color: colors.palette.neutral800,
                      }}
                      >
                        Available Offers
                    </Text>
                    <Box>
                      {product && product.offers && product.offers.map((offer, index) => (
                        <Box key={`available_offers_items_${offer.id}`}> 
                          <AvailableOffersCard 
                            key={`productDesc-availOffer-x1-${index}`}
                            {...offer}
                          />
                        </Box>
                      ))}
                    </Box>
                  </VStack>

                  {/* want to add your offer here? section */}
                  <Stack direction={'row'} w={"100%"} mb={5} p={3} rounded={10} style={{ backgroundColor: colors.palette.primary300, alignSelf: 'center', justifyContent: 'center' }}>
                    <VStack w={"70%"} justifyContent={"center"}>
                      <Text
                        style={{
                          fontFamily: typography.fonts.poppins.bold,
                          fontSize: 18,
                          lineHeight: 28.8,
                          color: colors.palette.neutral100,
                        }}
                      >
                        Do you want to add your offer?
                      </Text>
                    </VStack>
                    <Box w={"30%"} justifyContent={"center"} alignItems={"center"}>
                      <Pressable w={"100%"} onPress={()=>{navigation.navigate("AddNewOffer", {productId: product?.id})}}>
                        <Image
                          source={require(`../../../assets/images/product_description_screen/Sell_btn.png`)}
                          style={{ width: "100%" , height: 40, borderColor: colors.palette.neutral100, borderWidth: 2}}
                          resizeMode="contain"
                          alt="image"
                          rounded={5}
                        />
                      </Pressable>
                    </Box>
                  </Stack>                  
                </VStack>
              </Stack>
            </ScrollView>
          </PageLayout>
        </>
      })}
    {/* Platform select close here  */}
    </> 
  )    
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}

/**
 * Seller Card to be used on Product description page for Available Offers section
 */
const SellerCard =(props) =>{
  
  const navigation = useNavigation<any>()

  return (
    <VStack 
      w={useBreakpointValue({base: '96%',md: '45%',lg:'22%'})}
      minHeight={550} 
      style={{
      shadowColor: 'black',
      shadowOffset: {width: 1, height: 2},
      shadowOpacity: 0.1,
      shadowRadius: 15}}
      margin={4}
      >
      {/* image Part */}
      <VStack flex={'65%'} space={2} p={3} alignItems={'center'} justifyContent={'center'} style={{backgroundColor: colors.palette.primary100}}>
        {props.seller.user.avatar ? 
          <AutoImage 
            source={{uri: Config.MEDIA_URL+props.seller.user.avatar}}
            maxHeight={150} style={{ alignSelf: "center", width: 150, height: 150, borderRadius: 150/2}}
          />
          :
          <AutoImage
            source={require('../../../assets/images/user_profile.png')}
            maxHeight={140}
            style={{ width: 140, borderRadius: 140/2, marginTop: -50, borderColor: colors.palette.secondary300, borderWidth: 5}}
          />
        }        
        <Text 
          style={{
            fontFamily: typography.fonts.poppins.medium,
            fontSize: 18,
            lineHeight: 22,
            color: colors.palette.neutral800,
          }}
          >
          {props?.seller?.user?.first_name} {props?.seller?.user?.last_name}
        </Text>
        <Pressable onPress={()=>{
          navigation.navigate('SellerProfile', {sellerId:props?.seller?.id})
        }}>
          <Text 
            style={{
              fontFamily: typography.fonts.poppins.normal,
              fontSize: 16,
              lineHeight: 24,
              color: colors.palette.neutral500,
            }}
            >
            ({props?.seller?.business_name})
          </Text>
        </Pressable>
        <Text 
          style={{
            fontFamily: typography.fonts.poppins.normal,
            fontSize: 16,
            lineHeight: 24,
            color: colors.palette.neutral500,
            textAlign: 'center'
          }}
          >
          {props?.seller?.business_address}
        </Text>
        <HStack m={2} space={10} alignItems={'center'} justifyContent={'center'} >
          <Pressable onPress={()=>Linking.openURL(`tel://${props.seller.user.mobile}`)}>
            <Feather name="phone" size={30} color="black" />
          </Pressable>
          <Pressable onPress={()=>Linking.openURL(`https://wa.me/${props?.seller?.user?.mobile}`)}>
            <Ionicons name="logo-whatsapp" size={30} color="black" />
          </Pressable>
        </HStack>
      </VStack>
      {/* content part */}
      <VStack space={3} p={5} flex={'35%'}>
        <HStack space={3} alignItems="center">   
          <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral500 }}>Unit MRP:</Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral600 }}>Rs. {props?.price}</Text>
          <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.accent100 }}>{props?.percentage_off}%off</Text>
        </HStack> 
        <HStack space={3} alignItems="center">   
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral500 }}>Offer:</Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral600 }}>Rs. {props?.offer_price}</Text>
        </HStack>
        <HStack space={3} alignItems="center">   
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.angry100 }}>Expiry: </Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.angry100 }}>{props?.expiry_date}</Text>
        </HStack>
        <HStack space={3} alignItems="center">    
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral500 }}>Mfg Date: </Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 16, lineHeight: 24, color: colors.palette.neutral500 }}>{props?.manufacturing_date}</Text>
        </HStack>
      </VStack>

    </VStack>

  )
}

/**
 * Available offers card to be used on Product description page for mobile devices
 */
const AvailableOffersCard =(props) =>{

  const navigation = useNavigation<any>()

  return (
    <HStack 
      key={props.sellerId} 
      space={2} p={3} mb={3} 
      rounded={10} 
      style={{
        borderColor: colors.palette.neutral1200, 
        borderWidth: 1.5
      }}
    >
      {/* Seller Image */}
      {props.seller.user.avatar ? 
          <Image
            source={{uri: Config.MEDIA_URL+props.seller.user.avatar}}
            rounded={10}
            style={{ width: "30%" , height: 140}}
            alt="image"
          />
          :
          <Image
            source={require('../../../assets/images/user_profile.png')}            
            rounded={10}
            style={{ width: "30%" , height: 140}}
            alt="image"
          />
        }   
      
      <VStack space={1} w={"60%"}>
        <Pressable onPress={()=>{
          navigation.navigate('SellerProfile', {sellerId:props?.seller?.id})
        }}>
          <Text 
            style={{
              fontFamily: typography.fonts.poppins.medium,
              fontSize: 14,
              lineHeight: 22,
              color: colors.palette.neutral800,
              marginBottom: 7
            }}
            >
            {props?.seller?.user?.first_name} {props?.seller?.user?.last_name}
          </Text>
        </Pressable>
        <HStack space={2} alignItems="center">   
          <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.neutral500 }}>Unit MRP:</Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.neutral600 }}>Rs. {props?.price}</Text>
          <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.accent100 }}>{props?.percentage_off}%off</Text>
        </HStack> 
        <HStack space={2 } alignItems="center">   
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.neutral500 }}>Offer Price:</Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.neutral600 }}>Rs. {props?.offer_price}</Text>
        </HStack>
        <HStack space={2} alignItems="center">   
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.angry100 }}>Expiry: </Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.angry100 }}>{props?.expiry_date}</Text>
        </HStack>
        <HStack space={2} alignItems="center">    
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.neutral500 }}>Mfg Date: </Text>
          <Text style={{ fontFamily: typography.fonts.poppins.normal, fontSize: 13, lineHeight: 24, color: colors.palette.neutral500 }}>{props?.manufacturing_date}</Text>
        </HStack>
      </VStack>
      <VStack space={5} m={-3} mr={-5} style={{borderLeftColor: colors.palette.neutral1200, borderLeftWidth: 1.5, justifyContent: 'center', alignItems: 'center'}}>
        <Pressable onPress={()=>Linking.openURL(`tel://${props?.seller?.user?.mobile}`)}>
          <Feather name="phone" size={25} color={colors.palette.gray400} style={{padding: 6}}/>
        </Pressable>
        <Pressable onPress={()=>Linking.openURL(`https://wa.me/${props?.seller?.user?.mobile}`)}>
          <Ionicons name="logo-whatsapp" size={25} color={colors.palette.gray400} style={{padding: 6}}/>
        </Pressable>        
      </VStack>
    </HStack>
  )
}
