import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Alert, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen } from "../../components"
import { Box, Text, HStack, Pressable, ScrollView, Stack, VStack, useBreakpointValue, Image, Divider } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import { colors, typography } from "../../theme"
import { PageLayout } from "../../components/PageLayout"
import { useNavigation } from "@react-navigation/native"
import { MedicineCard } from "./AuthenticatedHomeScreen"
import { Platform } from "react-native"
import { useStores } from "../../models"
import Config from "../../config"
import { OverlayContext } from "../../components/OverlayProvider"
import { JumpingTransition } from "react-native-reanimated"
import { Product } from "../../components/Product"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Wishlist: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Wishlist" component={WishlistScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const WishlistScreen: FC<StackScreenProps<AppStackScreenProps, "Wishlist">> = observer(function WishlistScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated},
    ProductListStore: {fetchWishlist ,wishlist,},
    AddProductStore: {isWishlisted, setIsWishlisted}
  
  } = useStores()

  // const [list, setList] = useState<any>([])
  const { setOverlayVisible} = useContext(OverlayContext)

  useEffect(() =>{    
    fetchWishlist(setOverlayVisible);
  },[])

  const buttonSizeBreakpoint = useBreakpointValue({base: '40%', md: '18%', lg: '15%'})
  const medicineImageWidthBreakpoint = useBreakpointValue({base: '85%', sm: '70%',md: '75%',xl:"85%"});
  const fontSizeBreakpoint = useBreakpointValue({base: 18, sm: 14, md: 16, lg: 18})

  const [isWishlistEmpty, setIsWishlistEmpty] = useState(wishlist?.length === 0);

  // Pull in navigation via hook
  const navigation = useNavigation<any>() 
  
  return (
    <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
      //code for Web ------------------->
      default : 
        <Screen style={$root} preset="scroll">
          <PageLayout  variant={isAuthenticated?1:0}>
            <Stack w="100%" mb={100}>                        
              {/* Parent VStack */}
              <VStack space={8} pl={'3vw'} pr={'3vw'} pt={'4vw'} pb={20}>
                {/* go back */}
                <HStack space={2} alignItems="center">
                  <Pressable onPress={() => navigation.goBack()}>
                    <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                  </Pressable>
                  <Text 
                    style={{
                      fontFamily: typography.fonts.poppins.bold,
                      fontSize: 24,
                      lineHeight: 28.8,
                      color: colors.palette.neutral700,
                    }}
                    >
                      Wishlist 
                      {
                      wishlist?.length > 0 && <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: 24,
                          lineHeight: 28.8,
                          color: colors.palette.neutral400,
                        }}
                        > ({wishlist?.length}) </Text>
                      }
                  </Text>
                </HStack>
                {
                  wishlist?.length===0  ? 
                  <NoWishlist buttonSizeBreakpoint={buttonSizeBreakpoint}/>
                  :
                  <HStack 
                    flexWrap='wrap' 
                    space={5} 
                    justifyContent={{base:"center", md:"left"}}
                  >
                    {wishlist?.map((medicine,index)=>{                      
                      return (                        
                        <Product product={medicine.product}/>
                    )}
                    ) } 
                  </HStack>
                }

              </VStack>
            </Stack>
          </PageLayout>
        </Screen>, 

      //code for Mobile ------------------->
        native : 
        <PageLayout variant={1}>
          <ScrollView>
            <Stack w="100%" bg={'white'} pl={3} pr={3} pt={5} pb={100}>
              {/* Parent VStack */}
              <VStack space={8}>
                {/* go back */}
                <HStack space={2} alignItems="center">
                  <Pressable onPress={() => navigation.goBack()}>
                    <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                  </Pressable>
                  <Text 
                    style={{
                      fontFamily: typography.fonts.poppins.bold,
                      fontSize: 18,
                      lineHeight: 24,
                      color: colors.palette.neutral700,
                    }}
                    >
                      Wishlist 
                      {
                      isWishlistEmpty !== true && <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: 18,
                          lineHeight: 24,
                          color: colors.palette.neutral400,
                        }}
                        > ({wishlist?.length}) </Text>
                      }
                  </Text>
                </HStack>
                {
                  isWishlistEmpty ? 
                  <NoWishlist buttonSizeBreakpoint={200}/>
                  :
                  <HStack flexWrap='wrap'>
                    { wishlist?.map((medicine,index)=>(
                      <Box key={`wishlist_items_1_${index}`} maxH={330} mr={5} mb={5} w={'49%'} style={{borderColor: '#F7F6F7', borderWidth: 1}}>
                        <MedicineCard
                          key={`wishList-medCard-x2-${index}`}
                          medicinePhoto={{uri: Config?.MEDIA_URL + medicine?.product?.thumbnail}}
                          medicineImageMaxHeight={120}
                          medicineImageWidth={'70%'}
                          cardWidth={"100%"}
                          cardHeight={330}
                          imageContainer={'42%'}
                          contentContainer={'58%'}
                          productId={medicine?.id}
                          tagShow={false}
                          wishlisted={isWishlisted}
                        >
                        {/* {medicine.childrenData} */}
                        <VStack space={ Platform.select({default: 3, native: 0}) }>

                          <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, 
                            fontSize: Platform.select({default: fontSizeBreakpoint, native: 12 }), 
                            lineHeight: Platform.select({default: 22, native: 16 }), 
                            color: colors.palette.neutral800 }}>
                            {/* Triclenz Hair Cleanser 250 ml */}
                            {medicine?.product?.name}
                          </Text>

                          <HStack space={ Platform.select({default: 5, native: 1}) } alignItems="center">
                            <Text style={{ color: colors.palette.neutral300,
                              fontSize: Platform.select({default: 14, native: 11}), fontWeight: Platform.select({default: 'normal', native: 'bold'}) }}>MRP:</Text>
                            <Text style={{ color: colors.palette.primary400, 
                              fontSize: Platform.select({default: 14, native: 11}), textDecorationLine: 'line-through' }}>{medicine?.product?.price_range && medicine?.product?.price_range[0]}</Text>
                            <Text style={{ color: colors.palette.primary400, 
                              fontSize: Platform.select({default: 14, native: 11}) }}>{medicine?.product?.offer_price_range && medicine?.product?.offer_price_range[0]}</Text>
                          </HStack>

                          <HStack space={ Platform.select({default: 5, native: 1}) } alignItems="center">
                            <Text style={{ color: colors.palette.angry100, fontSize: Platform.select({default: 14, native: 11 }), fontWeight: Platform.select({default: 'normal', native: 'bold'}) }}>Expiry:</Text>
                            <Text style={{ color: colors.palette.angry100, fontSize: Platform.select({default: 14, native: 11 }) }}>{medicine?.product?.expiry_date_range && medicine?.product?.expiry_date_range[0]}</Text>
                          </HStack>

                          <HStack space={ Platform.select({default: 5, native: 1}) } alignItems="center">
                            <Text style={{ color: colors.palette.neutral300, fontSize: Platform.select({default: 14, native: 11 }), fontWeight: Platform.select({default: 'normal', native: 'bold'}) }}>Sellers: </Text>
                            <Text style={{ color: colors.palette.primary400, fontSize: Platform.select({default: 14, native: 11 }) }}>{medicine?.product?.unique_seller_count}</Text>
                          </HStack>

                          <Button
                            text="View Offers"
                            preset="default"
                            onPress={() => navigation.navigate("ProductDescription", {'productId':  medicine?.product?.id})}
                            style={[
                              { backgroundColor: colors.palette.primary600 },
                              { borderRadius: 5 },
                              { width: "100%" },
                              { marginTop: 5, marginBottom: 10 },
                            ]}
                            pressedStyle={[
                              { backgroundColor: colors.palette.neutral100 },
                              { borderRadius: 5 },
                              { borderRadius: 5 },
                              { borderWidth: 0 },
                            ]}
                            textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                            pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                          />
                          </VStack>
                        </MedicineCard>
                      </Box>
                    )) } 
                  </HStack>
                }

              </VStack>
            </Stack>
          </ScrollView>
          </PageLayout>
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}


const NoWishlist = ({buttonSizeBreakpoint}) => {
  const navigation = useNavigation<any>()
  return(
    <VStack space={3} mt={ Platform.select({default: 20, native: 0}) } >
        <Image
          source={require(`../../../assets/images/wishlist/wishlist.png`)}
          maxHeight={420}
          style={{ width: "100%", height: Platform.select({default: 420, native: 300}) }}
          resizeMode="contain"
          alt="image"
        />
        <Text 
          style={{
            fontFamily: typography.fonts.poppins.normal,
            fontSize: 16,
            lineHeight: 24 ,
            color: colors.palette.neutral400,
            textAlign: 'center'
          }}
          >
            Your wishlist is empty please add your product. 
        </Text>
        <Button
        text='Browse Product'
        preset="default"
        onPress={() => navigation.navigate("AuthenticatedHome")}
        style={{
          backgroundColor: colors.palette.primary600,
          borderRadius: 5,
          width: buttonSizeBreakpoint,
          marginTop: 5, 
          alignSelf: 'center'
        }}
        pressedStyle={{
          backgroundColor: colors.palette.neutral100,
          borderRadius: 5,
          borderWidth: 0,
      
        }}
        textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
        pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
      />
    </VStack>
    
  )
}