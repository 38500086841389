import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, Divider, HStack, Image, Pressable, ScrollView, Stack, View, VStack } from "native-base"
import { Ionicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import { colors, typography } from "../../theme"
// import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Notifications: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Notifications" component={NotificationsScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const NotificationsScreen: FC<StackScreenProps<AppStackScreenProps, "Notifications">> = observer(function NotificationsScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated}, 
  NotificationStore : {notificationList, fetchNotificationList,markAllRead, seenNotification, unseenNotification}
} = useStores()

  const [isNotificationEmpty, setIsNotificationEmpty] = useState(false);

  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  const { setOverlayVisible } = useContext(OverlayContext);

  // fetchChatList
  useEffect(()=>{
    fetchNotificationList(setOverlayVisible);

  },[])  
  
  console.log("notificationList", [...notificationList], seenNotification(), unseenNotification());
  return (
    <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
      //code for Web ------------------->
      default : 
        <Screen style={$root} preset="scroll">
        <PageLayout  variant={isAuthenticated?1:0}>
          <Stack w="100%" mb={100}>
            {/* Parent VStack */}
            <VStack space={8} ml={{base: 10, lg: 20}} mr={{base: 10, lg: 20}} mt={5}>
              {/* go back */}
              <HStack alignItems="center">
                <Pressable onPress={() => navigation.goBack()}>
                  <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                </Pressable>
                <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.bold,
                    fontSize: 24,
                    lineHeight: 28.8,
                    color: colors.palette.neutral700,
                  }}
                  >
                    Notifications
                </Text>
              </HStack>

              {
                isNotificationEmpty ? 
                <NoNotification/>
                :
                <>
                  {/* You have 3 unread messages. Mark All Read */}
                  <VStack space={1}>
                    <HStack space={3}>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.normal,
                          fontSize: 16,
                          lineHeight: 24,
                          color: colors.palette.neutral400,
                        }}
                        >
                          You have {unseenNotification().length} unread messages. 
                      </Text>
                      <Pressable onPress={()=>{markAllRead(setOverlayVisible); fetchNotificationList(setOverlayVisible);}}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.accent300,
                          }}
                          >
                          Mark All Read
                        </Text>
                      </Pressable>
                    </HStack>
                    <Divider/> 
                  </VStack>

                  {/* New */}
                  <VStack space={2}>
                    {unseenNotification().length != 0 && <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.medium,
                        fontSize: 18,
                        lineHeight: 22,
                        color: colors.palette.neutral800,
                      }}
                      >
                        New 
                    </Text>}
                    {unseenNotification().reverse().map((notification, i)=>{
                      return(
                        <MessageCard msgcontent={notification?.message} date={notification?.date} time={notification?.time} backgroundColor='primary100'/>
                      )
                    })}
                  </VStack>

                  {/* Before That */}
                  <VStack space={2}>
                    <Text 
                      style={{
                        fontFamily: typography.fonts.poppins.medium,
                        fontSize: 18,
                        lineHeight: 22,
                        color: colors.palette.neutral800,
                      }}
                      >
                        Before That 
                    </Text>
                    {seenNotification().reverse().map((notification, i)=>{
                      return(
                        <MessageCard msgcontent={notification?.message} date={notification?.date} time={notification?.time} backgroundColor='neutral100'/>
                      )
                    })}
                  </VStack>
                </>
              }
                
            </VStack>
          </Stack>
        </PageLayout>
        </Screen>, 

      //code for Mobile ------------------->
        native : 
        <>
          <PageLayout variant={1}>
              <ScrollView>
              <Stack w="100%" bg={'white'} pl={3} pr={3} pt={5} pb={100}>
                  {/* Parent VStack */}
                  <VStack space={8}>
                  {/* go back */}
                  <HStack space={2} alignItems="center">
                      <Pressable onPress={() => navigation.goBack()}>
                      <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                      </Pressable>
                      <Text 
                      style={{
                          fontFamily: typography.fonts.poppins.bold,
                          fontSize: 24,
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                      }}
                      >
                          Notifications
                      </Text>
                  </HStack>
                  {
                      isNotificationEmpty ? 
                      <NoNotification/>
                      :
                      <>
                      {/* You have 3 unread messages. Mark All Read */}
                      <VStack space={1}>
                          <HStack space={3}>
                          <Text 
                              style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.neutral400,
                              }}
                              >
                              You have 3 unread messages. 
                          </Text>
                          <Text 
                              style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.accent300,
                              }}
                              >
                              Mark All Read
                          </Text>
                          </HStack>
                          <Divider h={0.5}/> 
                      </VStack>

                      {/* New */}
                      <VStack space={2}>
                          <Text 
                          style={{
                              fontFamily: typography.fonts.poppins.medium,
                              fontSize: 18,
                              lineHeight: 22,
                              color: colors.palette.neutral800,
                          }}
                          >
                              New 
                          </Text>
                          <MessageCard msgcontent='Login Information Updated Successfully.' time='2 minutes ago' backgroundColor='primary100'/>
                      </VStack>

                      {/* Before That */}
                      <VStack space={2}>
                          <Text 
                          style={{
                              fontFamily: typography.fonts.poppins.medium,
                              fontSize: 18,
                              lineHeight: 22,
                              color: colors.palette.neutral800,
                          }}
                          >
                              Before That 
                          </Text>
                          <MessageCard msgcontent='Login Information Updated Successfully.' time='2 minutes ago' backgroundColor='neutral100'/>
                          <MessageCard msgcontent='Login Information Updated Successfully.' time='2 minutes ago'  backgroundColor='neutral100'/>
                          <MessageCard msgcontent='Login Information Updated Successfully.' time='2 minutes ago' backgroundColor='neutral100'/>
                      </VStack>
                      </>
                  }

                  </VStack>
              </Stack>
              </ScrollView>
          </PageLayout>
        </>
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}

const MessageCard = (props) =>{
  return(
    <HStack space={3} p={ Platform.select({default: 6, native: 3}) } minHeight={60} maxHeight={100}
      style={{backgroundColor: colors.palette[props.backgroundColor], 
              alignItems: 'center',
              shadowColor: 'black',
              shadowOffset: {width: 1, height: 2},
              shadowOpacity: 0.1,
              shadowRadius: 15}}>
    <Image
      source={require("../../../assets/images/notification/Icon.png")}
      maxHeight={60}
      style={{ width: 60, height: 60 }}
      resizeMode="contain"
      alt="image"
    />
    <VStack w={'80%'}>
      <Text 
        style={{
          fontFamily: typography.fonts.poppins.normal,
          fontSize: Platform.select({ default: 16, native: 14}),
          lineHeight: 24,
          color: colors.palette.neutral400,
          paddingRight: 3
        }}
        >
          {props.msgcontent}
      </Text>

      <Text 
        style={{
          fontFamily: typography.fonts.poppins.light,
          fontSize: Platform.select({ default: 16, native: 14}),
          lineHeight: 24,
          color: colors.palette.neutral400,
        }}
        >
         { `${props.date} , ${props.time}`}
      </Text>
    </VStack>
  </HStack>
  )
}

const NoNotification = () =>{
  return(
    <VStack space={3} mt={20} mb={20}>
      <Image
        source={require(`../../../assets/images/notification/notification_empty.png`)}
        maxHeight={320}
        style={{ width: "100%" , height: 320}}
        resizeMode="contain"
        alt="image"
      />
      <Text 
        style={{
          fontFamily: typography.fonts.poppins.normal,
          fontSize: 16,
          lineHeight: 24 ,
          color: colors.palette.neutral400,
          textAlign: 'center'
        }}
        >
          No Notification
      </Text>
    </VStack>
  )
}
