import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Alert, ViewStyle, ScrollView, Platform, Pressable, Share } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { PageLayout } from "../../components/PageLayout"
import { AutoImage, Button, Screen  } from "../../components"
import { Box, HStack, Image, Stack, View, VStack, useBreakpointValue, Text } from "native-base"
import { colors, typography } from "../../theme"
import { AntDesign, Entypo } from "@expo/vector-icons"
import {Carousel} from 'react-native-ui-lib';
import { useNavigation } from "@react-navigation/native";

//imports for mobile----->
import { useStores } from "../../models"
import { ProductApi } from "../../services/api/apiFatching/product-api"
import { Api, api } from "../../services/api"
import { OverlayContext } from "../../components/OverlayProvider"
import Config from "../../config"
import { AlertContext } from "../../components/AlertContext"
import { AddUserRequirementForm } from "../../components/AddUserRequirementForm"

// @ts-ignore
export const AuthenticatedHomeScreen: FC<StackScreenProps<AppStackScreenProps, "AuthenticatedHome">> = observer(function AuthenticatedHomeScreen() {
  // Pull in one of our MST stores
  const { 
    authenticationStore: {isAuthenticated},
    ProductListStore: {
      products, 
      dealOfTheDayProducts,
      categories,
      brands
    },
    AuthHomeStore: {
      requirement, setRequirement,
      quantityValidationError,
      mobileValidationError,
      cityValidationError,
      userValidationError,
      product_nameValidationError
    }
   } = useStores()
  
  const zeroRequirement = {
    product_name: "",
    user: "",
    city: "",
    mobile: "",
    quantity: "",
  }

  useEffect(()=>{
    setRequirement(zeroRequirement)
  },[])

  // USER REQUIREMENT FORM FIELDS
  const {  setOverlayVisible } = useContext(OverlayContext);
  const { showAlert } = useContext(AlertContext);

  // SUBMTI USER REQUIREMENT FUNCTION
  const submitUserRequirement = () => {
    const payload = {...requirement, quantity: parseInt(requirement?.quantity)};
    setRequirement({...zeroRequirement})
    const productApi = new ProductApi(api);    
    productApi?.addNewProductRequirement(payload, setOverlayVisible, showAlert)
  }
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  //user requirement validation error ----->
  const productNameError = isSubmitted ? product_nameValidationError : ""
  const userError = isSubmitted ? userValidationError : ""
  const cityError = isSubmitted ? cityValidationError : ""
  const mobileError = isSubmitted ? mobileValidationError : ""
  const quantityError = isSubmitted ? quantityValidationError : ""

  // user requirement form submission
  function submitForm() {
    setIsSubmitted(true)
    console.log('submitForm',userError, quantityError, mobileError, cityError,productNameError)
    //if there is something returned form validation error then return from here and do not call login api
    if (product_nameValidationError) return 
    if (userValidationError) return 
    if (cityValidationError) return 
    if (mobileValidationError) return 
    if (quantityValidationError) return 

    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token.
    submitUserRequirement();

    setIsSubmitted(false)
  }

  const stopSpecialOfferLoopAbove = useBreakpointValue({
    base: 2,
    md: 4,
    xl: 6
  })
  const showAbovelgScreen = useBreakpointValue({
    base: false,
    lg: true
  }) 
  const specialOfferMedicineCardWidht = useBreakpointValue({base:'96%', sm: '46.5%', xl: '30.5%'})
  const allProductMedicineCardWidht = useBreakpointValue({base: '100%',sm: '49%', lg: '31%'})
  const allProductmedicineImageMaxHeight = useBreakpointValue({base: 200 ,sm: 170, lg: 200})
  const allProductmedicineImageContainer = useBreakpointValue({base: "55%" ,sm: "45%", md: '50%',lg: "55%"})
  const allProductmedicinecontentContainer = useBreakpointValue({base: "45%" ,sm: "55%",md: '50%', lg: "45%"})


const [categoryCard_mobile_Data, setCategoryCard_mobile_Data] = useState([
    {
    itemId: 1,
    categoryName: 'Capsule',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Capsules.png`)
  },
  {
    itemId: 2,
    categoryName: 'Syrup',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Syrup.png`)
  },
  {
    itemId: 3,
    categoryName: 'Tablets',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Tablets.png`)
  },
  {
    itemId: 4,
    categoryName: 'Lotion',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Lotion_2.png`)
  },
  {
    itemId: 5,
    categoryName: 'Injection',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Injections.png`)
  },
  {
    itemId: 6,
    categoryName: 'Drip',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Drip.png`)
  },
  {
    itemId: 7,
    categoryName: 'Drops',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Drops_2.png`)
  },
  {
    itemId: 8,
    categoryName: 'Powder',
    categoryPhoto:  require(`../../../assets/images/authenticated_Home_screen/Mobile/Powder.png`)
  }
]);

const [serviceCardData, setServiceCardData] = useState([
      {itemId: 1, backgroundColor: 'primary300', headingColor: 'neutral100', photo:  require(`../../../assets/images/authenticated_Home_screen/Sell.png`), heading: 'SELL', content: 'Efficient pharmaceutical product reselling services for generic medicines, ensuring minimal losses.'},
      {itemId: 2, backgroundColor: 'primary100', headingColor: 'neutral400', photo: require(`../../../assets/images/authenticated_Home_screen/Buy.png`), heading: 'BUY', content: 'Exclusive benefits: Buy genuine pharmaceutical near expiry generic medicines directly from authorized manufacturers and distributors.'},
  ]);
 
  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  return (
    <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
      //code for Web ------------------->
      default : 
        <Screen style={[$root, { backgroundColor: colors.palette.accent400 }]} preset="scroll">
         <PageLayout  variant={isAuthenticated?1:0}>
            <Stack w="100%" mb={100}>
              <Stack minHeight={useBreakpointValue({base: '25vw',md: '20vw',lg: 300})}>
                {/* Banner Image */}  
                <AutoImage                  
                  source={require("../../../assets/images/landing_screen/banner_background.png")}
                  maxHeight={
                    useBreakpointValue({base: '25vw',md: '20vw',lg: 300})
                  } 
                  style={{width:"100%"}}
                />
                <AutoImage                  
                  source={require("../../../assets/images/authenticated_Home_screen/banner_background.png")}
                  maxHeight={
                    useBreakpointValue({base: '25vw',md: '20vw',lg: 300})
                  } 
                  style={{width:"100%", position:"absolute"}}
                />
                {/* Banner Capsule */}
                <AutoImage 
                    source={require("../../../assets/images/landing_screen/banner_Capsule.png")} 
                    maxHeight={360} style={{
                      width: useBreakpointValue({base:'40vw',xl: 500}),
                      height: useBreakpointValue({base:'20vw',xl: 300}),
                      position:"absolute", left: 0, zIndex: 1}}
                  />
                  {/* Banner photo */}
                  <AutoImage 
                      maxHeight={250} 
                      resizeMode="contain"
                      source={require("../../../assets/images/authenticated_Home_screen/home_banner.png")} 
                      style={{                        
                        width: useBreakpointValue({base:'40vw',xl: 500}),
                        height: useBreakpointValue({base:'20vw',xl: 250}),
                        position:"absolute", left: useBreakpointValue({base: '60%', xl: '65%'}), top: 15
                      }}
                    />
                {/* Banner Text */}
                <VStack position="absolute" h={
                  useBreakpointValue({base: '23vw',md: '20vw',lg: 260})} 
                  ml={{base: 5,sm: 15, md: 20}} justifyContent="center" zIndex={2}> 
                  <HStack w="50vw" space={2}>
                    <Text
                      style={{
                        color:colors.palette.primary600, 
                        fontFamily:typography.fonts.poppins.bold,
                        fontSize: useBreakpointValue({base: 18,sm: 30,md: 40,lg: 60}),
                        lineHeight: useBreakpointValue({base: 25,sm: 40,md: 50,lg: 72})
                      }}
                    >OPERATION</Text>
                    {/* </Pressable> */}
                    <Text
                      style={{
                        color:colors.palette.secondary300, 
                        fontFamily:typography.fonts.poppins.bold,
                        fontSize: useBreakpointValue({base: 18,sm: 30,md: 40,lg: 60}),
                        lineHeight: useBreakpointValue({base: 25,sm: 40,md: 50,lg: 72})
                      }}
                    >PROUD</Text>                    
                  </HStack>      
                  <Text
                    style={{
                      color:colors.palette.primary600, 
                      fontFamily:typography.fonts.poppins.bold,
                      fontSize: useBreakpointValue({base: 18,sm: 30,md: 40,lg: 60}),
                      lineHeight: useBreakpointValue({base: 25,sm: 40,md: 50,lg: 72})             
                    }}
                  >INDIA</Text>             
                  <Box w={'100%'}>
                    <Text
                      style={{
                        color:colors.palette.secondary300, 
                        fontFamily:typography.fonts.poppins.medium,
                        fontSize: useBreakpointValue({base: 10,sm: 12, md: 20}),
                        lineHeight: useBreakpointValue({base: 19,sm: 24, md: 30}),
                      }}
                    >"Programme on removal of unused drugs"</Text>
                  </Box>                  
                </VStack>
              </Stack>

              {/* Banner bottom callout */}
              <Box background={colors.palette.primary400} p={2}>
                <Text 
                  style={{
                    alignSelf:"center",
                    color:colors.palette.neutral100, 
                    fontFamily:typography.fonts.poppins.bold,
                    fontSize: useBreakpointValue({base: 16,sm: 24,md: 38,lg: 50, xl: 60}),
                    lineHeight: useBreakpointValue({base: 25,sm: 30,md: 50,lg: 62, xl: 72}) 
                  }}
                >
                  SAVE MONEY! SELL IT BEFORE IT EXPIRE
                </Text>
              </Box>
            
              {/* Parent VStack */}
              <VStack space={{base: 4, md: 8}} ml={'5vw'} mr={'5vw'} mt={5}>
                {/* categories carousal */}
                <HStack space={10}>
                  {/* loop categories card  */}
                  <ScrollView showsVerticalScrollIndicator={true}>
                    <Carousel animated={true} autoplay={true} pageWidth={300} loop={true} onChangePage={(pageIndex, oldPageIndex, info) => {console.log('pageIndex, oldPageIndex, info', pageIndex, oldPageIndex, info)}}>
                    {categories?.map((category:any, index )=> {
                        // console.log('category', category)
                        return ( 
                          <Pressable key={`authHome-categories-card${index}`} onPress={()=>navigation.navigate("Products", {'categoryName': category?.name})}>
                            <CategoryCard 
                              key={`authHome-categories-card${index}`} 
                              itemId={category?.id} 
                              categoryName={category?.name} 
                              categoryPhoto={category?.image}
                            />   
                          </Pressable>
                        )
                      })
                      } 
                    </Carousel>
                  </ScrollView>
                </HStack>
    
                {/* Services section web*/}
                <VStack space={5} p={5} style={{ backgroundColor: colors.palette.neutral100 }}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                    }}
                  >
                    Services
                  </Text>
                  <Stack direction={{base: 'column', md: 'row'}} space={5}>
                    {/* ------loop------- service card  */}
                    { serviceCardData?.map((service,index)=>(
                    <ServicesCard key={`authhome-service-card-${index}`} itemId={service?.itemId} backgroundColor={service?.backgroundColor} headingColor={service?.headingColor} photo={service?.photo} heading={service?.heading} content={service?.content}/>
                    )) }
                  </Stack>
                </VStack>
    
                {/* Sell Your Product section web*/}
                <Stack direction={{base: 'column', lg: 'row'}} space={5} p={5} style={{ backgroundColor: colors.palette.primary200 }}>
                { showAbovelgScreen && <AutoImage
                    source={require(`../../../assets/images/authenticated_Home_screen/sell_your_product.png`)}
                    maxHeight={100}
                    style={{ width: "10%" }}
                    resizeMode="contain"
                  />}
                  <Box w={{base: '100%', lg: "55%"}} pl={{base: 0, lg: 5}} pr={3}>
                    <Text
                      style={{
                        fontFamily: typography.fonts.poppins.bold,
                        fontSize: 40,
                        lineHeight: 48,
                        color: colors.palette.neutral600,
                      }}
                    >
                      Sell Your Product
                    </Text>
    
                    <Text
                      style={{
                        fontFamily: typography.fonts.poppins.normal,
                        color: colors.palette.neutral400,
                      }}
                    >
                     Efficient pharmaceutical product reselling services for generic medicines, ensuring minimal losses.
                    </Text>
                  </Box>
                  <Box w={{base: '100%', lg: "32%"}} justifyContent={"center"} alignItems={{base: 'flex-start',lg: "center"}}>
                    <Button
                      text="Get Started Now"
                      preset="default"
                      onPress={() => navigation.navigate("AddNewProduct")}
                      style={[
                        { backgroundColor: colors.palette.primary600 },
                        { borderRadius: 5 },
                        { width: 200, height: "max-content" },
                      ]}
                      pressedStyle={[
                        { backgroundColor: colors.palette.neutral100 },
                        { borderRadius: 5 },
                        { borderRadius: 5 },
                        { borderWidth: 0 },
                      ]}
                      textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                      pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                    />
                  </Box>
                </Stack>
    
                {/* Today's Deals section web*/}
                <VStack space={5} p={5} style={{ backgroundColor: colors.palette.neutral100 }}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                    }}
                  >
                    Today's Deals
                  </Text>
                  <HStack space={5}>
                    {/* ------loop------- Medicine card--  */}
                    <ScrollView showsVerticalScrollIndicator={false}>
                      <Carousel animated={true} autoplay={true} pageWidth={useBreakpointValue({base: 300, sm: 350, md: 410})} loop={true} onChangePage={(pageIndex, oldPageIndex, info) => {console.log('pageIndex, oldPageIndex, info', pageIndex, oldPageIndex, info)}}>
                        { dealOfTheDayProducts?.map((medicine:any,index)=>(
                          <Pressable key={`authHome-medicine-card-${index}`} onPress={()=>navigation.navigate("ProductDescription", {'productId': medicine?.id})}>
                            <MedicineCard
                              key={`authHome-medicine-card-${index}`}
                              medicinePhoto={medicine?.thumbnail}
                              medicineImageMaxHeight={200}
                              medicineImageWidth={"85%"}
                              cardWidth={"95%"}
                              cardHeight={300}
                              imageContainer={"60%"}
                              contentContainer={"40%"}
                              productId={medicine?.id}
                              tagShow={medicine?.offers_count === 0 ? false : true}
                              max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                              wishlisted={false}
                            >
                            {/* {medicine.childrenData} */}
                              <VStack space={2}>
                                <HStack space={5} alignItems="center">
                                  <Text
                                    style={{
                                      backgroundColor: colors.palette.accent100,
                                      color: colors.palette.primary100,
                                      padding: 5,
                                      borderRadius: 2,
                                    }}
                                  >
                                    {/* Up to 50% off */}
                                    Up to {medicine?.min_offer_percentage}% off
                                  </Text>
                                  <Text style={{ color: colors.palette.accent100 }}>Deal of the Day</Text>
                                </HStack>
                                <Pressable key={`authHome-medicine-card-${index}`} onPress={()=>navigation.navigate("ProductDescription", {'productId': medicine?.id})}>
                                  <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium }}>
                                    {/* Triclenz Hair Cleanser 250 ml */}
                                    {medicine?.name}
                                  </Text>
                                </Pressable>

                                <HStack space={5} alignItems="center">
                                  <Text style={{ color: colors.palette.neutral300 }}>Starts from</Text>
                                  <Text style={{ color: colors.palette.primary400 }}>
                                    {/* Rs. 402.50 */}
                                    {/* {Math.min.apply(Math, medicine?.min_mrp_offer)} */}
                                    {medicine?.min_mrp_offer}
                                  </Text>
                                </HStack>

                                <HStack space={5} alignItems="center">
                                  <Text style={{ color: colors.palette.angry100 }}>Expiry:</Text>
                                  <Text style={{ color: colors.palette.angry100 }}>
                                    {/* 8-12 Months */}
                                    {`${medicine?.expiry_month_range && medicine.expiry_month_range[0]}-${medicine?.expiry_month_range && medicine.expiry_month_range[1]} Months`}
                                  </Text>
                                </HStack>
                              </VStack>
                            </MedicineCard>
                          </Pressable>
                        )) } 
                      </Carousel>
                    </ScrollView>
                  </HStack>
                </VStack>
    
                {/* Special Offer section web*/}
                <Stack direction={{base: 'column', md: 'row'}} space={{base: 5, lg: 7, xl: 10}}>
                  <SpecialOfferCard 
                      medicinePhoto={
                        dealOfTheDayProducts && dealOfTheDayProducts[0]?.thumbnail
                        // require(`../../../assets/images/authenticated_Home_screen/Injection_specialoffer.png`)
                        } cardWidth={{base: '100%', md:"37%"}} min_offer_percentage={dealOfTheDayProducts && dealOfTheDayProducts[0]?.min_offer_percentage}>
                    <VStack space={2}>
                      <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium }}>
                        {/* Triclenz Hair Cleanser 250 ml */}
                        {dealOfTheDayProducts && dealOfTheDayProducts[0]?.name}
                      </Text>
                      <Text
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          color: colors.palette.primary400,
                        }}
                      >
                        Rs. {dealOfTheDayProducts && dealOfTheDayProducts[0]?.price_range[0]}
                      </Text>
    
                      <HStack space={5} alignItems="center">
                        <Text style={{ color: colors.palette.angry100 }}>Expiry:</Text>
                        <Text style={{ color: colors.palette.angry100 }}>{dealOfTheDayProducts && dealOfTheDayProducts[0]?.expiry_date_range[0]}</Text>
                      </HStack>
    
                      <Button
                        text="View Offer"
                        preset="default"
                        onPress={() => navigation.navigate("ProductDescription", {'productId': dealOfTheDayProducts && dealOfTheDayProducts[0]?.id})}
                        style={[
                          { backgroundColor: colors.palette.primary600 },
                          { borderRadius: 5 },
                          { width: "100%" },
                          { marginTop: 16 },
                        ]}
                        pressedStyle={[
                          { backgroundColor: colors.palette.neutral100 },
                          { borderRadius: 5 },
                          { borderRadius: 5 },
                          { borderWidth: 0 },
                        ]}
                        textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                        pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                      />
                    </VStack>
                  </SpecialOfferCard>
                  <VStack w={{base: "100%",md:"60%"}} space={3}>
                    <HStack flexWrap={'wrap'}>
                    { dealOfTheDayProducts?.map((medicine:any,index)=>{                      
                      return (
                      <Box key={`authHome-med-child-card-${index}`} width={specialOfferMedicineCardWidht} mr={3} marginBottom={3}>
                        <MedicineCard
                            medicinePhoto={medicine?.thumbnail}
                            medicineImageMaxHeight={120}
                            medicineImageWidth={'70%'}
                            cardWidth={'100%'}
                            cardHeight={{base:300,sm:200}}
                            imageContainer={"45%"}
                            contentContainer={"55%"}
                            productId={medicine?.id}
                            tagShow={medicine?.offers_count === 0 ? false : true}
                            max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                            wishlisted={false}
                          >
                          {/* {medicine.childrenData} */}
                          <VStack space={ 1 }>
                              <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, 
                                fontSize:  12, 
                                lineHeight: 16, 
                                color: colors.palette.neutral800 }}>
                                {/* Triclenz Hair Cleanser 250 ml */}
                                {medicine?.name}
                              </Text>

                              <HStack space={1} alignItems="center">
                                <Text style={{ color: colors.palette.neutral300,
                                  fontSize: 11, fontWeight: 'bold'}}>MRP:</Text>
                                <Text style={{ color: colors.palette.primary400, 
                                  fontSize: 11, textDecorationLine: 'line-through' }}>{medicine?.offers_count == 0 ? '' : `Rs.${medicine?.price_range && medicine?.price_range[0]}`}</Text>
                                <Text style={{ color: colors.palette.primary400, 
                                  fontSize: 11 }}>{medicine?.offers_count == 0 ? 'No offer added' : `Rs.${medicine && medicine?.offer_price_range && medicine?.offer_price_range[0]}`}</Text>
                              </HStack>

                              <HStack space={1} alignItems="center">
                                <Text style={{ color: colors.palette.angry100, fontSize: 11, fontWeight: 'bold' }}>Expiry:</Text>
                                <Text style={{ color: colors.palette.angry100, fontSize: 11}}>
                                  {/* 8-12 Months */}
                                  {medicine?.offers_count == 0 ? 'No offer added' : medicine && medicine?.expiry_date_range && medicine?.expiry_date_range[0]}
                                  </Text>
                              </HStack>

                              <Button
                                text="View Offers"
                                preset="default"
                                onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                                style={[
                                  { backgroundColor: colors.palette.primary600 },
                                  { borderRadius: 5 },
                                  { width: "100%" },
                                  { marginTop: 5, marginBottom: 10 },
                                ]}
                                pressedStyle={[
                                  { backgroundColor: colors.palette.neutral100 },
                                  { borderRadius: 5 },
                                  { borderRadius: 5 },
                                  { borderWidth: 0 },
                                ]}
                                textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                                pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                              />
                            </VStack>
                        </MedicineCard>
                      </Box>
                        )
                      }) } 
    
                    </HStack>                    
                  </VStack>
                </Stack>
                
                <AddUserRequirementForm client="web"/>               
    
                {/* Top Brands section web*/}
                <VStack space={5} p={5} style={{ backgroundColor: colors.palette.neutral100 }}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                    }}
                  >
                    Top Brands
                  </Text>
                  <HStack space={5}>
                    {/* ------loop------- brand card  */}
                    <ScrollView showsVerticalScrollIndicator={false}>
                      <Carousel animated={true} autoplay={true} pageWidth={useBreakpointValue({base: 300, sm: 350, md: 410})} loop={true} onChangePage={(pageIndex, oldPageIndex, info) => {console.log('pageIndex, oldPageIndex, info', pageIndex, oldPageIndex, info)}}>
                        {brands?.map((brand:any, index )=> {
                            return (   
                              <Pressable onPress={()=>navigation.navigate("Products",{"brandName":brand?.name})}>                           
                                <TopBrands
                                  key={`authHome-brand-card-${index}`}
                                  itemId={brand?.id}
                                  brandPhoto={
                                    `${Config?.MEDIA_URL}/${brand?.image}`
                                  }
                                  medicineImageMaxHeight={200}
                                  medicineImageWidth={"90%"}
                                  cardWidth={"96%"}
                                  cardHeight={300}
                                  imageContainer={'70%'}
                                  contentContainer={'30%'}
                                >
                                  {/* {brand.childrenData} */}
                                  
                                    <VStack space={3}>
                                      <Text
                                        isTruncated
                                        style={{ 
                                          textAlign: "center", 
                                          fontFamily: typography.fonts.poppins.medium 
                                        }}
                                      >
                                        {/* Head Shoulders */}
                                        {brand?.name}
                                      </Text>
                                      <Text
                                        style={{
                                          width: "40%",
                                          alignSelf: "center",
                                          textAlign: "center",
                                          backgroundColor: colors.palette.accent100,
                                          color: colors.palette.primary100,
                                          padding: 5,
                                          borderRadius: 2,
                                        }}
                                      >
                                        Up to 50% off
                                      </Text>
                                    </VStack>
                                </TopBrands>  
                              </Pressable> 
                            )
                          })
                          } 
                      </Carousel>
                    </ScrollView>
                  </HStack>
                </VStack>
    
                {/* Recent Listed section web*/}
                <VStack space={5} p={5} style={{ backgroundColor: colors.palette.neutral100 }}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                    }}
                  >
                    Recent Listed
                  </Text>
                  <HStack space={5}>
                    {/* ------loop-------Recent Listed Medicine card--  */}
                    <ScrollView showsVerticalScrollIndicator={false}>
                      <Carousel animated={true} autoplay={true} pageWidth={useBreakpointValue({base: 300, sm: 350, md: 410})} loop={true} currentPage onChangePage={(pageIndex, oldPageIndex, info) => {console.log('pageIndex, oldPageIndex, info', pageIndex, oldPageIndex, info)}}>
                        { products && products.slice(0,1)?.map((medicine,index)=>(
                          <MedicineCard
                            key={`authHome-recently-listed-med-card-${index}`}
                            medicinePhoto={medicine?.thumbnail}
                            medicineImageMaxHeight={200}
                            medicineImageWidth={"75%"}
                            cardWidth={"96%"}
                            cardHeight={300}
                            imageContainer={"60%"}
                            contentContainer={"40%"}
                            productId={medicine?.id}
                            tagShow={medicine?.offers_count === 0 ? false : true}
                            max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                            wishlisted={false}
                          >
                            {/* {medicine.childrenData} */}
                            <VStack>
                              
                              <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium }}>
                                {/* Triclenz Hair Cleanser 250 ml */}
                                {medicine?.name}
                              </Text>

                              <HStack space={5} alignItems="center">
                                <Text style={{ color: colors.palette.neutral300 }}>Starts from</Text>
                                <Text style={{ color: colors.palette.primary400 }}>Rs. {medicine?.offers_count == 0 ? 'No offer added' : medicine?.price_range && medicine?.price_range[0]}</Text>
                              </HStack>

                              <HStack space={5} alignItems="center">
                                <Text style={{ color: colors.palette.angry100 }}>Expiry:</Text>
                                <Text style={{ color: colors.palette.angry100 }}>{medicine?.offers_count == 0 ? 'No offer added' : medicine?.expiry_date_range && medicine.expiry_date_range[0]}</Text>
                              </HStack>

                              <HStack space={5} alignItems="center">
                                <Text style={{ color: colors.palette.neutral300 }}>Sellers: </Text>
                                <Text style={{ color: colors.palette.primary400 }}>{medicine?.offers_count}</Text>
                              </HStack>

                              <Button
                                text="View Offers"
                                preset="default"
                                onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                                style={[
                                  { backgroundColor: colors.palette.primary600 },
                                  { borderRadius: 5 },
                                  { width: "100%" },
                                  { marginTop: 5, marginBottom: 10 },
                                ]}
                                pressedStyle={[
                                  { backgroundColor: colors.palette.neutral100 },
                                  { borderRadius: 5 },
                                  { borderRadius: 5 },
                                  { borderWidth: 0 },
                                ]}
                                textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                                pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                              />
                            </VStack>
                          </MedicineCard>
                        )) } 
                      </Carousel>
                    </ScrollView>
                  </HStack>
                </VStack>
    
                {/* All Products section */}
                <VStack space={5} p={5} style={{ backgroundColor: colors.palette.neutral100 }}>
                  <Pressable onPress={()=>navigation.navigate("Products")}>
                    <Text
                      style={{
                        fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: 24,
                        lineHeight: 28.8,
                      }}
                    >
                      All Products
                    </Text>
                  </Pressable>
                  <HStack justifyContent={"space-evenly"} flexWrap={'wrap'}>
                    <WantToAddYourOfferCard cardWidth={{base: '100%',sm: '49%', lg: '32%'}} cardHeight={250} />
                    { products?.map((medicine,index)=>(
                          <Box key={`authHome-allProduct-Section-card-${index}`} width={allProductMedicineCardWidht} marginBottom={3}>
                            <MedicineCard
                              medicinePhoto={medicine?.thumbnail}
                              medicineImageMaxHeight={allProductmedicineImageMaxHeight}
                              medicineImageWidth={"70%"}
                              cardWidth={'100%'}
                              cardHeight={350}
                              productId={medicine?.id}
                              imageContainer={allProductmedicineImageContainer}
                              contentContainer={allProductmedicinecontentContainer}
                              tagShow={medicine?.offers_count === 0 ? false : true}
                              max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                              wishlisted={false}
                            >
                            {/* {medicine.childrenData} */}
                            <VStack>
                                <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium }}>
                                  {medicine?.name}
                                </Text>

                                <HStack space={5} alignItems="center">
                                  <Text style={{ color: colors.palette.neutral300 }}>Starts from</Text>
                                  <Text style={{ color: colors.palette.primary400 }}>Rs. {medicine?.offers_count == 0 ? 'No offer added' : medicine?.price_range && medicine?.price_range[0]}</Text>
                                </HStack>

                                <HStack space={5} alignItems="center">
                                  <Text style={{ color: colors.palette.angry100 }}>Expiry:</Text>
                                  <Text style={{ color: colors.palette.angry100 }}>{medicine?.offers_count == 0 ? 'No offer added' : medicine?.expiry_date_range && medicine.expiry_date_range[0]}</Text>
                                </HStack>

                                <HStack space={5} alignItems="center">
                                  <Text style={{ color: colors.palette.neutral300 }}>Sellers: </Text>
                                  <Text style={{ color: colors.palette.primary400 }}>{medicine?.unique_seller_count}</Text>
                                </HStack>

                                <Button
                                  text="View Offers"
                                  preset="default"
                                  onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                                  style={[
                                    { backgroundColor: colors.palette.primary600 },
                                    { borderRadius: 5 },
                                    { width: "100%" },
                                    { marginTop: 5, marginBottom: 10 },
                                  ]}
                                  pressedStyle={[
                                    { backgroundColor: colors.palette.neutral100 },
                                    { borderRadius: 5 },
                                    { borderRadius: 5 },
                                    { borderWidth: 0 },
                                  ]}
                                  textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                                  pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                                />
                                </VStack>
                            </MedicineCard>
                          </Box>
                            
                          )) } 
                  
                  </HStack>
                </VStack>
              </VStack>
            </Stack>
          </PageLayout>
        {/* </ScrollView> */}
        </Screen> 
        , 

      //code for Mobile ------------------->
        native : 
        <>
          <PageLayout  variant={1}>
               <ScrollView nestedScrollEnabled={true}>
                <Stack w="100%" bg={'white'} pt={0.9 } pb={100}>
                  {/* categories section mobile */}
                  <HStack p={2} mt={-2}>                      
                    <Carousel scrollEnabled={true} animated={true} pageWidth={100} loop={true}
                      pageControlPosition={Carousel.pageControlPositions.UNDER} pageControlProps={{size: 8, limitShownPages: true,}}
                      allowAccessibleLayout={true} style={{backgroundColor: "#FBFBFB"}}
                      >
                      {categoryCard_mobile_Data?.map((category, index )=> {
                        return ( 
                        <Box key={`authHome-carousal-card-x1-${index}`} style={{ overflow: 'visible' }}>
                          <CategoryCard_mobile key={category?.itemId} itemId={category?.itemId} categoryName={category?.categoryName} categoryPhoto={category?.categoryPhoto}/>   
                        </Box>
                        )
                        })} 
                    </Carousel>
                    {/* </Svg> */}
                  </HStack>

                  {/* brand section mobile */}
                  <HStack justifyContent={'center'} >
                    {/* ------loop---- brand card  */}
                    <Carousel animated={true} autoplay={true} pageWidth={250} loop={true} 
                      style={{backgroundColor: "#D3EDED"}}
                    >
                      {brands?.map((brand, index )=> {
                        return ( 
                          <Pressable key={`authHome-brandCard-mobile-x2-${index}`} onPress={()=>navigation.navigate("Products",{"brand_name":brand?.name})}>
                            <Brand_Card_Mobile key={`authHome-brandCard-mobile-x2-${index}`} itemId={brand?.id} brandName={brand?.name} 
                            brandPhoto={Config?.MEDIA_URL + brand?.image}
                            />   
                          </Pressable>
                        )
                      })} 
                    </Carousel>
                  </HStack>

                  {/* Sell Your Product section mobile */}
                  <Stack direction={'row'} p={3} m={3} rounded={10} style={{ backgroundColor: colors.palette.primary200 }}>
                    
                    <Box w={'66%'} pl={{base: 0, lg: 5}}>
                      <Text
                        style={{
                          fontFamily: typography.fonts.poppins.bold,
                          fontSize: 22,
                          lineHeight: 48,
                          color: colors.palette.neutral600,
                        }}
                      >
                        Sell Your Product
                      </Text>
                      <Box w={"32%"} justifyContent={"center"} alignItems={{base: 'flex-start',lg: "center"}}>
                        <Button
                          text="Get Started Now"
                          preset="default"
                          onPress={() => navigation.navigate("AddNewProduct")}
                          style={[
                            { backgroundColor: colors.palette.primary600 },
                            { borderRadius: 5 },
                            { width: 150},
                          ]}
                          pressedStyle={[
                            { backgroundColor: colors.palette.neutral100 },
                            { borderRadius: 5 },
                            { borderRadius: 5 },
                            { borderWidth: 0 },
                          ]}
                          textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                          pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                        />
                      </Box>
                    </Box>
                    <Image
                      source={require(`../../../assets/images/authenticated_Home_screen/sell_your_product.png`)}
                      maxHeight={100}
                      style={{ width: "34%", height: 100,}}
                      resizeMode="contain"
                      alt="image"
                    />
                  </Stack>

                  {/* Today's deals mobile */}
                <VStack space={5} p={3} style={{ backgroundColor: colors.palette.neutral100 }}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                    }}
                  >
                    Today's Deals
                  </Text>
                  <Carousel animated={true} autoplay={true} pageWidth={200} loop={true}
                  pageControlPosition={Carousel.pageControlPositions.OVER} pageControlProps={{size: 8, limitShownPages: true,}}
                      >
                      { dealOfTheDayProducts?.map((medicine,index)=>(
                        <Box key={`today_deal_${index}`} maxH={300} mb={5} w={'100%'} style={{borderColor: colors.palette.gray300, borderTopWidth: 1, borderLeftWidth: 4, borderRightWidth: 4}}>
                          <Pressable key={`today_deal_${index}`} onPress={()=>navigation.navigate("ProductDescription", {'productId': medicine?.id})} pointerEvents="box-none">
                            <MedicineCard
                              key={`authHome-todayDeal-medCard-${index}`}
                              medicinePhoto={{uri: medicine?.thumbnail}}
                              medicineImageMaxHeight={120}
                              medicineImageWidth={'70%'}
                              cardWidth={"100%"}
                              cardHeight={300}
                              imageContainer={'42%'}
                              contentContainer={'58%'}
                              productId={medicine?.id}
                              tagShow={medicine?.offers_count === 0 ? false : true}
                              max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                              wishlisted={false}
                            >
                            <VStack space={ 0 }>
                              <HStack space={1} alignItems="center">
                                <Text
                                  style={{
                                    fontFamily: typography.fonts.poppins.medium, 
                                    fontSize:  9, 
                                    lineHeight: 16,
                                    backgroundColor: colors.palette.accent100,
                                    color: colors.palette.primary100,
                                    padding: 5,
                                    borderRadius: 5,
                                  }}
                                >
                                  Up to {medicine?.max_offer_percentage}% off
                                </Text>
                                <Text style={{fontFamily: typography.fonts.poppins.medium, fontSize:  10, lineHeight: 16, color: colors.palette.accent100 }}>Deal of the Day</Text>
                              </HStack>
                                <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, 
                                  fontSize:  12, 
                                  lineHeight: 16, 
                                  color: colors.palette.neutral800}}
                                  >
                                  {/* Triclenz Hair Cleanser 250 ml */}
                                  {medicine?.name}
                                </Text>
                            
                                <HStack space={1} alignItems="center">
                                  <Text style={{ color: colors.palette.neutral300,
                                    fontSize: 11, fontWeight: 'bold'}}>Starts from:</Text>
                                  <Text style={{ color: colors.palette.primary400, 
                                    fontSize: 11 }}>{medicine?.offers_count === 0 ? 'No offer added' : `Rs. ${medicine?.offer_price_range && medicine.offer_price_range[0]}`}</Text>
                                </HStack>
                            
                                <HStack space={1} alignItems="center">
                                  <Text style={{ color: colors.palette.angry100, fontSize: 11, fontWeight: 'bold' }}>Expiry:</Text>
                                  <Text style={{ color: colors.palette.angry100, fontSize: 11}}>{medicine?.offers_count === 0 ? 'No offer added' : medicine?.expiry_date_range && medicine.expiry_date_range[0]}</Text>
                                </HStack>
                            
                                <Button
                                  text={medicine?.offers_count === 0 ? 'Add offer' : 'View offers'}
                                  preset="default"
                                  onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                                  style={[
                                    { backgroundColor: colors.palette.primary600 },
                                    { borderRadius: 5 },
                                    { width: "100%" },
                                    { marginTop: 5, marginBottom: 10 },
                                  ]}
                                  pressedStyle={[
                                    { backgroundColor: colors.palette.neutral100 },
                                    { borderRadius: 5 },
                                    { borderRadius: 5 },
                                    { borderWidth: 0 },
                                  ]}
                                  textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                                  pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                                />
                              </VStack>
                            </MedicineCard>
                          </Pressable>
                        </Box>
                      )) } 
                   </Carousel>
                  {/* </HStack> */}
                </VStack>
                
                {/* Special Offer section mobile */}
                <Stack direction={'column'} space={5} p={5}>
                  {products[0] && products[0].offers_count>0 &&
                    <SpecialOfferCard 
                    medicinePhoto={{uri: products && products[0]?.thumbnail}} 
                    min_offer_percentage={products && products[0]?.min_offer_percentage}
                    cardWidth={{base: '100%', md:"37%"}}>
                        <VStack space={2}>
                          <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium }}>
                            {products[0]?.name}
                          </Text>
                          <Text
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              color: colors.palette.primary400,
                            }}
                          >
                            Rs. {products && products[0]?.price_range[0]}
                          </Text>
        
                          <HStack space={5} alignItems="center">
                            <Text style={{ color: colors.palette.angry100 }}>Expiry:</Text>
                            <Text style={{ color: colors.palette.angry100 }}>{ products && products[0]?.expiry_date_range[0]}</Text>
                          </HStack>
        
                          <Button
                            text="Buy Now"
                            preset="default"
                            onPress={() => navigation.navigate("ProductDescription", {'productId': products && products[0]?.id})}
                            style={[
                              { backgroundColor: colors.palette.primary600 },
                              { borderRadius: 5 },
                              { width: "100%" },
                              { marginTop: 16 },
                            ]}
                            pressedStyle={[
                              { backgroundColor: colors.palette.neutral100 },
                              { borderRadius: 5 },
                              { borderRadius: 5 },
                              { borderWidth: 0 },
                            ]}
                            textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                            pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                          />
                        </VStack>
                    </SpecialOfferCard>
                  }
                  <HStack justifyContent={"space-between"} flexWrap='wrap'>
                    { dealOfTheDayProducts?.map((medicine,index)=>(
                      <Box key={index} maxH={300} mb={5} w={'49%'} style={{borderColor: colors.palette.gray300, borderWidth: 1}}>
                        <MedicineCard
                          key={`authHome-medCard-Deal-x3-${index}`}
                          medicinePhoto={{uri : medicine?.thumbnail}}
                          medicineImageMaxHeight={120}
                          medicineImageWidth={'70%'}
                          cardWidth={"100%"}
                          cardHeight={300}
                          imageContainer={'42%'}
                          contentContainer={'58%'}
                          productId={medicine?.id}
                          tagShow={medicine?.offers_count === 0 ? false : true}
                          max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                          wishlisted={false}
                        >
                        <VStack space={ 0 }>

                          <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, 
                            fontSize:  12, 
                            lineHeight: 16, 
                            color: colors.palette.neutral800 }}>
                            {medicine?.name}
                          </Text> 

                          <HStack space={1} alignItems="center">
                            <Text style={{ color: colors.palette.neutral300,
                              fontSize: 11, fontWeight: 'bold'}}>MRP:</Text>
                            <Text style={{ color: colors.palette.primary400, 
                              fontSize: 11, textDecorationLine: 'line-through' }}>{medicine?.offers_count == 0 ? 'no offer added' : `Rs.${medicine?.price_range && medicine?.price_range[0]}`}</Text>
                            <Text style={{ color: colors.palette.primary400, 
                              fontSize: 11 }}>{medicine.offers_count == 0 ? '' : `Rs.${medicine?.offer_price_range && medicine?.offer_price_range[0]}`}</Text>
                              
                          </HStack>

                          <HStack space={1} alignItems="center">
                            <Text style={{ color: colors.palette.angry100, fontSize: 11, fontWeight: 'bold' }}>Expiry:</Text>
                            <Text style={{ color: colors.palette.angry100, fontSize: 11}}>{medicine?.offers_count == 0 ? '' : `${medicine?.expiry_date_range && medicine?.expiry_date_range[0]}`}</Text>
                          </HStack>

                          <Button
                            text="View Offers"
                            preset="default"
                            onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                            style={[
                              { backgroundColor: colors.palette.primary600 },
                              { borderRadius: 5 },
                              { width: "100%" },
                              { marginTop: 5, marginBottom: 10 },
                            ]}
                            pressedStyle={[
                              { backgroundColor: colors.palette.neutral100 },
                              { borderRadius: 5 },
                              { borderRadius: 5 },
                              { borderWidth: 0 },
                            ]}
                            textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                            pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                          />
                          </VStack>
                        </MedicineCard>
                      </Box>
                    )) } 
                  </HStack>
                </Stack>

                {/* Tell us your requirement section mobile */}
                <AddUserRequirementForm client="mobile"/>

                {/* Recent Listed section mobile */}
                <VStack space={5} p={3} style={{ backgroundColor: colors.palette.primary200 }}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                      paddingTop: 2
                    }}
                  >
                    Recent Listed
                  </Text>
                  <Carousel 
                    animated={true} 
                    autoplay={true} 
                    pageWidth={230} 
                    loop={true}
                    pageControlPosition={Carousel.pageControlPositions.OVER} 
                    pageControlProps={{size: 8, limitShownPages: true,}}                      
                  >

                    {products && products.map((medicine,index)=>(
                      <Box key={index} maxH={310} mb={5} w={'100%'} style={{borderColor: colors.palette.gray300, borderTopWidth: 1, borderLeftWidth: 4, borderRightWidth: 4}}>
                        <MedicineCard
                          key={`autHome-recentListedCard-x1-${index}`}
                          medicinePhoto={{uri : medicine?.thumbnail}}
                          medicineImageMaxHeight={140}
                          medicineImageWidth={'70%'}
                          cardWidth={"100%"}
                          cardHeight={310}
                          imageContainer={'42%'}
                          contentContainer={'58%'}
                          productId={medicine?.id}
                          tagShow={medicine?.offers_count === 0 ? false : true}
                          max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                          wishlisted={false}
                        >
                          <VStack space={ 0 }>
                            <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, 
                              fontSize:  12, 
                              lineHeight: 16, 
                              color: colors.palette.neutral800 }}>
                              {medicine?.name}
                            </Text>

                            <HStack space={ Platform.select({default: 5, native: 1}) } alignItems="center">
                              <Text style={{ color: colors.palette.neutral300,
                                fontSize: Platform.select({default: 14, native: 11}), fontWeight: 'bold'}}>MRP:</Text>
                              <Text style={{ color: colors.palette.primary400, 
                                fontSize: Platform.select({default: 14, native: 11}), textDecorationLine: 'line-through' }}>{medicine.offers_count == 0 ? 'no offer added' : `Rs.${medicine.price_range && medicine?.price_range[0]}`}</Text>
                              <Text style={{ color: colors.palette.primary400, 
                                fontSize: Platform.select({default: 14, native: 11}) }}>{medicine?.offers_count == 0 ? '' : `Rs.${medicine?.offer_price_range && medicine?.offer_price_range[0]}`}</Text>
                            </HStack>
                        
                            <HStack space={1} alignItems="center">
                              <Text style={{ color: colors.palette.angry100, fontSize: 11, fontWeight: 'bold' }}>Expiry:</Text>
                              <Text style={{ color: colors.palette.angry100, fontSize: 11}}>{medicine?.offers_count == 0 ? '' : `${medicine?.expiry_date_range && medicine?.expiry_date_range[0]}`}</Text>
                            </HStack>
                            
                            <HStack space={1} alignItems="center">
                              <Text style={{ color: colors.palette.neutral300, fontSize: 11, fontWeight: 'bold' }}>Sellers: </Text>
                              <Text style={{ color: colors.palette.primary400, fontSize: 11}}>{medicine?.unique_seller_count}</Text>
                            </HStack>
                        
                            <Button
                              text="View Offers"
                              preset="default"
                              onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                              style={[
                                { backgroundColor: colors.palette.primary600 },
                                { borderRadius: 5 },
                                { width: "100%" },
                                { marginTop: 5, marginBottom: 10 },
                              ]}
                              pressedStyle={[
                                { backgroundColor: colors.palette.neutral100 },
                                { borderRadius: 5 },
                                { borderRadius: 5 },
                                { borderWidth: 0 },
                              ]}
                              textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                            />
                          </VStack>
                        </MedicineCard>
                      </Box>
                    )) } 
                  </Carousel>
                </VStack>

                {/* All Products section mobile */}
                <VStack space={5} p={3} style={{ backgroundColor: colors.palette.neutral100 }}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.semiBold,
                      fontSize: 24,
                      lineHeight: 28.8,
                      paddingTop: 2
                    }}
                  >
                    All Products
                  </Text>
                  <HStack justifyContent={"space-between"} flexWrap='wrap'>
                    { products?.map((medicine,index)=>(
                      <Box key={index} maxH={320} mb={5} w={'49%'} style={{borderColor: colors.palette.gray300, borderWidth: 1}}>
                        <MedicineCard
                          key={`authome-allProduct-medCard-x4-${index}`}
                          medicinePhoto={{uri: medicine?.thumbnail}}
                          medicineImageMaxHeight={120}
                          medicineImageWidth={'70%'}
                          cardWidth={"100%"}
                          cardHeight={320}
                          imageContainer={'42%'}
                          contentContainer={'58%'}
                          productId={medicine?.id}
                          tagShow={medicine?.offers_count === 0 ? false : true}
                          max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                          wishlisted={false}
                        >
                          <VStack space={ 0 }>
                            <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, 
                              fontSize:  12, 
                              lineHeight: 16, 
                              color: colors.palette.neutral800 }}>
                              {medicine?.name}
                            </Text>

                            <HStack space={ Platform.select({default: 5, native: 1}) } alignItems="center">
                              <Text style={{ color: colors.palette.neutral300,
                                fontSize: Platform.select({default: 14, native: 11}), fontWeight: 'bold'}}>MRP:</Text>
                              <Text style={{ color: colors.palette.primary400, 
                                fontSize: Platform.select({default: 14, native: 11}), textDecorationLine: 'line-through' }}>{medicine.offers_count == 0 ? 'no offer added' : `Rs.${medicine.price_range && medicine?.price_range[0]}`}</Text>
                              <Text style={{ color: colors.palette.primary400, 
                                fontSize: Platform.select({default: 14, native: 11}) }}>{medicine?.offers_count == 0 ? '' : `Rs.${medicine?.offer_price_range && medicine?.offer_price_range[0]}`}</Text>
                            </HStack>
                        
                            <HStack space={1} alignItems="center">
                              <Text style={{ color: colors.palette.angry100, fontSize: 11, fontWeight: 'bold' }}>Expiry:</Text>
                              <Text style={{ color: colors.palette.angry100, fontSize: 11}}>{medicine.offers_count == 0 ? '' : `${medicine?.expiry_date_range && medicine?.expiry_date_range[0]}`}</Text>
                            </HStack>
                            
                            <HStack space={1} alignItems="center">
                              <Text style={{ color: colors.palette.neutral300, fontSize: 11, fontWeight: 'bold' }}>Sellers: </Text>
                              <Text style={{ color: colors.palette.primary400, fontSize: 11}}>{medicine?.unique_seller_count}</Text>
                            </HStack>
                        
                            <Button
                              text="View Offers"
                              preset="default"
                              onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                              style={[
                                { backgroundColor: colors.palette.primary600 },
                                { borderRadius: 5 },
                                { width: "100%" },
                                { marginTop: 5, marginBottom: 10 },
                              ]}
                              pressedStyle={[
                                { backgroundColor: colors.palette.neutral100 },
                                { borderRadius: 5 },
                                { borderRadius: 5 },
                                { borderWidth: 0 },
                              ]}
                              textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                              pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                            />
                          </VStack>
                        </MedicineCard>
                      </Box>
                    )) } 

                    {/* want to add your offer here? */}
                    <WantToAddYourOfferCard cardWidth={"49%"} cardHeight={320} />
                  </HStack>
                  {/* see all results button */}
                  <Button        
                    text="See all results" 
                    preset="default"
                    onPress={() => {
                      navigation.navigate("Products")
                      console.log("pressed");
                    }}          
                    style={[{ borderRadius: 5 }]}
                    pressedStyle={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5 }]}
                    textStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                    pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                  />
                </VStack>

                </Stack>
              </ScrollView>
            </PageLayout>
        </>
      })}
  </>
  )
}) 


const $root: ViewStyle = {
  flex: 1,
}


/**
 * category to be used on Home page for category carousel
 */ 
const CategoryCard = ({itemId, categoryName, categoryPhoto}) => {
  return (
    <VStack w={'100%'} position='relative' key={itemId}>
      <AutoImage 
        source={categoryPhoto}
        maxHeight={200} style={{width:"100%"}}
        resizeMode='contain'
      />
      <Box width={"100%"} style={{position: 'absolute', top: '70%', left: -26,  justifyContent: 'center'}}>
        <AutoImage 
          source={require(`../../../assets/images/authenticated_Home_screen/Category_tag.png`)}
          maxHeight={50} style={{width:"100%"}}
          resizeMode='contain'
        />
        <Text style={{fontFamily: typography.fonts.poppins.normal, fontWeight: 'bold',position: 'absolute',  paddingLeft: 0, alignSelf: 'center'}}>
          {categoryName}
        </Text>
      </Box>
    </VStack>
  )
}

/**
 * category to be used on Home page for category carousel
 */
const CategoryCard_mobile = ({itemId, categoryName, categoryPhoto}) =>{
  return (
    <VStack w={'100%'} space={2} p={3} key={itemId} >
      <Box p={3} w={90} style={{backgroundColor: colors.palette.neutral100}}>
        <Image 
            source={categoryPhoto}
            style={{ width: 60, height:  60,  }}
            resizeMode='contain'
            alt="image"
          />
      </Box>
       <Text 
          style={{fontSize: 17, lineHeight: 22, fontFamily: typography.fonts.poppins.medium, color: colors.palette.gray200, textAlign: 'center'}}>
          {categoryName}
        </Text>
    </VStack>);
}

/**
 * ServicesCard to be used on Home page for services section
 */
const ServicesCard = ({itemId, backgroundColor, headingColor, photo, heading, content}) => {
  return (
    <HStack w={{base: '100%', md: '49%'}} p={{base: 3 ,lg: 5}} style={{backgroundColor: colors.palette[backgroundColor]}} key={itemId}>
      <AutoImage 
          source={photo}
          maxHeight={200} style={{width:"50%", height: '100%'}}
        />
      <Box w={'49%'} pl={{base: 5, md: 3, lg: 5}}>
        <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: useBreakpointValue({base: 16, md: '2vw'}), lineHeight: useBreakpointValue({base: 24, md: 26, lg: 30}), fontWeight: 'bold', color: colors.palette[headingColor]}}>
          {heading}
        </Text>
        
          <Text style={{fontFamily: typography.fonts.poppins.normal, fontSize: useBreakpointValue({base: 12, md: 14 , lg: 16}), lineHeight: useBreakpointValue({base: 18, md: 20, lg: 24}), color: colors.palette[headingColor],}}>
            {content}
          </Text>
      </Box>
    </HStack>
  )
}

/**
 * MedicineCard to be used on Home page for services section
 */
export const MedicineCard = (props:any) => {
  const navigation = useNavigation<any>()
  const tagTextBreakpoint = useBreakpointValue({base: 12, md: 14, lg: 16})
  const tagSizeBreakpoint = useBreakpointValue({base: 90, md: 110, lg: 130})

  const {setOverlayVisible} = useContext(OverlayContext)
  const { authenticationStore:{isAuthenticated,user},
          ProductListStore:{fetchWishlist,isFavorite}} = useStores()
  const {showAlert} = useContext(AlertContext)
  const handleShare = async (message:string,url:string) => {
    try {
      await Share.share({
        message,
        url,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };
  const addToWishlist = (productId:string) => {
    console.log("productId: ",productId);
    if(isAuthenticated){      
      if(!isFavorite(productId)){
         api.apisauce.post('wishlist/', {
          "product":productId,
          "user":user?.id,
        })
        .then(response =>{
          console.log(response)
        })
        .catch(error =>{console.log(error)})
        .finally(() =>{fetchWishlist(setOverlayVisible)})
      }
      else{
        api.apisauce.delete('wishlist/', {
          "product":productId,
        })
        .then(response =>{console.log(response)})
        .catch(error =>{console.log(error)})
        .finally(() =>{fetchWishlist(setOverlayVisible)})
      }
    }else{
      showAlert('Error', 'Login required for this action');
    }
  }
  return (
    <VStack 
      w={props.cardWidth}
      p={2} 
      minHeight={props.cardHeight} 
      maxHeight={'100%'}
      style={{
      backgroundColor:  colors.palette.neutral100,
      overflow: 'hidden',
      shadowColor: 'black',
      shadowOffset: {width: 1, height: 2},
      shadowOpacity: 0.1,
      shadowRadius: 15,
      }} >
      <HStack h={props.imageContainer} justifyContent='center'>
        {isFavorite(props.productId) ? 
        <AntDesign 
          name="heart" 
          size={24} 
          color={colors.palette.secondary300} 
          style={{
            zIndex: 1,
            position: 'absolute',
            top:2, left:2,
          }} 
          onPress={()=>addToWishlist(props.productId)}
        /> 
        : 
        <AntDesign 
          name="hearto" 
          size={24} 
          color="black" 
          style={{
            zIndex: 1,            
            position: 'absolute',
            top:2, left:2,
          }} 
          onPress={()=>addToWishlist(props.productId)}
        />
        }       
        <Image 
          source={props.medicinePhoto}
          maxHeight={props.medicineImageMaxHeight} 
          minWidth="100%"
          style={{ 
            alignSelf: "center", 
            width: props.medicineImageWidth, 
            height: props.medicineImageMaxHeight
          }}
          alt="image"
        />
        <Entypo 
          name="share" 
          size={30} 
          color="black" 
          style={{
            zIndex: 1,            
            position: 'absolute',
            bottom:2, right:2,
          }}
          onPress={()=>handleShare("Check this product", "url")}
        />
      </HStack>
      {props.tagShow &&
        <Box style={{width: Platform.select({default: tagSizeBreakpoint, native: 90 }), position: 'absolute', alignSelf: 'flex-end'}}>
          {/* offer_tag */}
          <Image 
            source={require(`../../../assets/images/landing_screen/offer_tag.png`)}
            maxHeight={50} style={{ alignSelf: "flex-end", width: '100%', height: 50, marginTop: -5, marginRight: -10}}
            resizeMode='contain'
            alt="image"
          />
          <Text style={{color: colors.palette.primary100, fontSize: Platform.select({default: tagTextBreakpoint, native: 11 }), padding: 5, borderRadius: 2, position: 'absolute', alignSelf: 'flex-end'}}>Upto- {props.max_offer_percentage}%</Text>
        </Box>
       }

      <Box  h={props.contentContainer} m={ Platform.select({default: 2, native: 0 }) } mt={2}>
         {props.children}
      </Box>
      
    </VStack>
  )
}

/**
 * TopBrands to be used on Home page for Top Brands section
 */
const TopBrands = (props) => {
  return (
    <VStack 
      key={props.itemId}
      space={2}
      w={props.cardWidth}
      p={2} 
      minHeight={props.cardHeight} 
      style={{
      backgroundColor:  colors.palette.neutral100,
      overflow: 'hidden',
      shadowColor: 'black',
      shadowOffset: {width: 1, height: 3},
      shadowOpacity: 0.1,
      shadowRadius: 15,
      }} >
        <AutoImage 
          source={props.brandPhoto}
          maxHeight={props.medicineImageMaxHeight} style={{ alignSelf: "center", width: props.medicineImageWidth}}
        />
        <Box  flex={props.contentContainer} m={2}>
            {props.children}
        </Box>
      
    </VStack>
  )
}

/**
 * category to be used on Home page for category carousel
 */ 
const Brand_Card_Mobile = ({itemId, brandName, brandPhoto}) => {
  return (
    <VStack w={'100%'} minHeight={200} position='relative' key={itemId} p={5} rounded={10}>
      <Image 
        source={{uri: brandPhoto}}
        alt="image"
        maxHeight={200} style={{width:"100%", height: 180}}
        rounded={10}
      />
      <Box rounded={10} style={{width:  120 , position: 'absolute', top: 20, right: 20 ,alignSelf: 'flex-end'}}>
        <Text style={{color: colors.palette.primary100, fontSize: 16, padding: 7, borderRadius: 10, backgroundColor: colors.palette.accent100, position: 'absolute', alignSelf: 'flex-end'}}>
          Up to 50% off
        </Text>
      </Box>
      <Box width={"100%"} style={{position: 'absolute', top: '70%', left: 5,  justifyContent: 'center'}}>
        <Image 
          source={require(`../../../assets/images/authenticated_Home_screen/Mobile/brand_tag.png`)}
          alt="image"
          style={{width:"95%", height: 100}}
          resizeMode='contain'
        />
        <Text style={{fontFamily: typography.fonts.poppins.normal, fontWeight: 'bold',position: 'absolute',  paddingLeft: 0, alignSelf: 'center'}}>
          {brandName}
        </Text>
      </Box>

    </VStack>
  )
}

/**
 * SpecialOfferCard to be used on Home page for Special Offer section
 */
const SpecialOfferCard = (props) => {
  return (
    <VStack 
      space={3}
      w={props.cardWidth}
      p={5} 
      height= {650}
      rounded={10}
      style={{
      backgroundColor: colors.palette.primary200,
      overflow: 'hidden',
      }} >
      <Box h={"68%"} justifyContent='center'>
        <Box style={{ position: 'absolute',left: 15, top: 25, zIndex: 1 }}>
          <Text style={{fontFamily: typography.fonts.poppins.bold, color: colors.palette.neutral1000}}>Special</Text>
          <Text style={{fontFamily: typography.fonts.poppins.bold, color: colors.palette.neutral1000}}>Offer</Text>
        </Box>
        <Box  style={{ position: 'absolute',right: 10, top: 15, zIndex: 1, backgroundColor: colors.palette.primary200, width: 70, height: 70, borderRadius: 70/2, alignItems: 'center', justifyContent: 'center'}}>
          <Text style={{fontFamily: typography.fonts.poppins.bold}}>Save</Text>
          <Text style={{fontFamily: typography.fonts.poppins.bold}}>{props.min_offer_percentage}%</Text>
        </Box>
        <Image 
          source={props.medicinePhoto}
          maxHeight={400} style={{ alignSelf: "center", width:"100%", height: 400, borderRadius: 10}}
          alt="image"
        />
      </Box>

      <Box  h={'20%' } m={2}>
         {props.children}
      </Box>
      
    </VStack>
  )
}

/**
 * Want to add your offer to be used on Home page for All Products section
 */
export const WantToAddYourOfferCard = (props) => {
  const { 
    authenticationStore: {isAuthenticated},
   } = useStores()
  const buttonBreakpoint =  useBreakpointValue({base: '2vw', lg: '4vw',xl: 25});
  const paddingBreakpoint = useBreakpointValue({base: 10, lg: 5, xl: 12})
  const { showAlert } = useContext(AlertContext);

  const navigation = useNavigation<any>()

  return (
    <VStack 
      space={ Platform.select({default: 7, native: 4}) }
      mb={useBreakpointValue({base:'10px', md: '0px'})}
      w={props.cardWidth}
      p={Platform.select({default: paddingBreakpoint, native: 3})} 
      height={Platform.select({default: 'max-content', native: props.cardHeight})}
      minHeight={props.cardHeight} 
      style={{
      backgroundColor: colors.palette.primary600,
      overflow: 'hidden',
      alignContent: 'center',
      justifyContent: 'center',      
      }}>
        <Text style={{fontSize: Platform.select({default: 15, native: 14}), lineHeight: Platform.select({default: 22, native: 15}), fontFamily: typography.fonts.poppins.medium, color: colors.palette.primary100, textAlign: 'center'}}>
          Want to add your offer here?
        </Text>
        <Text style={{fontSize: Platform.select({default: 13, native: 12}), lineHeight: Platform.select({default: 22, native: 15}),fontFamily: typography.fonts.poppins.normal, color: colors.palette.neutral200}}>
          We will get you wings. Add your offers to the product and we will save you a great time and money. Your offers will be visible to our visitors and we will promote it through search engines and advertising platforms.
        </Text>
        <Button
          text="Click Here"
          preset="default"
          onPress={() => 
            isAuthenticated 
              ? navigation.navigate("AddNewOffer", {'productId': props.productId}) 
              : showAlert('Error', "You need to Login first to sell products.")
          }          
          style={[{ backgroundColor: colors.palette.primary600}, 
            { borderRadius: 5 }, { width: '100%' }, {marginTop: Platform.select({default: 10, native: 0 }), marginBottom: Platform.select({default: buttonBreakpoint, native: 0})}, 
            {shadowColor: 'white',
            shadowOffset: {width: 2, height: 2},
            shadowOpacity: 0.2,
            shadowRadius: 15,}]}
          pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
          textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
          pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
        />
    </VStack>
  )
}


