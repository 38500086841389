import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Keyboard, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../navigators"
import { AutoImage, Button, Screen, Text } from "../../../components"
import { Box, HStack, Image, Pressable, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../../theme"
import { InputField } from "../../../components/InputField"
import { useNavigation } from "@react-navigation/native"
import { Ionicons } from "@expo/vector-icons"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

//imports for mobile----->
import Constants from 'expo-constants';
import { useStores } from "../../../models"
import { api } from "../../../services/api"
import { OverlayContext } from "../../../components/OverlayProvider"
import { AlertContext } from "../../../components/AlertContext"

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const ForgotPasswordScreen: FC<StackScreenProps<AppStackScreenProps, "ForgotPassword">> = observer(function ForgotPasswordScreen() {
  // Pull in one of our MST stores
  const { ForgotPasswordStore: { email, setEmail, emailValidationError } } = useStores()
  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  // const [email, setEmail] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false)

  //validation error ----->
  const emailError = isSubmitted ? emailValidationError : ""
  console.log('forgot password', emailError);

  const {  setOverlayVisible } = useContext(OverlayContext);
  const { showAlert } = useContext(AlertContext);

  function forgotPasswordFun() {
    setOverlayVisible(true)
    setIsSubmitted(true)
    //if there is something returned form validation error then return from here and do not call login api
    if (emailValidationError) return

    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token.
    api.apisauce.post('rest-auth/password/reset/', { "email": email })
      .then((response) => {
        console.log(response)
        if (response.ok) {
          showAlert("Success", `Reset password email is sent on ${email}. Follow the instructions mentioned in the email.`)
          navigation.navigate('Signin')
        }
        else {
          showAlert("Error", `Looks like the email ${email} is not registered with us. Try again.`)
        }
      }).catch((error) => {
        console.log(error)
        showAlert('Error', "Failed to send password reset link. Enter correct email.")
      }).finally(() => { setOverlayVisible(false) })
    setIsSubmitted(false)
  }

  useEffect(() => {
    setEmail('')
  },[])


  return (
    <>
      {/* divert code for web and android here  */}
      {Platform.select({
        // code for web------------------------>
        default:
          <Screen style={$root} preset="scroll">
            <HStack
              w="100%" height="100vh"
              maxWidth={1440} alignSelf="center"
              style={{
                shadowOffset: { width: 0, height: 0 },
                shadowColor: '#171717',
                shadowOpacity: 0.2,
                shadowRadius: 3
              }}
            >
              <VStack
                w={{ base: '100%', md: '45%', lg: '35%', xl: "30%" }} space={5} p={30}
                alignItems="left"
              >
                <Box style={{ minHeight: 120 }}>
                  <AutoImage
                    source={require("../../../../assets/images/color_logo.png")}
                    maxHeight={39}
                    style={{
                      // position:"absolute",
                      // top:30, left:30
                    }}
                  />
                </Box>
                <VStack space={2} mb={5}>
                  <Text
                    style={{
                      color: colors.palette.primary600,
                      fontFamily: typography.fonts.poppins.bold,
                      fontSize: 30,
                      lineHeight: 48
                    }}
                  >Forgot Password</Text>
                  <Text
                    style={{
                      color: colors.palette.neutral400,
                      fontFamily: typography.fonts.poppins.normal,
                      fontSize: 16,
                      lineHeight: 24
                    }}
                  >Please enter your email/mobile number for receiving OTP</Text>
                </VStack>

                <VStack space={5}>
                  <InputField
                    key="email"
                    value={email}
                    onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                    label="Email/Mobile"
                    placeholder="Email Your Email/Mobile"
                    helper={emailError}
                    status={emailError ? "error" : undefined}
                  />
                  <Button
                    text="Continue"
                    preset="default"
                    onPress={() => { forgotPasswordFun(); }}
                    style={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5 }, { width: "100%" }]}
                    pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5 }, { borderWidth: 0 }]}
                    textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                    pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                  />
                  <Text
                    style={{
                      color: colors.palette.neutral400,
                      fontFamily: typography.fonts.poppins.normal,
                      fontSize: 16,
                      lineHeight: 24,
                      alignSelf: 'center'
                    }}
                  >
                    <Pressable onPress={() => navigation.navigate("Signin")}><Text style={{ color: colors.palette.accent300 }}>Back to Sign in</Text></Pressable>
                  </Text>
                </VStack>
              </VStack>
              <AutoImage
                source={require("../../../../assets/images/sign_in_sign_up/sign_up_img.png")}
                maxHeight={600} style={{ height: "100%", width: "70%" }}
              />
            </HStack>
          </Screen>,

        // code for mobile------------------------>
        native:
          <Screen style={[$root, { marginTop: Constants.statusBarHeight }]} preset="scroll">
            <VStack bg={'#005C83'} w="100%" height="100%">
              {/* go back section */}
              <Box minH={300}>
                <HStack space={3} p={5} alignItems={'center'}>
                  <Pressable onPress={() => navigation.goBack()}>
                    <Ionicons name="arrow-back" alt="icon" size={30} color={colors.palette.neutral100} />
                  </Pressable>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.bold,
                      fontSize: 16,
                      lineHeight: 19,
                      color: colors.palette.neutral100,
                    }}
                  >
                    {/* {sellerName} */}
                    Back
                  </Text>
                </HStack>
              </Box>

              {/* Forgot password section start mobile */}
              <VStack space={5} bg={'white'} p={5} pt={10} pb={10} style={{ borderTopLeftRadius: 70, borderTopRightRadius: 70 }}>
                <VStack alignItems='center' space={5}>
                  <Text
                    style={{
                      fontFamily: typography.fonts.poppins.bold,
                      fontSize: 24,
                      lineHeight: 28.8,
                      color: colors.palette.primary300,
                    }}
                  >
                    Forgot Password
                  </Text>
                  <Text
                    style={{
                      color: colors.palette.neutral400,
                      fontFamily: typography.fonts.poppins.normal,
                      fontSize: 14,
                      lineHeight: 24
                    }}
                  >Please enter your email/mobile number for receiving OTP</Text>
                </VStack>
                <VStack space={5}>
                  <InputField
                    key="email"
                    value={email}
                    onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                    label="Email/Mobile"
                    placeholder="Email Your Email/Mobile"
                    helper={emailError}
                    status={emailError ? "error" : undefined}
                  />
                  <Button
                    text="Continue"
                    preset="default"
                    onPress={() => { forgotPasswordFun(); }}
                    style={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5 }, { width: "100%" }]}
                    pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5 }, { borderWidth: 0 }]}
                    textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                    pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                  />
                  <Text
                    style={{
                      color: colors.palette.neutral400,
                      fontFamily: typography.fonts.poppins.normal,
                      fontSize: 16,
                      lineHeight: 24,
                      alignSelf: 'center'
                    }}
                  >
                    <Pressable onPress={() => navigation.navigate("Signin")}><Text style={{ color: colors.palette.accent300 }}>Back to Sign in</Text></Pressable>
                  </Text>
                </VStack>
              </VStack>
              {/* forgot password section end */}

            </VStack>
          </Screen>
      })}
      {/* Platform select close here  */}
    </>
  )

}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}
