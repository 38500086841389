import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle, TouchableOpacity, Image, Text, StyleSheet, SafeAreaView,} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"

export interface StarRattingProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>;
  setRating: any,
}

/**
 * Describe your component here
 */
export const StarRatting = observer(function StarRatting(props: StarRattingProps) {
  const { style } = props
  const $styles = [$container, style]
  // To set the default Star Selected
  const [defaultRating, setDefaultRating] = React.useState(0);
  // To set the max number of Stars
  const [maxRating, setMaxRating] = React.useState([1, 2, 3, 4, 5]);

  // Filled Star. You can also give the path from local
  const starImageFilled =
    // 'https://raw.githubusercontent.com/AboutReact/sampleresource/master/star_filled.png';
    require("../../assets/images/sellerProfile/full_Star.png")
  // Empty Star. You can also give the path from local
  const starImageCorner =
    require("../../assets/images/sellerProfile/blank_Star.png")


  return (
    <View style={styles.customRatingBarStyle}>
          {maxRating.map((item, key) => {
            return (
              <TouchableOpacity
                activeOpacity={0.7}
                key={`star-${key}`}
                onPress={() => {props.setRating(item); setDefaultRating(item)}}>
                <Image
                  style={styles.starImageStyle}
                  source={
                    item <= defaultRating
                      ?  starImageFilled
                      :  starImageCorner
                  }
                />
              </TouchableOpacity>
            );
          })}
        </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}

const styles = StyleSheet.create({

  customRatingBarStyle: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  starImageStyle: {
    width: 40,
    height: 40,
    resizeMode: 'cover',
    margin: 5
  },
});