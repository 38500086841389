import React, { FC, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Alert, Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen, Text } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, Divider, HStack, Stack, VStack, useBreakpointValue } from "native-base"
import { colors, typography } from "../../theme"
import { BlogItem } from "../../components/BlogItem"
import { useNavigation } from "@react-navigation/native"
import { api } from "../../services/api/api"
import { ApiResponse } from "apisauce"
import { useStores } from "../../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Blog: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Blog" component={BlogScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const BlogScreen: FC<StackScreenProps<AppStackScreenProps, "Blog">> = observer(function BlogScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated} } = useStores()

  const blogItemWidthBreakpoint = useBreakpointValue({base: '100%', sm: '48%', md: '32%', lg: '24%'}) 

  // Pull in navigation via hook
  const navigation = useNavigation<any>()

  const [data, setData] = useState<any>()
  
  useEffect(()=>{
    api.apisauce.get("pages/?type=blog.BlogListingPage&fields=*")
    .then(
      (response:ApiResponse<any,any>)=>{
        if(response.data?.items)
          if(response.data?.items?.length > 0)
            setData(response.data?.items[0].children)
          // console.log(response)
      }
    )
  },[])

  const WebCode =()=>{
    return(
    <Screen style={$root} preset="scroll">
      <PageLayout variant={isAuthenticated?1:0}>        
        {/* Blogs section */}
        <VStack w="100%" p={10} pb={100} space={5} 
            background={colors.palette.primary100}
            justifyContent="center"
        >
          <VStack space={3}>
            <Text
              style={{
                color:colors.palette.neutral600, 
                fontFamily:typography.fonts.poppins.bold,
                fontSize:40,              
                alignSelf:"center"
              }}
            >Blogs</Text>
            <HStack justifyContent="center">
              <Divider
                width={120} alignSelf="center"
                height={0.5}                
                background={colors.palette.primary300}
              />
              <AutoImage
                source={require("../../../assets/icons/Heading_Design.png")}
                maxHeight={24}                            
              />
            </HStack>
          </VStack> 
          <Text
            style={{
              color:colors.palette.neutral400, 
              fontFamily:typography.fonts.poppins.semiBold,
              fontSize:16,
              lineHeight:22,
              alignSelf:"center"
            }}
          >"This is Blog Section. You must subscribe to receive daily updates about NearXpiry"</Text>
          <HStack 
            w="100%" space={2} justifyContent="center" flexWrap={'wrap'}          
          >
            {data?.map((item,index)=>{
              return (
                <BlogItem blogItemWidth={blogItemWidthBreakpoint} navigation={navigation} data={item}  key={`blogListScreen-x1-${index}`}/>
              )
            })}           
          </HStack>
          {/* <Button        
            text="Load More"   
            preset="default"
            onPress={() => Alert.alert("pressed")}          
            style={[{ borderRadius: 5 },{ width: 275 },{ height: 48 },{ alignSelf: "center" }]}
            pressedStyle={[{ backgroundColor: colors.palette.primary600 }, { borderRadius: 5 }]}
            textStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
            pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
          />  */}
        </VStack>
      </PageLayout>
    </Screen>
    )
  }

  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
    </Screen>
    )
  }


  return (
  <>
    {/* divert code for web and android here  */}
    {Platform.select({ 
        native : <MobileCode/>, //code for Mobile devices
        default : <WebCode/>, //code for web and other platforms
      })}
    {/* Platform select close here  */}
  </>
  )

}) // observer close here 

const $root: ViewStyle = {
  flex: 1,
}
