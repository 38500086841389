import * as React from "react"
import { StyleProp, TextStyle, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { InputField } from "./InputField"
import { Button } from "./Button"
import { Alert } from "react-native"
import { Box, HStack, Image, Stack, View, VStack, useBreakpointValue, Text } from "native-base"
import { api } from "../services/api/api"
import { useStores } from "../models"
import { ProductApi } from "../services/api/apiFatching/product-api"
import { OverlayContext } from "./OverlayProvider"
import { AlertContext } from "./AlertContext"
import { AutoImage } from "./AutoImage"
import { useNavigation } from "@react-navigation/native"

export interface AddUserRequirementFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>,
  client: string
}

/**
 * Describe your component here
 */
export const AddUserRequirementForm = observer(function AddUserRequirementForm(props: AddUserRequirementFormProps) {
    const { style } = props

    const { 
        authenticationStore: {isAuthenticated, user},
        AuthHomeStore: {
            requirement, setRequirement,          
            quantityValidationError,
            mobileValidationError,
            cityValidationError,
            userValidationError,
            product_nameValidationError
        }
       } = useStores()
    
    const [isSubmitted, setIsSubmitted] = React.useState(false)
    const userInputRef = React.useRef<any>()
    const cityInputRef = React.useRef<any>()
    const mobileInputRef = React.useRef<any>()
    const quantityInputRef = React.useRef<any>()

    //user requirement validation error ----->
    const productNameError = isSubmitted ? product_nameValidationError : ""
    const userError = isSubmitted ? userValidationError : ""
    const cityError = isSubmitted ? cityValidationError : ""
    const mobileError = isSubmitted ? mobileValidationError : ""
    const quantityError = isSubmitted ? quantityValidationError : ""
    const zeroRequirement = {
        product_name: "",
        // user: "",
        city: "",
        mobile: "",
        quantity: "",
    }
       
    const { setOverlayVisible } = React.useContext(OverlayContext);
    const { showAlert } = React.useContext(AlertContext);
    const navigation = useNavigation<any>()
    const [errors, setErrors] = React.useState({})
    // user requirement form submission
    function submitForm() {
        setIsSubmitted(true)        
        //if there is something returned form validation error then return from here and do not call login api
        if (product_nameValidationError) return 
        // if (userValidationError) return 
        if (cityValidationError) return 
        if (mobileValidationError) return 
        if (quantityValidationError) return 
        // Make a request to your server to get an authentication token.
        // If successful, reset the fields and set the token.
        submitUserRequirement();
        setIsSubmitted(false)
    }

    const submitUserRequirement = () => {
        const payload = {...requirement, quantity: parseInt(requirement?.quantity), user:user.id};
        setRequirement({...requirement, ...zeroRequirement})
        const productApi = new ProductApi(api);    
        productApi.addNewProductRequirement(payload, setOverlayVisible, showAlert)
        .then((response:any)=>{ 
            console.log(response)
            if(response?.ok)
              navigation.navigate('SellerProfile');
            else{
              setErrors({...response.data})
            }
        }) 
    }

    const showAbovelgScreen = useBreakpointValue({
    base: false,
    lg: true
    }) 

    const inputWidth = useBreakpointValue({base: '100%', md: '47%'});
    
    return (
        <>
        {props.client === 'web'?
            <VStack space={5} p={5} style={{ backgroundColor: colors.palette.neutral100 }}>
                <Text
                style={{
                    fontFamily: typography.fonts.poppins.semiBold,
                    fontSize: 24,
                    lineHeight: 28.8,
                }}
                >
                Tell us your requirement
                </Text>
                {Object.keys(errors)?.length > 0 && 
                    <VStack space={5}>
                        {errors && Object.keys(errors)?.length>0 && Object.keys(errors).map(key =>{
                        return <Text
                        style={{
                            color:'red', 
                            fontFamily:typography.fonts.poppins.normal,
                            fontSize:16,
                            lineHeight:24
                        }}
                        >{`${key}: ${errors[key]}`}</Text> 
                    })}
                    </VStack>
                }
                <HStack flex={"100%"} space={5}>
                    <VStack flex={"70%"} space={5}>
                        <Stack direction={{base: 'column', md: 'row'}} space={{base: 4, sm: 5, md: 6, lg: 10}}>
                            <Box style={{ width: inputWidth }}>
                                <InputField
                                ref={userInputRef}
                                key="Name"
                                value={`${user?.first_name} ${user?.last_name}`}
                                // onChangeText={(value) => setRequirement({...requirement, user:value})}
                                label="Name*"
                                placeholder="Your Name"
                                helper={userError}
                                status={userError ? "error" : undefined}
                                // onSubmitEditing={() => cityInputRef.current?.focus()}
                                editable={false}
                                />
                            </Box>
                            <Box style={{ width: inputWidth }}>
                                <InputField
                                key="Enter Product"
                                value={requirement?.product_name}
                                onChangeText={(value) => setRequirement({...requirement, product_name:value})}
                                label="Product Name*"
                                placeholder="Enter Product"
                                helper={productNameError}
                                status={productNameError ? "error" : undefined}
                                onSubmitEditing={() => cityInputRef?.current?.focus()}
                                />
                            </Box>                            
                        </Stack>

                        <Stack direction={{base: 'column', md: 'row'}} space={{base: 4, sm: 5, md: 6, lg: 10}}>
                            <Box style={{ width: inputWidth }}>
                                <InputField
                                ref={cityInputRef}
                                key="City"
                                value={requirement?.city}
                                onChangeText={(value) => setRequirement({...requirement, city:value})}
                                label="City*"
                                placeholder="Jaipur"
                                helper={cityError}
                                status={cityError ? "error" : undefined}
                                onSubmitEditing={() => mobileInputRef?.current?.focus()}
                                />
                            </Box>
                            <Box style={{ width: inputWidth }}>
                                <InputField
                                ref={mobileInputRef}
                                key="Mobile"
                                keyboardType="numeric"
                                value={requirement?.mobile}
                                onChangeText={(value) => setRequirement({...requirement, mobile:value})}
                                label="Mobile*"
                                placeholder="Mobile Number"
                                helper={mobileError}
                                status={mobileError ? "error" : undefined}
                                onSubmitEditing={() => quantityInputRef?.current?.focus()}
                                />
                            </Box>
                        </Stack>

                        <Box style={{ width: inputWidth }}>
                            <InputField
                                ref={quantityInputRef}
                                key="Bundle Qty."
                                keyboardType="numeric"
                                value={requirement?.quantity}
                                onChangeText={(value) => setRequirement({...requirement, quantity:value})}
                                label="Bundle Qty.*"
                                placeholder="0"
                                helper={quantityError}
                                status={quantityError ? "error" : undefined}
                            />
                        </Box>

                        <Button
                        text="Submit Requirement"
                        preset="default"
                        onPress={()=>submitForm()}
                        style={[
                            { backgroundColor: colors.palette.primary600 },
                            { borderRadius: 5 },
                            { width: useBreakpointValue({base: '100%', sm: '47%', md: '35%', lg: '25%'}) },
                            { marginTop: 5, marginBottom: 10 },
                        ]}
                        pressedStyle={[
                            { backgroundColor: colors.palette.neutral100 },
                            { borderRadius: 5 },
                            { borderRadius: 5 },
                            { borderWidth: 0 },
                        ]}
                        textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                        pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                        />
                    </VStack>

                    {showAbovelgScreen && 
                        <Box flex={"30%"} justifyContent="center">
                            <AutoImage
                            source={require(`../../assets/images/authenticated_Home_screen/Tellus_requirement.png`)}
                            maxHeight={200}
                            style={{ alignSelf: "center", width: "100%" }}
                            resizeMode="contain"
                            />
                        </Box>
                    }
                </HStack>
            </VStack>
        :
            <VStack space={5} p={5} m={5} mt={-3} style={{ backgroundColor: colors.palette.neutral100, borderColor: colors.palette.gray300, borderWidth: 1 }}>
                <Text
                    style={{
                        fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: 20,
                        lineHeight: 28.8,
                        color: colors.palette.neutral700
                    }}
                >
                    Tell us your requirement
                </Text>
                <Box justifyContent="center">
                    <Image
                        source={require(`../../assets/images/authenticated_Home_screen/Tellus_requirement.png`)}
                        maxHeight={200}
                        style={{ alignSelf: "center", width: "100%" , height: 200}}
                        resizeMode="contain"
                        alt="image"
                    />
                </Box>
                <VStack space={5}>
                    {errors && Object.keys(errors)?.length>0 && Object.keys(errors).map(key =>{
                        return <Text
                        style={{
                            color:'red', 
                            fontFamily:typography.fonts.poppins.normal,
                            fontSize:16,
                            lineHeight:24
                        }}
                        >{`${key}: ${errors[key]}`}</Text> 
                    })}
                    <Box style={{ width: "100%" }}>
                        <InputField
                        key="product"
                        value={requirement?.product_name}
                        onChangeText={(value) => setRequirement({...requirement, product_name:value})}
                        label="Product"
                        placeholder="Product Name*"
                        helper={productNameError}
                        status={productNameError ? "error" : undefined}
                        onSubmitEditing={() => userInputRef.current?.focus()}
                        />
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <InputField
                        ref={userInputRef}
                        key="User"
                        value={requirement?.user}
                        onChangeText={(value) => setRequirement({...requirement, user:value})}
                        label="User"
                        placeholder="Your Name*"
                        helper={userError}
                        status={userError ? "error" : undefined}
                        onSubmitEditing={() => cityInputRef.current?.focus()}
                        />
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <InputField
                        ref={cityInputRef}
                        key="City"
                        value={requirement?.city}
                        onChangeText={(value) => setRequirement({...requirement, city:value})}
                        label="City"
                        placeholder="Your City*"
                        helper={cityError}
                        status={cityError ? "error" : undefined}
                        onSubmitEditing={() => mobileInputRef.current?.focus()}
                        />
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <InputField
                        ref={mobileInputRef}
                        key="Mobile"
                        keyboardType="numeric"
                        value={requirement?.mobile}
                        onChangeText={(value) => setRequirement({...requirement, mobile:value})}
                        label="Mobile"
                        placeholder="Your Mobile*"
                        helper={mobileError}
                        status={mobileError ? "error" : undefined}
                        onSubmitEditing={() => quantityInputRef.current?.focus()}
                        />
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <InputField
                        ref={quantityInputRef}
                        key="Quantity"
                        keyboardType="numeric"
                        value={requirement?.quantity}
                        onChangeText={(value) => setRequirement({...requirement, quantity:value})}
                        label="Quantity*"
                        placeholder="0"
                        helper={quantityError}
                        status={quantityError ? "error" : undefined}
                        />
                    </Box>
                    <Button
                        text="Submit Requirement"
                        preset="default"
                        onPress={()=>submitForm()}
                        style={[
                        { backgroundColor: colors.palette.primary600 },
                        { borderRadius: 5 },
                        { width: "100%" },
                        { marginTop: 5, marginBottom: 10 },
                        ]}
                        pressedStyle={[
                        { backgroundColor: colors.palette.neutral100 },
                        { borderRadius: 5 },
                        { borderRadius: 5 },
                        { borderWidth: 0 },
                        ]}
                        textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                        pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                    />
                </VStack>
            </VStack>
        }
        </>
  )
})
