import React, { useRef, useState }  from "react"
import { StyleProp, TextStyle, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { Text } from "./Text"
import { View, TextInput, StyleSheet } from 'react-native';

export interface OtpInputfieldProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}



/**
 * Describe your component here
 */
export const OtpInputfield = observer(function OtpInputfield(props: OtpInputfieldProps) {
  const { style } = props
  const $styles = [$container, style]

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const refs = useRef([]);

  const handleOtpChange = (index, value) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value && index < refs?.current?.length - 1) {
      refs.current[index + 1].focus();
    }
  };

  return (
    <View style={styles.container}>
      {otp.map((digit, index) => (
        <TextInput
          key={index}
          style={styles.input}
          keyboardType="numeric"
          maxLength={1}
          value={digit}
          onChangeText={(value) => handleOtpChange(index, value)}
          ref={(ref) => (refs.current[index] = ref)}
          onSubmitEditing={() => {
            if (index < refs?.current?.length - 1) {
              refs.current[index + 1].focus();
            }
          }}
          // onFocus={() =>{setEnableShift(true)}}
        />
      ))}
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}


const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  input: {
    width: 40,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    textAlign: 'center',
    marginLeft: 5,
    marginRight: 5,
  },
});