import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, TouchableWithoutFeedback, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../../navigators"
import { Button, Screen, Text } from "../../../../components"
import { PageLayout } from "../../../../components/PageLayout"
import { Box, HStack, KeyboardAvoidingView, Pressable, ScrollView, Stack, VStack } from "native-base"
import { colors, typography } from "../../../../theme"
import { Keyboard } from "react-native"
import { useNavigation } from "@react-navigation/native"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"
//imports for mobile----->
import Constants from 'expo-constants';
import { Ionicons } from "@expo/vector-icons"
import { InputField } from "../../../../components/InputField"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `BusinessSettingMobileScreen: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="BusinessSettingMobileScreen" component={BusinessSettingMobileScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const BusinessSettingMobileScreen: FC<StackScreenProps<AppStackScreenProps, "BusinessSettingMobile">> = observer(function BusinessSettingMobileScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const [enableShift_80,setEnableShift_80] = useState(50)
  const [enableShift_350,setEnableShift_350] = useState(50)
  const [RegBusinessName,setRegBusinessName] = useState('')
  const [GSTNumber,setGSTNumber] = useState('')
  const [DLNumber,setDLNumber] = useState('')
  const [businessAddress,setbusinessAddress] = useState('')
  const [manager,setManager] = useState('')
  const [business_mobile,setBusiness_Mobile] = useState('')
  const [businessCountry,setBusinessCountry] = useState('')
  const [businessState,setBusinessState] = useState('')
  const [businessCity,setBusinessCity] = useState('')
  const [businessZipCode,setBusinessZipCode] = useState('')
  
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  // const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
      setKeyboardOpen(true);
      // setKeyboardHeight(e.endCoordinates.height);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', (e) => {
      setKeyboardOpen(false);
      // setKeyboardHeight(0);
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  return (
    <>
      {/* divert code for web and android here  */}
       {Platform.select({ 
          default : <></> , //code for web and other platforms
          
          native : 
            <PageLayout  variant={1}>
              <ScrollView style={{backgroundColor: colors.palette.primary700}}>
                {/* <KeyboardAvoidingView behavior={"position"} contentContainerStyle={{flex: 1}} style={{flex:1}} enabled={enableShift}> 
                  <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={{flex:1}}>  */}
                   
                    {/* parent stack */}
                    <VStack bg={colors.palette.primary300} w="100%" h="100%" paddingBottom={50}>
                      <Box minH={100} ></Box>
                        
                      {/* get started section */}
                      <VStack bg={'white'} space={6} p={5} pt={10} pb={10} style={{borderTopLeftRadius: 70, borderTopRightRadius: 70}} 
                        paddingBottom={Platform.select({ios: isKeyboardOpen ? enableShift_350 : 70 , android: isKeyboardOpen ? enableShift_80 : 60})}>
                        <VStack space={2}>
                          {/* go back section */}
                          <HStack space={3} alignItems={'center'}>
                            <Pressable onPress={() => navigation.goBack()}>
                              <Ionicons name="arrow-back" alt="icon" size={30} color={colors.palette.primary300}/>
                            </Pressable>
                            <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.bold,
                                fontSize: 20,
                                lineHeight: 28.8,
                                color: colors.palette.primary300,
                              }}
                              >
                                {/* {sellerName} */}
                                Business Settings
                            </Text>
                          </HStack>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.neutral300,
                            }}
                            >
                              Change account information and settings
                          </Text>
                        </VStack>
                        
                        {/* Business settings input fields */}
                        <VStack w={'100%'} space={4}>
                        {/* heading */}
                        <VStack space={2}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: 14,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              Keep your business information up to date
                          </Text>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.neutral400,
                            }}
                            >
                              Please read our 
                              <Text style={{color: colors.palette.secondary300}}>terms of use</Text>  to be informed how we manage your private data.
                          </Text>
                        </VStack>
                        
                        {/* input fileds */}
                        <VStack space={4}>
                            <Box w={'100%'}>
                              <InputField
                                key="Reg. Business Name"
                                value={RegBusinessName}              
                                onChangeText={(value) => setRegBusinessName(value)}
                                label="Reg. Business Name"
                                placeholder="Bhavana Addania"
                                // status="error"
                                // helper="Enter your name"
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              /> 
                            </Box>
                            <Box w={'100%'}>
                              <InputField
                                key="GST Number"
                                value={GSTNumber}              
                                onChangeText={(value) => setGSTNumber(value)}
                                label="GST Number"
                                placeholder="AS254255"
                                // status="error"
                                // helper="Enter your name"
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              /> 
                            </Box>
                            <Box w={'100%'}>
                              <InputField
                                key="DL Number"
                                value={DLNumber}              
                                onChangeText={(value) => setDLNumber(value)}
                                label="DL Number"
                                placeholder="8888888888"
                                // status="error"
                                // helper="Enter your name"
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              /> 
                            </Box>
                            <Box w={'100%'}>
                              <InputField
                                key="Business Address"
                                value={businessAddress}              
                                onChangeText={(value) => setbusinessAddress(value)}
                                label="Business Address"
                                placeholder="Lorem ipsum"
                                // status="error"
                                // helper="Enter your name"
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              /> 
                            </Box>
                            <Box w={'100%'}>
                              <InputField
                                  key="Manager"
                                  value={manager}              
                                  onChangeText={(value) => setManager(value)}
                                  label="Manager"
                                  placeholder="9672187065"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                />
                            </Box>
                            <Box w={'100%'}>
                              <InputField
                                  key="Mobile"
                                  value={business_mobile}              
                                  onChangeText={(value) => setBusiness_Mobile(value)}
                                  label="Mobile"
                                  placeholder="8888888888"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                />
                            </Box>
                            <HStack w={'100%'} justifyContent={'space-between'}>
                              <Box w={'49%'}>
                                <InputField
                                  key="Country"
                                  value={businessCountry}              
                                  onChangeText={(value) => setBusinessCountry(value)}
                                  label="Country"
                                  placeholder="9672187065"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>{setEnableShift_350(350); setEnableShift_80(60)}}
                                />
                              </Box>
                              <Box w={'49%'}>
                                <InputField
                                  key="State"
                                  value={businessState}              
                                  onChangeText={(value) => setBusinessState(value)}
                                  label="State"
                                  placeholder="8888888888"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>{setEnableShift_350(350); setEnableShift_80(60)}}
                                />
                              </Box>
                            </HStack>
                            <HStack w={'100%'} justifyContent={'space-between'}>
                              <Box w={'49%'}>
                                <InputField
                                  key="City"
                                  value={businessCity}              
                                  onChangeText={(value) => setBusinessCity(value)}
                                  label="City"
                                  placeholder="8888888888"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>{setEnableShift_350(350); setEnableShift_80(60)}}
                                />
                              </Box>
                              <Box w={'49%'}>
                                <InputField
                                  key="Zip Code"
                                  value={businessZipCode}              
                                  onChangeText={(value) => setBusinessZipCode(value)}
                                  label="Zip Code"
                                  placeholder="8888888888"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>{setEnableShift_350(350); setEnableShift_80(60)}}
                                />
                              </Box>
                            </HStack>
                        </VStack>

                        {/* save button */}
                        <Button
                            text='Save'
                            preset="default"
                            // onPress={() => Alert.alert("pressed")}
                            style={{
                              backgroundColor: colors.palette.primary600,
                              borderRadius: 5,
                              width: "40%",
                            }}
                            pressedStyle={{
                              backgroundColor: colors.palette.neutral100,
                              borderRadius: 5,
                              borderWidth: 0,
                          
                            }}
                            textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                            pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                          />
                          
                        <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral400,
                            }}
                            >
                              You may also consider to update your <Text style={{color: colors.palette.secondary300}}> billing information.</Text> 
                          </Text>
                        </VStack>
                      </VStack>
                        
                    </VStack>
                  {/* </TouchableWithoutFeedback>
                </KeyboardAvoidingView> */}
              </ScrollView>
          </PageLayout>, //code for Mobile devices
        })}
      {/* Platform select close here  */}
    </>
    )
})

const $root: ViewStyle = {
  flex: 1,
}
