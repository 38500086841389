import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, Pressable, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../navigators"
import { AutoImage, Button, Screen, Text, TextFieldAccessoryProps, TextField } from "../../../components"
import { Box, HStack, Image, Input, Link, VStack } from "native-base"
import { colors, typography } from "../../../theme"
import { Divider } from "@rneui/base"
import { useNavigation } from "@react-navigation/native"
import { InputField } from "../../../components/InputField"
import { Feather, Ionicons } from '@expo/vector-icons';
import { useStores } from "../../../models"
import { AuthenticationStore } from "../../../models/AuthenticationStore"

//imports for mobile----->
import Constants from 'expo-constants';
import { Dimensions } from "react-native"
import { OverlayContext } from "../../../components/OverlayProvider"
import { AlertContext } from "../../../components/AlertContext"

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const LoginScreen: FC<StackScreenProps<AppStackScreenProps, "Login">> = observer(function LoginScreen() {
  // Pull in one of our MST stores
  const { authenticationStore,
    LoginStore: {
      email, 
      setEmail, 
      password, 
      setPassword, 
      emailValidationError, 
      passwordValidationError} 
  } = useStores()
  const {  setOverlayVisible } = useContext(OverlayContext);
  // Pull in navigation via hook  ;
  // const [email, setEmail] = React.useState("")
  // const [password, setPassword] = React.useState("")
  const [isAuthPasswordHidden , setIsAuthPasswordHidden] = React.useState(true)
  const { height } = Dimensions.get('window');
  const [isSubmitted, setIsSubmitted] = useState(false)
  const authPasswordInput = useRef<any>()


  const navigation = useNavigation<any>()

  //validation error ----->
  const emailError = isSubmitted ? emailValidationError : ""
  const passwordError = isSubmitted ? passwordValidationError : ""
  const {showAlert } = useContext(AlertContext)
  const [errors, setErrors] = useState([])
  function login() {
    setErrors([])
    setIsSubmitted(true)
    //if there is something returned form validation error then return from here and do not call login api
    if (emailValidationError) return 
    if (passwordValidationError) return 
    // Make a request to your server to get an authentication token.
    // If successful, reset the fields and set the token.
    authenticationStore.login(email, password, setOverlayVisible)
    .then((response:any)=>{ 
      console.log(response)
      if(response.kind==='ok')
        navigation.navigate( 'Home');
      else{
        setErrors(response.non_field_errors)
      }
    })
    setIsSubmitted(false)
    // setEmail("")
    // setPassword("")
  } 

  const PasswordRightAccessory = React.useMemo(
    () =>
      function PasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isAuthPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsAuthPasswordHidden(!isAuthPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isAuthPasswordHidden],
  )

  useEffect(() => {
    setEmail('')
    setPassword('')
  },[])

  return (
  <>
    {/* divert code for web and android here  */}
     {Platform.select({ 
      //code for Web ------------------->
        default : 
        <Screen style={$root} preset="scroll">
          <HStack 
            w="100%" height={height}
            maxWidth={1440} alignSelf="center"
            style={{
              shadowOffset: {width: 0, height: 0},  
              shadowColor: '#171717',  
              shadowOpacity: 0.2,  
              shadowRadius: 3
            }}
          >
            <Pressable  onPress={()=>navigation.navigate("Home")}>
              <AutoImage 
                source={require("../../../../assets/images/color_logo.png")}
                maxHeight={39}
                style={{
                  position:"absolute",
                  top:30, left:30
                }}
              />
            </Pressable>
            
            <VStack 
              w={{base: '100%', md: '45%', lg: '35%', xl:"30%"}} space={5} p={30}
              alignItems="left"
            > 
              <Box style={{minHeight: 90}}>
              <Pressable  onPress={()=>navigation.navigate("Home")}>
                <AutoImage 
                  source={require("../../../../assets/images/color_logo.png")}
                  maxHeight={39}                 
                />
              </Pressable>
              </Box>   
              <VStack space={2}>
                <Text
                  style={{
                    color:colors.palette.primary600, 
                    fontFamily:typography.fonts.poppins.bold,
                    fontSize:40,
                    lineHeight:48
                  }}
                >Sign In</Text>                
                <Text
                  style={{
                    color:colors.palette.neutral400, 
                    fontFamily:typography.fonts.poppins.normal,
                    fontSize:16,
                    lineHeight:24
                  }}
                >Getting started is easy</Text>     
              </VStack> 
              <VStack>
                {errors && errors.length>0 && errors.map(error =>{
                  return <Text
                    style={{
                      color:'red', 
                      fontFamily:typography.fonts.poppins.normal,
                      fontSize:16,
                      lineHeight:24
                    }}
                  >{error}</Text> 
                })}
              </VStack>
              {/* <VStack space={5}> */}
                {/* <HStack space={5}>
                  <Image 
                    source={require("../../../../assets/images/sign_in_sign_up/google_btn.png")}
                    maxHeight={48} alt="image" style={{
                      width: 140, height: 48,
                      borderWidth:1, borderColor:colors.palette.secondary300, borderRadius:5
                    }}          
                  /> 
                  <Image 
                    source={require("../../../../assets/images/sign_in_sign_up/facebook_btn.png")}
                    maxHeight={48} alt="image" style={{
                      width: 140, height: 48,
                      borderWidth:1, borderColor:colors.palette.neutral300, borderRadius:5
                    }}                      
                  /> 
                </HStack>      
                <Divider subHeader="Or continue with" 
                    subHeaderStyle={{
                    marginTop: -10, 
                    backgroundColor: 'white',
                    zIndex: 1,
                    width: 110,
                    alignSelf: 'center'
                    }}/>       */}
                <VStack space={3}>             
                  <InputField
                    key="email"
                    value={email}              
                    onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                    label="Email"
                    placeholder="Email Address"
                    helper={emailError}
                    status={emailError ? "error" : undefined}
                    onSubmitEditing={() => authPasswordInput.current?.focus()}
                  />            
                  <InputField
                    ref={authPasswordInput}
                    key="password"
                    value={password}
                    onChangeText={(password) => setPassword(password)}
                    label="Password"              
                    placeholder="Password"
                    secureTextEntry={isAuthPasswordHidden}
                    helper={passwordError}
                    status={passwordError ? "error" : undefined}
                    RightAccessory={PasswordRightAccessory}
                    onSubmitEditing={login}
                  />
    
                  {/* forgot password */}
                  <Pressable onPress={()=>navigation.navigate("ForgotPassword")}><Text style={{color:colors.palette.accent300, marginTop: -7}}>Forgot Password?</Text></Pressable>         
    
                  <Button
                    text="Sign In"
                    preset="default"
                    onPress={()=> login()}
                    style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
                    pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                    textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                    pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                  /> 
                  <HStack alignItems='center' space={2}>
                    <Text
                      style={{
                        color:colors.palette.neutral400, 
                        fontFamily:typography.fonts.poppins.normal,
                        fontSize:16,
                        lineHeight:24,
                        
                      }}              
                    >
                      Don't have an account? 
                    </Text> 
                    <Pressable onPress={()=>navigation.navigate("Signup")}>
                      <Text style={{ color:colors.palette.accent300}}>Sign Up</Text>
                    </Pressable>
                  </HStack>
                  
                </VStack>
              {/* </VStack> */}
            </VStack>
            <AutoImage 
              source={require("../../../../assets/images/sign_in_sign_up/sign_up_img.png")}
              maxHeight={600} style={{height:"100%", width:"75%"}}                   
            />              
          </HStack>       
        </Screen> , 

      //code for Mobile ------------------->
        native : 
        <Screen style={[$root,{marginTop: Constants.statusBarHeight}]} preset="scroll">
          <VStack bg={colors.palette.primary300} w="100%" height="100%">
            {/* go back section */}
            <Box minH={200} >
              <HStack space={3} p={5} alignItems={'center'}>
                <Pressable onPress={() => navigation.goBack()}>
                  <Ionicons name="arrow-back" alt="icon" size={30} color={colors.palette.neutral100}/>
                </Pressable>
                <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.bold,
                    fontSize: 16,
                    lineHeight: 19,
                    color: colors.palette.neutral100,
                  }}
                  >
                    {/* {sellerName} */}
                    Back
                </Text>
              </HStack>
            </Box>
              
            {/* get started section */}
            <VStack bg={'white'} p={5} pt={10} pb={10} style={{borderTopLeftRadius: 70, borderTopRightRadius: 70}}>
              <VStack alignItems='center' space={5}>
                <Text 
                  style={{
                    fontFamily: typography.fonts.poppins.bold,
                    fontSize: 24,
                    lineHeight: 28.8,
                    color: colors.palette.primary300,
                  }}
                  >
                    Welcome Back
                </Text>
                {/* <HStack space={5}>
                  <Image 
                    source={require("../../../../assets/images/sign_in_sign_up/google_btn.png")}
                    maxHeight={48} alt="image" style={{
                      width: 140, height: 48,
                      borderWidth:1, borderColor:colors.palette.secondary300, borderRadius:5
                    }}          
                  /> 
                  <Image 
                    source={require("../../../../assets/images/sign_in_sign_up/facebook_btn.png")}
                    maxHeight={48} alt="image" style={{
                      width: 140, height: 48,
                      borderWidth:1, borderColor:colors.palette.neutral300, borderRadius:5
                    }}                      
                  /> 
                </HStack>      
                <Divider subHeader="Or continue with" 
                    subHeaderStyle={{
                    marginTop: -10, 
                    backgroundColor: 'white',
                    zIndex: 1,
                    width: 110,
                    alignSelf: 'center'
                    }}/>       */}
                <VStack space={3} w={'100%'}>             
                  <InputField
                    key="email"
                    value={email}              
                    onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                    label="Email"
                    placeholder="Email Address"
                    helper={emailError}
                    status={emailError ? "error" : undefined}
                    onSubmitEditing={() => authPasswordInput.current?.focus()}
                    // RightAccessory={() => <Icon icon="check" />}
                    // LeftAccessory={() => <Icon icon="bell" />}
                  />            
                  <InputField
                    ref={authPasswordInput}
                    key="password"
                    value={password}
                    onChangeText={(password) => setPassword(password)}
                    label="Password"              
                    placeholder="Password"
                    secureTextEntry={isAuthPasswordHidden}
                    helper={passwordError}
                    status={passwordError ? "error" : undefined}
                    RightAccessory={PasswordRightAccessory}
                    onSubmitEditing={login}
                    // LeftAccessory={() => <Icon icon="bell" />}
                  />
    
                  {/* forgot password */}
                  <Pressable onPress={()=>navigation.navigate("ForgotPassword")}><Text style={{color:colors.palette.accent300, marginTop: -15}}>Forgot Password?</Text></Pressable>         
    
                  <Button
                    text="Sign In"
                    preset="default"
                    onPress={() => login()}
                    style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, { width: "100%" }]}
                    pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
                    textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                    pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                  /> 
                  <HStack alignItems='center' space={2}>
                    <Text
                      style={{
                        color:colors.palette.neutral400, 
                        fontFamily:typography.fonts.poppins.normal,
                        fontSize:16,
                        lineHeight:24,
                        
                      }}              
                    >
                      Don't have an account? 
                    </Text> 
                    <Pressable onPress={()=>navigation.navigate("Signup")}>
                      <Text style={{ color:colors.palette.accent300}}>Sign Up</Text>
                    </Pressable>
                  </HStack>
                  
                </VStack>
              </VStack>
            </VStack>
              
          </VStack>
        </Screen>, 
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}
