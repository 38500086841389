import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../navigators"
import { AutoImage, Button, Screen, Text, TextFieldAccessoryProps } from "../../../components"
import { LogoutModal, PageLayout } from "../../../components/PageLayout"
import { Box, HStack, Pressable, Radio, Stack, VStack, useBreakpointValue } from "native-base"
import { Entypo, Feather, Ionicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import { colors, typography } from "../../../theme"
import { InputField } from "../../../components/InputField"
import { Checkbox, RadioButton, Switch } from "react-native-ui-lib"
// import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../../models"
import { OverlayContext } from "../../../components/OverlayProvider"
import { Api, api } from "../../../services/api"
import { da } from "date-fns/locale"
import { AlertContext } from "../../../components/AlertContext"
import Dropzone from "react-dropzone"
import DropDownPicker from 'react-native-dropdown-picker';

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `GeneralSettings: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="GeneralSettings" component={GeneralSettingsScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const GeneralSettingsScreen: FC<StackScreenProps<AppStackScreenProps, "GeneralSettings">> = observer(function GeneralSettingsScreen() {
  // Pull in one of our MST stores
  const { authenticationStore,
    AccountSettingStore: {
      // Notifications setting checkboxes-->
      emailAlerts,setEmailAlerts, pushNotifications,setPushNotifications, textMessages,setTextMessages,
      phoneCalls, setPhoneCalls, whatsappAlerts, setWhatsappAlerts, chatAlerts, setChatAlerts,
    // Billing setting inputs---->
      cardNumber, setCardNumber, cardHolderName, setCardHolderName, expiry, setExpiry, billingZipCode, setBillingZipCode,
      CVV, setCVV, cardType, setCardType,
    // Security setting inputs---->
      currentPassword, setCurrentPassword, newPassword, setNewPassword, repeatPassword, setRepeatPassword,
      switchPublicProfile, setSwitchPublicProfile, switchExposeEmail, setSwitchExposeEmail,
    // Business setting inputs---->
      RegBusinessName, setRegBusinessName, GSTNumber, setGSTNumber, DLNumber, setDLNumber, businessAddress, setbusinessAddress,
      manager, setManager, business_mobile, setBusiness_Mobile, businessCountry, setBusinessCountry,
      businessState, setBusinessState, businessCity, setBusinessCity, businessZipCode, setBusinessZipCode,
    // General setting inputs---->
      profileImage, setProfileImage, fullName, setFullName, email, setEmail, mobile, setMobile,
      address, setAddress, country, setCountry, state, setState, city, setCity, zipCode, setZipCode,
      bio, setBio, setLocation, location,
      currentPasswordValidationError, newPasswordValidationError, repeatPasswordValidationError, RegBusinessNameValidationError,
      GSTNumberValidationError, DLNumberValidationError, businessAddressValidationError, managerValidationError,
      business_mobileValidationError, businessCountryValidationError, businessStateValidationError, businessCityValidationError,
      businessZipCodeValidationError, cardNumberValidationError, cardHolderNameValidationError, expiryValidationError,
      billingZipCodeValidationError, CVVValidationError, fullNameValidationError, emailValidationError,
      mobileValidationError, addressValidationError, countryValidationError, stateValidationError, 
      cityValidationError, zipCodeValidationError, bioValidationError
    } } = useStores()
  const {  setOverlayVisible } = useContext(OverlayContext);
  const user = authenticationStore.user
  const showAboveSm = useBreakpointValue({
    base: false,
    sm: true
  })

  const goBackHeading = useBreakpointValue({base: 20, sm: 22, md: 24})
  const fontSizeHeading = useBreakpointValue({base: 18, sm: 20, md: 24});
  const buttonSize =  useBreakpointValue({base: '30%',md:"13%"});
  const profilePhotoSize =  useBreakpointValue({base: 130, md: 140, lg: 200});
  const profilePhotoBorderSize =  useBreakpointValue({base: 130/2,md: 140/2,lg: 200/2});
  const profilePhotoMarginTop =  useBreakpointValue({base: -40,md: -60,lg: -100});
  const resetButtonBoxSize =  useBreakpointValue({base: '60%',md: '25%'});
  const resetButtonSize =  useBreakpointValue({base: '100%',md: '65%'});
  const fontSizeHeadingNotification = useBreakpointValue({base: 16, sm: 18, md: 24});
  const sidePannelIconSize = useBreakpointValue({base: 40, sm: 20})

  useEffect(() => {
    // Notifications setting checkboxes-->
    setEmailAlerts(user?.profile?.email_alert)
    setPushNotifications(user?.profile?.push_notification)
    setTextMessages(user?.profile?.text_message)
    setPhoneCalls(user?.profile?.phone_call);
    setWhatsappAlerts(user?.profile?.whatsapp_alert);
    setChatAlerts(user?.profile?.chat_alert);

    // Billing setting inputs---->
    setCardNumber('');
    setCardHolderName('');
    setExpiry('');
    setBillingZipCode('');
    setCVV('');
    setCardType('');

    // Security setting inputs---->
    setCurrentPassword('');
    setNewPassword('');
    setRepeatPassword('');
    setSwitchPublicProfile(user?.is_public_profile);
    setSwitchExposeEmail(user?.is_show_email);

    // Business setting inputs---->
    setRegBusinessName(user?.seller?.business_name);
    setGSTNumber(user?.seller?.registration_number);
    setDLNumber(user?.seller?.license_number);
    setbusinessAddress(user?.seller?.business_address);
    setManager(user?.mobile);
    setBusiness_Mobile(user?.mobile);
    setBusinessCountry(user?.location_info?.country);
    setBusinessState(user?.location_info?.state);
    setBusinessCity(user?.location_info?.city);
    setBusinessZipCode(user?.location_info?.zipcode);

    // General setting inputs---->
    setProfileImage([`${user?.profile?.avatar}`]);
    setFullName(`${user?.first_name} ${user?.last_name}`);
    setEmail((user?.email).toLocaleLowerCase().trim());
    setMobile(user?.mobile);
    setAddress(user?.seller?.business_address);
    setCountry(user?.location_info?.country);
    setState(user?.location_info?.state);
    setCity(user?.location_info?.city);
    setZipCode(user?.location_info?.zipcode);
    setBio(user?.bio);
  },[]);

  // To know which tab is clicked------>
  const [tabName, setTabName] = useState('General');

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errors, setErrors] = useState([])
  const [isCurrentPasswordHidden , setIsCurrentPasswordHidden] = React.useState(true)
  const [isNewPasswordHidden , setIsNewPasswordHidden] = React.useState(true)
  const [isRepeatPasswordHidden , setIsRepeatPasswordHidden] = React.useState(true)


  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const { showAlert } = useContext(AlertContext);

  const SecondaryButton = ({width}) => {
    return(
      <Button
        text="Reset All"
        preset="default"
        onPress={() =>{
          setEmailAlerts(true);
          setPushNotifications(true);
          setChatAlerts(true);
          setTextMessages(true);
          setPhoneCalls(true);
          setWhatsappAlerts(true);
        }}          
        style={[{ backgroundColor: colors.palette.neutral100}, { borderRadius: 5, borderColor: colors.palette.primary300 }, { width: width }]}
        pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }, { borderRadius: 5}, {borderWidth:0 }]}
        textStyle={[{ fontSize: 14 }, { color: colors.palette.primary300 }]}
        pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
      />
    )
  }
  
  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
            <Box justifyContent={'center'} alignItems='center' style={{ backgroundColor: 'red', marginTop: 100}}>
         {/* <Pressable onPress={()=>{navigation.navigate('Welcome')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('About')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('Blog')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('BlogDetail')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('Contact')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('FAQ')}}> */}
         <Pressable onPress={()=>{navigation.navigate('Signin')}}>
         {/* <Pressable onPress={()=>{navigation.navigate('Signup')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('ResetPassword')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('ForgotPassword')}}> */}
         {/* <Pressable onPress={()=>{navigation.navigate('PasswordChanged')}}> */}
        

         {/* <Pressable onPress={()=>{navigation.navigate('Welcome')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('ProductDescription')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Notifications')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Chat')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('AddNewProduct')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('AddNewOffer')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('GeneralSettings')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('OpenChat')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Review')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('SellerProfile')}}> */}
          {/* <Pressable onPress={()=>{navigation.navigate('Wishlist')}}> */}
            <Text> General Settings Route-- </Text>
          </Pressable>
      </Box>
    </Screen>
    )
  }

  const CurrentPasswordRightAccessory = React.useMemo(
    () =>
      function CurrentPasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isCurrentPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsCurrentPasswordHidden(!isCurrentPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isCurrentPasswordHidden],
  )

  const NewPasswordRightAccessory = React.useMemo(
    () =>
      function NewPasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isCurrentPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsNewPasswordHidden(!isNewPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isNewPasswordHidden],
  )

  const RepeatPasswordRightAccessory = React.useMemo(
    () =>
      function RepeatPasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Feather 
          name={isCurrentPasswordHidden ? "eye-off" : "eye"} 
          size={24} 
          style={{alignSelf: 'center', margin: 5, position: 'absolute', right: 0}}
          color={colors.palette.neutral800}  
          onPress={() => {setIsRepeatPasswordHidden(!isRepeatPasswordHidden); console.log('toggle password')}}/>
        )
      },
    [isRepeatPasswordHidden],
  )

  //validation error ----->
  const currentPasswordError = isSubmitted ? currentPasswordValidationError : ""
  const newPasswordError = isSubmitted ? newPasswordValidationError : ""
  const repeatPasswordError = isSubmitted ? repeatPasswordValidationError : ""

  const RegBusinessNameError = isSubmitted ? RegBusinessNameValidationError : ""
  const GSTNumberError = isSubmitted ? GSTNumberValidationError : ""
  const DLNumberError = isSubmitted ? DLNumberValidationError : ""
  const businessAddressError = isSubmitted ? businessAddressValidationError : ""
  const managerError = isSubmitted ? managerValidationError : ""
  const business_mobileError = isSubmitted ? business_mobileValidationError : ""
  const businessCountryError = isSubmitted ? businessCountryValidationError : ""
  const businessStateError = isSubmitted ? businessStateValidationError : ""
  const businessCityError = isSubmitted ? businessCityValidationError : ""
  const businessZipCodeError = isSubmitted ? businessZipCodeValidationError : ""

  const cardNumberError = isSubmitted ? cardNumberValidationError : ""
  const cardHolderNameError = isSubmitted ? cardHolderNameValidationError : ""
  const expiryError = isSubmitted ? expiryValidationError : ""
  const billingZipCodeError = isSubmitted ? billingZipCodeValidationError : ""
  const CVVError = isSubmitted ? CVVValidationError : ""

  const fullNameError = isSubmitted ? fullNameValidationError : ""
  const emailError = isSubmitted ? emailValidationError : ""
  const mobileError = isSubmitted ? mobileValidationError : ""
  const addressError = isSubmitted ? addressValidationError : ""
  const countryError = isSubmitted ? countryValidationError : ""
  const stateError = isSubmitted ? stateValidationError : ""
  const cityError = isSubmitted ? cityValidationError : ""
  const zipCodeError = isSubmitted ? zipCodeValidationError : ""
  const bioError = isSubmitted ? bioValidationError : ""

    const updatePublicStatus = (type) => {
      let data = {}
      if(type === 'switchPublicProfile')
        data = {                           
          "is_public_profile": !switchPublicProfile,
        }
      if(type === 'switchExposeEmail')
        data =  {                           
          "is_show_email": !switchExposeEmail,
        }
      
      api.apisauce.patch('/rest-auth/user/', data)
      .then((response: any) => {authenticationStore.fetchUserInformation(setOverlayVisible);})
      .catch((error: any) => {console.error(error.message);})
      .finally(() => {setOverlayVisible(false);})
    }
  
    const updateProfileImage = (profileImage: any) => {
      try {
        setOverlayVisible(true)
        const formData = new FormData();         
        formData.append('profile.avatar', profileImage);          
        
        api.apisauce.patch('/rest-auth/user/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((response: any) => {
          showAlert("Success", "Hurrey! Profile photo is updated successfully.")
          authenticationStore.fetchUserInformation(setOverlayVisible);
        })
        .catch((error: any) => {
          showAlert("Error", "Oh! Could not update your profile photo. Please try again.")
          console.error(error.message);
        })
        .finally(() => {setOverlayVisible(false);})     
  
      } catch (error) {
        console.log(error);
      } 
    }
  
    const saveInformation = (type:any) => {    
      setErrors([])
      setIsSubmitted(true)
      console.log("Saving information:",type)
      if(['general', 'notification'].includes(type)) {
        if (fullNameValidationError) return
        if (emailValidationError) return
        if (mobileValidationError) return
        if (addressValidationError) return
        if (countryValidationError) return
        if (stateValidationError) return
        if (cityValidationError) return
        if (zipCodeValidationError) return
        if (bioValidationError) return

        setOverlayVisible(true)
        const data = {
          "first_name": fullName.split(' ')[0],
          "last_name": fullName.split(' ').length>1 ? fullName.split(' ')[1] : fullName.split(' ')[0],
          "email": email,
          "mobile": mobile,
          "bio": bio,
          "location":location,
          // "is_seller": false,
          // "is_buyer": true,
          "is_public_profile": switchPublicProfile,
          "is_show_email": switchExposeEmail,
          "profile": {
              "avatar": null,
              "email_alert": emailAlerts,
              "push_notification": pushNotifications,
              "text_message": textMessages,
              "phone_call": phoneCalls,
              "whatsapp_alert": whatsappAlerts,
              "chat_alert": chatAlerts
          },
        }
        authenticationStore.updateUser(data, setOverlayVisible, showAlert)
      } 
      if(type==='business') {
        if (RegBusinessNameValidationError) return 
        if (GSTNumberValidationError) return 
        if (DLNumberValidationError) return 
        if (businessAddressValidationError) return 
        if (managerValidationError) return
        if (business_mobileValidationError) return
        if (businessCountryValidationError) return
        if (businessStateValidationError) return
        if (businessCityValidationError) return
        if (businessZipCodeValidationError) return

        setOverlayVisible(true)
        const data = {
          "business_name": RegBusinessName,
          "business_address": businessAddress,
          "registration_number": GSTNumber,
          "license_number": DLNumber,
        }
        authenticationStore.updateSeller(data, setOverlayVisible, showAlert).then((response:any)=>{ 
          console.log(response)
          if(response.kind==='ok')
            navigation.navigate( 'Home');
          else{
            setErrors(response.non_field_errors)
          }
        })
      }
      if(type === 'security')  {
        if (currentPasswordValidationError) return 
        if (newPasswordValidationError) return 
        if (repeatPasswordValidationError) return 

        setOverlayVisible(true)
        const data = {
          "new_password1":repeatPassword,
          "new_password2":newPassword,
          "old_password":currentPassword,
        }
        authenticationStore.updatePassword(data, setOverlayVisible, showAlert).then((response:any)=>{ 
          console.log(response)
          if(response.kind==='ok')
            navigation.navigate( 'Home');
          else{
            setErrors(response.non_field_errors)
          }
        })
      }
      if(type=== 'billing'){   
        if(cardType == ""){
          showAlert("Error","Please select card type")
          return
        }
        if (cardNumberValidationError) return
        if (cardHolderNameValidationError) return
        if (expiryValidationError) return
        // if (billingZipCodeValidationError) return
        if (CVVValidationError) return

        setOverlayVisible(true)
        let date = expiry.split('/')
        let exp = '20'+date[1]+'-'+date[0]+'-01'
        const data = {"cardNumber":cardNumber.split(' ').join(""), cardHolderName, exp, CVV, cardType}        
        authenticationStore.updateBilling(data, setOverlayVisible, showAlert).then(()=>{ 
          // navigation.navigate( 'Home');          
        }).catch((error:any)=>{setErrors(error.non_field_errors)})
      } 
      setIsSubmitted(false)
    }

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Credit Card");
  const [items, setItems] = useState([
    {label: 'Credit Card', value: 'credit'},
    {label: 'Debit Card', value: 'debit'}
  ]);

  const [ showLogoutModal, setShowLogoutModal ] = React.useState(false);
  const [paymentOption, setPaymentOption] = React.useState("1");
  return (
  <>
    {/* divert code for web and android here  */}
      {Platform.select({ 
        default : 
          <Screen style={$root} preset="scroll">
            <PageLayout  variant={authenticationStore?.isAuthenticated?1:0}>            
              <Stack w="100%" mb={100}>              
                <VStack>
      
                  {/* Go back navigation */}
                  { tabName === 'General' ? 
                  <>
                    <AutoImage
                      source={require("../../../../assets/images/account_settings/header_setting.png")}
                      maxHeight={330}
                      style={{ width: "100%", height: '23vw' }}
                      resizeMode="contain"
                    />
                    {/* go back */}
                    <VStack space={{base: 0, lg:2}} ml={{base: 5,sm: 8, lg: 20}} mr={{base: 5,sm: 8, lg: 20}} mt={{base: 3,md: 5}} style={{ position: 'absolute', left: 0, top: 0}}>
                      <HStack alignItems="center">
                        <Pressable onPress={() => navigation.goBack()}>
                          <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                        </Pressable>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.bold,
                            fontSize: goBackHeading,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            Account Settings
                        </Text>
                      </HStack>
                      { showAboveSm && <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.normal,
                          fontSize: 16,
                          lineHeight: 24,
                          color: colors.palette.neutral300,
                        }}
                        >
                          Change account information and settings
                      </Text>}
                    </VStack>
      
                    {/* profile image */}
                    <HStack>
                      <VStack space={5} w={{base: '50%', md: '40%',lg: '35%', xl:'25%'}} style={{ marginTop: profilePhotoMarginTop, alignItems: 'center'}}> 
                        
                          <Dropzone 
                          onDrop={(acceptedFiles) => {                            
                            // setProfileImage(acceptedFiles);
                            updateProfileImage(acceptedFiles[0]);
                          }} 
                          maxFiles={1} multiple={false}>
                          {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                              <Box>         
                                <Entypo name="camera" size={16} color="white" 
                                  style={{
                                    backgroundColor:colors.palette.primary600, 
                                    padding:5, borderRadius:20,
                                    position: 'absolute',
                                    zIndex:10,
                                    bottom:16,
                                    right:20,
                                  }}
                                /> 
                                {user?.profile?.avatar ? 
                                <AutoImage
                                  source={{uri:user?.profile?.avatar}}
                                  maxHeight={profilePhotoSize}
                                  style={{ width: profilePhotoSize, borderRadius: profilePhotoBorderSize, borderColor: colors.palette.secondary300, borderWidth: 5}}
                                />
                                :
                                <AutoImage
                                  source={require('../../../../assets/images/user_profile.png')}
                                  maxHeight={profilePhotoSize}
                                  style={{ width: profilePhotoSize, borderRadius: profilePhotoBorderSize, borderColor: colors.palette.secondary300, borderWidth: 5}}
                                />
                                }                           
                              </Box>
                            </div>                          
                          )}
                          </Dropzone> 
                                                
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.bold,
                            fontSize: 24,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            {`${user?.first_name} ${user?.last_name}`}
                        </Text>
                      </VStack>
                      <Box w={{base:'0%', md: '60%',lg: '65%', xl:'75%'}}>
      
                      </Box>
                    </HStack> 
                  </>
                  : 
                  <VStack space={1} pl={{base: 5,sm: 8, lg: 20}} pr={{base: 5,sm: 8, lg: 20}} pt={3} pb={3} style={{ backgroundColor: colors.palette.secondary100}}>
                      <HStack alignItems="center">
                        <Pressable onPress={() => navigation.goBack()}>
                          <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                        </Pressable>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.bold,
                            fontSize: goBackHeading,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            Account Settings
                        </Text>
                      </HStack>
                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.normal,
                          fontSize: 16,
                          lineHeight: 24,
                          color: colors.palette.neutral300,
                        }}
                        >
                          Change account information and settings
                      </Text>
                  </VStack>
                  }
                  
      
                  {/* Parent HStack */}
                  <HStack w={'100%'}>
      
                    {/* SidePannel */}
                    <VStack w={{base: 100,sm: 190, md:250}}>
                      <Box h={100}></Box>
                      <VStack pl={'3vw'} pr={{base: 5,sm: 7,md:10}} space={5} style={{borderRightColor:'rgba(180, 190, 200, .1)', borderRightWidth: 3}}>
                        <Pressable onPress={()=>{setTabName('General')}}>
                          <HStack space={2} p={3} alignItems='center' backgroundColor={ tabName == 'General' && colors.palette.secondary100}>
                            <AutoImage
                              source={require("../../../../assets/images/account_settings/General.png")}
                              maxHeight={sidePannelIconSize}
                              style={{ width: sidePannelIconSize }}
                              resizeMode="contain"
                            />
                              {showAboveSm && <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.medium,
                                  fontSize: 16,
                                  lineHeight: 24,
                                  color: colors.palette.neutral600,
                                }}
                                >
                                  General
                              </Text>}
                          </HStack>
                        </Pressable>
                        <Pressable onPress={()=>{setTabName('Business')}}>
                          <HStack space={2} p={3} alignItems='center' backgroundColor={ tabName == 'Business' && colors.palette.secondary100}>
                            <AutoImage
                              source={require("../../../../assets/images/account_settings/Add_business.png")}
                              maxHeight={sidePannelIconSize}
                              style={{ width: sidePannelIconSize }}
                              resizeMode="contain"
                            />
                            {showAboveSm &&<Text 
                              style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral600,
                              }}
                              >
                                Business
                            </Text>}
                          </HStack>
                        </Pressable>
                        <Pressable onPress={()=>{setTabName('Security')}}>
                          <HStack space={2} p={3} alignItems='center' backgroundColor={ tabName == 'Security' && colors.palette.secondary100}>
                            <AutoImage
                              source={require("../../../../assets/images/account_settings/Security.png")}
                              maxHeight={sidePannelIconSize}
                              style={{ width: sidePannelIconSize }}
                              resizeMode="contain"
                            />
                            {showAboveSm &&<Text 
                              style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral600,
                              }}
                              >
                                Security
                            </Text>}
                          </HStack>
                        </Pressable>
                        <Pressable onPress={()=>{setTabName('Billing')}}>
                          <HStack space={2} p={3} alignItems='center' backgroundColor={ tabName == 'Billing' && colors.palette.secondary100}>
                            <AutoImage
                              source={require("../../../../assets/images/account_settings/Billing.png")}
                              maxHeight={sidePannelIconSize}
                              style={{ width: sidePannelIconSize }}
                              resizeMode="contain"
                            />
                            {showAboveSm &&<Text 
                              style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral600,
                              }}
                              >
                                Billing
                            </Text>}
                          </HStack>
                        </Pressable>
                        <Pressable onPress={()=>{setTabName('Notification')}}>
                          <HStack space={2} p={3} alignItems='center' backgroundColor={ tabName == 'Notification' && colors.palette.secondary100}>
                            <AutoImage
                              source={require("../../../../assets/images/account_settings/Notifications.png")}
                              maxHeight={sidePannelIconSize}
                              style={{ width: sidePannelIconSize }}
                              resizeMode="contain"
                            />
                            {showAboveSm &&<Text 
                              style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral600,
                              }}
                              >
                                Notification
                            </Text>}
                          </HStack>
                        </Pressable>
                        <Pressable onPress={()=>{setShowLogoutModal(true)}}>
                          <HStack space={2} p={3} alignItems='center' backgroundColor={ tabName == 'Logout' && colors.palette.secondary100}>
                            <AutoImage
                              source={require("../../../../assets/images/account_settings/Logout.png")}
                              maxHeight={sidePannelIconSize}
                              style={{ width: sidePannelIconSize }}
                              resizeMode="contain"
                            />
                            {showAboveSm && <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral600,
                              }}
                              >
                                Logout
                            </Text>}
                          </HStack>
                        </Pressable>
                        <LogoutModal showLogoutModal={showLogoutModal} setShowLogoutModal={setShowLogoutModal}/>
                      </VStack>
                    </VStack>
      
                    {/* General tab ----> Change Your input fields */}
                    { tabName == 'General' && <VStack w={{base: '70%', sm: '65%',md: '75%',lg:"80%"}} p={'3vw'} space={8}>
                      {/* heading */}
                      <VStack space={2}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.semiBold,
                            fontSize: fontSizeHeading,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            Change your private information
                        </Text>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral400,
                          }}
                          >
                            Please read our <Pressable onPress={()=>navigation.navigate("TermsAndCondition")}><Text style={{color: colors.palette.secondary300}}>terms of use</Text></Pressable> to be informed how we manage your private data.
                        </Text>
                      </VStack>
                      {/* input fileds */}

                      <VStack space={{base: 4,md:7}}>
                        {/* General tab --> 1st row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Full Name"
                              value={fullName}              
                              onChangeText={(value) => setFullName(value)}
                              label="Full Name*"
                              placeholder="Enter your full name"
                              helper={fullNameError}
                              status={fullNameError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Email Address"
                              value={email}              
                              onChangeText={(value) => setEmail(value.toLocaleLowerCase().trim())}
                              label="Email Address*"
                              placeholder="sample@neaxpiry.com"
                              helper={emailError}
                              status={emailError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                        </Stack>
      
                        {/* General tab --> 2st row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Mobile Number"
                              value={mobile}              
                              onChangeText={(value) => setMobile(value)}
                              label="Mobile Number*"
                              placeholder="Enter Mobile Number"
                              helper={mobileError}
                              status={mobileError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Address"
                              value={address}              
                              onChangeText={(value) => setAddress(value)}
                              label="Address*"
                              placeholder="Enter address"
                              helper={addressError}
                              status={addressError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                        </Stack>
      
                        {/* General tab --> 3rd row 4 input fields   */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <HStack w={{base: '100%',md:'48%'}} justifyContent={'space-between'}>
                            <Box w={'49%'}>
                              <InputField
                                key="Country"
                                value={country}              
                                onChangeText={(value) => setCountry(value)}
                                label="Country*"
                                placeholder="Your country"
                                editable={false}                                
                                helper={countryError}
                                status={countryError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                            <Box w={'49%'}>
                              <InputField
                                key="State"
                                value={state}              
                                onChangeText={(value) => setState(value)}
                                label="State*"
                                placeholder="Your State"
                                editable={false}
                                helper={stateError}
                                status={stateError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                          </HStack>
                          <HStack w={{base: '100%',md:'48%'}} justifyContent={'space-between'}>
                            <Box w={'49%'}>
                              <InputField
                                key="City"
                                value={city}              
                                onChangeText={(value) => setCity(value)}
                                label="City*"
                                placeholder="Your City"
                                editable={false}
                                helper={cityError}
                                status={cityError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                            <Box w={'49%'}>
                              <InputField
                                key="Zip Code"
                                value={zipCode}              
                                onChangeText={async (value) => {   
                                    setZipCode(value)
                                    await api.apisauce.post('zipcodeLookup/',{"zipcode": value})
                                    .then((response:any) => {                                      
                                      if (response.ok){
                                        // console.log(response)
                                        if (response.data.country != null) {    
                                          // console.log("coutuntry not null")                      
                                          setCountry(response.data.country)
                                          setState(response.data.state)
                                          setCity(response.data.city)
                                          setLocation((response.data.location).toString())
                                        }
                                      }                                 
                                    })
                                  }
                                }                                
                                label="Zip Code*"
                                placeholder="Enter ZIP Code"
                                helper={zipCodeError}
                                status={zipCodeError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                          </HStack>
                        </Stack>
      
                        {/* General tab -->  4th row (bio) */}
                        <Box w={'100%'}>
                          <InputField
                            multiline
                            style={{height: 100}}
                            key="Bio"
                            value={bio}
                            onChangeText={(value) => setBio(value)}
                            label="Bio"
                            placeholder="Write your bio here for your distribution network and manufacturer to see."
                            helper={bioError}
                            status={bioError ? "error" : undefined}
                            // RightAccessory={() => <Icon icon="check" />}
                            // LeftAccessory={() => <Icon icon="bell" />}
                          />
                        </Box>
                      </VStack>
      
                      {/* save button */}
                      <Button
                          text='Save'
                          preset="default"
                          onPress={() => saveInformation("general")}
                          style={{
                            backgroundColor: colors.palette.primary600,
                            borderRadius: 5,
                            width: buttonSize,
                          }}
                          pressedStyle={{
                            backgroundColor: colors.palette.neutral100,
                            borderRadius: 5,
                            borderWidth: 0,
                        
                          }}
                          textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                          pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                        />
                      <Pressable onPress={()=>{ setTabName('Billing');}}>
                        <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral400,
                            }}
                            >
                              You may also consider to update your <Text style={{color: colors.palette.secondary300}}> billing information.</Text> 
                          </Text>
                        </Pressable>
                    </VStack>}
      
                    {/* Business tab ----> Keep your business information up to date  */}
                    { tabName == 'Business' && <VStack w={{base: '70%', sm: '65%',md: '75%',lg:"80%"}} p={'3vw'} space={8}>
                      {/* heading */}
                      <VStack space={2}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.semiBold,
                            fontSize: fontSizeHeading,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            Keep your business information up to date
                        </Text>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral400,
                          }}
                          >
                            Please read our <Pressable onPress={()=>navigation.navigate("TermsAndCondition")}><Text style={{color: colors.palette.secondary300}}>terms of use</Text></Pressable> to be informed how we manage your private data.
                        </Text>
                      </VStack>
                      
                      {/* input fileds */}
                      <VStack space={{base: 4,md:7}}>
      
                        {/* Business tab --> 1st row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Reg. Business Name"
                              value={RegBusinessName}              
                              onChangeText={(value) => setRegBusinessName(value)}
                              label="Reg. Business Name*"
                              placeholder="Enter Registered Business Name"
                              helper={RegBusinessNameError}
                              status={RegBusinessNameError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="GST Number"
                              value={GSTNumber}              
                              onChangeText={(value) => setGSTNumber(value)}
                              label="GST Number*"
                              placeholder="Enter GST Number"
                              helper={GSTNumberError}
                              status={GSTNumberError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                        </Stack>
      
                        {/* Business tab --> 2nd row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="DL Number"
                              value={DLNumber}              
                              onChangeText={(value) => setDLNumber(value)}
                              label="DL Number*"
                              placeholder="Enter your Drug License Number"
                              helper={DLNumberError}
                              status={DLNumberError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Business Address"
                              value={businessAddress}              
                              onChangeText={(value) => setbusinessAddress(value)}
                              label="Business Address*"
                              placeholder="Enter Your Business Address"
                              helper={businessAddressError}
                              status={businessAddressError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                        </Stack>
      
                        {/* Business tab --> 3rd row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                                key="Manager"
                                value={manager}              
                                onChangeText={(value) => setManager(value)}
                                label="Manager*"
                                placeholder="Your Mobile"
                                editable={false}
                                helper={managerError}
                                status={managerError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                          </Box>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                                key="Mobile"
                                value={business_mobile}              
                                onChangeText={(value) => setBusiness_Mobile(value)}
                                label="Mobile*"
                                placeholder="Your Mobile"
                                // editable={false}
                                helper={business_mobileError}
                                status={business_mobileError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                          </Box>
                        </Stack>
                        
                        {/* Business tab --> 4rd row--> 4 input fields   */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <HStack w={{base: '100%',md:'48%'}} justifyContent={'space-between'}>
                            <Box w={'49%'}>
                              <InputField
                                key="Country"
                                value={businessCountry}              
                                onChangeText={(value) => setBusinessCountry(value)}
                                label="Country*"
                                placeholder="Your country"
                                editable={false}
                                helper={businessCountryError}
                                status={businessCountryError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                            <Box w={'49%'}>
                              <InputField
                                key="State"
                                value={businessState}              
                                onChangeText={(value) => setBusinessState(value)}
                                label="State*"
                                placeholder="Your business state"
                                editable={false}
                                helper={businessStateError}
                                status={businessStateError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                          </HStack>
                          <HStack w={{base: '100%',md:'48%'}} justifyContent={'space-between'}>
                            <Box w={'49%'}>
                              <InputField
                                key="City"
                                value={businessCity}              
                                onChangeText={(value) => setBusinessCity(value)}
                                label="City*"
                                placeholder="Your business city"
                                editable={false}
                                helper={businessCityError}
                                status={businessCityError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                            <Box w={'49%'}>
                              <InputField
                                key="Zip Code" 
                                value={businessZipCode}              
                                onChangeText={(value) => setBusinessZipCode(value)}
                                label="Zip Code*"
                                placeholder="324007"                                
                                helper={businessZipCodeError}
                                status={businessZipCodeError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                            </Box>
                          </HStack>
                        </Stack>
      
                      </VStack>
      
                      {/* save button */}
                      <Button
                          text='Save'
                          preset="default"
                          onPress={() => saveInformation("business")}
                          style={{
                            backgroundColor: colors.palette.primary600,
                            borderRadius: 5,
                            width: buttonSize,
                          }}
                          pressedStyle={{
                            backgroundColor: colors.palette.neutral100,
                            borderRadius: 5,
                            borderWidth: 0,
                        
                          }}
                          textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                          pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                        />
                      <Pressable onPress={()=>{setTabName('Billing')}}>
                        <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral400,
                            }}
                            >
                              You may also consider to update your <Text style={{color: colors.palette.secondary300}}> billing information.</Text> 
                          </Text>
                        </Pressable>
                    </VStack>}
                    
                    {/* Security tab ----> Change your password */}
                    { tabName == 'Security' && <VStack w={{base: '70%', sm: '65%',md: '75%',lg:"80%"}} p={'3vw'} space={8}>
                      {/* heading */}
                      <VStack space={2}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.semiBold,
                            fontSize: fontSizeHeading,
                            lineHeight: 28.8,
                            color: colors.palette.neutral700,
                          }}
                          >
                            Change your password
                        </Text>
                      </VStack>
                      
                      {/* input fileds */}
                      <VStack space={{base: 4,md:7}}>
      
                        {/* Security tab --> 1st row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Current Password"
                              value={currentPassword}              
                              onChangeText={(value) => setCurrentPassword(value)}
                              label="Current Password"
                              placeholder="************"
                              helper={currentPasswordError}
                              status={currentPasswordError ? "error" : undefined}
                              secureTextEntry={isCurrentPasswordHidden}
                              RightAccessory={CurrentPasswordRightAccessory}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="New Password"
                              value={newPassword}              
                              onChangeText={(value) => setNewPassword(value)}
                              label="New Password*"
                              placeholder="************"
                              helper={newPasswordError}
                              status={newPasswordError ? "error" : undefined}
                              secureTextEntry={isNewPasswordHidden}
                              RightAccessory={NewPasswordRightAccessory}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                        </Stack>
      
                        {/* Security tab --> 2nd row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Repeat Password"
                              value={repeatPassword}              
                              onChangeText={(value) => setRepeatPassword(value)}
                              label="Repeat Password*"
                              placeholder="************"
                              helper={repeatPasswordError}
                              status={repeatPasswordError ? "error" : undefined}
                              secureTextEntry={isRepeatPasswordHidden}
                              RightAccessory={RepeatPasswordRightAccessory}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                        </Stack>
      
                      </VStack>
      
                      {/* save button */}
                      <Button
                          text='Save'
                          preset="default"
                          onPress={() => saveInformation("security")}
                          style={{
                            backgroundColor: colors.palette.primary600,
                            borderRadius: 5,
                            width: buttonSize,
                          }}
                          pressedStyle={{
                            backgroundColor: colors.palette.neutral100,
                            borderRadius: 5,
                            borderWidth: 0,
                        
                          }}
                          textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                          pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                        />
                      
                      <HStack space={2}>
                        <Switch value={switchPublicProfile} onValueChange={() => {                          
                          updatePublicStatus("switchPublicProfile");     
                          setSwitchPublicProfile(!switchPublicProfile);  
                        }}/>
                        <Text style={{color: colors.palette.neutral400}}>Public Profile</Text>
                      </HStack>
                      <HStack space={2}>
                        <Switch value={switchExposeEmail} onValueChange={() => {                          
                          updatePublicStatus("switchExposeEmail");
                          setSwitchExposeEmail(!switchExposeEmail);
                        }}/>
                        <Text style={{color: colors.palette.neutral400}}>Expose your email</Text>
                      </HStack>
                      <Pressable onPress={()=>{setTabName('Notification')}}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral400,
                          }}
                          >
                            You may also consider to update your <Text style={{color: colors.palette.secondary300}}> notification settings.</Text> 
                        </Text>
                      </Pressable>
                    </VStack>}
                    
                    {/* Billing tab ----> Change your card data */}
                    { tabName == 'Billing' && <VStack w={{base: '70%', sm: '65%',md: '75%',lg:"80%"}} p={'3vw'} space={8}>
                      {/* heading */}
                      <Stack direction={{base: 'column', lg: 'row'}}>
                        <VStack w={{base:"85%", lg: '75%'}} space={2}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: fontSizeHeading,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              Change your card data
                          </Text>
                          <VStack>
                            <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.normal,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral400,
                              }}
                              >
                              Please be informed that we do not share any sensitive information such as your bank card data with any third party agencies and companies. 
                            </Text>
                            <Text style={{
                                fontFamily: typography.fonts.poppins.normal,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral400,
                              }}>
                              Please read our <Pressable onPress={()=>navigation.navigate("TermsAndCondition")}><Text style={{color: colors.palette.secondary300}}>terms of use</Text></Pressable> to be informed how we manage your bank data.
                            </Text>
                          </VStack>                          
                        </VStack>
                        {/* reset all button USE IF REQUIRED */}
                        {/* <Box w={resetButtonBoxSize} alignItems={{base: 'flex-start',lg:'flex-end'}} mt={{base: 2, lg: 0}} justifyContent={'center'}>
                          <SecondaryButton width={resetButtonSize}/>
                        </Box> */}
                      </Stack>
                      
                      
                      {/* input fileds */}
                      <VStack space={{base: 4,md:7}}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral400,
                          }}
                          >
                            Card Type (Credit/Debit)
                        </Text>
                        <DropDownPicker
                          zIndex={1000}                          
                          open={open}
                          value={value}
                          items={items}
                          setOpen={setOpen}
                          setValue={setValue}
                          setItems={setItems}                          
                          onChangeValue={(value)=>{
                            setCardType(value);
                          }}
                          containerStyle={{
                            zIndex:1000,
                            borderRadius:2
                          }}
                        /> 
                        {/* Billing tab --> 1st row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Card Number"
                              value={cardNumber}              
                              onChangeText={(value) => setCardNumber(value)}
                              label="Card Number*"
                              placeholder="9586 8666 8785 2578"
                              helper={cardNumberError}
                              status={cardNumberError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Card Holder Name"
                              value={cardHolderName}              
                              onChangeText={(value) => setCardHolderName(value)}
                              label="Card Holder Name*"
                              placeholder="Name Mentioned on Card"
                              helper={cardHolderNameError}
                              status={cardHolderNameError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>
                        </Stack>
      
                        {/* Billing tab --> 2nd row */}
                        <Stack space={{base: 4,md:0}} direction={{base: 'column', md: 'row'}} w={'100%'} justifyContent={'space-between'}>
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                              key="Expiry"
                              value={expiry}              
                              onChangeText={(value) => setExpiry(value)}
                              label="Expiry*"
                              placeholder="Enter Expiry Date Mentioned on Card" 
                              helper={expiryError}
                              status={expiryError ? "error" : undefined}
                              // RightAccessory={() => <Icon icon="check" />}
                              // LeftAccessory={() => <Icon icon="bell" />}
                            /> 
                          </Box>    
                          <Box w={{base: '100%',md:'48%'}}>
                            <InputField
                                key="CVV"
                                value={CVV}              
                                onChangeText={(value) => setCVV(value)}
                                label="CVV*"
                                placeholder="967"
                                helper={CVVError}
                                status={CVVError ? "error" : undefined}
                                // RightAccessory={() => <Icon icon="check" />}
                                // LeftAccessory={() => <Icon icon="bell" />}
                              />
                          </Box>                     
                        </Stack>
                      </VStack>
      
                      {/* save button */}
                      <Button
                          text='Save'
                          preset="default"                          
                          onPress={() => {console.log("save billing"); saveInformation("billing");}}
                          style={{
                            backgroundColor: colors.palette.primary600,
                            borderRadius: 5,
                            width: buttonSize ,
                            zIndex:-1
                          }}
                          pressedStyle={{
                            backgroundColor: colors.palette.neutral100,
                            borderRadius: 5,
                            borderWidth: 0,
                        
                          }}
                          textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                          pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                        />
                      <Pressable onPress={()=>{setTabName('Billing')}} style={{zIndex:-1}}>
                        <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 16,
                              lineHeight: 24,
                              color: colors.palette.neutral400,
                            }}
                            >
                              You may also consider to update your <Text style={{color: colors.palette.secondary300}}> billing information.</Text> 
                          </Text>
                      </Pressable>

                      <Text 
                        style={{
                          fontFamily: typography.fonts.poppins.semiBold,
                          fontSize: fontSizeHeading,
                          lineHeight: 28.8,
                          color: colors.palette.neutral700,
                        }}
                        >
                          Saved Cards ({user.payment_options.length})
                      </Text>
                      <Radio.Group 
                        name="payment_options" 
                        accessibilityLabel="favorite payment option" 
                        value={value} 
                        onChange={nextValue => {
                          setPaymentOption(nextValue);
                        }}
                        w="100%"
                      >
                      <VStack w="100%" >
                        {user && user?.payment_options.map((card:any, index) =>{
                          return (
                            <Stack 
                              flexDirection="row" 
                              justifyContent="space-between" 
                              alignItems="center" w="100%" 
                              style={{
                                borderColor: colors.palette.primary600,
                                borderWidth:1,  
                                borderRadius:5                               
                              }}              
                              mb={2}  
                              p={2}              
                            > 
                                <Radio value={`${index}`} my={1}>
                                  <VStack>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.semiBold,
                                        fontSize: 14,
                                        color: colors.palette.neutral700,
                                      }}
                                      >
                                        {card.card_number.match(/.{1,4}/g).join('-')}
                                    </Text>
                                    <Text 
                                      style={{
                                        fontFamily: typography.fonts.poppins.semiBold,
                                        fontSize: 14,
                                        color: colors.palette.primary400,
                                      }}
                                      >
                                        {card.cardholder_name}
                                    </Text>
                                  </VStack>
                                </Radio>                                                         
                               
                                <Button
                                  text='Remove'
                                  preset="default"                          
                                  onPress={() => {
                                    console.log("Remove Card Pressed")                                    
                                    authenticationStore.deletePaymentOption(card.id, setOverlayVisible, showAlert)
                                    .then(() => {
                                      authenticationStore.fetchUserData(setOverlayVisible);
                                    });                                    
                                  }}
                                  style={{
                                    backgroundColor: colors.palette.primary600,
                                    borderRadius: 5,
                                    width: buttonSize ,
                                    zIndex:-1
                                  }}
                                  pressedStyle={{
                                    backgroundColor: colors.palette.neutral100,
                                    borderRadius: 5,
                                    borderWidth: 0,
                                
                                  }}
                                  textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                                  pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                                />
                            </Stack>
                          )
                        })}
                        </VStack>
                      </Radio.Group>;
                    </VStack>}
      
                    {/* Notification tab ----> Update website notifications */}
                    { tabName == 'Notification' && <VStack w={{base: '70%', sm: '65%',md: '75%',lg:"80%"}} p={'5vw'} space={8}>
                      {/* heading */}
                      <Stack space={{base:3, md: 0}}  direction={{base: 'column', md: 'row'}}>
                        <VStack w={"75%"}>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.semiBold,
                              fontSize: fontSizeHeading,
                              lineHeight: 28.8,
                              color: colors.palette.neutral700,
                            }}
                            >
                              Update website notifications
                          </Text>
                        </VStack>
                        {/* reset all button */}
                        <Box w={resetButtonBoxSize} alignItems='flex-end'>
                          <SecondaryButton width={resetButtonSize}/>
                        </Box>
                      </Stack>
                      <Stack space={7} direction={{base: 'column', md: 'row'}}>
                        {/* System settings */}
                          <VStack space={7} w={{base: '100%',md:'48%'}}>
                            <Box>
                              <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.medium,
                                    fontSize: 18,
                                    lineHeight: 22,
                                    color: colors.palette.neutral800,
                                  }}
                                  >
                                    System settings
                                </Text>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 16,
                                    lineHeight: 24,
                                    color: colors.palette.neutral400,
                                  }}
                                  >
                                    You will receive emails in your business email address
                                </Text>
                            </Box>
                            <VStack space={7}>
                              <HStack w={'100%'} space={2}>
                                <Checkbox value={emailAlerts} 
                                  onValueChange={() => {setEmailAlerts(!emailAlerts)}} 
                                  color={colors.palette.primary600}
                                  style={{ borderColor: 'gray', borderRadius: 2}}/>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 16,
                                    lineHeight: 24,
                                    color: colors.palette.neutral400,
                                  }}
                                  >
                                  E-mail Alerts
                                </Text>
                              </HStack>
                              <HStack w={'100%'} space={2}>
                                <Checkbox value={pushNotifications} 
                                  onValueChange={() => {setPushNotifications(!pushNotifications)}} 
                                  color={colors.palette.primary600}
                                  style={{ borderColor: 'gray', borderRadius: 2}}/>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 16,
                                    lineHeight: 24,
                                    color: colors.palette.neutral400,
                                  }}
                                  >
                                  Push Notifications
                                </Text>
                              </HStack>
                              <HStack w={'100%'} space={2}>
                                <Checkbox value={textMessages} 
                                  onValueChange={() => {setTextMessages(!textMessages)}} 
                                  color={colors.palette.primary600}
                                  style={{ borderColor: 'gray', borderRadius: 2}}/>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 16,
                                    lineHeight: 24,
                                    color: colors.palette.neutral400,
                                  }}
                                  >
                                  Text Messages
                                </Text>
                              </HStack>
                              <HStack w={'100%'} space={2}>
                                <Checkbox value={phoneCalls} 
                                  onValueChange={() => {setPhoneCalls(!phoneCalls)}} 
                                  color={colors.palette.primary600}
                                  style={{ borderColor: 'gray', borderRadius: 2}}/>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 16,
                                    lineHeight: 24,
                                    color: colors.palette.neutral400,
                                  }}
                                  >
                                  Phone Calls
                                </Text>
                              </HStack>
                            </VStack>
                          </VStack>
                        {/* Chat settings */}
                          <VStack space={7} w={{base: '100%',md:'48%'}}>
                            <Box>
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.medium,
                                  fontSize: 18,
                                  lineHeight: 22,
                                  color: colors.palette.neutral800,
                                }}
                                >
                                  Chat settings
                              </Text>
                              <Text 
                                style={{
                                  fontFamily: typography.fonts.poppins.normal,
                                  fontSize: 16,
                                  lineHeight: 24,
                                  color: colors.palette.neutral400,
                                }}
                                >
                                  You will receive emails in your business email address
                              </Text>
                            </Box>
                            <VStack space={7}>
                              <HStack w={'100%'} space={2}>
                                <Checkbox value={whatsappAlerts} 
                                  onValueChange={() => {setWhatsappAlerts(!whatsappAlerts)}} 
                                  color={colors.palette.primary600}
                                  style={{ borderColor: 'gray', borderRadius: 2}}/>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 16,
                                    lineHeight: 24,
                                    color: colors.palette.neutral400,
                                  }}
                                  >
                                  Whatsapp Alerts
                                </Text>
                              </HStack>
                              <HStack w={'100%'} space={2}>
                                <Checkbox value={chatAlerts} 
                                  onValueChange={() => {setChatAlerts(!chatAlerts)}}
                                  color={colors.palette.primary600}
                                  style={{ borderColor: 'gray', borderRadius: 2}}/>
                                <Text 
                                  style={{
                                    fontFamily: typography.fonts.poppins.normal,
                                    fontSize: 16,
                                    lineHeight: 24,
                                    color: colors.palette.neutral400,
                                  }}
                                  >
                                  Chat Alerts
                                </Text>
                              </HStack>
                            </VStack>
                          </VStack>
                      </Stack>
      
                      {/* save button */}
                      <Button
                          text='Save'
                          preset="default"
                          onPress={() => saveInformation("notification")}
                          style={{
                            backgroundColor: colors.palette.primary600,
                            borderRadius: 5,
                            width: buttonSize,
                          }}
                          pressedStyle={{
                            backgroundColor: colors.palette.neutral100,
                            borderRadius: 5,
                            borderWidth: 0,
                        
                          }}
                          textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                          pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                        />
                      <Pressable onPress={()=>{setTabName('Billing')}}>
                        <Text 
                          style={{
                            fontFamily: typography.fonts.poppins.normal,
                            fontSize: 16,
                            lineHeight: 24,
                            color: colors.palette.neutral400,
                          }}
                          >
                            You may also consider to update your <Text style={{color: colors.palette.secondary300}}> billing information.</Text> 
                        </Text>
                      </Pressable>
                    </VStack>}
      
                  </HStack>
                  
                </VStack>
              </Stack>
            </PageLayout>
          </Screen>, //code for web and other platforms

        native : <MobileCode/>, //code for Mobile devices
      })}
      {/* {Platform.select({ 
          web: <WebCode/>, //code for Web and other platforms
          // default : <WebCode/>
        })} 
      {Platform.select({
        native : <MobileCode/>, //code for Mobile devices
      })} */}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 


const $root: ViewStyle = {
  flex: 1,
}
