import React, { FC, useState, useEffect, useContext } from "react"
import { observer } from "mobx-react-lite"
import { Platform, Pressable, ViewStyle, StyleSheet, StatusBar } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { Button, Screen } from "../../components"
import { PageLayout } from "../../components/PageLayout"
import { Box, HStack, VStack, useBreakpointValue, Text } from "native-base"
import { colors, typography } from "../../theme"
import { useNavigation, useRoute } from "@react-navigation/native"
import { api } from "../../services/api/api"
import { ApiResponse } from "apisauce"
import { Ionicons } from "@expo/vector-icons"
import { MedicineCard } from "../AuthPages/AuthenticatedHomeScreen"
import { Checkbox } from "react-native-ui-lib"
import { TouchableOpacity } from "react-native-gesture-handler"
import { Products, useStores } from "../../models" 
import { OverlayContext } from "../../components/OverlayProvider"

// @ts-ignore
export const ProductsScreen: FC<StackScreenProps<AppStackScreenProps, "Products">> = observer(function ProductsScreen() {
  // Pull in one of our MST stores
    const { 
        authenticationStore:{isAuthenticated},
        ProductListStore: {
        products, 
        dealOfTheDayProducts,
        categories,
        brands
      } 
    } = useStores()
    const route = useRoute<any>()
    const {searchString, brandName, categoryName } = route.params?route.params:{searchString:"", brandName:"", categoryName:""}

    type ItemProps = {
        item: any;
        onPress: () => void;
        backgroundColor: string;
        textColor: string;
    };
    
    const Item = ({item, onPress, backgroundColor, textColor}: ItemProps) => (
    <TouchableOpacity onPress={onPress} style={[styles.item, {backgroundColor}]}>
        <Text style={[styles.title, {color: textColor}]}>{item.title}</Text>
    </TouchableOpacity>
    );

    const styles = StyleSheet.create({
        container: {
        flex: 1,
        marginTop: StatusBar.currentHeight || 0,
        },
        item: {
        padding: 20,
        marginVertical: 8,
        marginHorizontal: 16,
        },
        title: {
        fontSize: 32,
        },
    });

    const [selectedId, setSelectedId] = useState<string>();

    const renderItem = ({item}: {item: any}) => {
        const backgroundColor = item.id === selectedId ? '#6e3b6e' : '#f9c2ff';
        const color = item.id === selectedId ? 'white' : 'black';

        return (
        <Item
            item={item}
            onPress={() => setSelectedId(item.id)}
            backgroundColor={backgroundColor}
            textColor={color}
        />
        );
    };

  const productsItemWidthBreakpoint = useBreakpointValue({base: '100%', sm: '48%', md: '32%', lg: '24%'}) 
  const medicineCardWidthBreakpoint = useBreakpointValue({base: '85%',sm: '46%', md: "47%", lg: '31%'})
  const medicineCardMaxHeightBreakpoint = useBreakpointValue({base: 200 ,sm: 170, lg: 200})
  const medicineCardImageBreakpoint = useBreakpointValue({base: "55%" ,sm: "45%", md: '50%',lg: "55%"})
  const medicineCardContentCardBreakpoint = useBreakpointValue({base: "45%" ,sm: "55%",md: '50%', lg: "45%"})
  const medicineCardHeightBreakpoint = useBreakpointValue({base: 300, md: 350,})
  const productsHeadingBreakpoint = useBreakpointValue({base: 16 ,sm: 19, md: 21, lg: 24})
  const goBackArrowSizeBreakpoint = useBreakpointValue({base: 25, md: 30, lg: 35})
  const filterTextBreakpoint = useBreakpointValue({base: 13 ,sm: 14,md: 15, lg: 18})
  const checkboxTextBreakpoint = useBreakpointValue({base: 12 ,md: 13, lg: 16})
  const medicineTextBreakpoint = useBreakpointValue({base: 12 ,md: 13, lg: 14})
  const medicineText_2Breakpoint = useBreakpointValue({base: 11 ,md: 13, lg: 14})
    
  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const [data, setData] = useState<Products[]>([])
  const [noProductFound, setNoProductFound] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState({})  
  const [selectedBrands, setSelectedBrands] = useState({})  
  
  const { overlayVisible, setOverlayVisible } = useContext(OverlayContext);

  const setBrandsandCategories = (type:string) => {
    let temp = {}  
    console.log(categoryName)
    categories.map((category)=>{
        temp[category.name] = type!='clear' && category.name===categoryName? true: false;  
    })
    setSelectedCategories({...temp}); 
    
    temp = {}
    brands.map((brand)=>{
        temp[brand.name] = type!='clear' && brand.name===brandName? true: false;  
    })
    setSelectedBrands({...temp}); 
  }
  useEffect(()=>{  
    setBrandsandCategories('url')
  },[])

  useEffect(()=>{    
    let categorySelection = []
    Object.keys(selectedCategories).forEach((categoryName)=>{
        if (selectedCategories[categoryName] === true)
            categorySelection.push(categoryName)
    })
    
    let brandSelection = []
    Object.keys(selectedBrands).forEach((brandName)=>{
        if (selectedBrands[brandName] === true)
            brandSelection.push(brandName)
    })
    if(searchString!="" || brandName!="" || categoryName!="" || brandSelection?.length > 0 || categorySelection?.length > 0){
        fetchProducts(`?search=${searchString || ""}&category__name__in=${categorySelection.join(',')}&brand__name__in=${brandSelection.join(',')}`)
    }else{
        setNoProductFound(products.length==0)
        setData(products)
    }   
  },[selectedCategories, selectedBrands, route])

  const fetchProducts = (searchTerm:string) => {
    setOverlayVisible(true)    
    api.apisauce.get(`/products/${searchTerm}`)
    .then(
      (response:ApiResponse<any,any>)=>{
        setData(response.data)        
        setNoProductFound(response.data.length==0)
      }
    )
    .catch((error)=>{return error.message})
    .finally(()=>{setOverlayVisible(false)})
  }   

  if(products.length==0)
    setOverlayVisible(true)
  else
    setOverlayVisible(false)

  const MobileCode=()=>{
    return(
    <Screen style={$root} preset="scroll">
      <Box justifyContent={'center'} alignItems='center' style={{width: 100, backgroundColor: 'red'}}><Text>hi.....................</Text></Box>
    </Screen>
    )
  }
  
  
  return (
  <>
    {/* divert code for web and android here  */}
    {Platform.select({ 
        default : 
        <Screen style={$root} preset="scroll">
            <PageLayout variant={isAuthenticated?1:0} noProductFound={noProductFound}>
                <Box style={{minHeight:500}}>                            
                    <HStack width="100%">                           
                        {/* FILTER SECTION */}
                        <VStack w={{base: "30%", sm: "25%", md: "20%"}}  p={{base: 4, md: 5, lg: 10}} pb={100} space={5}>
                            <Pressable onPress={()=>{                                
                                // navigation.navigate("Products")
                                setBrandsandCategories("clear")
                            }}>
                                <Text 
                                    style={{
                                    fontFamily: typography.fonts.poppins.medium,
                                    fontSize: filterTextBreakpoint,
                                    lineHeight: 16,
                                    color: colors.palette.primary400,
                                    }}
                                    >
                                    Clear Filter
                                </Text>
                            </Pressable>
                            <Text 
                                style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: filterTextBreakpoint,
                                lineHeight: 22,
                                color: colors.palette.neutral800,
                                }}
                                >
                                Categories
                            </Text>
                            <VStack space={7}>
                                {Object.keys(selectedCategories).map((category,index)=>{                                    
                                    return (
                                    <HStack w={'100%'} space={2} key={`category-filter-${index}`}>
                                        <Checkbox value={selectedCategories[category]} 
                                            onValueChange={(e) => {
                                                setSelectedCategories({
                                                    ...selectedCategories, 
                                                    [category]:!selectedCategories[category]})
                                            }}
                                            color={colors.palette.primary600}
                                            style={{ borderColor: 'gray', borderRadius: 2}}/>
                                        <Text 
                                            style={{
                                                fontFamily: typography.fonts.poppins.normal,
                                                fontSize: checkboxTextBreakpoint,
                                                lineHeight: 24,
                                                color: colors.palette.neutral400,
                                            }}
                                        >
                                            {category}
                                        </Text>
                                    </HStack> )
                                })}
                                                    
                            </VStack>

                            <Text 
                                style={{
                                fontFamily: typography.fonts.poppins.medium,
                                fontSize: filterTextBreakpoint,
                                lineHeight: 22,
                                color: colors.palette.neutral800,
                                }}
                                >
                                Brands
                            </Text>
                            <VStack space={7}>
                                {Object.keys(selectedBrands).map((brand,index)=>{                                    
                                    return (
                                    <HStack w={'100%'} space={2} key={`brand-filter-${index}`}>
                                        <Checkbox value={selectedBrands[brand]} 
                                            onValueChange={(e) => {
                                                setSelectedBrands({
                                                    ...selectedBrands, 
                                                    [brand]:!selectedBrands[brand]})
                                            }}
                                            color={colors.palette.primary600}
                                            style={{ borderColor: 'gray', borderRadius: 2}}/>
                                        <Text 
                                            style={{
                                                fontFamily: typography.fonts.poppins.normal,
                                                fontSize: checkboxTextBreakpoint,
                                                lineHeight: 24,
                                                color: colors.palette.neutral400,
                                            }}
                                        >
                                            {brand}
                                        </Text>
                                    </HStack> )
                                })}
                                                    
                            </VStack>
                        </VStack>
                        {/* PRODUCTS SECTION */}                        
                        <VStack w="80%" p={{base: 4, md: 5, lg: 10}} pb={100} space={5} 
                            background={colors.palette.primary100}
                            justifyContent="left"
                            >
                            {/* go back */}
                            <HStack space={2} alignItems="center" justifyContent='space-between'>
                                <HStack space={2} alignItems="center" >
                                    <Pressable onPress={() => navigation.goBack()}>
                                        <Ionicons name="arrow-back" size={goBackArrowSizeBreakpoint} color={colors.palette.neutral600}/>
                                    </Pressable>
                                    <Text 
                                    style={{
                                        fontFamily: typography.fonts.poppins.bold,
                                        fontSize: productsHeadingBreakpoint,
                                        lineHeight: 24,
                                        color: colors.palette.neutral700,
                                    }}
                                    >
                                        Products 
                                        {
                                        data?.length > 0 && <Text 
                                        style={{
                                            fontFamily: typography.fonts.poppins.semiBold,
                                            fontSize: productsHeadingBreakpoint,
                                            lineHeight: 24,
                                            color: colors.palette.neutral400,
                                        }}
                                        > ({data?.length}) </Text>
                                        }
                                    </Text> 
                                </HStack>                                
                            </HStack>
                            {!overlayVisible && 
                                <HStack 
                                    w="100%" flexWrap={'wrap'}>
                                    {data?.map((medicine,index)=>{
                                    return (
                                        <Box key={`authHome-allProduct-Section-card-${index}`} width={medicineCardWidthBreakpoint} mr={3} marginBottom={3}>
                                            <MedicineCard
                                                medicinePhoto={medicine?.thumbnail}
                                                medicineImageMaxHeight={medicineCardMaxHeightBreakpoint}
                                                medicineImageWidth={"70%"}
                                                cardWidth={'100%'}
                                                cardHeight={medicineCardHeightBreakpoint}
                                                imageContainer={medicineCardImageBreakpoint}
                                                contentContainer={medicineCardContentCardBreakpoint}
                                                tagShow={medicine?.offers_count === 0 ? false : true}
                                                max_offer_percentage={medicine?.offers_count !== 0 ? medicine?.max_offer_percentage : 0}
                                                wishlisted={false}
                                                productId={medicine?.id}
                                                data={medicine}
                                                >                            
                                                <VStack>
                                                    <Text isTruncated style={{ fontFamily: typography.fonts.poppins.medium, marginTop: 5 }}>
                                                        {medicine?.name}
                                                    </Text>

                                                    <HStack space={5} alignItems="center">
                                                        <Text style={{ color: colors.palette.neutral300, fontSize: medicineTextBreakpoint }}>Starts from</Text>
                                                        <Text style={{ color: colors.palette.primary400, fontSize: medicineText_2Breakpoint }}>{medicine.offers_count == 0 ? 'No offer' : `Rs. ${medicine.price_range && medicine?.price_range[0]}`}</Text>
                                                    </HStack>

                                                    <HStack space={5} alignItems="center">
                                                        <Text style={{ color: colors.palette.angry100, fontSize: medicineTextBreakpoint }}>Expiry:</Text>
                                                        <Text style={{ color: colors.palette.angry100, fontSize: medicineText_2Breakpoint }}>{medicine.offers_count == 0 ? 'No offer' : medicine.expiry_date_range && medicine.expiry_date_range[0]}</Text>
                                                    </HStack>

                                                    <HStack space={5} alignItems="center">
                                                        <Text style={{ color: colors.palette.neutral300, fontSize: medicineTextBreakpoint }}>Sellers: </Text>
                                                        <Text style={{ color: colors.palette.primary400, fontSize: medicineText_2Breakpoint }}>{medicine.unique_seller_count}</Text>
                                                    </HStack>

                                                    <Button
                                                        text="View Offers"
                                                        preset="default"
                                                        onPress={() => navigation.navigate("ProductDescription", {'productId': medicine?.id})}
                                                        style={[
                                                            { backgroundColor: colors.palette.primary600 },
                                                            { borderRadius: 5 },
                                                            { width: "100%" },
                                                            { marginTop: 5, marginBottom: 10 },
                                                        ]}
                                                        pressedStyle={[
                                                            { backgroundColor: colors.palette.neutral100 },
                                                            { borderRadius: 5 },
                                                            { borderRadius: 5 },
                                                            { borderWidth: 0 },
                                                        ]}
                                                        textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
                                                        pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}
                                                    />
                                                </VStack>
                                            </MedicineCard>
                                        </Box>
                                    )
                                    })}           
                                </HStack>
                            } 
                            {!data.length && <Text style={{textAlign:'center'}}>Loading Products....</Text>}
                        </VStack>      
                                  
                    </HStack> 
                
                </Box>
                {/* Productss section */}           
            </PageLayout>
        </Screen>, //code for web and other platforms

        native : <MobileCode/>, //code for Mobile devices
      })}
    {/* Platform select close here  */}
  </>
  )
}) // observer close here 

const $root: ViewStyle = {
  flex: 1,
}
