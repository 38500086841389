import React, { FC, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../navigators"
import { AutoImage, Button, Screen} from "../../components"
import { Box, HStack, Image, Menu, Pressable, ScrollView, Stack, VStack, useBreakpointValue, Text  } from "native-base"
import { PageLayout } from "../../components/PageLayout"
import { AntDesign, Entypo, Ionicons, Octicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import { colors, typography } from "../../theme"
import { Platform } from "react-native"
import { useStores } from "../../models"
import { OverlayContext } from "../../components/OverlayProvider"
import Config from "../../config"
// import { useNavigation } from "@react-navigation/native"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Chat: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Chat" component={ChatScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const ChatScreen: FC<StackScreenProps<AppStackScreenProps, "Chat">> = observer(function ChatScreen() {
  // Pull in one of our MST stores
  const { authenticationStore:{isAuthenticated, user},
      ChatStore:{allChatsList, fetchChatList}
    } = useStores()
  const [tabName, setTabName] = useState('all')
  const [isAllChatHistory, setAllIsChatHistory] = useState(true)
  const [isBuyingChatHistory, setBuyingIsChatHistory] = useState(true)
  const [isSellingChatHistory, setSellingIsChatHistory] = useState(true)
  const tabFontSize = useBreakpointValue({base: 14, sm: 16, md: 18});
  const tabIcon = useBreakpointValue({base: 20, sm: 22, md: 24});

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  const { setOverlayVisible } = useContext(OverlayContext);


  // fetchChatList
  useEffect(()=>{
    fetchChatList(setOverlayVisible);
  },[])  


  const NoChat = ({tabName, photo, buttonText}) => {
    return(
        <VStack space={2}>
        <Image
            source={photo}
            // source={require(`../../../assets/images/chats/noMsg.png`)}
            maxWidth={520}
            style={{ width: "100%", height: Platform.select({default: 520, native: 250}) , alignSelf: 'center'}}
            resizeMode="contain"
            alt="image"
          />
  
          {tabName !== 'all' && <Button
          text={buttonText}
          preset="default"
          // onPress={() => Alert.alert("pressed")}
          style={{
            backgroundColor: colors.palette.primary600,
            borderRadius: 5,
            width: useBreakpointValue({base: '50%',md:"20%"}),
            marginTop: 5, 
            alignSelf: 'center'
          }}
          pressedStyle={{
            backgroundColor: colors.palette.neutral100,
            borderRadius: 5,
            borderWidth: 0,
        
          }}
          textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
          pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
        />}
        </VStack>
    )
  }
  
  const SingleChatCard = ({chat, photo}) => {
      const { authenticationStore:{user},
    } = useStores()

    const spaceBreakpoint = useBreakpointValue({base: 2, sm:2});
    const brandNameWidthBreakpoint = useBreakpointValue({base: '50%', sm: '60%', md: '68%', lg:"80%", xl: '85%'});
    const imageWidthBreakpoint = useBreakpointValue({base: '20%',sm: '17%',md:'8%'});

    const userIsSellerOrBuyer = user.id === chat.buyer.id ? 'buyer' : 'seller' ;
    const avatar = userIsSellerOrBuyer === 'buyer' ? chat?.seller?.avatar : chat?.buyer?.avatar
    const last_message_date = chat?.last_message?.timestamp.split('T')[0];
    const last_message_time_arr = chat?.last_message?.timestamp.split('T')[1].split(':');
    const last_message_time = `${last_message_time_arr[0]}:${last_message_time_arr[1]}`;

    return(
      <HStack space={ Platform.select({default: spaceBreakpoint, native: 1}) } ml={ Platform.select({default: 5, native: 2}) } mr={Platform.select({default: 5, native: 2}) } pl={Platform.select({default: 2, native: 1})} 
      // w={'100%'}
      style={{ 
      borderColor: colors.palette.neutral1200, 
      borderWidth: 1,
      shadowColor: 'black',
      shadowOffset: {width: 1, height: 3},
      shadowOpacity: 0.1,
      shadowRadius: 15,
      justifyContent: 'space-evenly'
      }}
       minHeight={100}>
        <Box w={Platform.select({default: imageWidthBreakpoint, native: '15%'})} alignItems='center' justifyContent='center'>
          <Image
              source={{uri : Config.MEDIA_URL + avatar}}
              // source={photo}
              // source={require(`../../../assets/images/product_description_screen/sellerPhoto.png`)}
              // maxHeight={70}
              style={{ width: Platform.select({default: 70, native: 50}), height: Platform.select({default: 70, native: 50}) , borderRadius:  Platform.select({default: 70/2 , native: 50/2}), marginLeft: 2}}
              // resizeMode="contain"
              alt="image"
            />
        </Box>
        <VStack w={Platform.select({default: brandNameWidthBreakpoint, native: '60%'})}>
          <HStack pt={2} pb={2} alignItems={'center'} justifyContent={'space-between'} >
            <Text 
              isTruncated
              style={{
                fontFamily: typography.fonts.poppins.medium,
                fontSize: Platform.select({default: 18, native: 14}),
                lineHeight: 22,
                color: colors.palette.neutral800,
              }}
              >
              {userIsSellerOrBuyer === 'buyer' ? `Seller Name : ${chat?.seller?.name}` : `Buyer Name : ${chat?.buyer?.name}`}
              
            </Text>
            <Text 
              style={{
                fontFamily: typography.fonts.poppins.light,
                fontSize: Platform.select({default: 13, native: 12}),
                lineHeight: 19.5,
                color: colors.palette.neutral400,
              }}
              >
              {/* 05:50 pm timestamp*/}
              {last_message_time}
            </Text>
          </HStack>
          <Text 
           isTruncated
            style={{
              fontFamily: typography.fonts.poppins.normal,
              fontSize: Platform.select({default: 16, native: 12}),
              lineHeight: 24,
              color: colors.palette.neutral400,
            }}
            >
              {/* Lorem Ipsum................................. */}
              Product Name: {chat[userIsSellerOrBuyer]?.product}
          </Text>
          <HStack space={1} alignItems='center'>
            <Octicons name="check" size={15} color={colors.palette.neutral400} />
            <Text 
              style={{
                fontFamily: typography.fonts.poppins.normal,
                fontSize: Platform.select({default: 16, native: 12}),
                lineHeight: 24,
                color: colors.palette.neutral400,
              }}
              >
                {/* Hello */}
                {chat?.last_message?.content}
            </Text>
          </HStack>
        </VStack>
        <Box w={ Platform.select({default: '4%', native: '6%'})} alignItems='center' justifyContent='center'>
        <Box w="90%" alignItems="center">
            <Menu w="150" trigger={triggerProps => {
            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
              <Entypo name="dots-three-vertical" size={Platform.select({default: 24, native: 18})} color={colors.palette.neutral400} />
                    {/* <HamburgerIcon /> */}
                  </Pressable>;
            }}>
              <Menu.Item>Active</Menu.Item>
              <Menu.Item>Inactive</Menu.Item>
              <Menu.Item>Delete</Menu.Item>
            </Menu>
          </Box>
        </Box>
      </HStack>
    )
  }
  
  const ChatHistoryCard = ({tabName}) => {
    const selling_chat_cards = allChatsList?.filter(chat  => chat?.seller?.id === user?.id);
    const buying_chat_cards = allChatsList?.filter(chat  => chat?.buyer?.id === user?.id);
    selling_chat_cards.length === 0 ? setSellingIsChatHistory(false) : setSellingIsChatHistory(true);
    buying_chat_cards.length === 0 ? setBuyingIsChatHistory(false) : setBuyingIsChatHistory(true);

    return(
      <VStack style={{borderColor: colors.palette.neutral1200, borderWidth: 1}}>
        <HStack p={5} alignItems={'center'} justifyContent={'space-between'} >
          <Text 
            style={{
              fontFamily: typography.fonts.poppins.bold,
              fontSize: Platform.select({default: 24, native: 18}),
              lineHeight: 28.8,
              color: colors.palette.neutral700,
            }}
            >
            All Chats
          </Text>
          <Text 
            style={{
              fontFamily: typography.fonts.poppins.bold,
              fontSize: Platform.select({default: 14, native: 12}),
              lineHeight: 25,
              color: colors.palette.accent300,
            }}
            >
            See All
          </Text>
        </HStack>
        <VStack space={2} mb={3}>
          {tabName ==='all' && allChatsList?.map((chats, index )=>{
              return(
              <Pressable key={index} onPress={()=>{navigation.navigate("OpenChat",{chatId: chats?.id})}}> 
                <SingleChatCard key={index} chat={chats} photo={require(`../../../assets/images/product_description_screen/sellerPhoto.png`)}/>
              </Pressable>
                )
          })}
          {tabName === 'selling' && selling_chat_cards?.map((chats,index)=>{
            return(
              <Pressable key={index} onPress={()=>{navigation.navigate("OpenChat",{chatId: chats?.id})}}> 
                <SingleChatCard key={index} chat={chats} photo={require(`../../../assets/images/product_description_screen/sellerPhoto.png`)}/>
              </Pressable>
            
            )
          })}

          {tabName === 'buying' && buying_chat_cards?.map((chats,index)=>{
            return(
              <Pressable key={index} onPress={()=>{navigation.navigate("OpenChat",{chatId: chats?.id})}}> 
                <SingleChatCard key={index} chat={chats} photo={require(`../../../assets/images/product_description_screen/sellerPhoto.png`)}/>
              </Pressable>
            )
          })}
        </VStack>
        
        
      </VStack>
    )
  }

  return (
  <>
    {/* divert code for web and android here  */}
    
     {Platform.select({ 
       default : 
        <Screen style={$root} preset="scroll">
          <PageLayout  variant={isAuthenticated?1:0}>
            <Stack w="100%" mb={100}>
              {/* Parent VStack */}
              <VStack space={8} ml={{base:5, sm: 8, md:20}} mr={{base:5, sm: 8, md:20}} mt={5}>
                {/* go back */}
                <HStack alignItems="center">
                  <Pressable onPress={() => navigation.goBack()}>
                    <Ionicons name="arrow-back" size={35} color={colors.palette.neutral600}/>
                  </Pressable>
                  <Text 
                    style={{
                      fontFamily: typography.fonts.poppins.bold,
                      fontSize: 24,
                      lineHeight: 28.8,
                      color: colors.palette.neutral700,
                    }}
                    >
                      Chats
                  </Text>
                </HStack>
    
                {/* Tabs all-selling-buying */}
                <HStack space={2}>
                  <Pressable onPress={()=>{setTabName('all')}}>
                    <Box w={{base: 110, sm: 150, md: 200, lg:250}} pb={2} style={{borderBottomColor: tabName === 'all' ?  colors.palette.primary300 :    colors.palette.neutral800  , borderBottomWidth: tabName === 'all' ? 2 : 0}}>
                      <Text style={{fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: tabFontSize,
                        lineHeight: 22, 
                        color: tabName === 'all' ?  colors.palette.primary300 : colors.palette.neutral800,
                        textAlign: 'center'}}>
                          ALL
                      </Text>
                    </Box>
                  </Pressable>
                  <Pressable onPress={()=>{setTabName('selling')}}>
                    <HStack space={2} w={{base: 110, sm: 150, md: 200, lg:250}} pb={2} justifyContent='center' style={{ borderBottomColor: tabName === 'selling' ?  colors.palette.primary300 : colors.palette.neutral800  , borderBottomWidth: tabName === 'selling' ? 2 : 0}}>
                      <Ionicons name="md-pricetags-outline" size={tabIcon} 
                        color={ tabName === 'selling' ?  colors.palette.primary300 : colors.palette.neutral800} />
                      <Text style={{fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: tabFontSize,
                        lineHeight: 22,
                        color: tabName === 'selling' ?  colors.palette.primary300 : colors.palette.neutral800,
                          }}>
                          SELLING
                      </Text>
                    </HStack>
                  </Pressable>
                  <Pressable onPress={()=>{setTabName('buying')}}>
                    <HStack space={2} w={{base: 110, sm: 150, md: 200, lg:250}} pb={2} justifyContent='center'  style={{borderBottomColor: tabName === 'buying' ?  colors.palette.primary300 :  colors.palette.neutral800 , borderBottomWidth: tabName === 'buying' ? 2 : 0}}>
                      <AntDesign name="shoppingcart" size={tabIcon} 
                        color={ tabName === 'buying' ?  colors.palette.primary300 : colors.palette.neutral800} />
                      <Text style={{fontFamily: typography.fonts.poppins.semiBold,
                        fontSize: tabFontSize,
                        lineHeight: 22, 
                        color: tabName === 'buying' ?  colors.palette.primary300 : colors.palette.neutral800,
                        }}>
                          BUYING
                      </Text>
                    </HStack>
                  </Pressable>
                </HStack>
    
                {/* empty screen photoes noChat-noBuyMsg-nosellMsg */}
                <Box mb={20} alignContent='center' justifyContent='center'>
                {
                  tabName === 'all' && isAllChatHistory === false ? <NoChat tabName={tabName} photo={require(`../../../assets/images/chats/noMsg.png`)} buttonText=''/> 
                : tabName === 'selling' && isSellingChatHistory === false ? <NoChat tabName={tabName} photo={require(`../../../assets/images/chats/noSellMsg.png`)} buttonText='Start Selling'/> 
                : tabName === 'buying' && isBuyingChatHistory === false ? <NoChat tabName={tabName} photo={require(`../../../assets/images/chats/noBuyMsg.png`)} buttonText='Start Messaging'/> 
    
                // if chat History available then
                : 
                  // <Pressable onPress={()=>{navigation.navigate("OpenChat",{chatId: })}}> 
                    <ChatHistoryCard tabName={tabName}/> 
                  // </Pressable>
                }
                </Box>
              </VStack>
            </Stack>
          </PageLayout>
        </Screen>, //code for web and other platforms
        
       native : <></>
        // <PageLayout  variant={1}>
        //   <ScrollView nestedScrollEnabled={true}>
        //     <Stack w="100%" bg={'white'} pl={ Platform.select({default: 3, native: 1 }) } pr={Platform.select({default: 3, native: 1})} pt={Platform.select({default: 5, native: 1})} pb={100}>
        //       {/* Parent VStack */}
        //       <VStack space={8} ml={3} mr={3} mt={3}>
        //         {/* go back */}
        //         <HStack alignItems="center">
        //           <Pressable onPress={() => navigation.goBack()}>
        //             <Ionicons name="arrow-back" size={Platform.select({default: 35, native: 25})} color={colors.palette.neutral600}/>
        //           </Pressable>
        //           <Text 
        //             style={{
        //               fontFamily: typography.fonts.poppins.semiBold,
        //               fontSize: Platform.select({default: 24, native: 18}),
        //               lineHeight: 28.8,
        //               color: colors.palette.neutral700,
        //             }}
        //             >
        //               Chats
        //           </Text>
        //         </HStack>
    
        //         {/* Tabs all-selling-buying */}
        //         <HStack space={2}>
        //           <Pressable onPress={()=>{setTabName('all')}}>
        //             <Box w={100} pb={2} style={{borderBottomColor: tabName === 'all' ?  colors.palette.primary300 :    colors.palette.neutral800  , borderBottomWidth: tabName === 'all' ? 2 : 0}}>
        //               <Text style={{fontFamily: typography.fonts.poppins.semiBold,
        //                 fontSize: 14,
        //                 lineHeight: 22, 
        //                 color: tabName === 'all' ?  colors.palette.primary300 : colors.palette.neutral800,
        //                 textAlign: 'center'}}>
        //                   ALL
        //               </Text>
        //             </Box>
        //           </Pressable>
        //           <Pressable onPress={()=>{setTabName('selling')}}>
        //             <HStack space={2} w={100} pb={2} justifyContent='center' style={{ borderBottomColor: tabName === 'selling' ?  colors.palette.primary300 : colors.palette.neutral800  , borderBottomWidth: tabName === 'selling' ? 2 : 0}}>
        //               <Ionicons name="md-pricetags-outline" size={20} 
        //                 color={ tabName === 'selling' ?  colors.palette.primary300 : colors.palette.neutral800} />
        //               <Text style={{fontFamily: typography.fonts.poppins.semiBold,
        //                 fontSize: 14,
        //                 lineHeight: 22,
        //                 color: tabName === 'selling' ?  colors.palette.primary300 : colors.palette.neutral800,
        //                   }}>
        //                   SELLING
        //               </Text>
        //             </HStack>
        //           </Pressable>
        //           <Pressable onPress={()=>{setTabName('buying')}}>
        //             <HStack space={2} w={100} pb={2} justifyContent='center'  style={{borderBottomColor: tabName === 'buying' ?  colors.palette.primary300 :  colors.palette.neutral800 , borderBottomWidth: tabName === 'buying' ? 2 : 0}}>
        //               <AntDesign name="shoppingcart" size={20} 
        //                 color={ tabName === 'buying' ?  colors.palette.primary300 : colors.palette.neutral800} />
        //               <Text style={{fontFamily: typography.fonts.poppins.semiBold,
        //                 fontSize: 14,
        //                 lineHeight: 22, 
        //                 color: tabName === 'buying' ?  colors.palette.primary300 : colors.palette.neutral800,
        //                 }}>
        //                   BUYING
        //               </Text>
        //             </HStack>
        //           </Pressable>
        //         </HStack>
    
        //         {/* empty screen photoes noChat-noBuyMsg-nosellMsg */}
        //         <Box mb={20} alignContent='center' justifyContent='center'>
        //         {
        //           tabName === 'all' && isChatHistory === false ? <NoChat tabName={tabName} photo={require(`../../../assets/images/chats/noMsg.png`)} buttonText=''/> 
        //         : tabName === 'selling' && isChatHistory === false ? <NoChat tabName={tabName} photo={require(`../../../assets/images/chats/noSellMsg.png`)} buttonText='Start Selling'/> 
        //         : tabName === 'buying' && isChatHistory === false ? <NoChat tabName={tabName} photo={require(`../../../assets/images/chats/noBuyMsg.png`)} buttonText='Start Messaging'/> 
    
        //         // if chat History available then
        //         : 
        //           <Pressable onPress={()=>{navigation.navigate("OpenChat")}}> 
        //             <ChatHistoryCard /> 
        //           </Pressable>
        //         }
        //         </Box>
        //       </VStack>

        //     </Stack>
        //   </ScrollView>
        // </PageLayout>, 
        //code for Mobile devices
      })}
    {/* Platform select close here  */}
  </>
  )
  
}) // observer close here 



const $root: ViewStyle = {
  flex: 1,
}


