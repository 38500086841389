import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { Box, HStack, Stack, Text, VStack, useBreakpointValue } from "native-base"
import { AutoImage } from "./AutoImage"
import { Button } from "./Button"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "../models"
import { AntDesign, Entypo } from "@expo/vector-icons"
import { api } from "../services/api"
import { OverlayContext } from "./OverlayProvider"
import { AlertContext } from "./AlertContext"
import { Props } from "react-native-ui-lib/src/components/button/ButtonTypes"
import Config from "../config"
import { Share } from "react-native"

export interface ProductProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>,
  product: any,
  variant?: number,
}

/**
 * Describe your component here
 */
export const Product = observer(function Product(props: ProductProps) {
  const { style } = props
  const $styles = [$container, style, $imageBox]
  const navigation = useNavigation<any>()

  const {setOverlayVisible} = React.useContext(OverlayContext)
  const {showAlert} = React.useContext(AlertContext)

  const { 
    authenticationStore:{
      isAuthenticated,user
    },
    ProductListStore:{
      fetchWishlist,isFavorite
    }
  } = useStores()

  const handleShare = async (message:string,url:string) => {
    try {
      await Share.share({
        message,
        url,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const addToWishlist = () => {    
    if(isAuthenticated){      
      if(!isFavorite(props.product.id)){
         api.apisauce.post('wishlist/', {
          "product":props.product.id,
          "user":user?.id,
        })
        .then(response =>{
          console.log(response)
        })
        .catch(error =>{console.log(error)})
        .finally(() =>{fetchWishlist(setOverlayVisible)})
      }
      else{
        api.apisauce.delete('wishlist/', {
          "product":props.product.id,
        })
        .then(response =>{console.log(response)})
        .catch(error =>{console.log(error)})
        .finally(() =>{fetchWishlist(setOverlayVisible)})
      }
    }else{
      showAlert('Error', 'Login required for this action');
    }
  }

  return (    
    <VStack 
      w={useBreakpointValue({base: '100%',sm: '44%', lg: '30%'})} space={5} m={3}
      alignItems="center"
      borderRadius={3} shadow="9"
      background={colors.palette.neutral100}
      > 
      <Box w="100%">
        <AutoImage 
          source={{uri: props.product?.thumbnail}}
          maxWidth={410} style={{width:"100%", height:275}}
        />
        {isFavorite(props.product.id) ? 
          <AntDesign 
            name="heart" 
            size={24} 
            color={colors.palette.secondary300} 
            style={{
              zIndex: 1,
              position: 'absolute',
              top:5, left:5,
            }} 
            onPress={()=>addToWishlist()}
          /> 
          : 
          <AntDesign 
            name="hearto" 
            size={24} 
            color="black" 
            style={{
              zIndex: 1,            
              position: 'absolute',
              top:5, left:5,
            }} 
            onPress={()=>addToWishlist()}
          />
        }  
        <Entypo 
          name="share" 
          size={30} 
          color="black" 
          style={{
            zIndex: 1,            
            position: 'absolute',
            bottom:5, right:5,
          }}
          onPress={()=>handleShare("Check this product", "url")}
        />
        {props.product?.max_offer_percentage && 
          <Box position="absolute" right={0} top={0} justifyContent="center" alignItems="center">        
            <AutoImage 
              source={require("../../assets/images/landing_screen/offer_tag.png")}
              maxWidth={117}                            
            />
            <Text           
              style={{
                color:colors.palette.neutral100,
                fontFamily:typography.fonts.poppins.medium,
                fontSize:14,
                lineHeight:45,
                width:"100px",
                textAlign:"center",
                position:"absolute",
                top:"0",
                right:"0"
              }}>
                Upto - {props.product?.max_offer_percentage}%
              {/* 30%-50% */}
            </Text>
          </Box>
        }    
      </Box>
      <VStack px={5} pb={5} space={3} width={'100%'}>
        <Text
          isTruncated
          style={{
            color:colors.palette.neutral800, 
            fontFamily:typography.fonts.poppins.medium,
            fontSize:16,
            lineHeight:20,
            textAlign:"left"
          }}
        >{props.product?.name}
        </Text> 
        {props.variant && props.variant === 1 && <Text
          style={{
            color:colors.palette.neutral400,
            fontFamily:typography.fonts.poppins.normal,
            fontSize:16,
            lineHeight:24,
            textAlign:"left",
            minHeight: 120
          }}
        >
          {props.product.short_description}
        </Text>}
        <Text
          style={{
            color:colors.palette.neutral400,
            fontFamily:typography.fonts.poppins.normal,
            fontSize:13,
            lineHeight:19.5,
            textAlign:"left"
          }}
        >
          Price (INR): 
          <span 
            style={{
                textDecoration:props.product?.price_range?`line-through`:'none',
                marginLeft:5,
              }}
            >
            {props.product.price_range
            ? (props.product.price_range[0]!=props.product.price_range[1]
            ?`${props.product.price_range[0]} - ${props.product.price_range[1]}`
            :`${props.product.price_range[0]}`
            ):"No offer"}
          </span>
        </Text>
       
        <Text
          style={{
            color:colors.palette.neutral400,
            fontFamily:typography.fonts.poppins.normal,
            fontSize:13,
            lineHeight:19.5,
            textAlign:"left"
          }}
        >
          Offer:
            <span 
              style={{
                color:colors.palette.primary500,
                marginLeft:5,
              }}
            >
              {props.product?.offer_price_range ? `Rs. ${props.product?.offer_price_range[0]} - ${props.product?.offer_price_range[1]}`: `No offers`}
            </span>
        </Text>
        <Text
          style={{
            color:colors.palette.angry100,
            fontFamily:typography.fonts.poppins.normal,
            fontSize:13,
            lineHeight:19.5,
            textAlign:"left"
          }}
        >
          Expiry: 
            <span 
              style={{
                marginLeft:5,
              }}
            >
              {props.product?.expiry_month_range? `${props.product?.expiry_month_range[0]} - ${props.product?.expiry_month_range[1]} Months`: `No offers`}
            </span>
        </Text>
        <Text
          style={{
            color:colors.palette.neutral400,
            fontFamily:typography.fonts.poppins.normal,
            fontSize:13,
            lineHeight:19.5,
            textAlign:"left"
          }}
        >
          Sellers: {props.product?.unique_seller_count || 0}
        </Text>
        <Stack direction={{base: 'column', md: 'row'}} alignItems="left" space={3}>
          <Button
            text="View Offers"
            preset="default"
            onPress={() => navigation.navigate("ProductDescription", {'productId': props.product?.id})}          
            style={[{ backgroundColor: colors.palette.primary600}, { borderRadius: 5 }, {width:"100%"}]}
            pressedStyle={[{ backgroundColor: colors.palette.neutral100 }, { borderRadius: 5 }]}
            textStyle={[{ fontSize: 14 }, { color: colors.palette.neutral100 }]}
            pressedTextStyle={[{ fontSize: 14 }, { color: colors.palette.primary600 }]}            
          />
        </Stack>
      </VStack>
    </VStack>
  )
})

const $container: ViewStyle = {
  padding:2,
  justifyContent: "center",
  backgroundColor:  colors.palette.neutral100,
  overflow: 'hidden',
  shadowColor: 'black',
  shadowOffset: {width: 1, height: 2},
  shadowOpacity: 0.1,
  shadowRadius: 15,
  minWidth:350,
}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}

const $imageBox: ViewStyle = {
  margin:2
}
