import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Platform, TouchableWithoutFeedback, ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../../../../navigators"
import { Button, Screen, Text } from "../../../../components"
import { PageLayout } from "../../../../components/PageLayout"
import { Box, HStack, KeyboardAvoidingView, Pressable, ScrollView, Stack, Switch, VStack } from "native-base"
import { colors, typography } from "../../../../theme"
import { Keyboard } from "react-native"
import { useNavigation } from "@react-navigation/native"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"
//imports for mobile----->
import Constants from 'expo-constants';
import { Ionicons } from "@expo/vector-icons"
import { InputField } from "../../../../components/InputField"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `BusinessSettingMobileScreen: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="BusinessSettingMobileScreen" component={BusinessSettingMobileScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const SecuritySettingMobileScreen: FC<StackScreenProps<AppStackScreenProps, "SecuritySettingMobile">> = observer(function SecuritySettingMobileScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const [enableShift_80,setEnableShift_80] = useState(50)
  const [enableShift_350,setEnableShift_350] = useState(50)
  const [currentPassword,setCurrentPassword] = useState('')
  const [newPassword,setNewPassword] = useState('')
  const [repeatPassword,setRepeatPassword] = useState('')
  const [switchPublicProfile, setSwitchPublicProfile] = useState(false);
  const [switchExposeEmail, setSwitchExposeEmail] = useState(false);
  
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  // const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', (e) => {
      setKeyboardOpen(true);
      // setKeyboardHeight(e.endCoordinates.height);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', (e) => {
      setKeyboardOpen(false);
      // setKeyboardHeight(0);
    });

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  // Pull in navigation via hook
  const navigation = useNavigation<any>()
  return (
    <>
      {/* divert code for web and android here  */}
       {Platform.select({ 
          default : <></> , //code for web and other platforms
          
          native : 
            <PageLayout  variant={1}>
              <ScrollView style={{backgroundColor: colors.palette.primary700}}>
                {/* <KeyboardAvoidingView behavior={"position"} contentContainerStyle={{flex: 1}} style={{flex:1}} enabled={enableShift}> 
                  <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={{flex:1}}>  */}
                   
                    {/* parent stack */}
                    <VStack bg={colors.palette.primary300} w="100%" h="100%" paddingBottom={50}>
                      <Box minH={100} ></Box>
                        
                      {/* get started section */}
                      <VStack bg={'white'} space={6} p={5} pt={10} pb={10} style={{borderTopLeftRadius: 70, borderTopRightRadius: 70}} 
                        paddingBottom={Platform.select({ios: isKeyboardOpen ? enableShift_350 : 70 , android: isKeyboardOpen ? enableShift_80 : 60})}>
                        <VStack space={2}>
                          {/* go back section */}
                          <HStack space={3} alignItems={'center'}>
                            <Pressable onPress={() => navigation.goBack()}>
                              <Ionicons name="arrow-back" alt="icon" size={30} color={colors.palette.primary300}/>
                            </Pressable>
                            <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.bold,
                                fontSize: 20,
                                lineHeight: 28.8,
                                color: colors.palette.primary300,
                              }}
                              >
                                {/* {sellerName} */}
                                Security Settings
                            </Text>
                          </HStack>
                          <Text 
                            style={{
                              fontFamily: typography.fonts.poppins.normal,
                              fontSize: 14,
                              lineHeight: 24,
                              color: colors.palette.neutral300,
                            }}
                            >
                              Change account information and settings
                          </Text>
                        </VStack>
                        
                        {/* Security settings input fields */}
                        <VStack w={'100%'} space={8}>
                          {/* heading */}
                          <VStack space={2}>
                            <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.semiBold,
                                fontSize: 14,
                                lineHeight: 28.8,
                                color: colors.palette.neutral700,
                              }}
                              >
                                Change your password
                            </Text>
                          </VStack>
                          
                          {/* input fileds */}
                          <VStack space={{base: 4,md:7}}>
          
                              <Box w={'100%'}>
                                <InputField
                                  key="Current Password"
                                  value={currentPassword}              
                                  onChangeText={(value) => setCurrentPassword(value)}
                                  label="Current Password"
                                  placeholder="xxxxxxxxxx"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                /> 
                              </Box>
                              <Box w={'100%'}>
                                <InputField
                                  key="New Password"
                                  value={newPassword}              
                                  onChangeText={(value) => setNewPassword(value)}
                                  label="New Password"
                                  placeholder="xxxxxxxxx"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                /> 
                              </Box>
          
                              <Box w={'100%'}>
                                <InputField
                                  key="Repeat Password"
                                  value={repeatPassword}              
                                  onChangeText={(value) => setRepeatPassword(value)}
                                  label="Repeat Password"
                                  placeholder="xxxxxxxxxx"
                                  // status="error"
                                  // helper="Enter your name"
                                  // RightAccessory={() => <Icon icon="check" />}
                                  // LeftAccessory={() => <Icon icon="bell" />}
                                  onFocus={()=>{setEnableShift_350(350); setEnableShift_80(60)}}
                                /> 
                              </Box>
                          </VStack>
          
                          {/* save button */}
                          <Button
                              text='Save'
                              preset="default"
                              // onPress={() => Alert.alert("pressed")}
                              style={{
                                backgroundColor: colors.palette.primary600,
                                borderRadius: 5,
                                width: "40%",
                              }}
                              pressedStyle={{
                                backgroundColor: colors.palette.neutral100,
                                borderRadius: 5,
                                borderWidth: 0,
                            
                              }}
                              textStyle={{ fontSize: 14, color: colors.palette.neutral100 }}
                              pressedTextStyle={{ fontSize: 14, color: colors.palette.primary600 }}
                            />
                      
                          <HStack space={2}>
                            <Switch value={switchPublicProfile} onValueChange={() => {setSwitchPublicProfile(!switchPublicProfile)}}/>
                            <Text style={{color: colors.palette.neutral400}}>Public Profile</Text>
                          </HStack>
                          <HStack space={2}>
                            <Switch value={switchExposeEmail} onValueChange={() => {setSwitchExposeEmail(!switchExposeEmail)}}/>
                            <Text style={{color: colors.palette.neutral400}}>Expose your email</Text>
                          </HStack>
          
                          <Text 
                              style={{
                                fontFamily: typography.fonts.poppins.normal,
                                fontSize: 16,
                                lineHeight: 24,
                                color: colors.palette.neutral400,
                              }}
                              >
                                You may also consider to update your <Text style={{color: colors.palette.secondary300}}> notification settings.</Text> 
                            </Text>
                        </VStack>
                      </VStack>
                        
                    </VStack>
                  {/* </TouchableWithoutFeedback>
                </KeyboardAvoidingView> */}
              </ScrollView>
          </PageLayout>, //code for Mobile devices
        })}
      {/* Platform select close here  */}
    </>
    )
})

const $root: ViewStyle = {
  flex: 1,
}
